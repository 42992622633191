import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./widget.css";
import CardGiftcardSharp from "@material-ui/icons/CardGiftcardSharp";
import FlightHotel from "./flightHotel.jsx";
import BundaAndSave from "./bundleAndSave.jsx";
import FlightPackage from "./flightPackage";
import MultiCity from "./multiCity";

import { isBrowser, isMobile } from "react-device-detect";
//widget tabs adult buttons
class Widget extends Component {
  constructor(props) {
    super(props);
    this.FlightHotel = React.createRef();
    this.FlightPackage = React.createRef();
    var pathname = window.location.pathname;
    this.state = {
      pathname: pathname,
    };

    // this.firstTab ="FlightHotel";
    this.firstTab = "FlightPackage";
    this.secondTab = "RoundTrip";
    if (this.props.searchFlightAgain != null) {
      var selectedPreference = localStorage.getItem("selectedPreference");
      var searchObj = JSON.parse(localStorage.getItem("searchRequestObj"));
      if (selectedPreference == "package") {
        this.firstTab = "FlightPackage";
        if (searchObj.itineraryParts.length == 1) {
          this.secondTab = "OneWay";
        } else if (searchObj.itineraryParts.length == 2) {
          this.secondTab = "RoundTrip";
        } else {
          this.secondTab = "MultiCity";
        }
      } else if (selectedPreference == "bundle") {
        this.firstTab = "BundaAndSave";
        if (searchObj.itineraryParts.length == 1) {
          this.secondTab = "OneWay";
        } else if (searchObj.itineraryParts.length == 2) {
          this.secondTab = "RoundTrip";
        }
      } else {
        if (searchObj.itineraryParts.length == 1) {
          this.secondTab = "OneWay";
        } else if (searchObj.itineraryParts.length == 2) {
          this.secondTab = "RoundTrip";
        }
      }
    }
  }
  oneWayJourneyType = () => {};
  scrollWidget() {
    if (window.screen.width > 1200) {
      if (window.scrollY < 400 || window.scrollY > 600) {
        window.scrollTo(0, 300);
      }
    }
  }
  componentDidMount = () => {
    console.clear();
  };
  render() {
    return (
      <>
        {this.state.pathname == "/flight-hotel" && (
          <>{true && <div className="topwidgetmirgin"></div>}</>
        )}
        <div
          className="container widget-container"
          onClick={() => this.scrollWidget()}
        >
          <div className="form-all-widget pt-0">
            <Tabs
              defaultActiveKey={this.firstTab}
              transition={false}
              style={{ backgroundColor: "#fff" }}
            >
              {/*<Tab*/}
              {/*  tabClassName="booking-option-tab parent"*/}
              {/*  eventKey="FlightHotel"*/}
              {/*  title={*/}
              {/*    <span id="flight-hotel">*/}
              {/*      <span>*/}
              {/*        <span className="et-icons flight-icon"></span>{" "}*/}
              {/*        <span>Flight</span>*/}
              {/*      </span>{" "}*/}
              {/*      +{" "}*/}
              {/*      <span>*/}
              {/*        <span className="et-icons hotel-icon "> </span>{" "}*/}
              {/*        <span>Hotel</span>*/}
              {/*      </span>*/}
              {/*    </span>*/}
              {/*  }*/}
              {/*>*/}
              {/*  <Tabs*/}
              {/*    defaultActiveKey={this.secondTab}*/}
              {/*    transition={false}*/}
              {/*    style={{ backgroundColor: "#fff" }}*/}
              {/*  >*/}
              {/*    <Tab*/}
              {/*      eventKey="OneWay"*/}
              {/*      title={<span id="flight-hotel-OW">One Way</span>}*/}
              {/*      tabClassName="journey-type-option"*/}
              {/*    >*/}
              {/*      <FlightHotel*/}
              {/*        journeyType="Hotel Check Out"*/}
              {/*        searchFlightAgain={this.props.searchFlightAgain}*/}
              {/*      />*/}
              {/*    </Tab>*/}
              {/*    <Tab*/}
              {/*      eventKey="RoundTrip"*/}
              {/*      title={<span id="flight-hotel-RT">Round Trip</span>}*/}
              {/*      tabClassName="journey-type-option"*/}
              {/*    >*/}
              {/*      <FlightHotel*/}
              {/*        journeyType="Return Date"*/}
              {/*        searchFlightAgain={this.props.searchFlightAgain}*/}
              {/*      />*/}
              {/*    </Tab>*/}
              {/*  </Tabs>*/}
              {/*</Tab>*/}

              <Tab
                tabClassName="booking-option-tab  parent"
                eventKey="FlightPackage"
                title={
                  <span
                    id="flight-package"
                    onClick={() => {
                      localStorage.setItem(
                        "tourPreference",
                        "flightPlusPackage"
                      );
                    }}
                  >
                    <span>
                      <span className="et-icons flight-icon"></span>{" "}
                      <span> Flight</span>{" "}
                    </span>
                    +
                    <span>
                      {" "}
                      Tour Packages{" "}
                    </span>
                  </span>
                }
              >
                <Tabs
                  defaultActiveKey={this.secondTab}
                  transition={false}
                  style={{ backgroundColor: "#fff" }}
                >
                  <Tab
                    eventKey="OneWay"
                    title={<span id="flight-package-OW">One Way</span>}
                    tabClassName="journey-type-option"
                  >
                    <FlightPackage
                      journeyType="Validity"
                      searchFlightAgain={this.props.searchFlightAgain}
                    />
                  </Tab>
                  <Tab
                    eventKey="RoundTrip"
                    title={<span id="flight-package-RT">Round Trip</span>}
                    tabClassName="journey-type-option"
                  >
                    <FlightPackage
                      journeyType="Return Date"
                      searchFlightAgain={this.props.searchFlightAgain}
                    />
                  </Tab>
                  <Tab
                    eventKey="MultiCity"
                    title={<span id="Multicity">Multi City </span>}
                    tabClassName="journey-type-option"
                  >
                    <MultiCity
                      searchFlightAgain={this.props.searchFlightAgain}
                    />
                  </Tab>
                </Tabs>
              </Tab>
              {/* <Tab
              tabClassName="booking-option-tab parent"
              eventKey="BundaAndSave"
              title={
                <span>
                  <span className="pr-2">
                    <span className="et-icons flight-icon"></span>
                    <span>Bundle and Save</span>
                  </span>
                </span>
              }
            >
              <Tabs
                defaultActiveKey={this.secondTab}
                transition={false}
                style={{ backgroundColor: "#fff" }}
              >
                <Tab
                  eventKey="OneWay"
                  title={<span>One Way</span>}
                  tabClassName="journey-type-option"
                >
                  <BundaAndSave journeyType="Hotel Check Out" searchFlightAgain={this.props.searchFlightAgain} />
                  </Tab>
                <Tab
                  eventKey="RoundTrip"
                  title={<span>Round Trip</span>}
                  tabClassName="journey-type-option"
                > 
                <BundaAndSave journeyType="Return Date" searchFlightAgain={this.props.searchFlightAgain}/>
                </Tab>
              </Tabs>
            </Tab>         */}
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}
/*

*/

export default Widget;
