export default function swDev(){
    // let swUrl=`${process.env.PUBLIC_URL}/serviceworker.js`
    let swUrl=`${process.env.PUBLIC_URL}/sw.js`
    navigator.serviceWorker.register(swUrl).then((resp)=>{
    console.warn("response",resp)
})
}


// if('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker.register('./serviceworker.js')
//             .then((reg) => console.log('Success: ', reg.scope))
//             .catch((err) => console.log('Failure: ', err));
//     })
// }