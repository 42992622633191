import React, { Component } from "react";
import { DataService } from "../../services/shared-services/dataServices";
import ShoppingCart from "../ShoppingCart/shopping-cart";
import { withRouter } from "react-router-dom";
import { Row } from "react-bootstrap";
import "./flightalternative.css";

import FlightAlternative from "./flight-alternative";
import { isMobile } from "react-device-detect";
//#12575 change flight option on availability
class FlightHeader extends Component {
  constructor(props) {
    super(props);
    // console.log("alternateDayResponse header 2",props)
    this.state = {
      showWidget: false,
      departureAirport: "",
      arrivalAirport: "",
      isFlightSearchRequestNull: false,
      isOneWay: false,
      itineraryParts: null,
    };
  }
  async componentDidMount() {
    let flightSearchRequest = null;
    //debugger
    let test = localStorage.getItem("searchRequestObj");
    if (
      localStorage.getItem("searchRequestObj") != null &&
      localStorage.getItem("searchRequestObj") != "undefined"
    ) {
      flightSearchRequest = JSON.parse(
        localStorage.getItem("searchRequestObj")
      );
    }

    if (flightSearchRequest === null) {
      await this.setState({ isFlightSearchRequestNull: true });
      this.props.history.push({
        pathname: "/",
      });
    } else {
      await this.setState({ isFlightSearchRequestNull: false });

      let itineraryParts = [];
      itineraryParts = flightSearchRequest.itineraryParts;

      await this.setState({
        itineraryParts: itineraryParts,
        isOneWay: itineraryParts.length == 1 ? true : false,
      });

      if (itineraryParts.length > this.props.flightIndex) {
        await this.setState({
          departureAirport: DataService.getFullCityName(
            itineraryParts[this.props.flightIndex].from.code
          ),
        });
        await this.setState({
          arrivalAirport: DataService.getFullCityName(
            itineraryParts[this.props.flightIndex].to.code
          ),
        });
      }
    }
  }

  getSelectedBrandInfoShoppingCode = (
    shoppingCode,
    arrivalDate,
    departDate
  ) => {
    //debugger // 1 header page

    this.props.onSearchContextClickedHeader(
      shoppingCode,
      arrivalDate,
      departDate
    );
  };

  render() {
    if (this.state.isFlightSearchRequestNull) {
      return <div></div>;
    }
 


    let isMulticity = localStorage.getItem("isMulticity");
    return ( 
        <div className=" pb-1">
         <div className={isMobile?"shoppingCartPositionMobile":"shoppingCartPosition"} >
                  <ShoppingCart id={"flight-" + this.props.flightIndex} pageIndex={1}/>
        </div>
          {/* <Row className="ml-1  order-md-2 ">
            </Row> */}
          <Row className="ml-1">
            {isMulticity != "yes" && (
              <h6
                className="font-weight-bold"
                style={{
                  fontFamily: "Roboto, HelveticaNeue, Arial, sans-serif",
                }}
              >
                Select your flight from{" "}
                <span style={{ color: "#60ac5d" }}>
                  {this.state.departureAirport}
                </span>{" "}
                to{" "}
                <span style={{ color: "#60ac5d" }}>
                  {this.state.arrivalAirport}
                </span>
              </h6>
            )}
          </Row>
          <Row>
            <FlightAlternative
              itineraryParts={this.state.itineraryParts}
              isOneWay={this.state.isOneWay}
              flightalternateDay={this.props.flightalternateDay}
              flightIndex={this.props.flightIndex}
              // onSearchContextClicked={this.props.onSearchContextClicked}
              // onSearchContextClickedHeader={this.props.onSearchContextClicked}
              getSelectedBrandShoppingCode={
                this.getSelectedBrandInfoShoppingCode
              }
            />
          </Row>
        </div>
     
    );
  }
}

export default withRouter(FlightHeader);
