import React, { Component } from "react";
import "../payment-summery/paymentSummery";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap"; 
class LocalPaymentSummery extends Component {
  constructor(props) {
    super(props);
  } 
  async componentDidMount() {
    this.getLocalSummeryDetails();
  }

  getLocalSummeryDetails() {
    let summaryDetails = JSON.parse(localStorage.getItem("summaryDetail"));
    if (summaryDetails) {
      var num = summaryDetails.floCashOrderResponse.order.instruction;
      var n = num.toString();
      document.getElementById("demo").innerHTML = n;
    } else {
      var num = `<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Dear ATOENA MR WEYZERO, </span></span></p><p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">This is to inform you that you have a pending payment. </span></span></p><p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Go to nearest UBE branch office, pay amount 28738.00 to account no. 1421816162332018. </span></span></p><p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Give the FloCash Reference 61414178 to teller. </span></span></p><p><span style="color:#ff0000"><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Important Note: Please make sure that you include reference above so that we can process your payment correctly and without delay. To secure your booking please ensure you make the exact payment within 24 hours of the booking date. Payments for bookings after 24 hours from booking date may not be processed and you will have to make a new booking, which can be subject to availability and a change in price FloCash Customer Service. </span></span></span></p>`;
      var n = num.toString();
      document.getElementById("demo").innerHTML = n;
    }
  }

  render() {
    return (
      <div className="p-2 container pt-0 text-center ">
        <h4 className="text-center pt-0 pb-0">Payment Instruction </h4>
        <div class="ml-2  row" style={{ textAlign: "left" }}>
          <p>Thank you for your booking! </p>
          <p>
            {/* *ngIf="TourTypePreference == 'flightPlusHotel'" */}
            Your travel ticket and hotel booking are now ready. Please check
            your E-mail for more information.
          </p>
          {/* *ngIf="TourTypePreference == 'flightPlusPackage'" */}
          {/* <p> 
      Your travel ticket and package are now ready. Please check your E-mail for more information.
    </p> */}
          <p>
            For any further information please contact us via E-mail: 
            ETHolidays@ethiopianairlines.com or Phone: +251 116179900
          </p>
        </div>

        <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
          <Card.Header
            style={{ textAlign: "left" }}
            className="d-flex p-0 pl-3"
          >
            <h5>Payment Instruction</h5>{" "}
          </Card.Header>
          <Card.Body
            style={{ textAlign: "left" }}
            id="demo"
            className=""
          ></Card.Body>
        </Card>
        <br />

        <Row className="pl-5">
          <Col style={{ textAlign: "right" }} sm={12}>
            <Link to={"/"}>
              <button type="button" class="btn btn-success btn-sm">
                <strong className="pr-5 pl-5 "> Back Home</strong>
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LocalPaymentSummery;
