import React, { Component } from "react";
import "./passenger-information.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AdultInput from "./adult-input";
import ChildInput from "./child-input";
import PassengerInforamtion from "../../models/passenger/passengerInformation";
import Passenger from "../../models/passenger/passenger";
import InfantInput from "./infant-input";
import { HttpService } from "../../services/http/http-service";
import { Redirect } from "react-router-dom";
import { FlierOption } from "../shared/master-data/passenger-info/flier-option";
import { MealOption } from "../shared/master-data/passenger-info/meal-option";
import { SpecialRequetOption } from "../shared/master-data/passenger-info/special-request-opetion";
import PassengerList from "../../models/passenger/passenger-list";
import { Form } from "react-bootstrap";
import ProgressLoader from "../shared/progress-loading/progress-loader";
import Modal from "react-bootstrap/Modal";
import HotelBooking from "../hotel/hotel-booking";
import ShoppingCart from "../ShoppingCart/shopping-cart";
import { MobileView, BrowserView } from "react-device-detect";
import BreadCrumb from "../shared/breadcrumb/breadcrumb";
import FareRule from "../shared/fareRule/fareRule";
import { OverlayTrigger, Alert, Tooltip } from "react-bootstrap";

class PassengerInfo extends Component {
    constructor(props) {
        super(props);
        let tourPreference = localStorage.getItem("tourPreference");
        this.state = {
            agreePromotion: false,
            tourPreference: tourPreference,
            dateOfBirth: null,
            foucsed: false,
            showFlier: false,
            showRequest: false,
            additionaAdultlInput: [],
            additionalChildInput: [],
            additionalInfantInput: [],
            firstLoad: true,
            errorMessage: null,
            isLoading: false,
            redirectToPayment: false,
            redirectToHome: false,
            modalBody: "",
            passengerError: false,
        };

        this.searchRequestObj = JSON.parse(
            localStorage.getItem("searchRequestObj")
        );
        this.numberOfAdult = this.searchRequestObj.passengers.ADT;
        this.numberOfChild = this.searchRequestObj.passengers.CHD;
        this.numberOfInfant = this.searchRequestObj.passengers.INF;
        this.PassengerInformation = new PassengerInforamtion();
        this.SessionId = localStorage.getItem("sessionId");
        this.passengerList = new PassengerList();
    }
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    showFlier = () => {
        let temp = !this.state.showFlier;
        this.setState({ showFlier: temp });
    };
    showRequest = () => {
        let temp = !this.state.showRequest;
        this.setState({ showRequest: temp });
    };
    save = (event) => {
        this.PassengerInformation = new PassengerInforamtion();
        event.preventDefault();

        let formIsValid = true;
        for (let i = 0; i < this.numberOfAdult; i++) {
            if (i === 0) {
                if (
                    document.getElementById(`adult_phone_${i}`).value == "" ||
                    document.getElementById(`adult_phone_${i}`).value.length < 10 ||
                    document.getElementById(`adult_phone_${i}`).value == null
                ) {
                    document
                        .getElementById(`adult_phone_${i}`)
                        .classList.add("is-invalid");
                    formIsValid = false;
                } else {
                    document.getElementById(`adult_phone_${i}`).classList.add("is-valid");
                }
                if (document.getElementById(`adult_email_${i}`).value == "") {
                    document
                        .getElementById(`adult_email_${i}`)
                        .classList.add("is-invalid");
                    formIsValid = false;
                } else {
                    if (
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                            document.getElementById(`adult_email_${i}`).value
                        )
                    ) {
                        document
                            .getElementById(`adult_email_${i}`)
                            .classList.add("is-valid");
                    } else {
                        document
                            .getElementById(`adult_email_${i}`)
                            .classList.add("is-invalid");
                        formIsValid = false;
                    }
                }
            }
            if (document.getElementById(`adult_first_name_${i}`).value == "") {
                document
                    .getElementById(`adult_first_name_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document
                    .getElementById(`adult_first_name_${i}`)
                    .classList.add("is-valid");
            }

            if (document.getElementById(`adult_last_name_${i}`).value == "") {
                document
                    .getElementById(`adult_last_name_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document
                    .getElementById(`adult_last_name_${i}`)
                    .classList.add("is-valid");
            }
            if (document.getElementById(`adult_title_${i}`).value == "") {
                document.getElementById(`adult_title_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`adult_title_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`adult_day_${i}`).value == "") {
                document.getElementById(`adult_day_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`adult_day_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`adult_month_${i}`).value == "") {
                document.getElementById(`adult_month_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`adult_month_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`adult_year_${i}`).value == "") {
                document.getElementById(`adult_year_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`adult_year_${i}`).classList.add("is-valid");
            }

            if (document.getElementById(`adult_gender_${i}`).value == "") {
                document
                    .getElementById(`adult_gender_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`adult_gender_${i}`).classList.add("is-valid");
            }
        }
        for (let i = 1; i <= this.numberOfChild; i++) {
            if (document.getElementById(`child_first_name_${i}`).value == "") {
                document
                    .getElementById(`child_first_name_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document
                    .getElementById(`child_first_name_${i}`)
                    .classList.add("is-valid");
            }
            if (document.getElementById(`child_last_name_${i}`).value == "") {
                document
                    .getElementById(`child_last_name_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document
                    .getElementById(`child_last_name_${i}`)
                    .classList.add("is-valid");
            }
            if (document.getElementById(`child_day_${i}`).value == "") {
                document.getElementById(`child_day_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`child_day_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`child_month_${i}`).value == "") {
                document.getElementById(`child_month_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`child_month_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`child_year_${i}`).value == "") {
                document.getElementById(`child_year_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`child_year_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`child_gender_${i}`).value == "") {
                document
                    .getElementById(`child_gender_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`child_gender_${i}`).classList.add("is-valid");
            }
        }
        for (let i = 1; i <= this.numberOfInfant; i++) {
            if (document.getElementById(`infant_first_name_${i}`).value == "") {
                document
                    .getElementById(`infant_first_name_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document
                    .getElementById(`infant_first_name_${i}`)
                    .classList.add("is-valid");
            }
            if (document.getElementById(`infant_last_name_${i}`).value == "") {
                document
                    .getElementById(`infant_last_name_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document
                    .getElementById(`infant_last_name_${i}`)
                    .classList.add("is-valid");
            }
            if (document.getElementById(`infant_day_${i}`).value == "") {
                document.getElementById(`infant_day_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`infant_day_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`infant_month_${i}`).value == "") {
                document
                    .getElementById(`infant_month_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`infant_month_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`infant_year_${i}`).value == "") {
                document.getElementById(`infant_year_${i}`).classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`infant_year_${i}`).classList.add("is-valid");
            }
            if (document.getElementById(`infant_gender_${i}`).value == "") {
                document
                    .getElementById(`infant_gender_${i}`)
                    .classList.add("is-invalid");
                formIsValid = false;
            } else {
                document.getElementById(`infant_gender_${i}`).classList.add("is-valid");
            }
        }

        //debugger
        if (formIsValid) {
            this.setState({ errorMessage: null, isLoading: true });
            let indexCount = 1;
            for (let i = 0; i < this.numberOfAdult; i++) {
                let temp = new Passenger();
                //
                temp.params.id = indexCount;
                temp.params.passengerIndex = indexCount;
                indexCount++;
                if (i == 0) {
                    let phone = document.getElementById(`adult_phone_${i}`).value;
                    let email = document.getElementById(`adult_email_${i}`).value;
                    let phoneTemp = phone.split(" ");

                    this.PassengerInformation.params.contact.emails.push(email);
                    this.PassengerInformation.params.contact.phones[0].countryCode =
                        phoneTemp[0].replace("+", "");
                    this.PassengerInformation.params.contact.phones[0].areaCode =
                        phoneTemp[0].replace("+", "");
                    phoneTemp.shift();
                    this.PassengerInformation.params.contact.phones[0].number =
                        phoneTemp.join("");
                    this.PassengerInformation.params.contact.phones[0].number =
                        this.PassengerInformation.params.contact.phones[0].number.replace(
                            ")",
                            ""
                        );
                    this.PassengerInformation.params.contact.phones[0].number =
                        this.PassengerInformation.params.contact.phones[0].number.replace(
                            "(",
                            ""
                        );
                    this.PassengerInformation.params.contact.phones[0].number =
                        this.PassengerInformation.params.contact.phones[0].number.replace(
                            "-",
                            ""
                        );
                    this.PassengerInformation.params.contact.phones[0].number =
                        this.PassengerInformation.params.contact.phones[0].number.replace(
                            " ",
                            ""
                        );
                }
                temp.params.passengerDetails.firstName = document.getElementById(
                    `adult_first_name_${i}`
                ).value;
                temp.params.passengerDetails.lastName = document.getElementById(
                    `adult_last_name_${i}`
                ).value;
                temp.params.passengerDetails.prefix = document.getElementById(
                    `adult_title_${i}`
                ).value;
                if (document.getElementById(`adult_middle_name_${i}`).value == "") {
                    temp.params.passengerDetails.middleName = null;
                } else {
                    temp.params.passengerDetails.middleName = document.getElementById(
                        `adult_middle_name_${i}`
                    ).value;
                }
                temp.params.passengerInfo.dateOfBirth =
                    document.getElementById(`adult_year_${i}`).value +
                    "-" +
                    document.getElementById(`adult_month_${i}`).value +
                    "-" +
                    document.getElementById(`adult_day_${i}`).value;
                var meal = document.querySelector(
                    "#" + `adult_meal_${i}` + "  div.css-1uccc91-singleValue"
                );
                temp.params.passengerInfo.gender = document.getElementById(
                    `adult_gender_${i}`
                ).value;
                temp.params.passengerInfo.type = "ADT";

                var specialRequest = document.querySelectorAll(
                    "#" + `adult_special_${i}` + "  div.css-1rhbuit-multiValue"
                );

                if (specialRequest != null) {
                    specialRequest.forEach(function (item) {
                        SpecialRequetOption.getAll.forEach((element) => {
                            if (element.label == item.textContent) {
                                temp.params.preferences.specialPreferences.specialRequests.push(
                                    element.value
                                );
                            }
                        });
                    });
                }
                if (meal != null) {
                    MealOption.getAll.forEach((element) => {
                        if (element.label == meal.textContent) {
                            temp.params.preferences.specialPreferences.mealPreference =
                                element.value;
                        }
                    });
                }
                var flier = document.querySelector(
                    "#" + `adult_flier_${i}` + "  div.css-1uccc91-singleValue"
                );
                if (flier != null) {
                    FlierOption.getAll.forEach((element) => {
                        if (element.label == flier.textContent) {
                            var tempValue = {
                                airline: element.value,
                                number: document.getElementById(`adult_flier_number_${i}`)
                                    .value,
                            };
                            temp.params.preferences.frequentFlyer.push(tempValue);
                        }
                    });
                }
                this.passengerList.adultList.push({
                    firstName: temp.params.passengerDetails.firstName,
                    lastName: temp.params.passengerDetails.lastName,
                });
                this.PassengerInformation.params.passengers.push(temp.params);
            }
            for (let i = 1; i <= this.numberOfChild; i++) {
                let temp = new Passenger();
                //
                temp.params.id = indexCount;
                temp.params.passengerIndex = indexCount;
                indexCount++;
                temp.params.passengerDetails.firstName = document.getElementById(
                    `child_first_name_${i}`
                ).value;
                temp.params.passengerDetails.lastName = document.getElementById(
                    `child_last_name_${i}`
                ).value;
                if (document.getElementById(`child_middle_name_${i}`).value == "") {
                    temp.params.passengerDetails.middleName = null;
                } else {
                    temp.params.passengerDetails.middleName = document.getElementById(
                        `child_middle_name_${i}`
                    ).value;
                }
                temp.params.passengerInfo.dateOfBirth =
                    document.getElementById(`child_year_${i}`).value +
                    "-" +
                    document.getElementById(`child_month_${i}`).value +
                    "-" +
                    document.getElementById(`child_day_${i}`).value;
                temp.params.passengerInfo.type = "CHD";
                temp.params.passengerInfo.gender = document.getElementById(
                    `child_gender_${i}`
                ).value;
                var meal = document.querySelector(
                    "#" + `child_meal_${i}` + "  div.css-1uccc91-singleValue"
                );
                var specialRequest = document.querySelectorAll(
                    "#" + `child_special_${i}` + "  div.css-1rhbuit-multiValue"
                );

                if (specialRequest != null) {
                    specialRequest.forEach(function (item) {
                        SpecialRequetOption.getAll.forEach((element) => {
                            if (element.label == item.textContent) {
                                temp.params.preferences.specialPreferences.specialRequests.push(
                                    element.value
                                );
                            }
                        });
                    });
                }
                if (meal != null) {
                    MealOption.getAll.forEach((element) => {
                        if (element.label == meal.textContent) {
                            temp.params.preferences.specialPreferences.mealPreference =
                                element.value;
                        }
                    });
                }
                var flier = document.querySelector(
                    "#" + `child_flier_${i}` + "  div.css-1uccc91-singleValue"
                );
                if (flier != null) {
                    FlierOption.getAll.forEach((element) => {
                        if (element.label == flier.textContent) {
                            var tempValue = {
                                airline: element.value,
                                number: document.getElementById(`child_flier_number_${i}`)
                                    .value,
                            };
                            temp.params.preferences.frequentFlyer.push(tempValue);
                        }
                    });
                }
                this.passengerList.childList.push({
                    firstName: temp.params.passengerDetails.firstName,
                    lastName: temp.params.passengerDetails.lastName,
                });
                this.PassengerInformation.params.passengers.push(temp.params);
            }
            for (let i = 1; i <= this.numberOfInfant; i++) {
                let temp = new Passenger();
                //
                temp.params.id = indexCount;
                temp.params.passengerIndex = indexCount;
                indexCount++;
                temp.params.passengerDetails.firstName = document.getElementById(
                    `infant_first_name_${i}`
                ).value;
                temp.params.passengerDetails.lastName = document.getElementById(
                    `infant_last_name_${i}`
                ).value;

                if (document.getElementById(`infant_middle_name_${i}`).value == "") {
                    temp.params.passengerDetails.middleName = null;
                } else {
                    temp.params.passengerDetails.middleName = document.getElementById(
                        `infant_middle_name_${i}`
                    ).value;
                }
                temp.params.passengerInfo.dateOfBirth =
                    document.getElementById(`infant_year_${i}`).value +
                    "-" +
                    document.getElementById(`infant_month_${i}`).value +
                    "-" +
                    document.getElementById(`infant_day_${i}`).value;
                temp.params.passengerInfo.gender = document.getElementById(
                    `infant_gender_${i}`
                ).value;
                temp.params.passengerInfo.type = "INF";
                var meal = document.querySelector(
                    "#" + `infant_meal_${i}` + "  div.css-1uccc91-singleValue"
                );
                var specialRequest = document.querySelectorAll(
                    "#" + `infant_special_${i}` + "  div.css-1rhbuit-multiValue"
                );

                if (specialRequest != null) {
                    specialRequest.forEach(function (item) {
                        SpecialRequetOption.getAll.forEach((element) => {
                            if (element.label == item.textContent) {
                                temp.params.preferences.specialPreferences.specialRequests.push(
                                    element.value
                                );
                            }
                        });
                    });
                }
                if (meal != null) {
                    MealOption.getAll.forEach((element) => {
                        if (element.label == meal.textContent) {
                            temp.params.preferences.specialPreferences.mealPreference =
                                element.value;
                        }
                    });
                }
                var flier = document.querySelector(
                    "#" + `infant_flier_${i}` + "  div.css-1uccc91-singleValue"
                );
                if (flier != null) {
                    FlierOption.getAll.forEach((element) => {
                        if (element.label == flier.textContent) {
                            var tempValue = {
                                airline: element.value,
                                number: document.getElementById(`infant_flier_number_${i}`)
                                    .value,
                            };
                            temp.params.preferences.frequentFlyer.push(tempValue);
                        }
                    });
                }
                this.PassengerInformation.params.passengers.push(temp.params);
            }

            this.PassengerInformation.params.CookieSabreDataRequest.SessionId =
                this.SessionId;

            HttpService.postService(
                this.PassengerInformation.params,
                "/Passanger/AddPassangerInfo")
                .then((response) => {
                    //debugger
                    if (response.data.statusAPI == "SUCCESS") {
                        HttpService.postServiceHoldResr()
                            .then((HoldResrResponse) => {
                                localStorage.setItem(
                                    "flightDetails",
                                    JSON.stringify(HoldResrResponse)
                                );
                                if (HoldResrResponse.data.statusAPI == "ERROR") {
                                    this.setState({
                                        // redirectToHome: true,

                                        passengerError: true,
                                        isLoading: false,
                                        modalBody: HoldResrResponse.data.messageAPI,
                                    });
                                } else {
                                    if (this.state.agreePromotion) {
                                        this.submitSubscriptioin();
                                    }

                                    if (
                                        localStorage.getItem("tourPreference") == "flightPlusHotel"
                                    ) {
                                        let hotelBooking = new HotelBooking();
                                        hotelBooking.bookHotel(this.passengerList, this.props);
                                    }
                                    this.setState({ isLoading: false, redirectToPayment: true });
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    passengerError: true,
                                    isLoading: false,
                                    modalBody: "Sorry, something went wrong. Please try again.",
                                }).then((response) => { });
                            });
                        localStorage.setItem("passengerDetails", JSON.stringify(response));
                    } ////hold reseervation end tag
                    else {
                        this.setState({
                            passengerError: true,
                            isLoading: false,
                            modalBody: response.data.messageAPI,
                        });
                    }
                })
                .catch((error) => {
                    //debugger
                    this.setState({
                        passengerError: true,
                        isLoading: false,
                        modalBody: "Sorry, something went wrong. Please try again.",
                    });
                });
        } else {
            window.scrollTo({ top: 100, behavior: "smooth" });
            this.setState({ errorMessage: "Please fill in all the required fields(*)." });
            // Please fill the inputs with red border correctly!
        }
    };
    closeDialog = () => {
        this.setState({ passengerError: false, modalBody: null });
    };
    submitSubscriptioin() {
        var requestBody = {
            Email: this.state.Email,
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
        };
        console.log("submit submitSubscriptioin");
        console.log(requestBody);
        this.setState({ isLoading: true, modalView: false });
        HttpService.postEmail(requestBody)
            .then((response) => { })
            .catch((error) => {
                this.setState({
                    emailModal: true,
                    modalText: "Error occured, try again",
                    isLoading: false,
                });
            });
    }
    render() {
        if (this.state.redirectToPayment) {
            return (
                <Redirect
                    to={{
                        pathname: "/payment-summery",
                    }}
                />
            );
        }
        if (this.searchRequestObj == null || this.SessionId == null) {
            return (
                <Redirect
                    to={{
                        pathname: "/flight",
                    }}
                />
            );
        }
        const optionsFlier = FlierOption.getAll;
        const optionsMeal = MealOption.getAll;
        const optionsSperialRequest = SpecialRequetOption.getAll;

        let flierButton = null;
        let requestButton = null;
        let requestInput = null;
        let flierInput = null;
        let loading = null;
        let sessionExpired = null;
        if (this.state.redirectToHome) {
            sessionExpired = (
                <Modal
                    show={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Session Expired
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.modalBody}</p>
                        <p>{"please try booking again"}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-success" href="/">
                            Back To Home
                        </a>
                    </Modal.Footer>
                </Modal>
            );
        }

        if (this.state.isLoading) {
            loading = (
                <div className="Loading-div">
                    <div className="Loading">
                        <ProgressLoader displayMess="Saving Your Detail" />
                    </div>
                </div>
            );
        }
        let customStyles = {
            option: (provided, state) => ({
                ...provided,
                "text-align": "left",
                color: state.isSelected ? "white" : "black",
                width: state.selectProps.width,
                backgroundColor: state.isSelected ? "#4caf50" : "white",
                "&:hover": {
                    backgroundColor: "#8fde8f",
                    color: "white",
                },
            }),
        };
        if (!this.state.showFlier) {
            flierButton = (
                <span style={{ cursor: "pointer", float: "left" }} onClick={this.showFlier}  >
                    <AddIcon className="addFlight" />
                    <span style={{ color: "#72728c" }}> ADD FREQUENT FLIER</span>
                </span>
            );
            flierInput = null;
        } else {
            flierButton = (
                <span style={{ cursor: "pointer", float: "left" }} onClick={this.showFlier} >
                    <RemoveIcon className="minusFlight" />
                    <span style={{ color: "#72728c" }}> REMOVE FREQUENT FLIER</span>
                </span>
            );
            flierInput = (
                <div className="col-md-12 col-lg-12">
                    <div className="row">
                        <label for="program" className=" mbr-fonts-style display-7">
                            Frequent Flier Program{" "}
                        </label>
                    </div>
                    <div>
                        <Select
                            options={optionsFlier}
                            id="adult_flier_0"
                            styles={customStyles}
                            isSearchable={true}
                        />
                    </div>
                    <label>Frequent Flier Number</label>
                    <input
                        type="text"
                        className="form-control display-7"
                        id="adult_flier_number_0"
                    />
                </div>
            );
        }
        if (!this.state.showRequest) {
            requestButton = (
                <span style={{ cursor: "pointer", float: "right" }} onClick={this.showRequest} >
                    <AddIcon className="addFlight" />
                    <span style={{ color: "#72728c" }}> ADD SPECIAL REQUEST</span>
                </span>
            );
            requestInput = null;
        } else {
            requestButton = (
                <span style={{ cursor: "pointer", float: "right" }} onClick={this.showRequest} >
                    <RemoveIcon className="minusFlight" />
                    <span style={{ color: "#72728c" }}> REMOVE SPECIAL REQUEST</span>
                </span>
            );
            requestInput = (
                <div className="col-md-12 col-lg-12">
                    <div className="row">
                        <label for="program" className=" mbr-fonts-style display-7">
                            Meal Preference
                        </label>
                    </div>
                    <div>
                        <Select
                            value={this.state.selec}
                            onChange={(value) => this.setState({ selec: value })}
                            options={optionsMeal}
                            id="adult_meal_0"
                            isSearchable={true}
                            styles={customStyles}
                        />
                    </div>
                    <div className="row">
                        <label>Special Request</label>
                    </div>
                    <div>
                        <Select
                            options={optionsSperialRequest}
                            isSearchable={true}
                            id="adult_special_0"
                            isMulti
                            styles={customStyles}
                        />
                    </div>
                </div>
            );
        }

        let additionalAdultInput = null;
        let additionalChildInput = null;
        let additionalInfantInput = null;
        if (this.state.firstLoad) {
            let tempstate = this.state;
            for (let i = 1; i < this.numberOfAdult; i++) {
                tempstate.additionaAdultlInput.push(AdultInput);
            }
            for (let i = 0; i < this.numberOfChild; i++) {
                tempstate.additionalChildInput.push(ChildInput);
            }
            for (let i = 0; i < this.numberOfInfant; i++) {
                tempstate.additionalInfantInput.push(InfantInput);
            }
            tempstate.firstLoad = false;
            this.setState(tempstate);
        }
        additionalAdultInput = this.state.additionaAdultlInput.map(
            (Element, index) => {
                return <Element key={index} index={index} />;
            }
        );
        additionalChildInput = this.state.additionalChildInput.map(
            (Element, index) => {
                return <Element key={index} index={index} />;
            }
        );
        additionalInfantInput = this.state.additionalInfantInput.map(
            (Element, index) => {
                return <Element key={index} index={index} />;
            }
        );
        return (
            <>
                <div>
                    <BreadCrumb pageIndex={3} />
                </div>
                <Modal
                    show={this.state.passengerError}
                    aria-labelledby="contained-modal-title-vcenter"
                    onHide={this.closeDialog}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Error
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.modalBody}</p>
                    </Modal.Body>
                    {/* <Modal.Footer>
            <a className="btn btn-success" href="/">
              Back To Home
            </a>
          </Modal.Footer> */}
                </Modal>
                <div className="m-0 p-0">
                    <div className="row m-0 p-0">
                        <div className="col-12 col-md-3 order-md-2 m-0">
                            <ShoppingCart id={"hotel"} className="m-3" pageIndex={3} />
                        </div>
                        <div className="col-12 col-md-9 order-md-1 m-0">
                            <div>
                                {loading}
                                {sessionExpired}
                                <br />
                                <br />
                                <form className="jotform-form mt-5" onSubmit={this.save}>
                                    <div role="main" className="form-all">
                                        <ul className="form-section page-section">
                                            <li
                                                id="cid_1"
                                                className="form-input-wide"
                                                data-type="control_head"
                                            >
                                                <div className="form-header-group header-large">
                                                    <div className="header-text httal htvam">
                                                        <h1
                                                            id="header_1"
                                                            className="form-header"
                                                            data-component="header"
                                                        >
                                                            Passenger Information Form
                                                        </h1>
                                                        <div id="subHeader_1" className="form-subHeader">
                                                            Fill out the form carefully
                                                            <hr style={{ border: "1px solid #4caf50" }} />

                                                            {this.state.errorMessage &&
                                                                <Alert variant="danger">
                                                                    <p style={{ color: "red" }}>
                                                                        {" "}
                                                                        {this.state.errorMessage}
                                                                    </p>
                                                                </Alert>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li
                                                className="form-line"
                                                data-type="control_fullname"
                                                id="id_4"
                                            >
                                                <label
                                                    className="form-label form-label-top form-label-auto"
                                                    id="label_4"
                                                    for="first_4"
                                                >
                                                    Full Name for Adult 1
                                                </label>
                                                <div
                                                    id="cid_4"
                                                    className="form-input-wide"
                                                    data-layout="full"
                                                >
                                                    <div data-wrapper-react="true" className="extended">
                                                        <span
                                                            className="form-sub-label-container-title"
                                                            style={{ "vertical-align": "top" }}
                                                            data-input-type="first"
                                                        >
                                                            <select
                                                                required
                                                                id="adult_title_0"
                                                                className="form-textbox-title"
                                                                style={{ width: "90%" }}
                                                                placeholder="Title *"
                                                                onFocus={function (event) {
                                                                    event.target.classList.remove("is-invalid");
                                                                    event.target.classList.remove("is-valid");
                                                                }}
                                                            //required
                                                            >
                                                                <option selected disabled value="">
                                                                    Title *
                                                                </option>
                                                                <option value="MR">Mr</option>
                                                                <option value="MRS"> Mrs</option>
                                                                <option value="MS"> Ms</option>
                                                            </select>
                                                            {/* <label
                        className="form-sub-label"
                        for="adult_first_name_0"
                        style={{ "min-height": "13px" }}
                        aria-hidden="false"
                      >
                        Title *
                      </label> */}
                                                        </span>
                                                        <span
                                                            className="form-sub-label-container"
                                                            style={{ "vertical-align": "top" }}
                                                            data-input-type="first"
                                                        >
                                                            <input
                                                                className="form-textbox"
                                                                id="adult_first_name_0"
                                                                type="text"
                                                                onChange={function (e) {
                                                                    if (!e.target.value.match(/[A-Za-z]/g)) {
                                                                        e.target.value = "";
                                                                    }
                                                                }}
                                                                onKeyDown={function (e) {
                                                                    var keynum;
                                                                    if (window.event) {
                                                                        keynum = e.keyCode;
                                                                    } else if (e.which) {
                                                                        keynum = e.which;
                                                                    }
                                                                    let isValid =
                                                                        (keynum <= 90 && keynum >= 65) ||
                                                                        keynum == 8 ||
                                                                        keynum == 9 ||
                                                                        keynum == 13 ||
                                                                        keynum == 32 ||
                                                                        keynum == 36 ||
                                                                        keynum == 37 ||
                                                                        keynum == 38 ||
                                                                        keynum == 39 ||
                                                                        keynum == 40;

                                                                    if (!isValid) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                onFocus={function (event) {
                                                                    event.target.classList.remove("is-invalid");
                                                                    event.target.classList.remove("is-valid");
                                                                }}
                                                                //required
                                                                placeholder="First Name *"
                                                            />
                                                            {/* <label
                        className="form-sub-label"
                        for="adult_first_name_0"
                        style={{ "min-height": "13px" }}
                        aria-hidden="false"
                      >
                        First Name *
                      </label> */}
                                                        </span>
                                                        <span
                                                            className="form-sub-label-container"
                                                            style={{ "vertical-align": "top" }}
                                                            data-input-type="middle"
                                                        >
                                                            <input
                                                                type="text"
                                                                id="adult_middle_name_0"
                                                                className="form-textbox"
                                                                placeholder="Middle Name"
                                                                onChange={function (e) {
                                                                    if (!e.target.value.match(/[A-Za-z]/g)) {
                                                                        e.target.value = "";
                                                                    }
                                                                }}
                                                                onKeyDown={function (e) {
                                                                    var keynum;
                                                                    if (window.event) {
                                                                        keynum = e.keyCode;
                                                                    } else if (e.which) {
                                                                        keynum = e.which;
                                                                    }
                                                                    let isValid =
                                                                        (keynum <= 90 && keynum >= 65) ||
                                                                        keynum == 8 ||
                                                                        keynum == 9 ||
                                                                        keynum == 13 ||
                                                                        keynum == 32 ||
                                                                        keynum == 36 ||
                                                                        keynum == 37 ||
                                                                        keynum == 38 ||
                                                                        keynum == 39 ||
                                                                        keynum == 40;

                                                                    if (!isValid) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                            {/* <label
                        className="form-sub-label"
                        for="adult_middle_name_0"
                        style={{ "min-height": "13px" }}
                        aria-hidden="false"
                      >
                        Middle Name
                      </label> */}
                                                        </span>
                                                        <span
                                                            className="form-sub-label-container"
                                                            style={{ "vertical-align": "top" }}
                                                            data-input-type="last"
                                                        >
                                                            <input
                                                                type="text"
                                                                id="adult_last_name_0"
                                                                className="form-textbox"
                                                                onChange={function (e) {
                                                                    if (!e.target.value.match(/[A-Za-z]/g)) {
                                                                        e.target.value = "";
                                                                    }
                                                                }}
                                                                onKeyDown={function (e) {
                                                                    var keynum;
                                                                    if (window.event) {
                                                                        keynum = e.keyCode;
                                                                    } else if (e.which) {
                                                                        keynum = e.which;
                                                                    }
                                                                    let isValid =
                                                                        (keynum <= 90 && keynum >= 65) ||
                                                                        keynum == 8 ||
                                                                        keynum == 9 ||
                                                                        keynum == 13 ||
                                                                        keynum == 32 ||
                                                                        keynum == 36 ||
                                                                        keynum == 37 ||
                                                                        keynum == 38 ||
                                                                        keynum == 39 ||
                                                                        keynum == 40;

                                                                    if (!isValid) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                //required
                                                                placeholder="Last Name *"
                                                                onFocus={function (event) {
                                                                    event.target.classList.remove("is-invalid");
                                                                    event.target.classList.remove("is-valid");
                                                                }}
                                                            />
                                                            {/* <label
                        className="form-sub-label"
                        for="adult_last_name_0"
                        style={{ "min-height": "13px" }}
                        aria-hidden="false"
                      >
                        Last Name *
                      </label> */}
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li
                                                className="form-line form-line-column form-col-2"
                                                data-type="control_dropdown"
                                                id="id_3"
                                            >
                                                {/* <label
                  className="form-label form-label-top"
                  for="adult_gender_0"
                >
                  Gender
                </label> */}
                                                <div
                                                    id="cid_3"
                                                    className="form-input-wide"
                                                    data-layout="half"
                                                >
                                                    <select
                                                        required
                                                        className="form-dropdown"
                                                        id="adult_gender_0"
                                                        style={{ width: "310px;" }}
                                                        data-component="dropdown"
                                                        aria-labelledby="label_3"
                                                        onFocus={function (event) {
                                                            event.target.classList.remove("is-invalid");
                                                            event.target.classList.remove("is-valid");
                                                        }}
                                                    >
                                                        <option
                                                            selected
                                                            disabled
                                                            value=""
                                                            style={{
                                                                color: "#8894ab !important",
                                                                "font-weight": "300 !important",
                                                            }}
                                                        >
                                                            Gender *
                                                        </option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female"> Female </option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li
                                                className="form-line form-line-column form-col-1"
                                                data-type="control_birthdate"
                                                id="id_24"
                                            >
                                                {/* <label
                  className="form-label form-label-top"
                  id="label_24"
                  for="input_24"
                >
                  Birth Date *
                </label> */}
                                                <div
                                                    id="cid_24"
                                                    className="form-input-wide"
                                                    data-layout="full"
                                                >
                                                    <div data-wrapper-react="true">
                                                        <span
                                                            className="form-sub-label-container"
                                                            style={{ "vertical-align": "top" }}
                                                        >
                                                            <select
                                                                required
                                                                id="adult_month_0"
                                                                className="form-dropdown"
                                                                onFocus={function (event) {
                                                                    event.target.classList.remove("is-invalid");
                                                                    event.target.classList.remove("is-valid");
                                                                }}
                                                                title="Month of Birth"
                                                            >
                                                                <option selected disabled value="">
                                                                    Month *
                                                                </option>
                                                                <option value="01"> January </option>
                                                                <option value="02"> February </option>
                                                                <option value="03"> March </option>
                                                                <option value="04"> April </option>
                                                                <option value="05"> May </option>
                                                                <option value="06"> June </option>
                                                                <option value="07"> July </option>
                                                                <option value="08"> August </option>
                                                                <option value="09"> September </option>
                                                                <option value="10"> October </option>
                                                                <option value="11"> November </option>
                                                                <option value="12"> December </option>
                                                            </select>
                                                            <label
                                                                className="form-sub-label"
                                                                id="adult_month_0"
                                                                id="sublabel_24_month"
                                                                style={{ "min-height": "13px" }}
                                                                aria-hidden="false"
                                                            >
                                                                Birth Month
                                                            </label>
                                                        </span>
                                                        <span
                                                            className="form-sub-label-container"
                                                            style={{ "vertical-align": "top" }}
                                                        >
                                                            <select
                                                                required
                                                                id="adult_day_0"
                                                                className="form-dropdown"
                                                                data-component="birthdate-day"
                                                                aria-labelledby="label_24 sublabel_24_day"
                                                                onFocus={function (event) {
                                                                    event.target.classList.remove("is-invalid");
                                                                    event.target.classList.remove("is-valid");
                                                                }}
                                                                title="Day of Birth"
                                                            >
                                                                <option selected disabled value="">
                                                                    Day *
                                                                </option>
                                                                <option value="1"> 1 </option>
                                                                <option value="2"> 2 </option>
                                                                <option value="3"> 3 </option>
                                                                <option value="4"> 4 </option>
                                                                <option value="5"> 5 </option>
                                                                <option value="6"> 6 </option>
                                                                <option value="7"> 7 </option>
                                                                <option value="8"> 8 </option>
                                                                <option value="9"> 9 </option>
                                                                <option value="10"> 10 </option>
                                                                <option value="11"> 11 </option>
                                                                <option value="12"> 12 </option>
                                                                <option value="13"> 13 </option>
                                                                <option value="14"> 14 </option>
                                                                <option value="15"> 15 </option>
                                                                <option value="16"> 16 </option>
                                                                <option value="17"> 17 </option>
                                                                <option value="18"> 18 </option>
                                                                <option value="19"> 19 </option>
                                                                <option value="20"> 20 </option>
                                                                <option value="21"> 21 </option>
                                                                <option value="22"> 22 </option>
                                                                <option value="23"> 23 </option>
                                                                <option value="24"> 24 </option>
                                                                <option value="25"> 25 </option>
                                                                <option value="26"> 26 </option>
                                                                <option value="27"> 27 </option>
                                                                <option value="28"> 28 </option>
                                                                <option value="29"> 29 </option>
                                                                <option value="30"> 30 </option>
                                                                <option value="31"> 31 </option>
                                                            </select>
                                                            <label
                                                                className="form-sub-label"
                                                                id="adult_day_0"
                                                                id="sublabel_24_day"
                                                                style={{ "min-height": "13px" }}
                                                                aria-hidden="false"
                                                            >
                                                                Birth Day
                                                            </label>
                                                        </span>
                                                        <span
                                                            className="form-sub-label-container"
                                                            style={{ "vertical-align": "top" }}
                                                        >
                                                            <select
                                                                required
                                                                id="adult_year_0"
                                                                className="form-dropdown"
                                                                data-component="birthdate-year"
                                                                aria-labelledby="label_24 sublabel_24_year"
                                                                onFocus={function (event) {
                                                                    event.target.classList.remove("is-invalid");
                                                                    event.target.classList.remove("is-valid");
                                                                }}
                                                                title="Year of Birth"
                                                            >
                                                                <option selected disabled value="">
                                                                    Year *
                                                                </option>
                                                                <option value="2008"> 2008 </option>
                                                                <option value="2007"> 2007 </option>
                                                                <option value="2006"> 2006 </option>
                                                                <option value="2005"> 2005 </option>
                                                                <option value="2004"> 2004 </option>
                                                                <option value="2003"> 2003 </option>
                                                                <option value="2002"> 2002 </option>
                                                                <option value="2001"> 2001 </option>
                                                                <option value="2000"> 2000 </option>
                                                                <option value="1999"> 1999 </option>
                                                                <option value="1998"> 1998 </option>
                                                                <option value="1997"> 1997 </option>
                                                                <option value="1996"> 1996 </option>
                                                                <option value="1995"> 1995 </option>
                                                                <option value="1994"> 1994 </option>
                                                                <option value="1993"> 1993 </option>
                                                                <option value="1992"> 1992 </option>
                                                                <option value="1991"> 1991 </option>
                                                                <option value="1990"> 1990 </option>
                                                                <option value="1989"> 1989 </option>
                                                                <option value="1988"> 1988 </option>
                                                                <option value="1987"> 1987 </option>
                                                                <option value="1986"> 1986 </option>
                                                                <option value="1985"> 1985 </option>
                                                                <option value="1984"> 1984 </option>
                                                                <option value="1983"> 1983 </option>
                                                                <option value="1982"> 1982 </option>
                                                                <option value="1981"> 1981 </option>
                                                                <option value="1980"> 1980 </option>
                                                                <option value="1979"> 1979 </option>
                                                                <option value="1978"> 1978 </option>
                                                                <option value="1977"> 1977 </option>
                                                                <option value="1976"> 1976 </option>
                                                                <option value="1975"> 1975 </option>
                                                                <option value="1974"> 1974 </option>
                                                                <option value="1973"> 1973 </option>
                                                                <option value="1972"> 1972 </option>
                                                                <option value="1971"> 1971 </option>
                                                                <option value="1970"> 1970 </option>
                                                                <option value="1969"> 1969 </option>
                                                                <option value="1968"> 1968 </option>
                                                                <option value="1967"> 1967 </option>
                                                                <option value="1966"> 1966 </option>
                                                                <option value="1965"> 1965 </option>
                                                                <option value="1964"> 1964 </option>
                                                                <option value="1963"> 1963 </option>
                                                                <option value="1962"> 1962 </option>
                                                                <option value="1961"> 1961 </option>
                                                                <option value="1960"> 1960 </option>
                                                                <option value="1959"> 1959 </option>
                                                                <option value="1958"> 1958 </option>
                                                                <option value="1957"> 1957 </option>
                                                                <option value="1956"> 1956 </option>
                                                                <option value="1955"> 1955 </option>
                                                                <option value="1954"> 1954 </option>
                                                                <option value="1953"> 1953 </option>
                                                                <option value="1952"> 1952 </option>
                                                                <option value="1951"> 1951 </option>
                                                                <option value="1950"> 1950 </option>
                                                                <option value="1949"> 1949 </option>
                                                                <option value="1948"> 1948 </option>
                                                                <option value="1947"> 1947 </option>
                                                                <option value="1946"> 1946 </option>
                                                                <option value="1945"> 1945 </option>
                                                                <option value="1944"> 1944 </option>
                                                                <option value="1943"> 1943 </option>
                                                                <option value="1942"> 1942 </option>
                                                                <option value="1941"> 1941 </option>
                                                                <option value="1940"> 1940 </option>
                                                                <option value="1939"> 1939 </option>
                                                                <option value="1938"> 1938 </option>
                                                                <option value="1937"> 1937 </option>
                                                                <option value="1936"> 1936 </option>
                                                                <option value="1935"> 1935 </option>
                                                                <option value="1934"> 1934 </option>
                                                                <option value="1933"> 1933 </option>
                                                                <option value="1932"> 1932 </option>
                                                                <option value="1931"> 1931 </option>
                                                                <option value="1930"> 1930 </option>
                                                                <option value="1929"> 1929 </option>
                                                                <option value="1928"> 1928 </option>
                                                                <option value="1927"> 1927 </option>
                                                                <option value="1926"> 1926 </option>
                                                                <option value="1925"> 1925 </option>
                                                                <option value="1924"> 1924 </option>
                                                                <option value="1923"> 1923 </option>
                                                                <option value="1922"> 1922 </option>
                                                                <option value="1921"> 1921 </option>
                                                                <option value="1920"> 1920 </option>
                                                            </select>
                                                            <label
                                                                className="form-sub-label"
                                                                id="adult_year_0"
                                                                id="sublabel_24_year"
                                                                style={{ "min-height": "13px" }}
                                                                aria-hidden="false"
                                                            >
                                                                Birth Year
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li
                                                className="form-line form-line-column form-col-3"
                                                id="id_6"
                                            >
                                                {/* <label
                  className="form-label form-label-top"
                  for="adult_email_0"
                >
                  E-mail *
                </label> */}
                                                <div
                                                    id="cid_6"
                                                    className="form-input-wide"
                                                    data-layout="half"
                                                >
                                                    <span
                                                        className="form-sub-label-container"
                                                        style={{ "vertical-align": "top" }}
                                                    >
                                                        <input
                                                            type="email"
                                                            id="adult_email_0"
                                                            className="form-textbox"
                                                            style={{ width: "310px" }}
                                                            size="310"
                                                            placeholder="Email *"
                                                            data-component="email"
                                                            aria-labelledby="label_6 sublabel_input_6"
                                                            onFocus={function (event) {
                                                                event.target.classList.remove("is-invalid");
                                                                event.target.classList.remove("is-valid");
                                                            }}
                                                        />
                                                    </span>
                                                    <span>
                                                        <p className="emaillapeltext">
                                                            Use valid Email, we will send the documents to
                                                            this address
                                                        </p>{" "}
                                                    </span>
                                                </div>
                                            </li>
                                            <li
                                                className="form-line form-line-column form-col-4"
                                                data-type="control_phone"
                                                id="id_27"
                                            >
                                                <div
                                                    id="cid_27"
                                                    className="form-input-wide"
                                                    data-layout="half"
                                                >
                                                    <span
                                                        className="form-sub-label-container"
                                                        style={{ "vertical-align": "top" }}
                                                        className="formPhoneInput"
                                                    >
                                                        <PhoneInput
                                                            country={"us"}
                                                            inputProps={{
                                                                // class: "form-textbox display-7",
                                                                required: true,
                                                                id: "adult_phone_0",
                                                                placeholder: "Phone Number *",
                                                            }}
                                                            value={this.state.phone}
                                                            onChange={(phone) => this.setState({ phone })}
                                                            onFocus={function (event) {
                                                                event.target.classList.remove("is-invalid");
                                                                event.target.classList.remove("is-valid");
                                                            }}
                                                        />

                                                        <span>
                                                            <p className="emaillapeltext">
                                                                Please put country code and phone number
                                                                correctly
                                                            </p>{" "}
                                                        </span>

                                                        {/* <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={
      <Tooltip > <strong>{"packageDetails.package.name"}</strong>.
      </Tooltip>}
  >
                      <span class="badge badge-pill">
                        {"packageDetails.package.name"}
                      </span> 
  </OverlayTrigger> */}

                                                        <label
                                                            className="form-sub-label"
                                                            for="input_27_full"
                                                            id="sublabel_27_masked"
                                                            style={{ "min-height": "13px" }}
                                                            aria-hidden="false"
                                                        ></label>
                                                    </span>
                                                </div>
                                            </li>
                                            <li
                                                className="form-line form-line-column form-col-3"
                                                data-type="control_email"
                                                id="id_6"
                                            >
                                                <label
                                                    className="form-label form-label-top"
                                                    id="label_6"
                                                >
                                                    {flierButton}
                                                </label>
                                                <div
                                                    id="cid_6"
                                                    className="form-input-wide"
                                                    data-layout="half"
                                                >
                                                    {flierInput}
                                                </div>
                                            </li>
                                            <li
                                                className="form-line form-line-column form-col-4"
                                                data-type="control_phone"
                                                id="id_27"
                                            >
                                                <label
                                                    className="form-label form-label-top"
                                                    id="label_27"
                                                >
                                                    {requestButton}
                                                </label>
                                                <div
                                                    id="cid_27"
                                                    className="form-input-wide"
                                                    data-layout="half"
                                                >
                                                    {requestInput}
                                                </div>
                                            </li>
                                        </ul>
                                        {additionalAdultInput}
                                        {additionalChildInput}
                                        {additionalInfantInput}

                                        <Form.Row className="p-2" style={{ textAlign: "left" }}>
                                            <div className="m-2 ml-5">
                                                <FareRule firstLoad={true} />
                                                <label className="main" for="defaultCheck1">
                                                    <span className="mx-1">
                                                        I would like to recieve newsfeed and promotion from
                                                        Ethiopian Holidays
                                                    </span>

                                                    <input
                                                        type="checkbox"
                                                        className=" travel_age"
                                                        onChange={(value) =>
                                                            this.setState({
                                                                agreePromotion: !this.state.agreePromotion,
                                                            })
                                                        }
                                                        id="defaultCheck1"
                                                    />
                                                    <span className="geekmark"></span>
                                                </label>
                                            </div>
                                        </Form.Row>

                                        <ul>
                                            <li
                                                className="form-line form-line-column form-col-5"
                                                data-type="control_button"
                                                id="id_20"
                                            >
                                                <div
                                                    id="cid_20"
                                                    className="form-input-wide"
                                                    data-layout="full"
                                                >
                                                    <div
                                                        data-align="left"
                                                        className="form-buttons-wrapper form-buttons-left jsTest-button-wrapperField"
                                                    >
                                                        <button
                                                            type="submit"
                                                            className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                                                            onClick={this.save}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PassengerInfo;
