import "./packageAvailability.css";
import React, { Component } from "react";
import TagManager from 'react-gtm-module'
import { MobileView, BrowserView } from "react-device-detect";

import Alert from 'react-bootstrap/Alert'
import { Link } from "react-router-dom";
import queryString from "query-string";
import { HttpService } from "../../../services/http/http-service";
import { DataService } from "../../../services/shared-services/dataServices";

import Widget from "../../home/widget/widget";
import FormControl from "react-bootstrap/FormControl";
import {
  Card,
  Modal, 
  Row,
  Col, 
} from "react-bootstrap";

import { Edit } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import ShoppingCart from "../../ShoppingCart/shopping-cart"; 

import PackageDetails from "./../Holidays-type/package-details/package-details";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import parse from "html-react-parser";

import ProgressLoader from "../../shared/progress-loading/progress-loader";
import JwPagination from "jw-react-pagination";
import BreadCrumb from "../../shared/breadcrumb/breadcrumb"; 
const { htmlToText } = require("html-to-text");
// #12576 Make the package name bolder 
class PackageAvailability extends Component {
  constructor(props) {
    super(props); 
    let search = queryString.parse(this.props.location.search);
    this.onChangePage = this.onChangePage.bind(this); 

    let searchRequestObj = JSON.parse(localStorage.getItem("searchRequestObj"));
    let flightInfo = JSON.parse(localStorage.getItem("flightInfo"));
    let currency =  localStorage.getItem("Currency");

    let items = [
      {
        excludes: [],
        includes: [],
        itinerariesList: [],

        package: {
          description:
            "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
          destination: "ADD",
          destinationId: 1,
          hotelRate: 0,
          id: 57,
          imagePath:
            "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
          isChildAllowed: "Yes",
          isHotelIncluded: false,
          name: "Debre zeit Crater Lakes",
          numberOfDays: 1,
          origin: null,
          packageBookingValidDateFrom: "2020-06-17T00:00:00",
          packageBookingValidDateTo: "2020-12-31T00:00:00",
          status: 0,
        },

        price: {
          amount: 635.3771,
          childRate: 50,
          currency: {
            code: "AED",
          },
          description: null,
          id: 0,
          name: null,
          status: 0,
          currencyID: 7,
          description: null,
          id: 399,
          max: 1,
          min: 1,
          name: null,
          status: 0,
        },
      },
      {
        excludes: [],
        includes: [],
        itinerariesList: [],

        package: {
          description:
            "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
          destination: "ADD",
          destinationId: 1,
          hotelRate: 0,
          id: 59,
          imagePath:
            "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
          isChildAllowed: "Yes",
          isHotelIncluded: false,
          name: "Debre zeit Crater Lakes",
          numberOfDays: 1,
          origin: null,
          packageBookingValidDateFrom: "2020-06-17T00:00:00",
          packageBookingValidDateTo: "2020-12-31T00:00:00",
          status: 0,
        },

        price: {
          amount: 635.3771,
          childRate: 50,
          currency: {
            code: "AED",
          },
          description: null,
          id: 0,
          name: null,
          status: 0,
          currencyID: 7,
          description: null,
          id: 399,
          max: 1,
          min: 1,
          name: null,
          status: 0,
        },
      },
      {
        excludes: [],
        includes: [],
        itinerariesList: [],

        package: {
          description:
            "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
          destination: "ADD",
          destinationId: 1,
          hotelRate: 0,
          id: 59,
          imagePath:
            "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
          isChildAllowed: "Yes",
          isHotelIncluded: false,
          name: "Debre zeit Crater Lakes",
          numberOfDays: 1,
          origin: null,
          packageBookingValidDateFrom: "2020-06-17T00:00:00",
          packageBookingValidDateTo: "2020-12-31T00:00:00",
          status: 0,
        },

        price: {
          amount: 635.3771,
          childRate: 50,
          currency: {
            code: "AED",
          },
          description: null,
          id: 0,
          name: null,
          status: 0,
          currencyID: 7,
          description: null,
          id: 399,
          max: 1,
          min: 1,
          name: null,
          status: 0,
        },
      },
      {
        excludes: [],
        includes: [],
        itinerariesList: [],

        package: {
          description:
            "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
          destination: "ADD",
          destinationId: 1,
          hotelRate: 0,
          id: 59,
          imagePath:
            "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
          isChildAllowed: "Yes",
          isHotelIncluded: false,
          name: "Debre zeit Crater Lakes",
          numberOfDays: 1,
          origin: null,
          packageBookingValidDateFrom: "2020-06-17T00:00:00",
          packageBookingValidDateTo: "2020-12-31T00:00:00",
          status: 0,
        },

        price: {
          amount: 635.3771,
          childRate: 50,
          currency: {
            code: "AED",
          },
          description: null,
          id: 0,
          name: null,
          status: 0,
          currencyID: 7,
          description: null,
          id: 399,
          max: 1,
          min: 1,
          name: null,
          status: 0,
        },
      },
      {
        excludes: [],
        includes: [],
        itinerariesList: [],
        package: {
          description:
            "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
          destination: "ADD",
          destinationId: 1,
          hotelRate: 0,
          id: 51,
          imagePath:
            "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
          isChildAllowed: "Yes",
          isHotelIncluded: false,
          name: "Debre zeit Crater Lakes",
          numberOfDays: 1,
          origin: null,
          packageBookingValidDateFrom: "2020-06-17T00:00:00",
          packageBookingValidDateTo: "2020-12-31T00:00:00",
          status: 0,
        },

        price: {
          amount: 635.3771,
          childRate: 50,
          currency: {
            code: "AED",
          },
          description: null,
          id: 0,
          name: null,
          status: 0,
          currencyID: 7,
          description: null,
          id: 399,
          max: 1,
          min: 1,
          name: null,
          status: 0,
        },
      },
    ];

    this.state = {
      showWidget: false,
      // descriptionTest:'<p class="Body" style="text-align:justify;text-justify:inter-ideograph"><span lang="EN-US" style="font-family:&quot;Gill Sans&quot;">Wonchi Crater Lake is the beautifullegacy of a once powerful volcano located within a mountain range between thetowns of Ambo and Woliso. The beautiful lake is situated at an altitude ofabout 3000m above sea level. Wonchi contains hot springs, waterfalls andbreathtaking views of valleys and stunning scenery. On the island in the middleof the lake there is an ancient monastery named Cherkos. Wonchi also hasindigenous forest reserves. The surrounding beauty mesmerizes visitors toWonchi!<o:p></o:p></span></p><p class="Body" style="text-align:justify;text-justify:inter-ideograph"><span lang="EN-US" style="font-family:&quot;Gill Sans&quot;"><o:p>&nbsp;</o:p></span></p>',
      dateOpen: false,
      searchRequestObj: searchRequestObj,
      currency: currency,
      flightInfo: flightInfo,
      initialPackageRequest: { Routes: [{ from: "today" }] },
      pageOfItems: [],
      date: null,
      minDate: null,
      NoOfNights: null,
      showModal: false,
      showModalData: {},
      selectedPackage: {},
      selectedPackageSet: [],
      selectedPackageIndex: 0,
      continueLoading: false,
      MultiplePackageSet: [{ packagesList: items }],
      MultiplePackageSetIndex: 0,
      supplements: [],

      isLoaded: false,
      search: search,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  async componentDidMount() {
    localStorage.removeItem("PackageSummery");
    localStorage.removeItem("hotelfinalsummary");
    localStorage.removeItem("hotelDetails");
    this.getAvailablePackages();
    this.configureGoogleTag();  
  }
  
  showWidgetModel = () => {
    var currentValue = this.state.showWidget;
    this.setState({ showWidget: !currentValue });
  };
  configureGoogleTag(){
    if((this.state.searchRequestObj.itineraryParts.length<1)||this.state.flightInfo.length<1){
      return
    }
    const tagManagerArgs = {
      dataLayer: {
          events:"addFlightToCart",
          flightItineraryParts: this.state.searchRequestObj.itineraryParts,
          flightInfo: this.state.flightInfo,
          flightCurrency: this.state.currency,  
          packagesAdded: [],  
      } 
     } 
     TagManager.dataLayer(tagManagerArgs)
  }

  addPackageToGoogleTag(PackagesAdded){
     if((PackagesAdded.length<1) ){  return  }
     const tagManagerArgs = {
     dataLayer: {
        events:"addPackagesToCart",
        flightItineraryParts: this.state.searchRequestObj.itineraryParts,
        flightInfo: this.state.flightInfo,
        flightCurrency: this.state.currency,  
        packagesAdded: PackagesAdded,  
    }  
    } 
    TagManager.dataLayer(tagManagerArgs)
  }

  getAvailablePackages() {
    this.setState({
      isLoaded: false,
    });

    // console.log("get package availability");
    let sessionId = localStorage.getItem("sessionId");
    let apiRelativePath = "/Availability/GetMulticityPackages";
    let data = {};
    if (this.state.searchRequestObj) {
      let Routes = [];
      let length = this.state.searchRequestObj.itineraryParts.length;
      for (let i = 0; i < length; i++) {
        let NoOfNight = 0;
        if (length === i + 1) {
          NoOfNight = 0;
        } else {
          let firstArrivalDate = new Date(
            this.state.searchRequestObj.itineraryParts[i].when.date
          );
          let nextDepartureDate = new Date(
            this.state.searchRequestObj.itineraryParts[i + 1].when.date
          );
          var Difference_In_Time =
            nextDepartureDate.getTime() - firstArrivalDate.getTime();
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          NoOfNight = Difference_In_Days.toString();
          NoOfNight = parseInt(NoOfNight);
          if (NoOfNight == 0) {
            NoOfNight = 1;
          }
        }
        let route = {
          from: this.state.searchRequestObj.itineraryParts[i].when.date,
          destinationCode:
            this.state.searchRequestObj.itineraryParts[i].to.code,
          NumberOfNights: NoOfNight,
        };
        Routes.push(route);
      }
      data = {
        Routes: Routes,
        Adult: this.state.searchRequestObj.passengers.ADT,
        Child: this.state.searchRequestObj.passengers.CHD,
        Sessionid: sessionId,
      };
      this.setState({ initialPackageRequest: data });

      HttpService.postServicePackageBooking(data, apiRelativePath)
        .then((response) => {
          // console.log("get package availability response");
          // console.log(response);
          let responseData = response.data;
          if (responseData.packages) {
            this.setState({
              MultiplePackageSet: responseData.packages,
              isLoaded: true,
            });

            // console.log("MultiplePackageSet");
            // console.log(this.state.MultiplePackageSet);
          } else {
            this.setState({
              MultiplePackageSet: [],
              isLoaded: true,
            });
            // console.log("MultiplePackageSet");
            // console.log(this.state.MultiplePackageSet);
          }
        })
        .catch((error) => {
          // console.log("responseData error2 ");
        });
    } else {
      // console.log("No reqest data");
    }
  }

  handleChange(date) {
    this.setState({
      date: date,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    // console.log(this.state.date)
  }

  onChangePage(pageOfItems) {
    window.scrollTo({ top: 50, behavior: "smooth" });
    this.setState({ pageOfItems });
  }

  dateOpen = () => {
    //12613 defuelt date removed

    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <FormControl
          value={!this.state.date? " Start Date* " :  value }
          name="dateValue"
          id="dateValue"
          readOnly
          className="trip"
          required
          placeholderText=" Departure Date* "
          aria-label="DepartureAirport"
          aria-describedby="DepartureAirport"
          onClick={onClick}
        />
      </>
    );
    return (
      <DatePicker
        disabledKeyboardNavigation
        id="date"
        selected={
          this.state.date === null
            ? new Date(
                this.state.searchRequestObj.itineraryParts[
                  this.state.MultiplePackageSetIndex
                ].when.date
              )
            : this.state.date
        }
        onChange={(value) => {
          this.setState({
            date: value,
          });
        }}
        name="startDate"
        customInput={<ExampleCustomInput />}
        dateFormat="MM/dd/yyyy"
        minDate={
          new Date(
            this.state.searchRequestObj.itineraryParts[
              this.state.MultiplePackageSetIndex
            ].when.date
          )
        }
        maxDate={
          this.state.searchRequestObj.itineraryParts.length > 1
            ? new Date(
                this.state.searchRequestObj.itineraryParts[
                  this.state.searchRequestObj.itineraryParts.length - 1
                ].when.date
              )
            : null
        }
      />
    );
  };


  setSupplements(index) {
    var oldSupplements = this.state.supplements;
    oldSupplements[index] = {
      checked: !oldSupplements[index].checked,
      id: oldSupplements[index].id,
      suplement: oldSupplements[index].suplement,
    };
    this.setState({ supplements: oldSupplements });
  }

  showPackageDetails = (packageData) => {
    let search =
      "?packageName=" +
      packageData.package.name +
      "&packageId=" +
      packageData.package.id;
    let path = "/package-details/" + search;
  };

  dayInterval = (day1st, day2nd) => {
    let firstDate = new Date(day1st);
    let nextDate = new Date(day2nd);
    let Difference_In_Time = nextDate.getTime() - firstDate.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let totalNight = Difference_In_Days.toString();
    totalNight = parseInt(totalNight);
    return totalNight;
  };

  parseDate = (dateString) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };

  nextPackageIndex() {
    let nextIndex = this.state.MultiplePackageSetIndex + 1;
    this.setState({ MultiplePackageSetIndex: nextIndex });
  }

  addToCart(packageSelected) {
    let itineraryPartsLength =
      this.state.searchRequestObj.itineraryParts.length;
    let index = this.state.MultiplePackageSetIndex;
    let PackagesAdded = [];
    PackagesAdded = this.state.selectedPackageSet;
    // let startDate = document.getElementById("date").value;
    let startDate = document.getElementById("dateValue").value;
    let bookingEndDate = new Date(startDate); //.format("YYYY-MM-DD"),
    bookingEndDate.setDate(
      bookingEndDate.getDate() +
        this.state.initialPackageRequest.Routes[index].NumberOfNights
    );
    let bookingEndDateToString =
      bookingEndDate.getFullYear() +
      "-" +
      (bookingEndDate.getMonth() + 1) +
      "-" +
      bookingEndDate.getDate();

    PackagesAdded.push({
      BookingDate: startDate,
      BookingEndDate: bookingEndDateToString,
      PackageId: packageSelected.package.id,
      PackagePrice: packageSelected.price.amount,
      PackageName: packageSelected.package.name,
      NumberOfPassenger: this.state.searchRequestObj.passengers.ADT,
      Supplements: [],
      IsFlightandPackage: true,
    });

    this.addPackageToGoogleTag(PackagesAdded);
    this.setState({ selectedPackageSet: PackagesAdded });

    // if ((index + 1) === itineraryPartsLength) {
    this.setSelectedPackages();
    // }
    // else {
    //     this.scrollView("initial")
    //     this.setState({ MultiplePackageSetIndex: index + 1 });
    // }
  }

  selectPackageIndex(item) {
    var oldSupplements = this.state.supplements;
    this.setState({ selectedPackage: item.package.id });
    for (let i = 0; i < item.supplements.length; i++) {
      oldSupplements[i] = {
        checked: false,
        id: item.supplements[i].id,
        suplement: item.supplements[i],
      };
    }

    this.setState({ supplements: oldSupplements });
  }

  setSelectedPackages() {
    this.setState({ continueLoading: true });
    let sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      let apiRelativePath = "/Availability/MultiPackagesSelect";
      let selectedPackage = this.state.selectedPackageSet;
      if (this.state.supplements) {
        for (let i = 0; i < this.state.supplements.length; i++) {
          if (this.state.supplements[i].checked === true) {
            selectedPackage[0].Supplements.push(this.state.supplements[i].id);
          }
        }
      }

      let select = {
        Packages: selectedPackage,
        SessionId: sessionId,
      };
      HttpService.postServicePackageBooking(select, apiRelativePath)
        .then((response) => {
          //debugger
          let responseData = response.data;
          if (
            responseData.statusAPI == "SUCCESS" ||
            responseData.isOkResponse == true
          ) {
            localStorage.setItem(
              "PackageSummery",
              JSON.stringify(responseData.packagesList)
            );
            this.props.history.push("/passenger");
          } else {
            // console.log("unsuccesful selection of package");
          }
        })
        .catch((error) => {
          // console.log("responseData error2 ");
        });
    } else {
      // console.log(" no session error");
      return;
    }
  }

  scrollView(place) {
    let elmnt = document.getElementById(place);
    elmnt.scrollIntoView();
  }

  render() {
    //debugger
    var {
      isLoaded,
      search,
      MultiplePackageSet,
      showModalData,
      MultiplePackageSetIndex,
    } = this.state;

    if (!isLoaded) {
      return <ProgressLoader displayMess="Loading Packages"></ProgressLoader>;
    } else {
      return (
        <>
          <Modal
            scrollable={true}
            size="xl"
            aria-labelledby="example-custom-modal-styling-title"
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Header closeButton className="p-1 pl-3 pt-0">
              <Modal.Title>Package Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PackageDetails packageDetails={this.state.showModalData} />
            </Modal.Body>
          </Modal>
          <BreadCrumb pageIndex={2} />
          <div className="row m-0 p-0">
            <div className="col-12 col-md-3 order-md-2 m-0">
              <ShoppingCart
                id={"flight-" + this.props.flightIndex}
                pageIndex={2}
              />
            </div>
            <div className="col-12 col-md-9 order-md-1 m-0">
              <div
                className=" container pt-0 mt-2"
                id="initial"
                style={{ width: "100%" }}
              >
                <Card.Header
                  style={{ textAlign: "left" }}
                  className="d-flex p-0 pl-3 m-2 mb-3 success"
                  style={{}}
                  variant="success"
                >
                  <h5>
                    Available Packages Starting From{" "}
                    {this.parseDate(
                      this.state.initialPackageRequest.Routes[
                        MultiplePackageSetIndex
                      ].from
                    )}
                    {this.state.initialPackageRequest.Routes[
                      MultiplePackageSetIndex
                    ].NumberOfNights !== 0 && (
                      <>
                        {" "}
                        For{" "}
                        {
                          this.state.initialPackageRequest.Routes[
                            MultiplePackageSetIndex
                          ].NumberOfNights
                        }{" "}
                        Day(s)
                      </>
                    )}
                    , In{" "}
                    {DataService.getFullCityName(
                      this.state.initialPackageRequest.Routes[
                        MultiplePackageSetIndex
                      ].destinationCode
                    )}
                  </h5>
                </Card.Header>
                {MultiplePackageSet[MultiplePackageSetIndex].packagesList
                  .length < 1 && (
                  <>
                    <Card className="text-center pt-2 pb-0 shadow bg-white rounded z-index-1">
                      <Card.Header
                        style={{ textAlign: "left" }}
                        className="d-flex p-0 pl-3 success"
                        style={{}}
                        variant="success"
                      >
                        <h5>No packages found!</h5>{" "}
                      </Card.Header>
                      <Card.Body
                        style={{ textAlign: "left" }}
                        id="demo"
                        className=""
                      >
                        {/* <div className="container"> */}
                          <br />
                          <h6 className="payment-status-msg ">
                            
                          <Alert className= "alertField"  variant={"danger"}>
                            {/* <p> */}
                              {" "}
                              No packages found in{" "}
                              {DataService.getFullCityName(
                                this.state.initialPackageRequest.Routes[
                                  MultiplePackageSetIndex
                                ].destinationCode
                              )}{" "}
                              on specified date {"( starting from "}
                              {this.parseDate(
                                this.state.initialPackageRequest.Routes[
                                  MultiplePackageSetIndex
                                ].from
                              )}
                              {this.state.initialPackageRequest.Routes[
                                MultiplePackageSetIndex
                              ].NumberOfNights !== 0 && (
                                <>
                                  {" "}
                                  For{" "}
                                  {
                                    this.state.initialPackageRequest.Routes[
                                      MultiplePackageSetIndex
                                    ].NumberOfNights
                                  }{" "}
                                  Day(s)
                                </>
                              )}{" )"}
                            {/* </p>  */}
                            </Alert>
                          </h6>
                        {/* </div> */}
                      </Card.Body>
                    </Card>
                    <br />
                    <Row className="pl-5">
                      {MultiplePackageSet.length !=
                        MultiplePackageSetIndex + 1 && (
                        <Col style={{ textAlign: "left" }} sm={12}>
                          <button
                            type="button"
                            className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                            onClick={() => {
                              this.nextPackageIndex();
                            }}
                          >
                            <strong> Next Flight's Package</strong>
                          </button>
                        </Col>
                      )}
                      {MultiplePackageSet.length ===
                        MultiplePackageSetIndex + 1 && (
                        <>
                          {this.state.selectedPackageSet.length < 1 && (
                            
                    <Row Row>
                            <Col style={{ textAlign: "left" }} >
                              {/* <Link to={"/"}> */}
                                <button
                                  onClick={this.showWidgetModel}
                                  type="button"
                                  className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                                >
                                <Edit />{"  "}
                                  <strong>Edit Selection</strong>
                                </button>
                              {/* </Link> */}
                              
                            </Col>
                            <Col style={{ textAlign: "left" }}  >
                              <Link to={"/"}>
                              <button 
                                  type="button"
                                  className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                                > 
                                  <strong>Back Home</strong>
                                </button>
                              </Link>
                            </Col></Row>
                          )}
                          {this.state.selectedPackageSet.length > 0 && (
                            <Col style={{ textAlign: "left" }} sm={12}>
                              <button
                                type="button"
                                className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                                onClick={() => {
                                  this.setSelectedPackages()();
                                }}
                              >
                                <strong> Continue</strong>
                              </button>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </>
                )}

                {MultiplePackageSet[MultiplePackageSetIndex].packagesList
                  .length >= 1 && (
                  <>
                    {this.state.pageOfItems.map((item) => (
                      <>
                        <Card
                          className="text-center rounded2  pt-0 pb-0 shadow bg-white"
                          style={{
                            borderColor:
                              this.state.selectedPackage === item.package.id
                                ? "green"
                                : "white",
                          }}
                        >
                          <Card.Body className="text-center  mb-0 p-0 ">
                            <Row>
                              <Col sm={4}> 
                                  <LazyLoadImage
                                    className="card-img rounded2"
                                    style={{
                                      cursor: "pointer !important",
                                      maxHeight: "150px",
                                    }}
                                    alt={""}
                                    effect="blur"
                                    src={item.package.imagePath}
                                  /> 
                              </Col>
                              <Col sm={5}>
                                <Card.Body
                                  className="pt-3 pr-3 pl-2"
                                  style={{ textAlign: "left" }}
                                > 
                                  <Card.Subtitle className="mb-2 shorttitle">
                                  <strong> {item.package.name}</strong>
                                  </Card.Subtitle>
                                  <Card.Text className="shortDesc  ">
                                    {/* <p style={{whiteSpace: "pre-wrap"}} className="multiParagragh">{item.package.description}</p> */}
                                    <p>{parse(item.package.description)}</p>
                                  </Card.Text>
                                  <Card.Link
                                    onClick={() =>
                                      this.setState({
                                        showModal: true,
                                        showModalData: item,
                                      })
                                    }
                                    style={{ color: "green" }}
                                  >
                                    View More
                                  </Card.Link>
                                  <Card.Link>
                                    {this.state.selectedPackage !==
                                      item.package.id && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.selectPackageIndex(item);
                                          // this.setState({ selectedPackage: item.package.id,supplement: item.supplements})
                                        }}
                                        class="btn  btn-outline-success btn-sm float-right"
                                      >
                                        Select
                                        <span className="">
                                          {" "}
                                          <FontAwesomeIcon
                                            size="lg"
                                            icon={faCaretDown}
                                          />
                                        </span>
                                      </button>
                                    )}
                                    {this.state.selectedPackage ===
                                      item.package.id && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.setState({
                                            selectedPackage: null,
                                          })
                                        }
                                        class="btn btn-outline-success rounded2 btn-sm float-right"
                                      >
                                        <span className="">
                                          {" "}
                                          <FontAwesomeIcon
                                            size="lg"
                                            icon={faCaretUp}
                                          />
                                        </span>
                                      </button>
                                    )}
                                  </Card.Link>
                                </Card.Body>
                              </Col>
                              <Col
                                className="p-3 pl-1"
                                style={{ borderLeft: "1px solid green" }}
                                sm={3}
                              >
                                <div
                                  className="row d-flex pl-3"
                                  style={{ textAlign: "left" }}
                                >
                                  <div className="col-6 col-md-12 col-sm-12">
                                    <Card.Subtitle className="mb-2 text-muted">
                                      Price Per Adult:{" "}
                                    </Card.Subtitle>
                                  </div>
                                  <div className="col-6 col-md-12 col-sm-12">
                                    <Card.Subtitle className="mb-2">
                                      <strong>
                                        {item.price.amount}{" "}
                                        {item.price.currency.code}
                                      </strong>
                                    </Card.Subtitle>
                                  </div>
                                  <div className="col-6 col-sm-12">
                                    <Card.Subtitle className="mb-2 text-muted">
                                      Duration:
                                    </Card.Subtitle>
                                  </div>
                                  <div className="col-6 col-sm-12">
                                    <Card.Subtitle className="mb-2">
                                      <strong>
                                        {item.package.numberOfDays} Day(s)
                                      </strong>{" "}
                                    </Card.Subtitle>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          {this.state.selectedPackage === item.package.id && (
                            <Card.Footer
                              className=" text-center mt-1 rounded2"
                              style={{ borderTop: "0.5px solid green" }}
                            >
                              <Row>
                                <Col sm={7}>
                                  <Row>
                                 <Col sm={12}>
                                      <div
                                        className="row d-flex pl-1"
                                        style={{ textAlign: "left" }}
                                      >
                                        <div class="col-7 col-lg-6 col-sm-9">
                                          <Card.Subtitle className="m-2">
                                            {" "}
                                            <strong> Passengers</strong>{" "}
                                          </Card.Subtitle>
                                          <Card.Subtitle className="m-2">
                                            {" "}
                                            Number of adult:{" "}
                                            <strong>
                                              {" "}
                                              {
                                                this.state.searchRequestObj
                                                  .passengers.ADT
                                              }
                                            </strong>{" "}
                                          </Card.Subtitle>
                                          <Card.Subtitle className="m-2">
                                            {" "}
                                            Number of Child:{" "}
                                            <strong>
                                              {" "}
                                              {
                                                this.state.searchRequestObj
                                                  .passengers.CHD
                                              }
                                            </strong>{" "}
                                          </Card.Subtitle>
                                          <Card.Subtitle className="m-2">
                                            {" "}
                                            Number of Infant:{" "}
                                            <strong>
                                              {" "}
                                              {
                                                this.state.searchRequestObj
                                                  .passengers.INF
                                              }
                                            </strong>{" "}
                                          </Card.Subtitle>
                                        </div>

                                        {item.supplements && (
                                          <div class="col-7 col-lg-6 col-sm-9">
                                            <Card.Subtitle className="m-2">
                                              {" "}
                                              <strong> Supplement</strong>{" "}
                                            </Card.Subtitle>

                                            {item.supplements.map(
                                              (suplement, index) => (
                                                <Card.Subtitle className="m-2">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(event) => {
                                                      this.setSupplements(
                                                        index
                                                      );
                                                    }}
                                                    id="defaultCheck1"
                                                  />
                                                  <label
                                                    class="form-check-label"
                                                    for="defaultCheck1"
                                                  >
                                                    {" "}
                                                    {suplement.name} {"|  "}{" "}
                                                    {suplement.amount}{" "}
                                                    {item.price.currency.code}{" "}
                                                  </label>
                                                </Card.Subtitle>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm={5}>
                                  <div className="row text-center">
                                    <div
                                      class="col-12 col-lg-7  col-sm-12 "
                                      style={{ textAlign: "left" }}
                                    >
                                      <Card.Subtitle className="m-2">
                                        {" "}
                                        Total Price :
                                        <strong>
                                          {" "}
                                          {item.totalAmount}{" "}
                                          {item.price.currency.code}
                                        </strong>
                                      </Card.Subtitle>
                                      <form onSubmit={this.onFormSubmit}>
                                        <div className="form-group">
                                          <this.dateOpen />
                                          {!this.state.date && (
                                            <span>
                                              <p className="emaillapeltext  ">
                                                {" "}
                                                Select package booking date to
                                                proceed.
                                              </p>{" "}
                                            </span>
                                          )} 
                                        </div>
                                      </form>
                                    </div>
                                    <div
                                      class="col-12 col-lg-5  col-sm-12"
                                      style={{ textAlign: "center" }}
                                    >
                                      {this.state.date && (
                                        <button
                                          type="button"
                                          class="btn btn-success rounded2"
                                          onClick={() => this.addToCart(item)}
                                        >
                                          <strong className="">Continue</strong>
                                        </button>
                                      )}
                                      {!this.state.date && (
                                        <button
                                          type="button"
                                          class="btn btn-success rounded2"
                                          disabled
                                        >
                                          <strong className="">Continue</strong>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Footer>
                          )}
                        </Card>
                        <br />
                      </>
                    ))}
                  </>
                )}

                <JwPagination
                  pageSize={4}
                  items={
                    MultiplePackageSet[MultiplePackageSetIndex].packagesList
                  }
                  onChangePage={this.onChangePage}
                />
                {this.state.continueLoading && (
                  <ProgressLoader displayMess="Selecting Package"></ProgressLoader>
                )}
              </div>
            </div>
          </div>

          <div>
          <MobileView>
            <Modal
              show={this.state.showWidget}
              onHide={this.showWidgetModel}
              size="xl"
              className="closingWidget"
              centered
            >
              <Modal.Header
                closeButton
                style={{
                  border: "none",
                  backgroundColor: "#808080",
                  zIndex: "",
                }}
              ></Modal.Header>
              <div
                className="container float-right"
                style={{ position: "absolute", marginTop: "100px" }}
              >
                <div className="row">
                  {this.state.showWidget ? (
                    <Widget searchFlightAgain="true" />
                  ) : null}
                </div>
              </div>
            </Modal> 
          </MobileView>
          <BrowserView>
            <Modal
              show={this.state.showWidget}
              onHide={this.showWidgetModel}
              size="xl"
              className="closingWidget"
              style={{ marginTop: "2em" }}
            >
              <Modal.Header
                closeButton
                style={{
                  border: "none",
                  backgroundColor: "#808080",
                  zIndex: "",
                }}
              ></Modal.Header>
              <div
                className="container float-right"
                style={{ position: "absolute", marginTop: "100px" }}
              >
                <div className="row">
                  {this.state.showWidget ? (
                    <Widget searchFlightAgain="true" />
                  ) : null}
                </div>
              </div>
            </Modal>
 
          </BrowserView>
        </div>


        </>
      );
    }
  }
}

{/* <button
onClick={this.showWidgetModel}
className=" btn btn-success mr-2"
>
{" "}
<Edit />
</button> */}

export default PackageAvailability;
