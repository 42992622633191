import React, { Component } from "react";
import FlightTakeoffSharp from "@material-ui/icons/FlightTakeoffSharp";
import Clear from "@material-ui/icons/Clear";

import GroupAdd from "@material-ui/icons/GroupAdd";

import "bootstrap/dist/css/bootstrap.min.css";

import "./widget.css";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import FlightInput from "./flightInput.jsx";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Autocomplete from "react-autocomplete";
import { Redirect } from "react-router-dom";
import { HttpService } from "../../../services/http/http-service";
import ProgressLoader from "../../shared/progress-loading/progress-loader";
import { HotelSearch } from "../../../models/hotel/hotel-search";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Alert from 'react-bootstrap/Alert'
import { AirportList } from "../../shared/master-data/airport-list/airport-list";
import { isBrowser, isMobile } from "react-device-detect";
import $ from "jquery";
import { DataService } from "../../../services/shared-services/dataServices";
import moment from "moment";
import {
    faSync
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FlightPackage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formValid: true,
            TotalNumberOfAdult: 1,
            TotalNumberOfChildren: 0,
            TotalNumberOfInfant: 0,
            showPassengerSelecter: false,
            AdultText: "Adult",
            ChildText: "Child",
            InfantText: "Infant",
            passengerText: "1 Adult ,0 Child ,0 Infant",
            flight: [],
            class: "All Class",
            from: "",
            to: "",
            class: 3,
            startDate: null,
            endDate: null,
            airportlist: [],
            bodyParameters: {
                cabinClass: 3,
                awardBooking: false,
                searchType: 1,
                promoCodes: null,
                itineraryParts: [],
                passengers: {
                    ADT: 1,
                    CHD: 0,
                    INF: 0,
                },
            },
            responseFromServer: null,
            isLoading: false,
            isFromValid: false,
            isToValid: false,
            showDialog: false,
            valid_date: "valid_date",
            messageAPI: null,
            networkError: false,
            networkErrorMessage: false,

        };
        this.hotelSearch = new HotelSearch();
    }

    search = (event) => {

        event.preventDefault();
        var formValid = true;
        let defaultSearchParameter = this.state.bodyParameters;
        defaultSearchParameter.cabinClass = 3;
        defaultSearchParameter.searchType = 1;
        defaultSearchParameter.itineraryParts = [];
        defaultSearchParameter.passengers.ADT = 1;
        defaultSearchParameter.passengers.CHD = 0;
        defaultSearchParameter.passengers.INF = 0;
        this.setState({ bodyParameters: defaultSearchParameter });

        if (
            this.state.from == "" ||
            this.state.to == "" ||
            this.state.startDate == null ||
            this.state.endDate == null
        ) {
            if (this.state.from == "") {
                formValid = false;
                this.setState({ isFromValid: true });
            }
            if (this.state.to == "") {
                formValid = false;
                this.setState({ isToValid: true });
            }
            if (this.props.journeyType == "Validity") {
                if (this.state.startDate == null) {
                    formValid = false;
                    this.setState({ valid_date: "invalid_date" });
                }
            } else {
                if (this.state.startDate == null || this.state.endDate == null) {
                    formValid = false;
                    this.setState({ valid_date: "invalid_date" });
                }
            }
        }

        this.setState({ formValid: formValid });
        if (formValid) {
            this.setState({ isLoading: true });

            if (this.props.journeyType == "Validity") {
                let tempitineraryParts = {
                    from: {
                        code: this.state.from.value.substring(
                            this.state.from.value.indexOf(")") - 3,
                            this.state.from.value.indexOf(")")
                        ),
                    },
                    to: {
                        code: this.state.to.value.substring(
                            this.state.to.value.indexOf(")") - 3,
                            this.state.to.value.indexOf(")")
                        ),
                    },
                    when: {
                        date: this.state.startDate.format("YYYY-MM-DD"),
                    },
                };

                let tempState = this.state.bodyParameters;
                tempState.itineraryParts.push(tempitineraryParts);
                tempState.passengers.ADT = this.state.TotalNumberOfAdult;
                tempState.passengers.CHD = this.state.TotalNumberOfChildren;
                tempState.passengers.INF = this.state.TotalNumberOfInfant;
                tempState.cabinClass = parseInt(this.state.class);
                this.setState({ bodyParameters: tempState });
            } else {

                let tempitineraryParts = {
                    from: {
                        code: this.state.from.value.substring(
                            this.state.from.value.indexOf(")") - 3,
                            this.state.from.value.indexOf(")")
                        ),
                    },
                    to: {
                        code: this.state.to.value.substring(
                            this.state.to.value.indexOf(")") - 3,
                            this.state.to.value.indexOf(")")
                        ),
                    },
                    when: {
                        date: this.state.startDate.format("YYYY-MM-DD"),
                    },
                };

                let tempState = this.state.bodyParameters;
                tempState.itineraryParts.push(tempitineraryParts);
                tempitineraryParts = {
                    from: {
                        code: this.state.to.value.substring(
                            this.state.to.value.indexOf(")") - 3,
                            this.state.to.value.indexOf(")")
                        ),
                    },
                    to: {
                        code: this.state.from.value.substring(
                            this.state.from.value.indexOf(")") - 3,
                            this.state.from.value.indexOf(")")
                        ),
                    },
                    when: {
                        date: this.state.endDate.format("YYYY-MM-DD"),
                    },
                };
                tempState.itineraryParts.push(tempitineraryParts);

                tempState.passengers.ADT = this.state.TotalNumberOfAdult;
                tempState.passengers.CHD = this.state.TotalNumberOfChildren;
                tempState.passengers.INF = this.state.TotalNumberOfInfant;
                tempState.cabinClass = parseInt(this.state.class);

                this.setState({ bodyParameters: tempState });
            }
            localStorage.setItem("totalFlighAmount", 0);
            localStorage.setItem("flightFare", 0);
            localStorage.setItem("flightTax", 0);
            localStorage.setItem("hotelTotalPrice", 0);
            localStorage.setItem("surcharges", 0);
            localStorage.setItem("Currency", "");
            localStorage.setItem("flightInfo", null);
            localStorage.setItem("bundAndSave", false);
            if (this.props.searchFlightAgain == "true") {
                localStorage.setItem(
                    "searchRequestObj",
                    JSON.stringify(this.state.bodyParameters)
                );
                localStorage.setItem("selectedPreference", "package");
                var getUrl = window.location;
                var baseUrl = getUrl.protocol + "//" + getUrl.host;
                window.location = baseUrl + "/flight"

                return true;
            }

            //debugger
            HttpService.postService(this.state.bodyParameters, "/Availability/Search")
                .then((response) => {

                    //debugger
                    localStorage.setItem(
                        "searchRequestObj",
                        JSON.stringify(this.state.bodyParameters)
                    );

                    HttpService.getFlightInformation()
                        .then((response) => {
                            //debugger
                            localStorage.setItem(
                                "FlightInformation",
                                JSON.stringify(response.data.fareCompares)
                            );
                        })
                        .catch((error) => {
                            // console.log(error);
                        });
                    this.setState({ responseFromServer: response.data });

                    this.setState({ isLoading: false });
                })
                .catch((error) => {
                    //debugger
                    this.setState({
                        isLoading: false,
                        messageAPI: "Sorry, something went wrong. Please try again.",
                        networkError: true,
                        networkErrorMessage: true
                    });
                });
        }
    };

    ChildPlusButton = () => {
        if (this.state.TotalNumberOfAdult + this.state.TotalNumberOfChildren < 9) {
            let tempState = this.state;
            tempState.TotalNumberOfChildren = this.state.TotalNumberOfChildren + 1;
            if (this.state.TotalNumberOfChildren >= 2) {
                tempState.ChildText = "Children";
            }
            tempState.passengerText =
                this.state.TotalNumberOfAdult +
                " " +
                this.state.AdultText +
                " , " +
                this.state.TotalNumberOfChildren +
                " " +
                this.state.ChildText +
                " , " +
                this.state.TotalNumberOfInfant +
                " " +
                this.state.InfantText;
            this.setState({ tempState });
        }
    };
    ChildMinusButton = () => {
        if (this.state.TotalNumberOfChildren > 0) {
            let tempState = this.state;
            tempState.TotalNumberOfChildren = this.state.TotalNumberOfChildren - 1;
            if (this.state.TotalNumberOfChildren <= 1) {
                tempState.ChildText = "Child";
            }
            tempState.passengerText =
                this.state.TotalNumberOfAdult +
                " " +
                this.state.AdultText +
                " , " +
                this.state.TotalNumberOfChildren +
                " " +
                this.state.ChildText +
                " , " +
                this.state.TotalNumberOfInfant +
                " " +
                this.state.InfantText;
            this.setState({ tempState });
        }
    };

    numberOfPassengerHandler = (event) => {
        const tempState = this.state.showPassengerSelecter;
        this.setState({ showPassengerSelecter: !tempState });
    };

    AdultPlusButton = () => {
        if (this.state.TotalNumberOfAdult + this.state.TotalNumberOfChildren < 9) {
            let tempState = this.state;
            tempState.TotalNumberOfAdult = this.state.TotalNumberOfAdult + 1;
            if (this.state.TotalNumberOfAdult >= 2) {
                tempState.AdultText = "Adults";
            }
            tempState.passengerText =
                this.state.TotalNumberOfAdult +
                " " +
                this.state.AdultText +
                " , " +
                this.state.TotalNumberOfChildren +
                " " +
                this.state.ChildText +
                " , " +
                this.state.TotalNumberOfInfant +
                " " +
                this.state.TotalNumberOfInfant;
            this.setState({ tempState });
        }
    };
    AdultMinusButton = () => {
        if (this.state.TotalNumberOfAdult > 1) {
            let tempState = this.state;
            tempState.TotalNumberOfAdult = this.state.TotalNumberOfAdult - 1;
            if (this.state.TotalNumberOfAdult <= 1) {
                tempState.AdultText = "Adult";
            }
            tempState.passengerText =
                this.state.TotalNumberOfAdult +
                " " +
                this.state.AdultText +
                " , " +
                this.state.TotalNumberOfChildren +
                " " +
                this.state.ChildText +
                " , " +
                this.state.TotalNumberOfInfant +
                " " +
                this.state.InfantText;
            this.setState({ tempState });
        }
    };
    InfantPlusButton = () => {
        if (this.state.TotalNumberOfInfant < 2) {
            let tempState = this.state;
            tempState.TotalNumberOfInfant = this.state.TotalNumberOfInfant + 1;
            if (this.state.TotalNumberOfInfant >= 2) {
                tempState.InfantText = "Infants";
            }
            tempState.passengerText =
                this.state.TotalNumberOfAdult +
                " " +
                this.state.AdultText +
                " , " +
                this.state.TotalNumberOfChildren +
                " " +
                this.state.ChildText +
                " , " +
                this.state.TotalNumberOfInfant +
                " " +
                this.state.InfantText;
            this.setState({ tempState });
        }
    };
    InfantMinusButton = () => {
        if (this.state.TotalNumberOfInfant > 0) {
            let tempState = this.state;
            tempState.TotalNumberOfInfant = this.state.TotalNumberOfInfant - 1;
            if (this.state.TotalNumberOfInfant <= 1) {
                tempState.InfantText = "Infant";
            }
            tempState.passengerText =
                this.state.TotalNumberOfAdult +
                " " +
                this.state.AdultText +
                " , " +
                this.state.TotalNumberOfChildren +
                " " +
                this.state.ChildText +
                " , " +
                this.state.TotalNumberOfInfant +
                " " +
                this.state.InfantText;
            this.setState({ tempState });
        }
    };
    closeDialog = () => {
        if (this.state.networkErrorMessage) {
            localStorage.setItem("holidaysToken", null);
            window.location.reload(false);
        }
        this.setState({ showDialog: false, messageAPI: null });
    };
    showDialog = () => {
        this.setState({
            responseFromServer: null,
            showDialog: true,
            networkError: false,
        });
    };
    componentWillMount = () => {

        var searchObj = JSON.parse(localStorage.getItem("searchRequestObj"));
        if (this.props.searchFlightAgain == "true") {
            var temp = this.state;
            temp.from = { label: DataService.getFullCityName(searchObj.itineraryParts[0].from.code), value: DataService.getFullCityName(searchObj.itineraryParts[0].from.code) };
            temp.to = { label: DataService.getFullCityName(searchObj.itineraryParts[0].to.code), value: DataService.getFullCityName(searchObj.itineraryParts[0].to.code) };
            temp.startDate = moment(searchObj.itineraryParts[0].when.date);
            try {
                temp.endDate = moment(searchObj.itineraryParts[1].when.date);
            } catch {
            }
            this.setState(temp);

        }
    }
    render() {
        let showRemoveLabel = null;
        let loading = null;
        var numberOfMonth;
        if (isMobile) {
            numberOfMonth = 1;
        }
        if (isBrowser) {
            numberOfMonth = 3;
        }

        if (this.state.isLoading) {
            loading = (
                <div className="Loading-div">
                    <div className="Loading">
                        <ProgressLoader displayMess="Searching Available Flights" />
                    </div>
                </div>
            );
        }
        if (this.state.responseFromServer != null || this.state.networkError) {
            if (this.state.networkError) {
                this.showDialog();
            } else if (this.state.responseFromServer != null) {
                if (this.state.responseFromServer.single.statusAPI === "ERROR") {
                    this.setState({
                        messageAPI: this.state.responseFromServer.single.messageAPI,
                    });
                    this.showDialog();
                } else if (
                    this.state.responseFromServer.single.statusAPI === "SUCCESS" &&
                    this.state.responseFromServer.single.searchResponse.brandedResults
                        .itineraryPartBrands[0].length === 0
                ) {
                    this.setState({
                        messageAPI: this.state.responseFromServer.single.messageAPI,
                    });
                    this.showDialog();
                } else {
                    if (this.state.showOneWay) {
                        localStorage.setItem("selectedPreference", "package");
                        localStorage.setItem("isMulticity", "no");
                        return (
                            <Redirect
                                to={{
                                    pathname: "/flight",
                                    props: {
                                        result: this.state.responseFromServer,
                                        type: "oneWay",
                                        category: "package",
                                        hotelSearch: this.hotelSearch,
                                    },
                                }}
                            />
                        );
                    } else {
                        localStorage.setItem("selectedPreference", "package");
                        localStorage.setItem("isMulticity", "no");
                        return (
                            <Redirect
                                to={{
                                    pathname: "/flight",
                                    props: {
                                        result: this.state.responseFromServer,
                                        type: "round",
                                        category: "package",
                                        hotelSearch: this.hotelSearch,
                                    },
                                }}
                            />
                        );
                    }
                }
            }
        }
        let airpotList = [];
        airpotList = AirportList.getAirports();
        if (airpotList != null && airpotList.length > 0) {
            let airports = [];
            airpotList.map((Element, index) => {
                airports.push({ value: Element, label: Element });
            });
            airpotList = [...airports]
        }
        else {
            airpotList = AirportList.getAll;
        }
        let customStylesFrom = {
            option: (provided, state) => ({
                ...provided,
                "text-align": "left",
                color: state.isSelected ? "white" : "black",
                width: state.selectProps.width,
                backgroundColor: state.isSelected ? "#4caf50" : "white",
                "&:hover": {
                    backgroundColor: "#4caf50",
                    color: "white",
                },
            }),
            control: (base, state) => ({
                ...base,
                "min-height": "50px",
                "min-width": "100% !important",
                "&:active": {
                    "border-color": "#4caf50 !important",
                    "box-shadow": "none !important",
                    outline: "none !important",
                },
                "&:hover": {
                    "border-color": "#4caf50 !important",
                    "box-shadow": "none !important",
                    outline: "none !important",
                },
                "&:focus": {
                    "border-color": "#4caf50 !important",
                    "box-shadow": "none !important",
                    outline: "none !important",
                },
            }),
        };
        let customStylesTo = {
            option: (provided, state) => ({
                ...provided,
                "text-align": "left",
                color: state.isSelected ? "white" : "black",
                width: state.selectProps.width,
                backgroundColor: state.isSelected ? "#4caf50" : "white",
                "&:hover": {
                    backgroundColor: "#4caf50",
                    color: "white",
                },
            }),
            control: (base, state) => ({
                ...base,
                "min-height": "50px",
                "min-width": "100% !important",
                "&:active": {
                    "border-color": "#4caf50 !important",
                    "box-shadow": "none !important",
                    outline: "none !important",
                },
                "&:hover": {
                    "border-color": "#4caf50 !important",
                    "box-shadow": "none !important",
                    outline: "none !important",
                },
                "&:focus": {
                    "border-color": "#4caf50 !important",
                    "box-shadow": "none !important",
                    outline: "none !important",
                },
            }),
        };
        if (this.state.isFromValid) {
            customStylesFrom = {
                option: (provided, state) => ({
                    ...provided,
                    "text-align": "left",
                    color: state.isSelected ? "white" : "black",
                    width: state.selectProps.width,
                    backgroundColor: state.isSelected ? "#4caf50" : "white",
                    "&:hover": {
                        backgroundColor: "#4caf50",
                        color: "white",
                    },
                }),
                control: (base, state) => ({
                    ...base,
                    "min-height": "50px",
                    "min-width": "100% !important",
                    "border-color": "#dc3545",
                    "background-image":
                        " url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' hei…circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e) !important",
                    "background-repeat": "no-repeat",
                    "background-position": "right calc(.375em + .1875rem) center centerWidget",
                    " background-size": "calc(.75em + .375rem) calc(.75em + .375rem)",
                    "&:active": {
                        "border-color": "#4caf50 !important",
                        "box-shadow": "none !important",
                        outline: "none !important",
                    },
                    "&:hover": {
                        "border-color": "#4caf50 !important",
                        "box-shadow": "none !important",
                        outline: "none !important",
                    },
                    "&:focus": {
                        "border-color": "#4caf50 !important",
                        "box-shadow": "none !important",
                        outline: "none !important",
                    },
                }),
            };
        }
        if (this.state.isToValid) {
            customStylesTo = {
                option: (provided, state) => ({
                    ...provided,
                    "text-align": "left",
                    color: state.isSelected ? "white" : "black",
                    width: state.selectProps.width,
                    backgroundColor: state.isSelected ? "#4caf50" : "white",
                    "&:hover": {
                        backgroundColor: "#4caf50",
                        color: "white",
                    },
                }),
                control: (base, state) => ({
                    ...base,
                    "min-height": "50px",
                    "min-width": "100% !important",
                    "border-color": "#dc3545",
                    "background-image":
                        " url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' hei…circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e) !important",
                    "background-repeat": "no-repeat",
                    "background-position": "right calc(.375em + .1875rem) center centerWidget",
                    " background-size": "calc(.75em + .375rem) calc(.75em + .375rem)",
                    "&:active": {
                        "border-color": "#4caf50 !important",
                        "box-shadow": "none !important",
                        outline: "none !important",
                    },
                    "&:hover": {
                        "border-color": "#4caf50 !important",
                        "box-shadow": "none !important",
                        outline: "none !important",
                    },
                    "&:focus": {
                        "border-color": "#4caf50 !important",
                        "box-shadow": "none !important",
                        outline: "none !important",
                    },
                }),
            };
        }
        let airportListLable = airpotList.map((Element, index) => {
            return (
                <option className="airportlist" value={Element}>
                    {Element}
                </option>
            );
        });
        let fligthList = this.state.flight.map((Element, index) => {
            return <Element key={index} index={index} />;
        });
        return (
            <div className="tab-content-widget">
                <Modal
                    show={this.state.showDialog}
                    onHide={this.closeDialog}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="closingModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div id="wrapper-sc">
                                <img
                                    class="planeImage-sc"
                                    src="https://d22j4fzzszoii2.cloudfront.net/images/1521/2314/EthiopainAirlinesPlane.png"
                                    width="633"
                                    height="338"
                                    alt="Ethiopain Airlines"
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4> Oops! Something went wrong. </h4> <br/>
                        {this.state.networkErrorMessage ? <p style={{ textAlign: "center centerWidget" }}><b>Please reload the page to try again.</b>
                            <br />
                            <FontAwesomeIcon
                                icon={faSync}
                                style={{ color: "#60ac5d", fontSize: "4em", cursor: "pointer", textAlign: "center", marginLeft: "30%", marginTop:"15px" }}
                                onClick={() => {
                                    localStorage.setItem("holidaysToken", null);
                                    window.location.reload(false);
                                }}
                            />
                        </p> : <span>Sorry, we can't find your flight, please try again or change your search criteria.</span>
                        }
                    </Modal.Body>
                </Modal>
                {loading}
                <Form id="flightPlusPackageForm" onSubmit={this.search} method="Post">
                    <Form.Row>
                        {!this.state.formValid &&
                            <Alert className="alertField" variant={"danger"}>
                                Please select all required fields.
                            </Alert>
                        }
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="departureAirport">
                                        <span className="et-icons departure-flight-icon"></span>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <div className="autocomplete-wrapper">
                                    <Select
                                        // focus() 
                                        value={this.state.from}
                                        onChange={(value) => this.setState({ from: value, formValid: true })}
                                        options={airpotList}
                                        isSearchable={true}
                                        onFocus={() => this.setState({ isFromValid: false })}
                                        styles={customStylesFrom}
                                        placeholder={<div>Departure Airport</div>}
                                        id="flight-package-departure-airport"
                                    />
                                </div>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}  >
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="departureAirport">
                                        <span className="et-icons arrival-flight-icon"></span>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <div className="autocomplete-wrapper">
                                    <Select
                                        value={this.state.to}
                                        onChange={(value) => this.setState({ to: value, formValid: true })}
                                        options={airpotList}
                                        isSearchable={true}
                                        styles={customStylesTo}
                                        onFocus={() => this.setState({ isToValid: false })}
                                        placeholder={<div>Arrival Airport</div>}
                                        id="flight-package-arrival-airport"
                                    />
                                </div>
                            </InputGroup>
                        </Form.Group>
                        {(false) &&
                            <Form.Group as={Col}   >
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="date-range-icon">
                                            <DateRangeIcon />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <div >
                                        <DateRangePicker
                                            required={true}
                                            readOnly={true}
                                            noBorder={true}
                                            numberOfMonths={numberOfMonth}
                                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                            startDateId="start_date4" // PropTypes.string.isRequired,
                                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                            endDateId="end_date4" // PropTypes.string.isRequired,
                                            startDatePlaceholderText="Departure Date"
                                            endDatePlaceholderText={this.props.journeyType}
                                            onDatesChange={({ startDate, endDate }) => {
                                                this.setState({ startDate, endDate })
                                            }
                                            } // PropTypes.func.isRequired,
                                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                            onFocusChange={(focusedInput) =>
                                                this.setState({ focusedInput })
                                            } // PropTypes.func.isRequired,
                                        />


                                    </div>
                                </InputGroup>
                            </Form.Group>
                        } {" "}
                        {(true) && <>
                            <Form.Group as={Col}  >
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="date-range-icon">
                                            <DateRangeIcon />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <div className={this.state.valid_date}>

                                        <SingleDatePicker
                                            required={true}
                                            readOnly={true}
                                            noBorder={true}
                                            numberOfMonths={1}

                                            date={this.state.startDate} // momentPropTypes.momentObj or null,
                                            id="start_date4"
                                            showClearDate
                                            placeholder="Departure Date"
                                            // small={true}
                                            // date={this.state.date} // momentPropTypes.momentObj or null
                                            onDateChange={date => {
                                                this.setState({
                                                    startDate: date, formValid: true,
                                                    valid_date: "valid_date"
                                                })
                                                if (this.state.endDate && date) {
                                                    if ((new Date(this.state.endDate).getTime()) <= (new Date(date).getTime())) {
                                                        this.setState({ endDate: date })

                                                    }
                                                    // console.log(new Date(date).getTime()) 
                                                }


                                                this.setState({ focusedEnd: true })

                                            }} // PropTypes.func.isRequired
                                            focused={this.state.focusedStart} // PropTypes.bool
                                            onFocusChange={({ focused }) => this.setState({ focusedStart: focused })} // PropTypes.func.isRequired
                                            // id="your_unique_id" // PropTypes.string.isRequired,
                                            block
                                        />


                                    </div>
                                </InputGroup>
                            </Form.Group>{" "}

                            <Form.Group as={Col}  >
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="date-range-icon">
                                            <DateRangeIcon />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <div className={this.state.valid_date}>

                                        <SingleDatePicker
                                            required={true}
                                            readOnly={true}
                                            noBorder={true}
                                            numberOfMonths={1}
                                            // minDate={}
                                            date={this.state.endDate} // momentPropTypes.momentObj or null,
                                            id="start_date4"
                                            showClearDate
                                            placeholder={(this.props.journeyType == "Validity") ? "Package Validity" : "Return Date"}
                                            // small={true}
                                            // date={this.state.date} // momentPropTypes.momentObj or null
                                            onDateChange={endDate => {
                                                this.setState({
                                                    endDate: endDate, formValid: true,
                                                    valid_date: "valid_date"
                                                })
                                                if (this.state.startDate && endDate) {
                                                    if ((new Date(this.state.startDate).getTime()) >= (new Date(endDate).getTime())) {
                                                        this.setState({ startDate: endDate })

                                                    }
                                                    // console.log(new Date(endDate).getTime()) 
                                                }
                                            }} // PropTypes.func.isRequired
                                            focused={this.state.focusedEnd} // PropTypes.bool
                                            onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })} // PropTypes.func.isRequired
                                            // id="your_unique_id" // PropTypes.string.isRequired,
                                            block
                                        />


                                    </div>
                                </InputGroup>
                            </Form.Group>{" "}

                        </>
                        }
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="TravelersRoom">
                                        <span className="et-icons passengers-icon"></span>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    value={this.state.passengerText}
                                    name="TravelersRoom"
                                    readOnly
                                    className="trip"
                                    required
                                    aria-label="DepartureAirport"
                                    aria-describedby="DepartureAirport"
                                    onClick={this.numberOfPassengerHandler}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <InputGroup className="mb-3">
                                <select
                                    className="form-control select-widget"
                                    onChange={(e) => this.setState({ class: e.target.value })}
                                >
                                    <option defaultValue value={3}>
                                        All Class
                                    </option>
                                    <option value={1}>Economy</option>
                                    <option value={2}>Business</option>
                                </select>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <InputGroup className="mb-3 ">
                                {/* justify-content-center */}
                                <button className="search-btn2 btn btn-success" type="submit">
                                    Search
                                </button>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <Modal
                    show={this.state.showPassengerSelecter}
                    onHide={this.numberOfPassengerHandler}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ paddingLeft: "1em" }}>
                            <div className="row">
                                <div className="col-6">
                                    <span>{this.state.AdultText}</span>
                                </div>
                                <div className="col-6 mb-2">
                                    <div className="top-circle">
                                        <button className="btn" onClick={this.AdultMinusButton}>
                                            <RemoveIcon className="inside-icon-minus" />
                                        </button>
                                    </div>
                                    &nbsp;&nbsp;
                                    <span> {this.state.TotalNumberOfAdult}</span>
                                    &nbsp;&nbsp;
                                    <div className="top-circle">
                                        <button className="btn" onClick={this.AdultPlusButton}>
                                            <AddIcon className="inside-icon-plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <span>{this.state.ChildText}</span>
                                </div>
                                <div className="col-6 mb-2">
                                    <div className="top-circle">
                                        <button className="btn" onClick={this.ChildMinusButton}>
                                            <RemoveIcon className="inside-icon-minus" />
                                        </button>
                                    </div>
                                    &nbsp;&nbsp;
                                    <span> {this.state.TotalNumberOfChildren}</span>
                                    &nbsp;&nbsp;
                                    <div className="top-circle">
                                        <button className="btn" onClick={this.ChildPlusButton}>
                                            <AddIcon className="inside-icon-plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <span>{this.state.InfantText}</span>
                                </div>
                                <div className="col-6 mb-2">
                                    <div className="top-circle">
                                        <button className="btn" onClick={this.InfantMinusButton}>
                                            <RemoveIcon className="inside-icon-minus" />
                                        </button>
                                    </div>
                                    &nbsp;&nbsp;
                                    <span> {this.state.TotalNumberOfInfant}</span>
                                    &nbsp;&nbsp;
                                    <div className="top-circle">
                                        <button className="btn" onClick={this.InfantPlusButton}>
                                            <AddIcon className="inside-icon-plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="search-btn btn btn-success"
                            onClick={this.numberOfPassengerHandler}
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default FlightPackage;
