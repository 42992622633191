export class MealOption {
  static getAll = [
    { value: "AVML", label: "ASIAN VEGETARIAN MEAL" },
    { value: "BLML", label: "BLAND MEAL" },
    { value: "DBML", label: "DIABETIC MEAL" },
    { value: "FPML", label: "FRUIT PLATTER" },
    { value: "GFML", label: "GLUTEN-FREE MEAL" },
    { value: "HNML", label: "HINDU (NON VEGETARIAN) MEAL" },
    { value: "KSML", label: "KOSHER MEAL" },
    { value: "LCML", label: "LOW CALORIE MEAL" },
    { value: "LFML", label: "LOW CHOLESTEROL/LOW FAT MEAL" },
    { value: "LSML", label: "LOW SODIUM, NO SALT ADDED" },
    { value: "MOML", label: "MOSLEM MEAL" },
    { value: "NLML", label: "NON LACTOSE MEAL" },
    { value: "RVML", label: "RAW VEGETARIAN MEAL" },
    { value: "SFML", label: "SEA FOOD MEAL" },
  ];
}
