 
import React, { useState } from "react";
import { Document, Page } from "react-pdf";

export default function Brochure(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    document.body.style.zoom=1.5;
    setNumPages(numPages);
  }
  return (
    <Document
      file="/Brochure2.pdf"
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
} 