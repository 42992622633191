import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { HttpService } from "./../../services/http/http-service";

import Search from "@material-ui/icons/Search";
import Autosuggest from "react-autosuggest";
import ProgressLoader from "../shared/progress-loading/progress-loader";


var languages = [];

const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : languages.filter(
        (lang) => lang.toLowerCase().slice(0, inputLength) === inputValue
      );
};
const renderSuggestion = (suggestion) => (
  <span className="name">{suggestion}</span>
);

const getSuggestionValue = (suggestion) => suggestion;

class SearchHotel extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, searchParameter: "",
    value: "",
    progressLoading:false,
    suggestions: [], };
    this.searchHotel = this.searchHotel.bind(this);
    this.onSearchParameterChanged = this.onSearchParameterChanged.bind(this);
  }
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    // console.log("method",method)
    if (method === "click" || method === "enter") {
      this.search(newValue);
    }
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };




  async searchHotel(e) {
    this.setState({progressLoading:true})
    // e.preventDefault();
    let relativePath = "HotelAvailability/SearchAvailability";

    relativePath +=
      "?SessionId=" +
      localStorage.getItem("sessionId") +
      "&SearchParameter=" +
      this.state.searchParameter;
    await this.setState({ isLoading: true });
    let searchResponse = await HttpService.getServiceHotel(relativePath);
    await this.setState({ isLoading: false,progressLoading:false});

    this.props.searchHotel(searchResponse);
  }
  // async onSearchParameterChanged(event) {
  //   await this.setState({ searchParameter: event.target.value });
  // }


  
  onSearchParameterChanged = async(searchValue = null) => {
    if (this.state.value !== "" || searchValue !== null) {
      var searchkey = "";
      if (searchValue !== null) {
        searchkey =  searchValue;
      } else {
        searchkey =  this.state.value;
      }
////////////////

    await this.setState({ searchParameter: searchkey });
this.searchHotel("");

//////////
    }
  };


  renderOnProgress() {
    if (this.state.isLoading) {
      return (
        <div
          className="spinner-border text-primary float-right "
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else {
      return (
        <button
          type="submit"
          className="btn  mb-2 float-right p-0 pt-2 pl-2 hotel-search-btn"
        >
          <FontAwesomeIcon
            icon={faSearch}
            style={{ color: "#60ac5d", fontSize: "25px" }}
          />
        </button>
      );
    }
  }
  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder:"Search hotel...",
      value,
      onChange: this.onChange,
      type: "search",
    };
    return (<>
      {/* <div className="row m-0"> */}
        {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
          <form className="form m-0" onSubmit={this.searchHotel}>
            {this.renderOnProgress()}
            <input
              type="text"
              className="form-control mb-2 col-10 col-sm-11 col-md-10 col-lg-8 col-xl-10 float-right"
              id="hotel-search-input"
              placeholder="Search hotel..."
              value={this.state.value}
              required
              onChange={this.onSearchParameterChanged}
            />
          </form>
        </div> */}

        <div  className=" pl-2" style={{ display: "inline" }}>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
              <button className="search-button" onClick={() => this.onSearchParameterChanged()}>
                <Search />
              </button>
            </div>



      {/* </div> */}
      
    { this.state.progressLoading&& <div>
        <ProgressLoader displayMess="" />
      </div>}
      </>
    );
  }
}

export default SearchHotel;
