class Passenger {
  params = {
    id: 1,
    passengerIndex: 1,
    passengerDetails: {
      firstName: null,
      lastName: null,
      prefix: null,
      middleName: null,
      maidenName: null,
      suffix: null,
    },
    passengerInfo: {
      dateOfBirth: null,
      gender: null,
      redressNumber: null,
      knownTravelerNumber: null,
      type: null,
      emails: [],
      phones: [],
      address: null,
    },
    preferences: {
      specialPreferences: {
        mealPreference: null,
        seatPreference: null,
        specialRequests: [],
      },
      frequentFlyer: [
      ],
    },
    documentInfo: null,
    visaInfo: null,
  };
}

export default Passenger;
