import React, { Component } from "react";
import { HttpService } from "../../../services/http/http-service";
import { Redirect } from "react-router-dom";
import ProgressLoader from "../../shared/progress-loading/progress-loader";
import Modal from "react-bootstrap/Modal";


class StopOver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      bodyParameters: {
        cabinClass: 3,
        awardBooking: false,
        searchType: 1,
        promoCodes: null,
        itineraryParts: [],
        passengers: {
          ADT: 1,
          CHD: 0,
          INF: 0,
        },
      },
      responseFromServer:null
    } 
    };
    componentDidMount=()=>{
      
      const searchData = this.props.location.search;
      var searchTrip = this.state.bodyParameters;
      searchTrip.cabinClass =parseInt(new URLSearchParams(searchData).get('class'));
      searchTrip.passengers.ADT = parseInt(new URLSearchParams(searchData).get('ADT'));
      searchTrip.passengers.CHD = parseInt(new URLSearchParams(searchData).get('CHD'));
      searchTrip.passengers.INF = parseInt(new URLSearchParams(searchData).get('INF'));
      let maxFlight = parseInt(new URLSearchParams(searchData).get('maxFlight'));
      for (let index = 0; index < maxFlight; index++) {
        var originName  = "origin";
        var destinationName = "destination";
        var date = "date";
        if(index !=0){
           originName  = "origin"+index;
           destinationName = "destination"+index;
           date = "date"+index;
        }
        let tempitineraryParts = {
          from: {
            code: new URLSearchParams(searchData).get(originName)
          },
          to: {
            code:new URLSearchParams(searchData).get(destinationName)
          },
          when: {
            date: new URLSearchParams(searchData).get(date)
          },
        };
        searchTrip.itineraryParts.push(tempitineraryParts);
      }
      var numberOfNight = new URLSearchParams(searchData).get('night');
      localStorage.setItem("numberOfNight",numberOfNight);
      this.setState({bodyParameters:searchTrip});
      localStorage.clear();
      HttpService.getToken().then((response)=>{
        let newToken =  response.data.token;
        localStorage.setItem("holidaysToken", newToken);
        this.search();
      }).catch(()=>{
        this.showDialog();
      });
        }
        search =()=>{
          
      HttpService.postService(this.state.bodyParameters, "/Availability/Search")
      .then((response) => {
        
            localStorage.setItem(
          "searchRequestObj",
          JSON.stringify(this.state.bodyParameters)
        );
        let responseFromServer =  response.data;
              
        HttpService.getFlightInformation()
          .then((response) => {
            
            localStorage.setItem("totalFlighAmount", 0);
            localStorage.setItem("flightFare", 0);
            localStorage.setItem("flightTax", 0);
            localStorage.setItem("hotelTotalPrice", 0);
            localStorage.setItem("surcharges", 0);
            localStorage.setItem("Currency", "");
            localStorage.setItem("flightInfo",null);
            localStorage.setItem("bundAndSave", false);
            localStorage.setItem("selectedPreference", "package");
            localStorage.setItem(
              "FlightInformation",
              JSON.stringify(response.data.fareCompares)
            );
            this.setState({ responseFromServer: responseFromServer,isLoading: false });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
          });

      })
      .catch((error) => {
        this.setState({ isLoading: false });

      });
        }
        closeDialog = () => { 
          this.props.history.push({
            pathname: "/",
          });
        };
        showDialog = () => {
          this.setState({
            responseFromServer: null,
            showDialog: true,
          });
        };
  render() {
    if (this.state.responseFromServer != null) {
      if (this.state.networkError) {
        this.showDialog();
      } else if (this.state.responseFromServer != null) {
        if (this.state.responseFromServer.single.statusAPI === "ERROR") {
          this.setState({
            messageAPI: this.state.responseFromServer.single.messageAPI,
          });
          this.showDialog();
        } else if (
          this.state.responseFromServer.single.statusAPI === "SUCCESS" &&
          this.state.responseFromServer.single.searchResponse.brandedResults
            .itineraryPartBrands[0].length === 0
        ) {
          this.setState({
            messageAPI: this.state.responseFromServer.single.messageAPI,
          });
          this.showDialog();
        } else {
            localStorage.setItem("selectedPreference", "package");
            localStorage.setItem("isMulticity", "yes");
            return (
              <Redirect
              to={{
                pathname: "/flight",
                props: {
                  result: this.state.responseFromServer,
                  category: "multiCity",
                },
                category: "multiCity",
              }}
            />
            );
          }
      }
    }
    let loading = null;
    if (this.state.isLoading) {
      loading = (
        <div className="Loading-div">
          <div className="Loading">
            <ProgressLoader displayMess="Searching Available Flight" />
          </div>
        </div>
      );
    }
      return(

        <div className="tab-content-widget">
          {loading}
        <Modal
          show={this.state.showDialog}
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="closingModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div id="wrapper-sc">
                <img
                  class="planeImage-sc"
                  src="https://d22j4fzzszoii2.cloudfront.net/images/1521/2314/EthiopainAirlinesPlane.png"
                  width="633"
                  height="338"
                  alt="Ethiopain Airlines"
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2> {this.state.messageAPI} </h2>
            {/* <h2>sorry something went wrong.</h2> */}
            <p>
              Sorry we can't find your flight, please try again or change your
              search criteria
            </p>
          </Modal.Body>
        </Modal>
        </div>
      );
    }
}
export default StopOver;
