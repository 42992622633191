import React, { Component, Suspense } from "react";
import HomeSlider from "./slider/slider";
import Widget from "./widget/widget";
import MataDecorator from "../shared/meta-decorator/meta-helmet";
import "video-react/dist/video-react.css";

import TagManager from "react-gtm-module";
import { Offline, Online } from "react-detect-offline";
import lazy from "react-lazy-with-preload";
import VideoPlayer from "../video-player/video-player";
import "./home.css";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "project",
    page: "home",
  },
  dataLayerName: "PageDataLayer",
};
const TopDestinations = lazy(() =>
  import("./top-destinations/top-destinations")
);
const SpecialOffer = lazy(() => import("./special-offers/special-offer"));
const HolidaysType = lazy(() => import("./holidays-type/holidays-type"));
//#  check updated tasks
class Home extends Component {
  constructor(props) {
    super(props);
    let currentpageURL = window.location.href;
    this.state = {
      pageURL: currentpageURL,
    };
  }
  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <div>
        <MataDecorator
          metaInfo={{
            title: "Welcome to Ethiopian Holidays Official Website", //packageDetails.package.name,
            description:
              "Ethiopian Holidays could help you plan your vacation.Special offers from Africa's leading airlines Plan your vacations and holidays to the most fascinating places with Ethiopian Airlines.", //htmlToText(packageDetails.package.description),
            // image:packageDetails.package.imagePath,
            url: this.state.pageURL,
            // twitterCard:packageDetails.package.imagePath,
            // imageAlt:packageDetails.package.name,
          }}
        />
        {/* <Online> */}
        <HomeSlider />
        <Widget />
        {/* </Online>   */}
        <Suspense fallback={<p></p>}>
          <div className="pb-5" style={{ marginTop: "-98px" }}>
            <div className=" mb-0 mt-0">
              <HolidaysType />
            </div>
            <div className=" mb-0 mt-0">
              <TopDestinations />
            </div>
            <div className="row">
              <div className="video-container">
                <p className="lead">
                  Discover the newly renovated attraction sites in Ethiopia -
                  Wanchi crater lakes, Halala Kella and Chebera Churchura
                  National Park and witness the remarkable treasures of
                  Ethiopia's nature and culture.
                </p>
                <VideoPlayer />
              </div>
            </div>
          </div>
        </Suspense>
      </div>
    );
  }
}

export default Home;
