class Itinerary {
  params = {
    from: {
      code: null,
    },
    to: {
      code: null,
    },
    when: {
      date: null,
    },
    selectedOfferRef: null,
  };
}

export default Itinerary;
