import React, { Component } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";

class FlightBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.modalHeader = null;
    this.modalBody = [];
    this.flightInfo = [];
  }

  getSelectedBrandInfoShoppingCode(shoppingBasketHashCode,
    arrivalDate,
    departDate,
    amount,
    fare,
    tax,
    currency,
    type,
    flightInfo) {
      
      var localFlightInfo = JSON.parse(localStorage.getItem("flightInfo"));
      if(localFlightInfo == null){
        localFlightInfo=[];
      }
      var isMulticity = localStorage.getItem("isMulticity");
      if(isMulticity == "yes"){
        flightInfo.itineraryParts.forEach(element => {
          var currentFlightInfo={
            "amount":amount,
            "class":type,
            "departDate":element.segments[0].departure,
            "arrivalDate":element.segments.pop().arrival,
            "fare":fare,
            "tax":tax,
            "surcharges":null,
            "shoppingBasketHashCode":shoppingBasketHashCode,
          };
          localFlightInfo.push(currentFlightInfo);
        });
        localStorage.setItem("flightInfo",JSON.stringify(localFlightInfo));
        localStorage.setItem("shoppingBasketHashCode", shoppingBasketHashCode );
      }
      else{
        var currentFlightInfo={
          "amount":amount,
          "class":type,
          "departDate":departDate,
          "arrivalDate":arrivalDate,
          "fare":fare,
          "tax":tax,
          "surcharges":null,
          "shoppingBasketHashCode":shoppingBasketHashCode,
        };
        
        localFlightInfo.push(currentFlightInfo);
        localStorage.setItem("flightInfo",JSON.stringify(localFlightInfo));
        localStorage.setItem("shoppingBasketHashCode", shoppingBasketHashCode );
      }

    this.props.getSelectedBrandShoppingCode(shoppingBasketHashCode,arrivalDate,departDate);
  }

  closeDialog = () => {
    this.setState({ showModal: false });
  };
  viewDailog = (type) => {
    
    this.modalBody = [];
    this.modalHeader = type;
    if (this.flightInfo.length == 0) {
      this.flightInfo = JSON.parse(localStorage.getItem("FlightInformation"));
    }
    this.flightInfo.forEach((element) => {
      if (element.brand == type) {
        this.modalBody.push(
          <div className="collection-item">
            <span>{element.brandAttribiute}</span>
            <span style={{ float: "right" }}>{element.brandValue}</span>
          </div>
        );
      }
    });

    this.setState({ showModal: true });
  };
  renderBrand = () => {
    const { brand, itineraryPart } = this.props;
    let selectedBrand = this.props.selectedBrand;
    // console.log("selected brand............");
    // console.log(itineraryPart);
    if (selectedBrand === "Eco") {
      if (brand.brandId !== "EA" && brand.cabinClass === "Economy") {
        return (
          <>
            <br />
            <span className="float-left brand-info-icon">
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ color: "#60ac5d", fontSize: "25px" }}
                className="pr-2"
                onClick={this.viewDailog.bind(this, brand.brandLabel)}
              />
            </span>
            <div
              className="fare-option-card mt-3"
              onClick={() =>
                this.getSelectedBrandInfoShoppingCode(
                  brand.shoppingBasketHashCode,
                  itineraryPart.arrival,
                  itineraryPart.departure,
                  brand.total.alternatives[0][0].amount,
                  brand.fare.alternatives[0][0].amount,
                  brand.taxes.alternatives[0][0].amount,
                  brand.total.alternatives[0][0].currency,
                  "Economy",
                  itineraryPart
                  )
              }
              title="Click to select"
            >
              <div className="row m-0 fare-option-header">
                <div className="col-6 brand-name">
                  <span className="float-right brand-info-icon">
                    {brand.brandLabel}
                  </span>
                </div>
                <div className="col-6 text-right" style={{ color: "#60ac5d" }}>
                  {" "}
                  <span>{brand.total.alternatives[0][0].amount + " "}</span>
                  <span>{brand.total.alternatives[0][0].currency}</span>
                <button  className="btn  submit-fare-option-btn ml-2" size="small">select</button>
                </div>
              </div>
              <div className="row m-0 pt-3 pb-4 fare-option-body">
                <div className="col-sm-4 col-4 fare-col">
                  <div className="fare-title mb-2">Fare</div>
                  <div className="fare-price">
                    <span>{brand.fare.alternatives[0][0].amount + " "}</span>
                    <span>{brand.fare.alternatives[0][0].currency}</span>{" "}
                  </div>
                </div>
                <div className="col-sm-4 col-4 tax-col">
                  <div className="tax-title  mb-2">Tax</div>
                  <div className="tax-price">
                    <span>{brand.taxes.alternatives[0][0].amount + " "}</span>
                    <span>{brand.taxes.alternatives[0][0].currency}</span>
                  </div>
                </div>
                <div className="col-sm-4 col-4">
                  <div className="seat-left-title  mb-2">Seat Left</div>
                  <div className="seat-left-value">
                    {brand.seatsRemaining.count}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
    if (selectedBrand === "Bus") {
      if (brand.brandId !== "BA" && brand.cabinClass === "Business") {
        return (
          <>
            <br />
            <span className="float-left brand-info-icon">
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ color: "#60ac5d", fontSize: "25px" }}
                className="pr-2"
                onClick={this.viewDailog.bind(this, brand.brandLabel)}
              />
            </span>
            <div
              className="fare-option-card mt-3"
              onClick={() =>
                this.getSelectedBrandInfoShoppingCode(
                  brand.shoppingBasketHashCode,
                  this.flightInfo.arrival,
                  this.flightInfo.departure,
                  brand.total.alternatives[0][0].amount,
                  brand.fare.alternatives[0][0].amount,
                  brand.taxes.alternatives[0][0].amount,
                  brand.total.alternatives[0][0].currency,
                  "Business",
                  this.flightInfo
                )
              }
            >
              <div className="row m-0 fare-option-header">
                <div className="col-6 brand-name">{brand.brandLabel}</div>
                <div className="col-6 text-right" style={{ color: "#60ac5d" }}>
                  <span>{brand.total.alternatives[0][0].amount + " "}</span>
                  <span>{brand.total.alternatives[0][0].currency}</span>
                <button  className="btn  submit-fare-option-btn ml-2" size="small">select</button>
                </div>
              </div>
              <div className="row m-0 pt-3 pb-4 fare-option-body">
                <div className="col-sm-4 col-4 fare-col">
                  <div className="fare-title mb-2">Fare</div>
                  <div className="fare-price">
                    <span>{brand.fare.alternatives[0][0].amount + " "}</span>
                    <span>{brand.fare.alternatives[0][0].currency}</span>{" "}
                  </div>
                </div>
                <div className="col-sm-4 col-4 tax-col">
                  <div className="tax-title  mb-2">Tax</div>
                  <div className="tax-price">
                    <span>{brand.taxes.alternatives[0][0].amount + " "}</span>
                    <span>{brand.taxes.alternatives[0][0].currency}</span>{" "}
                  </div>
                </div>
                <div className="col-sm-4 col-4">
                  <div className="seat-left-title  mb-2">Seat Left</div>
                  <div className="seat-left-value">
                    {" "}
                    {brand.seatsRemaining.count}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
    return <></>;
  };
  render() {
    return (
      <>
        <Modal
          show={this.state.showModal}
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#60ac5d", color: "white" }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              {this.modalHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.modalBody}</Modal.Body>
        </Modal>
        {this.renderBrand()}
      </>
    );
  }
}

export default FlightBrand;
