import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar,faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
class HotelRating extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRating() {
    let starRating = [];
    let hoteStar = parseInt(this.props.hotelRating.replace(/[^0-9]/g, "")[0]);
    let halfHoteStar = parseInt(this.props.hotelRating.replace(/[^0-9]/g, "")[1]);

    for (let i = 0; i < hoteStar; i++) {
      starRating.push("star");
    }
    if(halfHoteStar == 5){
      starRating.push('halfStar')
    }
    return (
      <span>
        {starRating.map((star, key) => (
          <>
          {star =="star"?          <span key={key}>
            {" "}
            <FontAwesomeIcon
              icon={faStar}
              style={{ color: "#60ac5d", fontSize: "13px" }}
            />
          </span>:
                    <span key={key}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faStarHalfAlt}
                      style={{ color: "#60ac5d", fontSize: "13px" }}
                    />
                  </span> }
          </>
        ))}
      </span>
    );
  }
  render() {
    return <>{this.renderRating()}</>;
  }
}

export default HotelRating;
