import React, { Component } from "react";
import FlightBrand from "./flight-brand";
class FareOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itineraryParts: null,
      brandOffers: [],
      selectedBrand: "Eco",
      isEconomyBrandSelected: true,
      isBusinessBrandSelected: false,
    };
    this.itineraryParts = null;
  }
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    await this.setState({
      brandOffers: this.props.state.brandOffers,
    });
    await this.setState({
      itineraryPart: this.props.state,
    });
  }
  renderEconomyCheapPrice = () => {
    if (this.state.itineraryPart != null && this.state.itineraryPart.echeepPrice != "N/A") {
      return(<div>
        <div>
              <span>Economy</span>
            </div>
            <div className="fare-opt-cabin-currency">
              <span>From</span>
              {/* <span>AED</span> */}
            </div>
            <div className="fare-opt-cabin-price">
            <span>{this.state.itineraryPart.echeepPrice}</span>
            </div>
      </div>);
    }
  };
  renderBusinessCheapPrice = () => {
    if (this.state.itineraryPart != null && this.state.itineraryPart.bcheepPrice !="N/A" ) {
      return(<div>
          <div>
              <span>Business</span>
            </div>
            <div className="fare-opt-cabin-currency">
              <span>From </span>
            </div>
            <div className="fare-opt-cabin-price">
            <span>{this.state.itineraryPart.bcheepPrice}</span>
            </div>
      </div>);
    }
  };
  renderBrand = (selectedBrand) => {
    if (this.state.itineraryPart != null) {
      return (
        <>
          {this.state.brandOffers.map((brand, key) => (
            <FlightBrand
              key={key}
              brand={brand}
              itineraryPart={this.state.itineraryPart}
              selectedBrand={this.state.selectedBrand}
              getSelectedBrandShoppingCode={this.getSelectedBrandShoppingCode}
            />
          ))}
        </>
      );
    }
  };
  async changeBrand(brand) {
    await this.setState({ selectedBrand: brand });
    await this.setState({
      isBusinessBrandSelected: !this.state.isBusinessBrandSelected,
    });
    await this.setState({
      isEconomyBrandSelected: !this.state.isEconomyBrandSelected,
    });
  }
  getSelectedBrandShoppingCode = (shoppingCode,arrivalDate,departDate) => {
    this.props.onSearchContextClicked(shoppingCode,arrivalDate,departDate);
  };
  closePage = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="flight-options-container">
        <div className="fare-options row m-0 mt-4">
          <div
            className={`fare-options-cabin col-6 ${
              this.state.isEconomyBrandSelected ? "active" : ""
            }`}
            onClick={() => this.changeBrand("Eco")}
          >
             {this.renderEconomyCheapPrice()}
          </div>
          <div
            className={`fare-options-cabin col-6 ${
              this.state.isBusinessBrandSelected ? "active" : ""
            }`}
            onClick={() => this.changeBrand("Bus")}
          >
           {this.renderBusinessCheapPrice()}
          </div>
        </div>

        {/* fare option card */}
        {this.renderBrand("bus")}
      </div>
    );
  }
}

export default FareOptions;
