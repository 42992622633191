import React, { Component } from "react"; 
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

import ReactToPrint from 'react-to-print';
import TagManager from 'react-gtm-module'

import { 
  Card, 
  Row,
  Col, 
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faFighterJet, 
} from "@fortawesome/free-solid-svg-icons"; 

import { DataService } from "../../../services/shared-services/dataServices";
import BreadCrumb from "../../shared/breadcrumb/breadcrumb";
import { htmlToText } from "html-to-text"; 
class PaymentSummery extends Component {
  constructor(props) {
    super(props);
    let tourPreference = localStorage.getItem("tourPreference");
    let bundAndSave = localStorage.getItem("bundAndSave");
    this.state = {
      summaryData: null,
      bundAndSave: bundAndSave,
      tourPreference: tourPreference,
      PackageSummary: [],
      HotelSummary: null,
      flightDetail: null,
      agreeTerms: false,
      showModal: false,
      iternery: null,
      passenger:   null  ,
      reservationItems: null,
      isLoaded: true,
      flightPrice: {
        amount: 2071.0,
        currencyCode: null,
      },
    };
  }

  async componentDidMount() {

    let summaryDetails = JSON.parse(localStorage.getItem("summaryDetails"));
    let BundleSummaryData = JSON.parse(localStorage.getItem("BundleSummery"));
    let bundleTotalPrice = 0;
    let hotelfinalsummary = JSON.parse(
      localStorage.getItem("hotelfinalsummary")
    );


    if (BundleSummaryData && (this.state.bundAndSave == "true")) {
      this.setState({ BundleSummary: BundleSummaryData });
      for (let i = 0; i < BundleSummaryData.length; i++) {
        bundleTotalPrice = +BundleSummaryData[i].amount;
      }
    }
    if (summaryDetails) {// this condition to be removed after print page
      this.setState({
        summaryData: summaryDetails ,
        PackageSummary: summaryDetails.multiPackages,
        HotelSummary: summaryDetails.hotelDetail,
        passenger: summaryDetails.flightDetail.travelItinerary.customerInfo,
        flightDetail: summaryDetails.flightDetail.travelItinerary,
        reservationItems:
          summaryDetails.flightDetail.travelItinerary.itineraryInfo
            .reservationItems.item,
        // flightPrice: summaryDetails.flightDetail.travelItinerary.itineraryInfo.itineraryPricing.priceQuoteTotals.totalFare,
        flightPrice:
          summaryDetails.flightDetail.travelItinerary.itineraryInfo
            .itineraryPricing.priceQuote[0].pricedItinerary
            .airItineraryPricingInfo.itinTotalFare.totalFare,
        isLoaded: true,
      });

      const tagManagerArgs = {
        dataLayer: {
            events:"flightConfirmation", 
            flightItineraryParts: summaryDetails.flightDetail.travelItinerary,
            flightPrice:summaryDetails.flightDetail.travelItinerary.itineraryInfo
              .itineraryPricing.priceQuote[0].pricedItinerary
              .airItineraryPricingInfo.itinTotalFare.totalFare,
            packagesAdded: summaryDetails.multiPackages,  
        } 
       } 
       TagManager.dataLayer(tagManagerArgs)


    }
  }

  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + "mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };

  render() {
    var { isLoaded, iternery, currentDestinations } = this.state;
    if (true) {
      //   return <div>loading Destination {currentDestinations.name}...{isLoaded}</div>;
      // }
      // else {
      return (
        <>
        <BreadCrumb pageIndex={6}/>
          <div className="p-2 container pt-0" ref={el => (this.componentRef = el)} >
            <h4 className="text-center pt-0 pb-0"> Summary Information </h4>
            <br /> 
           {!this.state.summaryData && 
                <p>No Summary Data Found! </p>
                }
            
           {this.state.summaryData &&  <div class="ml-2  row" style={{ textAlign: "left" }}>
              <p>Thank you for your booking! </p>
              <br />
              {this.state.tourPreference === "flightPlusHotel" && (
                <p>
                  {" "}
                Your travel ticket and hotel booking are now ready. Please check
                your E-mail for more information.
                </p>
              )}
              {this.state.tourPreference === "flightPlusPackage" && (
                <p>
                  Your travel ticket and package are now ready. Please check your
                  E-mail for more information.
                </p>
              )}
              <br />
              <p>
                For any further information please contact us via E-mail: 
                ETHolidays@ethiopianairlines.com or Phone: +251 116179900
            </p>
            </div>}

          { this.state.passenger && <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
              <Card.Header style={{ textAlign: "left" }} className="p-0 pl-3">
                <h5>Passenger Information</h5>{" "}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    {this.state.passenger.personName.map((psngr, index) => (
                      <ul class="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          P({index + 1}) Full Name :
                        <span className="badge badge-pill">
                            {psngr.title} {psngr.givenName} {psngr.surname}{" "}
                          </span>
                        </li>
                        {(this.state.flightDetail.itineraryInfo.ticketing
                          .flightTickets.length > 0) && <>
                            <li className="list-group-item d-flex justify-content-between align-items-center" id={"ticket-number-" + this.state.flightDetail.itineraryInfo.ticketing.flightTickets.length}>
                              Ticket Number :{" "}
                              <span className="badge badge-pill">
                                {
                                  this.state.flightDetail.itineraryInfo.ticketing
                                    .flightTickets[index].eTicketNumber
                                  // .emdNumber.eTicketNumber
                                }
                              </span>
                            </li>

                          </>}

                        <li className="list-group-item d-flex justify-content-between align-items-center" id={"pnr-" + this.state.flightDetail.itineraryInfo.ticketing.flightTickets.length}>
                          PNR :{" "}
                          <span className="badge badge-pill">
                            {this.state.flightDetail.itineraryRef.id}
                          </span>
                        </li>
                      </ul>
                    ))}
                  </Col>
                </Row>

                <Row></Row>
              </Card.Body>
              {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
            </Card>}
            <br />

         {  this.state.reservationItems &&   <Card className="text-center mb-2 shadow  bg-white rounded">
              <Card.Header style={{ textAlign: "left" }} className="p-0 pl-3">
                <h5> Flight Information </h5>{" "}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    {this.state.reservationItems.map(
                      (item, index) => (
                        <>
                          {" "}
                          {this.state.reservationItems[index].flightSegment && (
                            <Row>
                              <Col sm={4}>
                                <p className="mb-0">from:</p>
                                <strong>
                                  {DataService.getFullCityName(
                                    this.state.reservationItems[0].flightSegment
                                      .originLocation.locationCode
                                  )}
                                </strong>
                                <p>
                                  {this.parseDate(
                                    this.state.reservationItems[0].product
                                      .productDetails.air.departureDateTime
                                  )}
                                </p>
                              </Col>
                              <Col sm={2}>
                               {(index<1) && <FontAwesomeIcon
                                  className="pr-2"
                                  icon={faFighterJet}
                                  size="lg"
                                /> }
                                
                                {(index>=1) &&<FontAwesomeIcon
                                  className="pr-2"
                                  icon={faFighterJet}
                                  size="lg" transform={{ rotate: 180 }} 
                                />}
                              </Col>
                              <Col sm={3}>
                                <p className="mb-0">to:</p>
                                <strong>
                                  {DataService.getFullCityName(
                                    this.state.reservationItems[0].flightSegment
                                      .destinationLocation.locationCode
                                  )}
                                </strong> 
                                <p>
                                  {this.parseDate(
                                    this.state.reservationItems[0].product
                                      .productDetails.air.arrivalDateTime
                                  )}
                                </p>
                              </Col>
                              <Col sm={3}>
                                <p className="mb-0">Flight:</p>
                                {/* <strong>Economic</strong> */}
                                <strong>
                                  {item.flightSegment.operatingAirline.code} -
                                {
                                    item.flightSegment.operatingAirline
                                      .flightNumber
                                  }
                                </strong>
                              </Col>
                            </Row>
                          )}
                        </>
                      )
                      // }
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm={12}>
                    <ul class="list-group">
                      <li className="list-group-item d-flex justify-content-between align-items-right">
                        Flight Price :
                      <span className="badge badge-pill ">
                          {this.state.flightPrice.amount}{" "}
                          {this.state.flightPrice.currencyCode}
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card.Body>
              {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
            </Card>}

            <br />

            {this.state.HotelSummary && (
              <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                <Card.Header style={{ textAlign: "left" }} className="p-0 pl-3">
                  <h5> Hotel Information</h5>{" "}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <ul class="list-group">
                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">Hotel EMD  :  <span className="badge badge-pill"> hotelInformation.flightDetail.travelItinerary.itineraryInfo.ticketing.emdNumber.eTicketNumber   </span></li> */}
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Hotel Name :{" "}
                          <span className="badge badge-pill">
                            {" "}
                            {this.state.HotelSummary.hotel.name}{" "}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Hotel Destination :{" "}
                          <span className="badge badge-pill">
                            {" "}
                            {this.state.HotelSummary.hotel.destinationName}{" "}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Check In Date :{" "}
                          <span className="badge badge-pill">
                            {" "}
                            {this.parseDate(
                              this.state.HotelSummary.hotel.checkIn
                            )}{" "}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Check Out Date :{" "}
                          <span className="badge badge-pill">
                            {" "}
                            {this.parseDate(
                              this.state.HotelSummary.hotel.checkOut
                            )}{" "}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Hotel Fare :{" "}
                          <span className="badge badge-pill">
                            {" "}
                            <NumberFormat
                              value={parseFloat(localStorage.getItem("hotelTotalPrice"))}
                              displayType="text"
                              thousandSeparator={true}
                            />  {" "}
                            {this.state.HotelSummary.localCurrency}{" "}
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
            <br />
            {this.state.PackageSummary && <>
              {this.state.PackageSummary.map((packageitem) => (
                <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                  <Card.Header style={{ textAlign: "left" }} className="p-0 pl-3">
                    <h5> Package Information</h5>{" "}
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={12}>
                        <ul class="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Package EMD :{" "}
                            <span class="badge badge-pill">
                              {" "}
                              {packageitem.emd}{" "}
                            </span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Package Name :{" "}
                            <span class="badge badge-pill">
                              {" "}
                              {packageitem.package.name}{" "}
                            </span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Package Validity Start :{" "}
                            <span class="badge badge-pill">
                              {" "}
                              {this.parseDate(
                                packageitem.package.packageBookingValidDateFrom
                              )}{" "}
                            </span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Package Valid For :{" "}
                            <span class="badge badge-pill">
                              {" "}
                              {packageitem.package.numberOfDays} Day(s)
                        </span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            Package Price :{" "}
                            <span class="badge badge-pill">
                              {" "}
                              {packageitem.totalAmount}{" "}
                              {packageitem.price.currency.code}{" "}
                            </span>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}

            </>}
            <br />


            {/* {((this.state.BundleSummary)  && //to be uncommented */}
            {((this.state.BundleSummary) && (this.state.bundAndSave === "true")) && (
              <>
                <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                  <Card.Header
                    style={{ textAlign: "left" }}
                    className="p-0 pl-3"
                  >
                    <h5> Activities Information </h5>{" "}
                  </Card.Header>
                  {this.state.BundleSummary.map((packageItem) => (
                    <Card.Body>
                      <Row>
                        <Col sm={10}>
                          <Row>
                            <Col sm={5}>
                              <div
                                class="card"
                                style={{ cursor: "pointer !important" }}
                              >
                                <div
                                  className="card-img"
                                  style={{
                                    cursor: "pointer !important",
                                    backgroundImage:
                                      "url(" +
                                      packageItem.imagePath +
                                      ")",
                                    maxHeight: "150px",
                                  }}
                                ></div>
                              </div>
                            </Col>

                            <Col sm={7}>
                              <ul class="list-group">
                                <li class="list-group-item  d-flex justify-content-between align-items-center  ">
                                  <h6>{packageItem.name} </h6>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  City, Country
                                  <span class="badge badge-pill">
                                    {" "}
                                    {DataService.getFullCityName(
                                      packageItem.cityCode
                                    )}{" "}
                                  </span>
                                </li>
                              </ul>
                            </Col>
                            <Col sm={12}>
                              <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span
                          className="linebreak">
                                  {" "}
                                  <strong> Description: </strong>
                                  {htmlToText(packageItem.description)}
                                </span>
                              </li>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          className="p-3"
                          style={{ borderLeft: "1px solid green" }}
                          sm={2}
                        >
                          <Card.Text className="text-muted">
                            {" "}
                            <h5> Activity Price: </h5>
                          </Card.Text>
                          <Card.Text style={{ color: "green" }}>
                            <strong>
                              {Math.round(packageItem.amount)}{" "}
                              {packageItem.currency.code}
                            </strong>
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card.Body>

                  ))}

                  {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                </Card>
                <br />
              </>
            )}


            {/* <Card className="text-center mb-3 shadow  bg-white rounded">
                        <Card.Footer className="text-muted" style={{ backgroundColor: "white" }}>
                            <Row >
                                <Col sm={10}>
                                    <h5>Total Price</h5>
                                </Col>

                                <Col style={{ borderLeft: "1px solid green" }} sm={2}>
                                    <Card.Text><strong>5000 ETB</strong> </Card.Text>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card> */}

          </div>
          <div className="p-2 container pt-0">
            <Row>
              <Col style={{ textAlign: "right" }} sm={12}>
                <Link className="ml-2" to={"/"}>
                  <button type="button" className="btn  btn-secondary btn-sm">
                    <strong className="pr-5 pl-5 "> Back Home</strong>
                  </button>
                </Link>


               {  this.state.summaryData &&  <ReactToPrint className=""
                  trigger={() => {
                    return <button type="button" className="btn btn-success btn-sm m-2">
                      <strong className="pr-5 pl-5 "> Print this Page</strong></button>;


                  }}
                  content={() => this.componentRef}
                />}
              </Col>
            </Row>
          </div>
        </>

      );
    }
  }
}

export default PaymentSummery;
