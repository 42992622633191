import React, { Component } from 'react'; 

import { HttpService } from "../../../services/http/http-service";

import { Modal, Alert,Card, Spinner, Form, InputGroup, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faFlag, faUser,faTools, faMailBulk } from '@fortawesome/free-solid-svg-icons' 

import FormControl from "react-bootstrap/FormControl";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
 
import DatePicker from 'react-datepicker';

import "./Campaign.css";
import "react-datepicker/dist/react-datepicker.css"; 

import Select from 'react-select' 
import CampainTermsCondition from './campaignTerms';
import parse from 'html-react-parser';

class CampainWidget extends Component {
    constructor(props) {
        super(props);
        let search = {}
        this.state = {
            showModal: false,
            search: search,
            validated: false,
            isLoadingPayment: false,
             Title:null,
            FirstName: null,
            LastName: null,
            Email: null, 
            Telephone: null,
             Country:null,    
            ResponseMessage: null, 

            agreePromotion: false,
            invalidGoogleRecapthcha:true,
            invalidGoogleRecapthcha:true,
        }

    }

    dateOpen = () => {
        // console.log("date picker")

        const ExampleCustomInput = ({ value, onClick }) => (
            <>
                <FormControl
                    value={(value === "") ? " Departure Date* " : (value)}
                    name="dateSelected"
                    id="dateSelected"
                    readOnly
                    className="trip"
                    required
                    placeholderText=" Departure Date* "
                    aria-label="DepartureAirport"
                    aria-describedby="DepartureAirport"
                    onClick={onClick}
                />
            </>);
        return (
            <DatePicker
                required
                disabledKeyboardNavigation

                id="date"
                placeholderText=" Departure Date* "
                selected={(this.state.date === null) ? (new Date()) : (this.state.date)}
                onChange={(value) => {
                    this.setState({
                        date: value
                    })
                }}
                onDateChange={(date) =>
                    this.setState({ date })
                }
                minDate={new Date()}

                customInput={<ExampleCustomInput />}
                name="startDate"
                dateFormat="MM/dd/yyyy"
            />
        )

    }
 

    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ ResponseMessage: "Please provide all required information with symbol(*)" });

        }
        else {
            event.preventDefault();
            event.stopPropagation();
            this.submitPayment();

        }

        this.setState({ validated: true })
        //   setValidated(true);
    };


    submitPayment() {

        this.setState({ isLoadingPayment: true })
        let apiRelativePackageEnquire = "Advertisements/Add" 

        // let startDate = document.getElementById("dateSelected").value;
        // startDate = new Date(startDate);//.format("YYYY-MM-DD"),
       let startDate = new Date();//.format("YYYY-MM-DD"),
        startDate = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate();
        if (true) {
            var RequestObject = { "Advertisements":[{
                "Title": this.state.Title.label,
                "GivenName": this.state.FirstName,
                "LastName": this.state.LastName,
                "Email": this.state.Email, 
                "PhoneNumber": this.state.phone,
                "TransactionDate": startDate,  
                "CountryOfOrigin": this.state.Country.label,  } ]
            };
            this.setState({ ResponseMessage: null });

            // return;// to be deleted
            HttpService.postServicePayment(RequestObject, apiRelativePackageEnquire)
                .then((response) => {
                    // console.log("response",response)
                    this.setState({ isLoadingPayment: false });
                    let paymentApiResponse = response.data; 
                    if (paymentApiResponse.statusAPI== "SUCCESS") { 
                        this.setState({   isLoading: false,
                            // showModal: true,
                             submitStatus: true,
                              ResponseMessage: "<p>Successfully received the request. </p> <p>We will reach you via the provided email address. </p><p>Thanks for being part of the competition!</p>"})
                    }
                    else {
                        console.log("Your enquire not sent please try again")
                        this.setState({  isLoading: false, ResponseMessage:  paymentApiResponse.messageAPI, submitStatus: false });
                    }
                },
                    error => {
                        console.log("error ; Your enquire not sent please try again")
                    });
        } 
    } 

    changeTitle = selectedOption => {
        this.setState({ Title:selectedOption }); 
      };
    
    handleChangeCountry = selectedOption => {
        this.setState({ Country:selectedOption });  
      };
    

    render() {

        var { validated } = this.state;
 
        let countriesCode = [
            {
               "code": "7840",
                "label": "Abkhazia"
            },
            {
               "code": "93",
                "label": "Afghanistan"
            },
            {
               "code": "355",
                "label": "Albania"
            },
            {
               "code": "213",
                "label": "Algeria"
            },
            {
               "code": "1684",
                "label": "American Samoa"
            },
            {
               "code": "376",
                "label": "Andorra"
            },
            {
               "code": "244",
                "label": "Angola"
            },
            {
               "code": "1264",
                "label": "Anguilla"
            },
            {
               "code": "1268",
                "label": "Antigua and Barbuda"
            },
            {
               "code": "54",
                "label": "Argentina"
            },
            {
               "code": "374",
                "label": "Armenia"
            },
            {
               "code": "297",
                "label": "Aruba"
            },
            {
               "code": "247",
                "label": "Ascension"
            },
            {
               "code": "61",
                "label": "Australia"
            },
            {
               "code": "672",
                "label": "Australian External Territories"
            },
            {
               "code": "43",
                "label": "Austria"
            },
            {
               "code": "994",
                "label": "Azerbaijan"
            },
            {
               "code": "1242",
                "label": "Bahamas"
            },
            {
               "code": "973",
                "label": "Bahrain"
            },
            {
               "code": "880",
                "label": "Bangladesh"
            },
            {
               "code": "1246",
                "label": "Barbados"
            },
            {
               "code": "1268",
                "label": "Barbuda"
            },
            {
               "code": "375",
                "label": "Belarus"
            },
            {
               "code": "32",
                "label": "Belgium"
            },
            {
               "code": "501",
                "label": "Belize"
            },
            {
               "code": "229",
                "label": "Benin"
            },
            {
               "code": "1441",
                "label": "Bermuda"
            },
            {
               "code": "975",
                "label": "Bhutan"
            },
            {
               "code": "591",
                "label": "Bolivia"
            },
            {
               "code": "387",
                "label": "Bosnia and Herzegovina"
            },
            {
               "code": "267",
                "label": "Botswana"
            },
            {
               "code": "55",
                "label": "Brazil"
            },
            {
               "code": "246",
                "label": "British Indian Ocean Territory"
            },
            {
               "code": "1284",
                "label": "British Virgin Islands"
            },
            {
               "code": "673",
                "label": "Brunei"
            },
            {
               "code": "359",
                "label": "Bulgaria"
            },
            {
               "code": "226",
                "label": "Burkina Faso"
            },
            {
               "code": "257",
                "label": "Burundi"
            },
            {
               "code": "855",
                "label": "Cambodia"
            },
            {
               "code": "237",
                "label": "Cameroon"
            },
            {
               "code": "1",
                "label": "Canada"
            },
            {
               "code": "238",
                "label": "Cape Verde"
            },
            {
               "code": " 345",
                "label": "Cayman Islands"
            },
            {
               "code": "236",
                "label": "Central African Republic"
            },
            {
               "code": "235",
                "label": "Chad"
            },
            {
               "code": "56",
                "label": "Chile"
            },
            {
               "code": "86",
                "label": "China"
            },
            {
               "code": "61",
                "label": "Christmas Island"
            },
            {
               "code": "61",
                "label": "Cocos-Keeling Islands"
            },
            {
               "code": "57",
                "label": "Colombia"
            },
            {
               "code": "269",
                "label": "Comoros"
            },
            {
               "code": "242",
                "label": "Congo"
            },
            {
               "code": "243",
                "label": "Congo, Dem. Rep. of (Zaire)"
            },
            {
               "code": "682",
                "label": "Cook Islands"
            },
            {
               "code": "506",
                "label": "Costa Rica"
            },
            {
               "code": "385",
                "label": "Croatia"
            },
            {
               "code": "53",
                "label": "Cuba"
            },
            {
               "code": "599",
                "label": "Curacao"
            },
            {
               "code": "537",
                "label": "Cyprus"
            },
            {
               "code": "420",
                "label": "Czech Republic"
            },
            {
               "code": "45",
                "label": "Denmark"
            },
            {
               "code": "246",
                "label": "Diego Garcia"
            },
            {
               "code": "253",
                "label": "Djibouti"
            },
            {
               "code": "1767",
                "label": "Dominica"
            },
            {
               "code": "1809",
                "label": "Dominican Republic"
            },
            {
               "code": "670",
                "label": "East Timor"
            },
            {
               "code": "56",
                "label": "Easter Island"
            },
            {
               "code": "593",
                "label": "Ecuador"
            },
            {
               "code": "20",
                "label": "Egypt"
            },
            {
               "code": "503",
                "label": "El Salvador"
            },
            {
               "code": "240",
                "label": "Equatorial Guinea"
            },
            {
               "code": "291",
                "label": "Eritrea"
            },
            {
               "code": "372",
                "label": "Estonia"
            },
            {
               "code": "251",
                "label": "Ethiopia"
            },
            {
               "code": "500",
                "label": "Falkland Islands"
            },
            {
               "code": "298",
                "label": "Faroe Islands"
            },
            {
               "code": "679",
                "label": "Fiji"
            },
            {
               "code": "358",
                "label": "Finland"
            },
            {
               "code": "33",
                "label": "France"
            },
            {
               "code": "596",
                "label": "French Antilles"
            },
            {
               "code": "594",
                "label": "French Guiana"
            },
            {
               "code": "689",
                "label": "French Polynesia"
            },
            {
               "code": "241",
                "label": "Gabon"
            },
            {
               "code": "220",
                "label": "Gambia"
            },
            {
               "code": "995",
                "label": "Georgia"
            },
            {
               "code": "49",
                "label": "Germany"
            },
            {
               "code": "233",
                "label": "Ghana"
            },
            {
               "code": "350",
                "label": "Gibraltar"
            },
            {
               "code": "30",
                "label": "Greece"
            },
            {
               "code": "299",
                "label": "#60ac5dland"
            },
            {
               "code": "1 473",
                "label": "Grenada"
            },
            {
               "code": "590",
                "label": "Guadeloupe"
            },
            {
               "code": "1671",
                "label": "Guam"
            },
            {
               "code": "502",
                "label": "Guatemala"
            },
            {
               "code": "224",
                "label": "Guinea"
            },
            {
               "code": "245",
                "label": "Guinea-Bissau"
            },
            {
               "code": "595",
                "label": "Guyana"
            },
            {
               "code": "509",
                "label": "Haiti"
            },
            {
               "code": "504",
                "label": "Honduras"
            },
            {
               "code": "852",
                "label": "Hong Kong SAR China"
            },
            {
               "code": "36",
                "label": "Hungary"
            },
            {
               "code": "354",
                "label": "Iceland"
            },
            {
               "code": "91",
                "label": "India"
            },
            {
               "code": "62",
                "label": "Indonesia"
            },
            {
               "code": "98",
                "label": "Iran"
            },
            {
               "code": "964",
                "label": "Iraq"
            },
            {
               "code": "353",
                "label": "Ireland"
            },
            {
               "code": "972",
                "label": "Israel"
            },
            {
               "code": "39",
                "label": "Italy"
            },
            {
               "code": "225",
                "label": "Ivory Coast"
            },
            {
               "code": "1876",
                "label": "Jamaica"
            },
            {
               "code": "81",
                "label": "Japan"
            },
            {
               "code": "962",
                "label": "Jordan"
            },
            {
               "code": "7",
                "label": "Kazakhstan"
            },
            {
               "code": "254",
                "label": "Kenya"
            },
            {
               "code": "686",
                "label": "Kiribati"
            },
            {
               "code": "965",
                "label": "Kuwait"
            },
            {
               "code": "996",
                "label": "Kyrgyzstan"
            },
            {
               "code": "856",
                "label": "Laos"
            },
            {
               "code": "371",
                "label": "Latvia"
            },
            {
               "code": "961",
                "label": "Lebanon"
            },
            {
               "code": "266",
                "label": "Lesotho"
            },
            {
               "code": "231",
                "label": "Liberia"
            },
            {
               "code": "218",
                "label": "Libya"
            },
            {
               "code": "423",
                "label": "Liechtenstein"
            },
            {
               "code": "370",
                "label": "Lithuania"
            },
            {
               "code": "352",
                "label": "Luxembourg"
            },
            {
               "code": "853",
                "label": "Macau SAR China"
            },
            {
               "code": "389",
                "label": "Macedonia"
            },
            {
               "code": "261",
                "label": "Madagascar"
            },
            {
               "code": "265",
                "label": "Malawi"
            },
            {
               "code": "60",
                "label": "Malaysia"
            },
            {
               "code": "960",
                "label": "Maldives"
            },
            {
               "code": "223",
                "label": "Mali"
            },
            {
               "code": "356",
                "label": "Malta"
            },
            {
               "code": "692",
                "label": "Marshall Islands"
            },
            {
               "code": "596",
                "label": "Martinique"
            },
            {
               "code": "222",
                "label": "Mauritania"
            },
            {
               "code": "230",
                "label": "Mauritius"
            },
            {
               "code": "262",
                "label": "Mayotte"
            },
            {
               "code": "52",
                "label": "Mexico"
            },
            {
               "code": "691",
                "label": "Micronesia"
            },
            {
               "code": "1808",
                "label": "Midway Island"
            },
            {
               "code": "373",
                "label": "Moldova"
            },
            {
               "code": "377",
                "label": "Monaco"
            },
            {
               "code": "976",
                "label": "Mongolia"
            },
            {
               "code": "382",
                "label": "Montenegro"
            },
            {
               "code": "1664",
                "label": "Montserrat"
            },
            {
               "code": "212",
                "label": "Morocco"
            },
            {
                "code": "258",
                "label": "Mozambique"
            },
            {
               "code": "95",
                "label": "Myanmar"
            },
            {
               "code": "264",
                "label": "Namibia"
            },
            {
               "code": "674",
                "label": "Nauru"
            },
            {
               "code": "977",
                "label": "Nepal"
            },
            {
               "code": "31",
                "label": "Netherlands"
            },
            {
               "code": "599",
                "label": "Netherlands Antilles"
            },
            {
               "code": "1869",
                "label": "Nevis"
            },
            {
               "code": "687",
                "label": "New Caledonia"
            },
            {
               "code": "64",
                "label": "New Zealand"
            },
            {
               "code": "505",
                "label": "Nicaragua"
            },
            {
               "code": "227",
                "label": "Niger"
            },
            {
               "code": "234",
                "label": "Nigeria"
            },
            {
               "code": "683",
                "label": "Niue"
            },
            {
               "code": "672",
                "label": "Norfolk Island"
            },
            {
               "code": "850",
                "label": "North Korea"
            },
            {
               "code": "1670",
                "label": "Northern Mariana Islands"
            },
            {
               "code": "47",
                "label": "Norway"
            },
            {
               "code": "968",
                "label": "Oman"
            },
            {
               "code": "92",
                "label": "Pakistan"
            },
            {
               "code": "680",
                "label": "Palau"
            },
            {
               "code": "970",
                "label": "Palestinian Territory"
            },
            {
               "code": "507",
                "label": "Panama"
            },
            {
               "code": "675",
                "label": "Papua New Guinea"
            },
            {
               "code": "595",
                "label": "Paraguay"
            },
            {
               "code": "51",
                "label": "Peru"
            },
            {
               "code": "63",
                "label": "Philippines"
            },
            {
               "code": "48",
                "label": "Poland"
            },
            {
               "code": "351",
                "label": "Portugal"
            },
            {
               "code": "1787",
                "label": "Puerto Rico"
            },
            {
               "code": "974",
                "label": "Qatar"
            },
            {
               "code": "262",
                "label": "Reunion"
            },
            {
               "code": "40",
                "label": "Romania"
            },
            {
               "code": "7",
                "label": "Russia"
            },
            {
               "code": "250",
                "label": "Rwanda"
            },
            {
               "code": "685",
                "label": "Samoa"
            },
            {
               "code": "378",
                "label": "San Marino"
            },
            {
               "code": "966",
                "label": "Saudi Arabia"
            },
            {
               "code": "221",
                "label": "Senegal"
            },
            {
               "code": "381",
                "label": "Serbia"
            },
            {
               "code": "248",
                "label": "Seychelles"
            },
            {
               "code": "232",
                "label": "Sierra Leone"
            },
            {
               "code": "65",
                "label": "Singapore"
            },
            {
               "code": "421",
                "label": "Slovakia"
            },
            {
               "code": "386",
                "label": "Slovenia"
            },
            {
               "code": "677",
                "label": "Solomon Islands"
            },
            {
               "code": "27",
                "label": "South Africa"
            },
            {
               "code": "500",
                "label": "South Georgia and the South Sandwich Islands"
            },
            {
               "code": "82",
                "label": "South Korea"
            },
            {
               "code": "34",
                "label": "Spain"
            },
            {
               "code": "94",
                "label": "Sri Lanka"
            },
            {
               "code": "249",
                "label": "Sudan"
            },
            {
               "code": "597",
                "label": "Suriname"
            },
            {
               "code": "268",
                "label": "Swaziland"
            },
            {
               "code": "46",
                "label": "Sweden"
            },
            {
               "code": "41",
                "label": "Switzerland"
            },
            {
               "code": "963",
                "label": "Syria"
            },
            {
               "code": "886",
                "label": "Taiwan"
            },
            {
               "code": "992",
                "label": "Tajikistan"
            },
            {
               "code": "255",
                "label": "Tanzania"
            },
            {
               "code": "66",
                "label": "Thailand"
            },
            {
               "code": "670",
                "label": "Timor Leste"
            },
            {
               "code": "228",
                "label": "Togo"
            },
            {
               "code": "690",
                "label": "Tokelau"
            },
            {
               "code": "676",
                "label": "Tonga"
            },
            {
               "code": "1868",
                "label": "Trinidad and Tobago"
            },
            {
               "code": "216",
                "label": "Tunisia"
            },
            {
               "code": "90",
                "label": "Turkey"
            },
            {
               "code": "993",
                "label": "Turkmenistan"
            },
            {
               "code": "1649",
                "label": "Turks and Caicos Islands"
            },
            {
               "code": "688",
                "label": "Tuvalu"
            },
            {
               "code": "1340",
                "label": "U.S. Virgin Islands"
            },
            {
               "code": "256",
                "label": "Uganda"
            },
            {
               "code": "380",
                "label": "Ukraine"
            },
            {
               "code": "971",
                "label": "United Arab Emirates"
            },
            {
               "code": "44",
                "label": "United Kingdom"
            },
            {
               "code": "1",
                "label": "United States"
            },
            {
               "code": "598",
                "label": "Uruguay"
            },
            {
               "code": "998",
                "label": "Uzbekistan"
            },
            {
               "code": "678",
                "label": "Vanuatu"
            },
            {
               "code": "58",
                "label": "Venezuela"
            },
            {
               "code": "84",
                "label": "Vietnam"
            },
            {
               "code": "1 808",
                "label": "Wake Island"
            },
            {
               "code": "681",
                "label": "Wallis and Futuna"
            },
            {
               "code": "967",
                "label": "Yemen"
            },
            {
               "code": "260",
                "label": "Zambia"
            },
            {
               "code": "255",
                "label": "Zanzibar"
            },
            {
               "code": "263",
                "label": "Zimbabwe"
            }
        ]; 
        return (
            <div 
            className="container widget-container">

<div className="form-all-widget pt-0" >
                <Form noValidate validated={validated} onSubmit={this.handleSubmit} className=" shadow pl-3 pr-3 pb-3  pt-2 mb-3 bg-white rounded">


                    <Form.Row   className="justify-content-md-center">
                        <h3 className="pt-2   font-weight-bolder " className="text-success text-weight-bolder card-title" variant={"success"}><span className="font-weight-bolder"> Enter to Win </span></h3>
                    </Form.Row>
                    <Form.Row className="pl-2 ">
                        
           <Col style={{ textAlign: "left" }} sm={6}>
               <h5 className="pt-2 float-left font-weight-bolder" >Like Our Facebook Page {" "} 
                        <a
                      className="links white-text"
                      href="https://www.facebook.com/EthiopianHolidays/"
                      target="_blank"
                    >
                      <img src={ window.location.origin +   "/assets/image/social-media/facebook.png"  } />
                    </a>
</h5>
           </Col>
              
           <Col style={{ textAlign: "left" }} sm={6}>  
                  <h5 className="pt-2 float-right font-weight-bolder" >
                  Follow our Instagram page {" "} 
 
                         {" "}
                    <a
                      className="links white-text"
                      href="https://www.instagram.com/ethiopian_holidays/"
                      target="_blank"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/assets/image/social-media/instagram.png"
                        }
                      />
                    </a> </h5> 
         
           </Col>           
              </Form.Row>
                    <Form.Row className=" pl-2">
                        <p className="pt-2 float-right">Fill the below form and hit submit.</p> 
                    </Form.Row>
 
                    <Form.Row>
                       <Form.Group as={Col} md="4" controlId="Title"> 
                            <InputGroup>
                     

                            <Select  className="  selectOptionscampaign  " 
                                        placeholder="Title *"   
                                         options={ [
                                            {"label":"Mr"},
                                            {"label":"Ms"},
                                            {"label":"Mrs"}
                                             ]}
                                            
                                        onChange={this.changeTitle} />



                            </InputGroup>
                        </Form.Group>
                    
                        <Form.Group as={Col} md="4" controlId="FirstName">
                            {/* <Form.Label className="d-flex">First Name*</Form.Label> */}
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependFirstName">
                                        <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    required
                                    minLength="2"
                                    aria-describedby="inputGroupPrependFirstName"
                                    type="text"
                                    id="enquiry_first_name"
                                    placeholder="Given name *"
                                    defaultValue=""
                                    value={this.state.FirstName}
                                    onChange={(e) =>
                                        this.setState({ FirstName: e.target.value })
                                    }

                                />
                                {/* <Form.Control.Feedback type="invalid">
                                    Please input a valid First Name
                                    </Form.Control.Feedback> */}

                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="LastName">
                            {/* <Form.Label className="d-flex">Last Name*</Form.Label> */}
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependLastName">
                                        <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    required
                                    minLength="2"
                                    type="text"
                                    placeholder="Last name*"
                                    id="enquiry_last_name"
                                    aria-describedby="inputGroupPrependLastName"
                                    defaultValue=""
                                    value={this.state.LastName}
                                    onChange={(e) =>
                                        this.setState({ LastName: e.target.value })
                                    }
                                />
                                {/* <Form.Control.Feedback type="invalid">
                                    Please input a valid Last Name
</Form.Control.Feedback> */}
                            </InputGroup>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row></Form.Row>
                    <Form.Row>

                        <Form.Group as={Col} md="4" controlId="Phone">

                            <InputGroup> 
<PhoneInput style={{ width: "fit-content !important;" }}
                                    required
                            country={'us'}
                            inputProps={{
                              // class: "form-textbox display-7",
                              required: true,
                              id:"enquiry_phone",
                              placeholder: "Phone Number *",
                            }}
                            value={this.state.phone}
                            onChange={phone => this.setState({ phone })}
                            onFocus={function (event) {
                              event.target.classList.remove("is-invalid");
                              event.target.classList.remove("is-valid");
                            }}  />




                                {/* <Form.Control.Feedback type="invalid">
                                    Please Enter your Phone number
</Form.Control.Feedback> */}
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="Email">
                            {/* <Form.Label className="d-flex">Email</Form.Label> */}

                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependEmail">
                                        <FontAwesomeIcon className="pr-2" icon={faMailBulk} size="lg" /></InputGroup.Text>
                                </InputGroup.Prepend>

                                <Form.Control required
                                    minLength="3"
                                    id="enquiry_email"
                                    type="email"
                                    placeholder="Enter Email*"
                                    aria-describedby="inputGroupPrependEmail"
                                    defaultValue=""
                                    value={this.state.Email}
                                    onChange={(e) =>
                                        this.setState({ Email: e.target.value })
                                    }

                                >
                                </Form.Control>
                                {/* <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email.
</Form.Control.Feedback> */}
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="Nationality"> 
                            <InputGroup>      
                                <Select  className="  selectOptionscampaign  " 
                                placeholder="Country of Origin *"   
                                options={countriesCode} 
                                onChange={this.handleChangeCountry} /> 
                                {/* </div> */} 
                                <Form.Control.Feedback type="invalid">
                                     Please select Nationality
                            </Form.Control.Feedback>                
                            </InputGroup>
                        </Form.Group>


                    </Form.Row>
       
                    <Form.Row >

                        <Col style={{ textAlign: "left" }} sm={6} className="my-2 ml-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(value) =>
                                    this.setState({ agreePromotion: !this.state.agreePromotion })
                                }
                                id="defaultCheck1"
                            />
                            <label class="form-check-label" for="defaultCheck1">
                             I agree to the 
                             
              <Card.Link
                onClick={() => this.setState({ showModal: true })}
                style={{ color: "green", cursor: "pointer" }}
              >
                {" "}
                Terms and Conditions{" "}
              </Card.Link>
                            </label>
                        </Col>
           <Col style={{ textAlign: "right" }} sm={11} className="my-2 ml-3">
                            {this.state.isLoadingPayment &&

                                <Button variant="success" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                Submiting...
                                </Button>
                            }

                            {(this.state.isLoadingPayment === false) &&
                                <Button type="submit" className="btn btn-success" variant="success" disabled={!this.state.agreePromotion}><strong className='pr-5 pl-5 '>Submit</strong></Button>

                            }

                        </Col>
            
                    </Form.Row>
            
                    <Row className=" ">
                     </Row>

                    {((this.state.ResponseMessage) && (this.state.isLoadingPayment === false)) &&
                        <>
                            {!this.state.submitStatus &&
                                <Alert variant="danger">
                                    { parse(this.state.ResponseMessage)}

                                </Alert>
                            }
                            {this.state.submitStatus &&
                                <Alert variant="success">
                                    { parse(this.state.ResponseMessage)}

                                </Alert>
                            }
                        </>
                    }
                </Form>
 
            </div >

            
        <Modal
          scrollable={true}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton className="p-1 pl-3 pt-0">
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CampainTermsCondition></CampainTermsCondition>
          </Modal.Body>
        </Modal>
            </div >

        );
    }
}

export default CampainWidget;

//   render(<FormExample />);