import React, { Component } from 'react';
import ProgressLoader from '../../shared/progress-loading/progress-loader';
import './Destinations.css';
// #8253		accessing packages through destination
class Destinations extends Component {
  constructor(props) {
    super(props);
    let currentDestinations = JSON.parse(localStorage.getItem("currentDestinations"));
    if (currentDestinations) {
      this.state = {
        currentDestinations: currentDestinations,
        isLoaded: false,
      }
    }
    else {
      this.state = {
        currentDestinations: { countries: [], name: '' },
        isLoaded: false,
      }

    }
  }



  showCountry = (countryCode) => {
    let path = "/country/?countryCode=" + countryCode;

    window.location.assign(path);

  }

  render() {
    var { isLoaded, currentDestinations } = this.state;
    if (false) {
      return   <ProgressLoader displayMess= {"loading Destination " +currentDestinations.name} />
      // <div>loading Destination {currentDestinations.name}...</div>;
    }
    else {
      return (
        <div className= "text-center container pt-2">
          <h1 className="text-center">{currentDestinations.name} </h1>

          <div class="row row-cols-1 row-cols-md-2">
            {currentDestinations.countries.map((item) =>
              <div class="col-lg-4 col-md-6 mb-4" key={item} value={item} >

                <div class="card">
                  <div className="card-img" style={{ backgroundImage: "url(" + item.imagePath + ")" }}>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">{item.name}</h5>
                    <button type="button" className="btn et-btn" onClick={() => this.showCountry(item.code)}> See Packages </button>
                  </div>
                </div>
              </div>
            )}
          </div>



        </div>

      );
    }

  }


}

export default Destinations;