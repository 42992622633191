import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./hotel.css";

class HotelAmenity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { facilty } = this.props;
    return (
      <div className="col-12 col-sm-12 col-md-5 col-lg-5 ml-md-4 pb-3 hotel-facility">
        <span>
          {" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "#60ac5d", fontSize: "17px" }}
          />
        </span>
        <span className="pl-2">{facilty.facilityType}</span>
      </div>
    );
  }
}

export default HotelAmenity;
