class PassengerInforamtion {
  params = {
    passengers: [],
    businessLoyalty: null,
    contact: {
      emails: [],
      phones: [
        {
          type: "MOBILE",
          countryCode: null,
          areaCode: null,
          number: null,
          extension: null,
        },
      ],
    },
    CookieSabreDataRequest: {
      SessionId: null,
    },
  };
}

export default PassengerInforamtion;
