import { HttpService } from "./../http/http-service";
export class GetNextHotelResult {
  static getNextHotel(pageNumber, sortBy, sortMode) {
    let relativePath = "HotelAvailability/CacheAvailability";
    relativePath =
      relativePath +
      "?SessionId=" +
      localStorage.getItem("sessionId") +
      "&PageNumber=" +
      pageNumber +
      "&SortBy=" +
      sortBy +
      "&SortMode=" +
      sortMode;
    return HttpService.getServiceHotel(relativePath);
  }
}
