import React, { Component } from "react";
import "./page-not-found.css";

class PageNotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div id="notfound" className="justify-content-center d-flex">
        <div className="notfound">
          <div className="notfound-404 mb-3">
            <h1>404</h1>
          </div>
          <h1 className="py-5">Sorry, page not found!</h1>
          <div>
            <h6 className="text-left">
              We cannot find the page you have requested. Using your back button
              and trying the request again may resolve the issue.
            </h6>
          </div>

          <a href="/" className="btn btn-success">
            Go to the Homepage
          </a>
        </div>
      </div>
    );
  }
}
export default PageNotFound;
