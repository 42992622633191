import React from "react";
import FlightTakeoffSharp from "@material-ui/icons/FlightTakeoffSharp";
import "bootstrap/dist/css/bootstrap.min.css";

import "./widget.css";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Autocomplete from "react-autocomplete";
class FlightInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Form: "",
      To: "",
    };
  }
  render() {
    const airpotList = [
      "Aarhus (AAR), Denmark",
      "Aberdeen Dyce (ABZ)), United Kingdom",
      "Abha (AHB), Saudi Arabia",
      "Abidjan (ABJ), Côte d'Ivoire",
      "Abuja (ABV), Nigeria",
      "Accra (ACC), Ghana",
      "Addis Ababa (ADD), Ethiopia",
      "Adelaide (ADL), Australia",
      "Albany Intl (ALB), United States",
      "Alicante (ALC), Spain",
      "Amman (AMM), Jordan",
      "Amsterdam (AMS), Netherland",
      "Antananarivo (TNR), Madagascar",
      "Aomori(AOJ), Japan",
      "Arba Minch (AMH), Ethiopia",
      "Asmara (ASM), Eritrea",
      "Assosa (ASO), Ethiopia",
      "Athens (ATH), Greece",
      "Atlanta (ATL), United States",
      "Auckland Airport (AKL), New Zealand",
      "Austin (AUS), United States",
      "Avalon AU(AVV), Australia",
      "Axum (AXU), Ethiopia",
      "Ayers Rock(AYQ), Australia ",
      "Bahar Dar (BJR), Ethiopia",
      "Bahrain (BAH), Bahrain",
      "Ballina(BNK), Australia",
      "Bamako (BKO), Mali",
      "Bandung (BDO), Indonesia",
      "Bangkok Suvarnabhumi (BKK), Thailand",
      "Bangui (BGF), Central African Republic",
      "Barcelona (BCN), Spain",
      "Beijing (BJS), China",
      "Beijing (PEK), China",
      "Beirut (BEY), Lebanon",
      "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil",
      "Bergen (BGO), Norway",
      "Bergenz (XGZ), Austria",
      "Berlin Tegel (TXL), Germany",
      "Bilbao (BIO), Spain",
      "Billund (BLL), Denmark",
      "Birmingham (BHX) , United Kingdom",
      "Blantyre (BLZ), Malawi",
      "Boston (BOS), United States",
      "Boulder (BLD), United States",
      "Brasilia (BSB), Brazil",
      "Brazzaville (BZV), Republic of Congo",
      "Bremen (BRE), Germany",
      "Brisbane (BNE), Australia",
      "Brussels (BRU), Belgium",
      "Bucharest (OTP), Romania",
      "Budapest, Liszt Ferenc Airport (BUD), Hungary",
      "Buenos Aires (EZE), Argentina",
      "Buffalo (BUF), United States",
      "Bujumbura (BJM), Burundi",
      "Burlington (BTV), United States",
      "Cairo (CAI), Egypt",
      "Calgary Intl AB (YYC), Canada",
      "Campo Grande International Airport (CGR), Brazil",
      "Cape Town (CPT), South Africa",
      "Cardiff (CWL) , United Kingdom",
      "Changsha (CSX), China",
      "Charleston (CHS), United States",
      "Charleston (CRW), United States",
      "Charlotte (CLT), United States",
      "Charlottesville (CHO), United States",
      "Chengdu (CTU), China",
      "Chiang Mai (CNX), Thailand",
      "Chicago Ohare (ORD), United States",
      "Chongqing (CKG), China",
      "Christchurch Airport (CHC), New Zealand",
      "Cincinnati (CVG), United States",
      "Cleveland (CLE), United States",
      "Columbia (CAE), United States",
      "Columbus (CMH), United States",
      "Conakry (CKY), Guinea",
      "Copenhagen (CPH), Denmark",
      "Coruna (LCG), Spain",
      "Cotonou (COO), Benin",
      "Cuiaba (CGB), Brazil",
      "Curitiba (CWB), Brazil",
      "Dakar Blaise Diagne Airport (DSS), Senegal",
      "Dakar(DKR), Senegal",
      "Dalian (DLC), China",
      "Dallas FT Worth , TX (DFW), United States",
      "Dammam (DMM), Saudi Arabia",
      "Dar Es Salaam (DAR), Tanzania",
      "Darwin(DRW), Australia",
      "Dayton (DAY), United States",
      "Delhi (DEL), India",
      "Dembidollo (DEM), Ethiopia",
      "Denpasar Bali (DPS), Indonesia",
      "Denver (DEN), United States",
      "Detroit (DTW), United States",
      "Dire Dawa (DIR), Ethiopia",
      "Djibouti (JIB), Djibouti",
      "Doha (DOH), Qatar",
      "Douala (DLA), Cameroon",
      "Dresden (DRS), Germany",
      "Dubai (DXB), UAE",
      "Dubai Al Maktoum (DWC), UAE",
      "Dublin (DUB), Ireland",
      "Durban (DUR), South Africa",
      "Dusseldorf (DUS), Germany",
      "Edinburgh (EDI) , United Kingdom",
      "Edmonton Intl AB (YEG), Canada",
      "El Paso, TX (ELP), United States",
      "Entebbe (EBB), Uganda",
      "Enugu (ENU), Nigeria",
      "Florianopolis (FLN) , Brazil",
      "Fortaleza (FOR), Brazil",
      "Frankfurt (FRA), Germany",
      "Freetown (FNA), Metropolitan Area,Sierra Leone",
      "Friedrichshafen (FDH), Germany",
      "Ft Lauderdale (FLL), United States",
      "Fukuoka (FUK), Japan",
      "Funchal (FNC), Portugal",
      "Gaborone (GBE), Botswana",
      "Gambella (GMB), Ethiopia",
      "Garoua International Airport (GOU), Cameroon",
      "Gassim (ELQ), Saudi Arabia",
      "Geneva (GVA), Switzerland",
      "Glasgow (GLA), United Kingdom",
      "GOA (GOI), INDIA",
      "Goba, Bale - Goba (GOB), Ethiopia",
      "Gode (GDE), Ethiopia",
      "Goiania, Santa Genoveva Airport (GYN), Brazil",
      "Goma (GOM), Democratic Republic of Congo",
      "Gondar (GDQ), Ethiopia",
      "Gothenburg (GOT), Sweden",
      "Grand Rapids (GRR), United States",
      "Graz (GRZ), Austria",
      "#60ac5dsboro (GSO), United States",
      "#60ac5dville Spartanburg  (GSP), United States",
      "Guangzhou (CAN), China",
      "Haikou (HAK), China",
      "Hali (HAS), Saudi Arabia",
      "Halifax (YHZ), Canada",
      "Hamburg (HAM), Germany",
      "Hamilton Island(HTI), Australia",
      "Hannover (HAJ), Germany",
      "Hanoi(HAN), Vietnam",
      "Harare (HRE), Zimbabwe",
      "Hargeisa (HGA), Somaliland",
      "Harrisburg (MDT), United States",
      "Hartford Sprngfld, CT (BDL), United States",
      "Hawassa (AWA), Ethiopia",
      "Helsinki (HEL), Finland",
      "Ho Chi Minh City(SGN), Vietnam",
      "Hobart(HBA), Australia ",
      "Hong Kong (HKG), China",
      "Honolulu (HNL), United States",
      "Houston (HOU), United States",
      "Houston (IAH), United States",
      "Humera (HUE), Ethiopia",
      "Huntsville (HSV), United States",
      "Hyderabad(HYD), India",
      "Ibiza (IBZ), Spain",
      "Iguassu (IGU), Brazil",
      "Indianapolis (IND), United States",
      "Innsbruck (IOB), Austria",
      "Istanbul Ataturk (IST), Turkey",
      "Jacksonville (JAX), United States",
      "Jakarta (CGK), Indonesia",
      "Jakarta(JKT), Indonesia",
      "Jazan (GIZ), Saudi Arabia",
      "Jeddah (JED), Saudi Arabia",
      "Jijiga (JIJ), Ethiopia",
      "Jimma (JIM), Ethiopia",
      "Jinka (BCO), Ethiopia",
      "Johannesburg (JNB), South Africa",
      "Juazeiro Norte (JDO), Brazil",
      "Juba (JUB), South Sudan",
      "Kabri Dar (ABK), Ethiopia",
      "Kaduna (KAD), Nigeria",
      "Kano (KAN), Nigeria",
      "Kansas City (MCI), United States",
      "Khartoum (KRT), Sudan",
      "Kigali (KGL), Rwanda",
      "Kilimanjaro (JRO), Tanzania",
      "Kinshasa Ndjili (FIH), Democratic Republic of Congo",
      "Kisangani (FKI), Democratic Republic of Congo",
      "Klagenfurt (KGV) , Austria",
      "Knoxville (TYS), United States",
      "Koh Samui (USM), Thailand ",
      "kombolcha (DSE), Ethiopia",
      "Kota Kinabalu (BKI), Malaysia",
      "Kristiansand (KRS), Norway",
      "Kuala Lumpur (KUL), Malaysia",
      "Kuching (KCH), Malaysia",
      "Kunming (KMG), China",
      "Kuwait (KWI), Kuwait",
      "Lagos (LOS), Nigeria",
      "Lalibella (LLI), Ethiopia",
      "Langkawi (LGK), Malaysia",
      "Larnaca (LCA), Cyprus",
      "Las Vegas (LAS), United States",
      "Launceston(LST) , Australia",
      "Leeds Bradford (LBA), United Kingdom",
      "Leipzig Halle (LEJ), Germany",
      "Libreville (LBV), Gabon",
      "Lilongwe (LLW), Malawi",
      "Linz (LNZ), Austria",
      "Lisbon (LIS), Portugal",
      "Lome (LFW), Togo",
      "London (LON), United Kingdom",
      "London Heathrow (LHR), United Kingdom",
      "London On (YXU), CANADA",
      "Londrina Airport (LDB), Brazil",
      "Los Angeles (LAX), United States",
      "Louisville (SDF), United States",
      "Luanda (LAD), Angola",
      "Lubumbashi (FBM), Democratic Republic of Congo",
      "Lulea (LLA), Sweden",
      "Lusaka (LUN), Zambia",
      "Lyon, Satolaos Saint Exupery Airport (LYS), France",
      "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil",
      "Madrid (MAD), Spain",
      "Mahe Island (SEZ), Seychelles",
      "Malabo (SSG), Equatorial Guinea",
      "Malmo (MMA), Sweden",
      "Manado (MDC), Indonesia",
      "Manchester(MAN), United Kingdom",
      "Manila (MNL), Philippines",
      "Maputo (MPM), Mozambique",
      "Marseille, Marignane Airport(MRS), France",
      "Mauritius (MRU), Mauritius",
      "Mbuji Mayi (MJM), Democratic Republic of Congo",
      "Medan Kuala Namu(KNO), Indonesia",
      "Medina (MED), Saudi Arabia",
      "Mekelle (MQX), Ethiopia",
      "Melbourne (MEL), Australia",
      "Memphis(MEM), United States",
      "Miami (MIA), United States",
      "Milan Malpensa (MXP), Italy",
      "Minneapolis (MSP), United States",
      "Mombassa (MBA), Kenya",
      "Moncton (YQM), Canada",
      "Monrovia (MLW), Republic of Liberia",
      "MONT TREMBLANT (YTM), Canada",
      "Montreal Trudeau (YUL), Canada",
      "Moroni (HAH), Comoros",
      "Moscow (MOW), Russia",
      "Muenster (FMO), Germany",
      "Mumbai (BOM), India",
      "Munich (MUC), Germany",
      "Muscat (MCT), Oman",
      "Nagoya (NGO), Japan",
      "Nairobi Kenyatta (NBO), Kenya",
      "Nashville (BNA), United States",
      "Natal International Airport (NAT), Brazil",
      "Navegantes, Victor Konder Airport (NVT), Brazil",
      "N'djamena (NDJ), Chad",
      "Ndola (NLA), Zambia",
      "New Orleans (MSY), United States",
      "NEW YORK (JFK), United States",
      "New York (LGA), United States",
      "Newark (EWR), United States",
      "Newark (NYC), United States",
      "Niamey (NIM), Niger",
      "Norfolk VA Beach (ORF), United States",
      "Nosy Be (NOS), Madagascar",
      "Nuremberg (NUE), Germany",
      "Oakland (OAK), United States",
      "Okinawa (OKA), Japan",
      "Oklahoma City (OKC), United States",
      "Orange County (SNA), United States",
      "Orlando (MCO), United States",
      "Osaka Itami (ITM), Japan",
      "Osaka Kansai (KIX), Japan",
      "Oslo (OSL), Norway",
      "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ",
      "Ottawa On (YOW), Canada",
      "Ouagadougou (OUA), Burkina Faso",
      "Palembang (PLM), Indonesia",
      "Palma De Mallorca (PMI), Spain",
      "Paris De Gaulle (CDG), France",
      "Pekanbaru (PKU), Indonesia",
      "Penang (PEN), Malaysia",
      "Perth (PER), Australia",
      "Philadelphia (PHL), United States",
      "Phoenix (PHX), United States",
      "Phuket (HKT), Thailand",
      "Pittsburgh (PIT), United States",
      "Pointe Noire (PNR), Republic of Congo",
      "Portland ME (PWM), United States",
      "Portland OR (PDX), United States",
      "Porto Alegre (POA), Brazil",
      "Porto Portugal (OPO), Portugal",
      "Porto Santo (PXO), Portugal",
      "Prague - Ruzyne Airport(PRG), Czech Republic",
      "Praya Lombok (LOP), Indonesia",
      "Providence (PVD), United States",
      "Pusan (PUS), South Korea",
      "Qaisumah (AQI), Saudi Arabia",
      "Québec (YQB), Canada",
      "Railway Germany (QYG), Germany",
      "Raleigh Durham (RDU), United States",
      "Recife (REC), Brazil",
      "Regina (YQR), Canada",
      "Richmond (RIC), United States",
      "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil",
      "Rio Janeiro Gig (GIG), Brazil",
      "Riyadh (RUH), Saudi Arabia",
      "Roanoke (ROA), United States",
      "Rochester (ROC), United States",
      "Rome (ROM), Italy",
      "Rome Fiumicino (FCO), Italy",
      "Rotterdam (RTM), Netherlands",
      "Sacramento (SMF), United States",
      "Salvador (SSA), Brazil",
      "Salzburg (SZG), Austria",
      "San Antonio (SAT), United States",
      "San Diego (SAN), United States",
      "San Francisco (SFO), United States",
      "Santa Maria Azors (SMA), Portugal",
      "Sao Paulo (GRU), Brazil",
      "Sao Paulo (SAO), Brazil",
      "Sapporo Chitose (CTS), Japan",
      "Saskatoon SK (YXE), Canada",
      "Savannah (SAV), United States",
      "Seattle Tacoma (SEA), United States",
      "Semarang (SRG), Indonesia",
      "Semera (SZE), Ethiopia",
      "Seoul (ICN), South Korea",
      "Seoul (SEL), South Korea",
      "Shanghai (PVG), China",
      "Shanghai (SHA), China",
      "Shantou (SWA), China",
      "Shenzhen (SZX), China",
      "Shire (SHC), Ethiopia",
      "Singapore (SIN), Singapore",
      "Skelleftea (SFT), Sweden",
      "Sofia Airport (SOF), Bulgaria",
      "ST Johns NF (YYT), Canada",
      "St. Louis (STL), United States",
      "St. Polten (POK), Austria",
      "State College (SCE), United States",
      "Stavanger(SVG), Norway",
      "Stockholm (STO), Sweden",
      "Stockholm Arlanda (ARN), Sweden",
      "Stuttgart (STR), Germany",
      "Sultan Hasanuddin (UPG), Indonesia",
      "Sunshine Coast(MCY), Australia",
      "Surabaya (SUB), Indonesia",
      "Sydney (SYD), Australia",
      "Syracuse (SYR), United States",
      "Tabuk (TUU), Saudi Arabia",
      "Taif (TIF), Saudi Arabia",
      "Tampa (TPA), United States",
      "Tel Aviv (TLV), Israel",
      "Tete (TET), Mozambique",
      "The Hague (HAG), Netherlands",
      "Tokyo (TYO), Japan",
      "Tokyo Haneda (HND), Japan",
      "Tokyo Narita (NRT), Japan",
      "Toronto (YTO), Canada",
      "Toronto (YYZ), Canada",
      "Toulouse (TLS), France",
      "Townsville(TSV), Australia",
      "Trondheim (TRD), Norway",
      "Turku (TKU), Finland",
      "Umea (UME), Sweden",
      "Vaasa (VAA), Finland",
      "Valencia (VLC), Spain",
      "Vancouver BC (YVR), Canada",
      "Victoria (YYJ), Canada",
      "Victoria Falls (VFA), Zimbabwe",
      "Vienna (VIE), Austria",
      "Vigo (VGO), Spain",
      "Vilnius, Vilnius Airport(VNO), Lithuania",
      "Warsaw, Chopin Okecie Airport (WAW), Poland",
      "Washington (WAS), United States",
      "Washington Dulles (IAD), United States",
      "Windhoek (WDH), Namibia",
      "Winnipeg MB (YWG), Canada",
      "Wuhan (WUH), China",
      "Xiamen (XMN), China",
      "Yaounde Nsimalen (NSI), Cameroon",
      "Yogyakarta (JOG), Indonesia",
      "Yundum Airport (BJL), Gambia",
      "Zanzibar (ZNZ), Tanzania",
      "Zurich (ZRH), Switzerland",
    ];
    return (
      <div>
        <Form.Row>
          <label>Flight {this.props.index + 3}</label>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>From*</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="departureAirport">
                  <FlightTakeoffSharp />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  inputProps={{ placeholder: "Departure Airport" }}
                  items={airpotList}
                  shouldItemRender={(item, value) =>
                    item
                      .toString()
                      .toLowerCase()
                      .indexOf(value.toString().toLowerCase()) > -1
                  }
                  getItemValue={(item) => item}
                  renderMenu={(item) => (
                    <div Key={item} className="dropdown">
                      {item}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item}
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item}
                    </div>
                  )}
                  value={this.state.from}
                  onChange={(e) => this.setState({ from: e.target.value })}
                  onSelect={(value) => this.setState({ from: value })}
                />
              </div>
            </InputGroup>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>To*</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="departureAirport">
                  <FlightTakeoffSharp />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  inputProps={{ placeholder: "Arrival Airport" }}
                  items={airpotList}
                  shouldItemRender={(item, value) =>
                    item
                      .toString()
                      .toLowerCase()
                      .indexOf(value.toString().toLowerCase()) > -1
                  }
                  getItemValue={(item) => item}
                  renderMenu={(item) => (
                    <div Key={item} className="dropdown">
                      {item}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item}
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item}
                    </div>
                  )}
                  value={this.state.to}
                  onChange={(e) => this.setState({ to: e.target.value })}
                  onSelect={(value) => this.setState({ to: value })}
                />
              </div>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>
              Depart date* &nbsp;&nbsp; &nbsp;&nbsp;Return Date*
            </Form.Label>

            <InputGroup className="mb-3">
              <DateRangePicker
                className="DatePicker"
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="start_date" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="end_date" // PropTypes.string.isRequired,
                startDatePlaceholderText="Depart date"
                endDatePlaceholderText="Return Date"
                block="true"
                onDatesChange={({ startDate, endDate }) =>
                  this.setState({ startDate, endDate })
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
      </div>
    );
  }
}
export default FlightInput;
