import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { faPlane, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataService } from "../../../services/shared-services/dataServices";
import { HttpService } from "../../../services/http/http-service";
class FareRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fareRule: null,
      showFareRule: false,
    };
    this.SessionId = localStorage.getItem("sessionId");
  }
  componentDidMount() {
    if (this.props.firstLoad === true) {
      HttpService.getFareRule(this.SessionId)
        .then((response) => {
          this.setState({
            fareRule: response.data.segmentFareRules,
          });
          localStorage.setItem(
            "fareRule",
            JSON.stringify(response.data.segmentFareRules)
          );
        })
        .catch((error) => {});
    } else {
      var fareRules = JSON.parse(localStorage.getItem("fareRule"));
      this.setState({ fareRule: fareRules });
    }
  }
  render() {
    return (
      <>
        <label>See Applicable<span style={{ color: "green", cursor: "pointer" }} onClick={()=>{
            var test = this.state.showFareRule;
            this.setState({ showFareRule: !test });
            }}> Fare Rules</span></label>
        <Modal
          show={this.state.showFareRule}
          onHide={()=>{
            var test = this.state.showFareRule;
            this.setState({ showFareRule: !test });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Fare Rules</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              When it comes to fares and fees, we don't want there to be any
              unwanted surprises. Take the time to read through this section, so
              you know exactly what fees can expect and even better, what fees
              you can avoid altogether.
            </p>
            {this.state.fareRule != null ? (
              <>
                {this.state.fareRule.map((item, key1) => (
                  <div key={key1}>
                    {item.segmentKeys.map((segmentKeys, key2) => (
                      <div key={key2}>
                        <h5 style={{ textAlign: "center" }}>
                          {DataService.getFullCityName(segmentKeys.origin)}
                          {"  "}
                          <FontAwesomeIcon
                            icon={faPlane}
                            size="lg"
                            style={{ fontSize: "20px", color: "#60ac5d" }}
                          />
                          {"  "}
                          {DataService.getFullCityName(segmentKeys.destination)}
                        </h5>
                        <hr
                          style={{ height: "1px", backgroundColor: "#60ac5d" }}
                        />
                      </div>
                    ))}
                    {item.fareBasisRules.fareRules.map((fareRules, key3) => (
                      <div key={key3}>
                        <h5>
                          <pre>{fareRules.ruleCode}</pre>
                        </h5>
                        {/* <div dangerouslySetInnerHTML={{__html: fareRules.ruleText}}></div> */}
                        <div>
                          <pre>{fareRules.ruleText}</pre>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <h2 style={{ textAlign: "center" }}>Loading...</h2>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-success" onClick={()=>{
            var test = this.state.showFareRule;
            this.setState({ showFareRule: !test });
            }}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default FareRule;
