import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import "./hotel.css";
import HotelRating from "./hotel-rating";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class HotelInformation extends Component {
  constructor(props) {
    super(props);
  }
  async componentDidMount() {
    // await this.setState({ hotelInfo: this.props.hotelInfo });
  }
  renderHotelImage(image) {
    if (image !== "" && image !== null) {
      return (
        <div
          className="col-12 col-sm-4 col-md-4 col-lg-4 hotel-img"
          // style={{
          //   backgroundImage:
          //     "url(" +
          //     "http://photos.hotelbeds.com/giata/bigger/" +
          //     image +
          //     ")",
          // }}
        >
        <LazyLoadImage  className="card-img  "  style={{ cursor: "pointer !important", maxHeight: "150px" }}
      alt={""} 
      effect="blur"
      src={"http://photos.hotelbeds.com/giata/bigger/" +image} />

        </div>
      );
    } else {
      return (
        <div
          className="col-12 col-sm-4 col-md-4 col-lg-4 hotel-img"
          style={{
            backgroundImage:
              "url(" +
              window.location.origin +
              "/assets/image/hotel/hotel-noimage.jpg" +
              ")",
          }}
        ></div>
      );
    }
  }
  render() {
    const { hotel, content } = this.props.hotelInfo;
    if(hotel != null && content !=null){
      return (
        <div className="text-center hotel-info-container">
          <div className="row m-0">
            {this.renderHotelImage(content.images[0].path)}
            <div className="col-12 col-sm-8  col-md-8 col-lg-8 text-left pl-4 pr-3 pt-4">
              <div className="row m-0">
                <div className="hotel-name col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                  <h4>{hotel.name}</h4>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right pr-5">
                  <HotelRating hotelRating={hotel.categoryCode} />
                </div>
              </div>
  
              <div className="hotel-address">
                <FontAwesomeIcon
                  icon={faMapMarked}
                  style={{ color: "red", fontSize: "17px" }}
                />
                <span className="pl-2">
                  {content.address.content}
                  {content.city.content}
                </span>
              </div>
              <div className="hotel-price text-right pr-4 pt-2">
                <h4>
                  <span className="hotel-price-start-from">from </span>
                  {localStorage.getItem("Currency") + " "}
                  {hotel.minRate}
                </h4>
                {/* <span>per night</span> */}
              </div>
              {/* <div className="hotel-cancellation pt-1">
                <span>Free Cancellation</span>
              </div> */}
            </div>
          </div>
        </div>
      );
    }
    else{
      return null;
    }

  }
}

export default HotelInformation;
