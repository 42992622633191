import React, { Component } from "react";
import "./selected-hotel-confirmation.css";
import { withRouter } from "react-router-dom";
class ConfirmSelectedHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "0",
      right: "-100px",
    };
  }
  componentDidMount() {
    
    if (this.props.isConfirmationOpen) {
      this.closeConfirmation();
      this.openConfirmation();
    }
  }
  async openConfirmation() {
    // await this.setState({ width: "450px" });
    // await this.setState({ right: "0" });
    
    let elts = document.getElementsByClassName("sidenav");
    for (let i = 0; i < elts.length; i++) {
      

      let elm = elts[i];
      if (elm.className.includes("sidenav" + this.props.classId)) {
        elm.style.width = "350px";
        elm.style.right = "0";
      } else {
        elm.style.width = "0";
        elm.style.right = "-100px";
      }
    }

    let totalPrice = 0;
    if (this.props.roomConfirmationInfo.length > 0) {
      this.props.roomConfirmationInfo.forEach((roomInfo) => {
        totalPrice += parseFloat(roomInfo.price);
      });

      localStorage.setItem("hotelTotalPrice", totalPrice.toFixed(3));
    }
  }
  async closeConfirmation() {
    let elts = document.getElementsByClassName("sidenav");
    for (let i = 0; i < elts.length; i++) {
      
      let elm = elts[i];
      elm.style.width = "0";
      elm.style.right = "-100px";
    }
  }
  navigateToPassengerInfo() {
    var test = localStorage.getItem("bundAndSave");
    if (test == "true") {
      this.props.history.push("/bundAndSave");
    }
    else {
      this.props.history.push("/passenger");
    }
  }
  renderSelectedRooms() {
    
    if (this.props.roomConfirmationInfo.length > 0) {
      return (
        <>
          {this.props.roomConfirmationInfo.map((roomInfo, key) => (
            <div key={key} className="pt-3">
              <h5 className="text-left">Room {key + 1}</h5>{" "}
              <h5 className="text-left room-name"> {roomInfo.name}</h5>
              <h6 className="text-left">
                <span>Price: </span>
                <span>
                  {localStorage.getItem("Currency") + " " + roomInfo.price}
                </span>
              </h6>
              <h6 className="text-left">
                <span> Cancelation Date : </span>
                <span>
                  { roomInfo.cancelationDate}
                </span>
              </h6>
            </div>
          ))}
        </>
      );
    }
  }
  render() {
    let sideNavContainerStyle = {
      width: this.state.width,
      right: this.state.right,
    };

    return (
      <div>
        <div className={"sidenav" + this.props.classId + " sidenav pl-5"}>
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={() => {
              this.closeConfirmation();
            }}
          >
            &times;
          </a>
          <div className="pb-2">
            <h3 className="text-left">Confirm your selection</h3>
            {(!this.props.allRoomSelected)&&<p>Please select all {this.props.roomsLength} Room(s) to Continue</p>}
          </div>
          <div className="pb-2">
            <h5 className="text-left">Hotel</h5>
            <h5 className="text-left hotel-name">{this.props.hotelName}</h5>
          </div>
          <div className="pb-2">
            {this.renderSelectedRooms()}
            <div className="pt-5">
              {/* <h3 className="text-left">
                <span>Total : </span>
                <span></span>
              </h3> */}
            </div>
            <div className="pt-2 m-2">
              <button
                className="btn btn-primary float-right mr-5 confirm-room-selection-btn"
                // disabled={this.props.roomsLength>this.props.roomConfirmationInfo.length+1}
                disabled={!this.props.allRoomSelected}
                onClick={() => {
                  this.navigateToPassengerInfo();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConfirmSelectedHotel);
