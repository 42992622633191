import React, { Component } from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Modal from "react-bootstrap/Modal";
import "./shopping-cart.css";
import NumberFormat from "react-number-format";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faPlane,
  faAngleDoubleRight,
  faAngleDown,
  faUsers,
  faAngleUp,
  faGift,
  faBed,
} from "@fortawesome/free-solid-svg-icons";
import Widget from "../home/widget/widget";
import { Edit } from "@material-ui/icons";
import { MobileView, BrowserView } from "react-device-detect";
import {
  Accordion,
  Card,
  Row,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"; 
// #12577 Package booking date error
class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    let tourPreference = localStorage.getItem("tourPreference");
    let searchRequestObj = JSON.parse(localStorage.getItem("searchRequestObj"));
    this.state = {
      tourPreference: tourPreference,
      showDialog: false,
      packagePrice: 0,
      hotelPrice: 0,
      flightPrice: 0,
      bunldePrice: 0,
      adultList: [],
      childList: [],
      infantList: [],
      flightAccordion: true,
      hotelOrPackageAccordion: false,
      passengerAccordion: false,
    };

    this.total = 0;
    this.flightDetail = [];
    this.hotelDetail = null;
    this.PackageSummaryData = null;
    this.bundleSummary = null;
    this.isMulticity = false;
    this.checkInDate = null;
    this.checkOutDate = null;
    this.adult = searchRequestObj.passengers.ADT;
    this.child = searchRequestObj.passengers.CHD;
    this.infant = searchRequestObj.passengers.INF;
  }
  changeDateFormat = (date) => {
    var stringDate =
      date.getDate() +
      " " +
      date.toLocaleString("en-us", { month: "short" }) +
      " " +
      date.getFullYear();
    return stringDate;
  };
  passengerDetail = () => {
    //debugger
    var passengerDetails = JSON.parse(localStorage.getItem("passengerDetails"));
    var adultList = [];
    var childList = [];
    var infantList = [];
    var adultNo = 0;
    var childNo = 0;
    var infantNo = 0;
    try {
      passengerDetails.data.passengers.passengers.forEach((passenger) => {
        if (passenger.passengerInfo.type === "ADT") {
          adultNo++;
          adultList.push(
            <div>
              <p className="text-left">
                A{adultNo} : {passenger.passengerDetails.firstName}{" "}
                {passenger.passengerDetails.lastName}
              </p>
            </div>
          );
        } else if (passenger.passengerInfo.type === "CHD") {
          childNo++;
          childList.push(
            <div>
              <p className="text-left">
                C{childNo} : {passenger.passengerDetails.firstName}{" "}
                {passenger.passengerDetails.lastName}
              </p>
            </div>
          );
        } else {
          infantNo++;
          infantList.push(
            <div className="float-left">
              <p className="text-left">
                I{infantNo} : {passenger.passengerDetails.firstName}{" "}
                {passenger.passengerDetails.lastName}
              </p>
            </div>
          );
        }
      });
      this.setState({
        adultList: adultList,
        childList: childList,
        infantList: infantList,
      });
    } catch (error) {
      //debugger
      // console.log(error);
    }
  };

  async componentDidMount() {
    this.passengerDetail();
    let flightPriceTemp = parseFloat(localStorage.getItem("totalFlighAmount"));
    let packagesPriceTemp = 0;
    if (this.state.tourPreference === "flightPlusPackage") {
      let PackageSummery = JSON.parse(localStorage.getItem("PackageSummery"));
      if (PackageSummery) {
        for (let i = 0; i < PackageSummery.length; i++) {
          packagesPriceTemp += PackageSummery[i].totalAmount;
        }
      }
    }
    let hotelPriceTemp =
      this.state.tourPreference === "flightPlusHotel"
        ? parseFloat(localStorage.getItem("hotelTotalPrice"))
        : 0;
    var bundlePriceTemp = 0;
    this.bundleSummary = JSON.parse(localStorage.getItem("BundleSummery"));
    if (
      this.bundleSummary != null &&
      localStorage.getItem("bundAndSave") == "true"
    ) {
      this.bundleSummary.forEach((element) => {
        bundlePriceTemp += parseFloat(element.amount);
      });
    }
    this.total =
      flightPriceTemp + packagesPriceTemp + hotelPriceTemp + bundlePriceTemp;
    this.setState({
      flightPrice: flightPriceTemp,
      packagePrice: packagesPriceTemp,
      hotelPrice: hotelPriceTemp,
      bunldePrice: bundlePriceTemp,
    });
    let hotelSearchRequest = JSON.parse(
      localStorage.getItem("hotelSearchRequest")
    );
    try {
      this.checkInDate = this.changeDateFormat(
        new Date(hotelSearchRequest.params.CheckIn)
      );
      this.checkOutDate = this.changeDateFormat(
        new Date(hotelSearchRequest.params.CheckOut)
      );
    } catch (error) {
      // console.log(error);
    }

    let localFlightInfo = JSON.parse(localStorage.getItem("flightInfo"));
    let localSearchRequest = JSON.parse(
      localStorage.getItem("searchRequestObj")
    );
    this.hotelDetail = JSON.parse(localStorage.getItem("hotelDetails"));
    this.PackageSummaryData = JSON.parse(
      localStorage.getItem("PackageSummery")
    );

    if (localFlightInfo != null) {
      var i = 0;
      localSearchRequest.itineraryParts.forEach((element) => {
        try {
          var tempFligtDate = {
            amount: localFlightInfo[i].amount,
            class: localFlightInfo[i].class,
            departDate: localFlightInfo[i].departDate,
            arrivalDate: localFlightInfo[i].arrivalDate,
            from: element.from.code,
            to: element.to.code,
            fare: localFlightInfo[i].fare,
            flightFare: localFlightInfo[i].flightFare,
            tax: localFlightInfo[i].tax,
            surcharges: localFlightInfo[i].surcharges,
          };
        } catch (error) {}
        this.flightDetail.push(tempFligtDate);
        i++;
      });
    }
    let isMulticity = localStorage.getItem("isMulticity");
    if (isMulticity == "yes") {
      this.isMulticity = true;
    }
  }
  renderRating(hotelstarStr) {
    let starRating = [];
    // let hoteStar = parseInt(this.props.hotelRating.replace(/[^0-9]/g, ""));
    let hoteStar = parseInt(hotelstarStr.replace(/[^0-9]/g, ""));
    for (let i = 0; i < hoteStar; i++) {
      starRating.push("star");
    }
    return (
      <div>
        {starRating.map((star, key) => (
          <span key={key}>
            <FontAwesomeIcon
              icon={faStar}
              style={{ color: "#60ac5d", fontSize: "13px" }}
            />
          </span>
        ))}
      </div>
    );
  }
  showModal = () => {
    this.setState({ showDialog: !this.state.showDialog });
  };
  hideShowflighInfo = (index) => {
    var pId = "#flight" + index;
    $(pId).toggle(500);
  };
  parseDate = (dateString) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  showWidgetModel = () => {
    var currentValue = this.state.showWidget;
    this.setState({ showWidget: !currentValue });
  };
  getHotelName = (device) => {
    if (device === "desktop") {
      try {
        return (
          <div className="row">
            <div className="col-6">
              <span className="float-left">{this.hotelDetail.hotel.name}</span>
            </div>
            <div className="col-6">
              <span className="float-right">
                {this.renderRating(this.hotelDetail.hotel.categoryName)}
              </span>
            </div>
          </div>
        );
      } catch (error) {
        try {
          return (
            <div className="row">
              <div className="col-6">
                <span className="float-left">
                  {this.hotelDetail.data.hotel.name}
                </span>
              </div>
              <div className="col-6">
                <span className="float-right">
                  {this.renderRating(this.hotelDetail.data.hotel.categoryName)}
                </span>
              </div>
            </div>
          );
        } catch (error) {
          return <></>;
        }
      }
    } else {
      try {
        return (
          <span>
            <span>{this.hotelDetail.hotel.name}</span>
            {this.renderRating(this.hotelDetail.hotel.categoryName)}
          </span>
        );
      } catch (error) {
        try {
          return (
            <span>
              <span>{this.hotelDetail.data.hotel.name}</span>
              {this.renderRating(this.hotelDetail.data.hotel.categoryName)}
            </span>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  };
  desktopViewHandler = () => {
    return (
      <div className="shopping-cart mt-5 flight-selection-container">
        <Accordion defaultActiveKey="0" style={{ padding: 0, margin: 0 }}>
          <Card>
            <h1 className="ml-3 shopping-cart-header">Trip Summary</h1>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="shopping-cart-header-border  shopping-cart-active"
              onClick={() =>
                this.setState({ flightAccordion: !this.state.flightAccordion })
              }
            >
              <div className="row">
                <div className="col-6" style={{ fontSize: "20px" }}>
                  <span className="float-left shopping-cart-header-text">
                    <FontAwesomeIcon
                      icon={faPlane}
                      className="shopping-cart-active shopping-cart-left-icon mr-2 plane-icon "
                    />
                    {"  "}
                    Flight
                  </span>
                </div>
                <div className="col-6">
                  <span className="float-right shopping-cart-header-text">
                    <NumberFormat
                      value={this.state.flightPrice}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                    />
                    {"  "}
                    {localStorage.getItem("Currency")}
                    <FontAwesomeIcon
                      icon={
                        this.state.flightAccordion ? faAngleUp : faAngleDown
                      }
                      className="shopping-cart-right-icon ml-3"
                    />
                  </span>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {this.isMulticity ? (
                  <div>
                    {this.flightDetail.map((tempFlightDetail, index) => (
                      <div key={index}>
                        <div className="collection-item">
                          <div className="row">
                            <div className="col-1 align-self-center">
                              <h4>
                                <FontAwesomeIcon
                                  className="pl-2 pr-2"
                                  icon={faPlane}
                                  size="lg"
                                />
                              </h4>
                            </div>
                            <div className="col-3">
                              <h4>{tempFlightDetail.from}</h4>
                              <span style={{ "font-size": "11px" }}>
                                {this.parseDate(tempFlightDetail.departDate)}
                              </span>
                            </div>
                            <div className="col-1 align-self-center">
                              <h4 className="d-flex justify-content-center">
                                <FontAwesomeIcon
                                  icon={faAngleDoubleRight}
                                  size="lg"
                                />
                              </h4>
                            </div>
                            <div className="col-3">
                              <h4>{tempFlightDetail.to}</h4>
                              <span style={{ "font-size": "11px" }}>
                                {this.parseDate(tempFlightDetail.arrivalDate)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-3 float-right collection-item">
                        <h6>
                          {localStorage.getItem("Currency")}{" "}
                          <NumberFormat
                            value={this.flightDetail[0].amount}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                          />{" "}
                        </h6>
                        <span style={{ "font-size": "11px" }}>
                          {this.flightDetail[0].class}
                        </span>
                      </div>
                      <div
                        id="flight0"
                        className="float-left"
                        style={{ display: "none" }}
                      >
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Fare</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={this.flightDetail[0].fare}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Surcharges</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={this.flightDetail[0].surcharges}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Taxs</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={this.flightDetail[0].tax}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Total</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={this.flightDetail[0].amount}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p
                          className="priceDetail float-right"
                          onClick={this.hideShowflighInfo.bind(this, 0)}
                        >
                          Price Detail
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.flightDetail.map((tempFlightDetail, index) => (
                      <div>
                        <div className="collection-item">
                          <div className="row">
                            <div className="col-1 align-self-center">
                              <h4>
                                <FontAwesomeIcon
                                  className="pl-2 pr-2"
                                  icon={faPlane}
                                  size="lg"
                                />
                              </h4>
                            </div>
                            <div className="col-3">
                              <h4>{tempFlightDetail.from}</h4>
                              <span style={{ "font-size": "11px" }}>
                                {this.parseDate(tempFlightDetail.departDate)}
                              </span>
                            </div>
                            <div className="col-1 align-self-center">
                              <h4 className="d-flex justify-content-center">
                                <FontAwesomeIcon
                                  icon={faAngleDoubleRight}
                                  size="lg"
                                />
                              </h4>
                            </div>
                            <div className="col-3">
                              <h4>{tempFlightDetail.to}</h4>
                              <span style={{ "font-size": "11px" }}>
                                {this.parseDate(tempFlightDetail.arrivalDate)}
                              </span>
                            </div>
                            <div className="col-3">
                              <h6>
                                {localStorage.getItem("Currency")}{" "}
                                <NumberFormat
                                  value={tempFlightDetail.amount}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />{" "}
                              </h6>
                              <span style={{ "font-size": "11px" }}>
                                {tempFlightDetail.class}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <p
                                className="priceDetail float-right"
                                onClick={this.hideShowflighInfo.bind(
                                  this,
                                  index
                                )}
                              >
                                Price Detail
                              </p>
                            </div>
                          </div>
                          <div
                            id={"flight" + index}
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-2"></div>
                              <div className="col-5">
                                <span>Flight-Fare</span>
                              </div>
                              <div className="col-5">
                                <NumberFormat
                                  value={tempFlightDetail.fare}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />{" "}
                                {localStorage.getItem("Currency")}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2"></div>
                              <div className="col-5">
                                <span>Flight-Surcharges</span>
                              </div>
                              <div className="col-5">
                                <NumberFormat
                                  value={tempFlightDetail.surcharges}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />{" "}
                                {localStorage.getItem("Currency")}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2"></div>
                              <div className="col-5">
                                <span>Flight-Taxs</span>
                              </div>
                              <div className="col-5">
                                <NumberFormat
                                  value={tempFlightDetail.tax}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />{" "}
                                {localStorage.getItem("Currency")}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2"></div>
                              <div className="col-5">
                                <span>Flight-Total</span>
                              </div>
                              <div className="col-5">
                                <NumberFormat
                                  value={tempFlightDetail.amount}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />{" "}
                                {localStorage.getItem("Currency")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {this.state.tourPreference === "flightPlusHotel" ? (
          <Accordion>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={this.props.pageIndex <= 2 ? "" : "1"}
                className={
                  this.props.pageIndex <= 2
                    ? "shopping-cart-header-border  shopping-cart-disapled"
                    : "shopping-cart-header-border  shopping-cart-active "
                }
                onClick={() => {
                  if (this.props.pageIndex > 2) {
                    this.setState({
                      hotelOrPackageAccordion:
                        !this.state.hotelOrPackageAccordion,
                    });
                  }
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <span className="float-left shopping-cart-header-text">
                      <FontAwesomeIcon
                        icon={faBed}
                        className={
                          this.props.pageIndex <= 2
                            ? " shopping-cart-left-icon mr-2 shopping-cart-disapled"
                            : "shopping-cart-active shopping-cart-left-icon mr-2"
                        }
                        style={{
                          rotate: "90%",
                        }}
                      />
                      {"  "}
                      Hotel
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="float-right shopping-cart-header-text">
                      {this.props.pageIndex <= 2 ? (
                        0
                      ) : (
                        <NumberFormat
                          value={parseFloat(
                            localStorage.getItem("hotelTotalPrice")
                          )}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      )}

                      {"  "}
                      {localStorage.getItem("Currency")}
                      <FontAwesomeIcon
                        icon={
                          this.state.hotelOrPackageAccordion
                            ? faAngleUp
                            : faAngleDown
                        }
                        className={
                          this.props.pageIndex <= 2
                            ? " shopping-cart-right-icon ml-3 shopping-cart-disapled"
                            : "shopping-cart-right-icon ml-3"
                        }
                      />
                    </span>
                  </div>
                </div>

                <div className="row px-0 mx-0">
                  <div className="col-12 col-lg-4 px-0 shopping-cart-price"></div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {this.state.hotelPrice > 0 &&
                    this.state.tourPreference == "flightPlusHotel" && (
                      <div
                        className="collection-item"
                        id={"hotel-price-" + this.props.id}
                      >
                        {this.getHotelName("desktop")}
                        <div className="row">
                          <div className="col-6">
                            <b className="float-left">Check in date</b>
                          </div>
                          <div className="col-6">
                            <span className="float-right">
                              {this.checkInDate}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <b className="float-left">Check out date</b>
                          </div>
                          <div className="col-6">
                            <span className="float-right">
                              {this.checkOutDate}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <b className="float-left">Amount</b>
                          </div>
                          <div className="col-6">
                            <span className="float-right">
                              <NumberFormat
                                value={parseFloat(
                                  localStorage.getItem("hotelTotalPrice")
                                )}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                              {"  "}
                              {localStorage.getItem("Currency")}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <Accordion>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={this.props.pageIndex <= 2 ? "" : "1"}
                className={
                  this.props.pageIndex <= 2
                    ? "shopping-cart-header-border  shopping-cart-disapled"
                    : "shopping-cart-header-border shopping-cart-active"
                }
                onClick={() => {
                  if (this.props.pageIndex > 2) {
                    this.setState({
                      hotelOrPackageAccordion:
                        !this.state.hotelOrPackageAccordion,
                    });
                  }
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <span className="float-left shopping-cart-header-text">
                      <FontAwesomeIcon
                        icon={faGift}
                        className={
                          this.props.pageIndex <= 2
                            ? " shopping-cart-left-icon mr-2 shopping-cart-disapled"
                            : "shopping-cart-active shopping-cart-left-icon mr-2"
                        }
                        style={{
                          rotate: "90%",
                        }}
                      />
                      {"  "}
                      Package
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="float-right shopping-cart-header-text">
                      {this.props.pageIndex <= 2 ? (
                        0
                      ) : (
                        <NumberFormat
                          value={this.state.packagePrice}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      )}

                      {"  "}
                      {localStorage.getItem("Currency")}
                      <FontAwesomeIcon
                        icon={
                          this.state.hotelOrPackageAccordion
                            ? faAngleUp
                            : faAngleDown
                        }
                        className={
                          this.props.pageIndex <= 2
                            ? " shopping-cart-right-icon ml-3 shopping-cart-disapled"
                            : "shopping-cart-right-icon ml-3"
                        }
                      />
                    </span>
                  </div>
                </div>

                <div className="row px-0 mx-0">
                  <div className="col-12 col-lg-4 px-0 shopping-cart-price"></div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {this.state.packagePrice > 0 &&
                    this.state.tourPreference == "flightPlusPackage" && (
                      <div
                        className="collection-item"
                        id={"Package-Total-" + this.props.id}
                      >
                        {this.PackageSummaryData.map((packageItem) => (
                          <>
                            <div className="row">
                              <div className="col-6">
                                <span className="float-left">
                                  {packageItem.package.name}
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="float-right">
                                  <NumberFormat
                                    value={packageItem.price.amount}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />{" "}
                                  {localStorage.getItem("Currency")}
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="float-left">
                                  Package booking date
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="float-right">
                                  {this.changeDateFormat(
                                    new Date(packageItem.bookingDate)
                                  )}
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="float-left">Duration</span>
                              </div>
                              <div className="col-6">
                                <span className="float-right">
                                  {packageItem.package.numberOfDays} Day
                                </span>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={this.props.pageIndex <= 3 ? "" : "1"}
              className={
                this.props.pageIndex <= 3
                  ? "shopping-cart-header-border  shopping-cart-disapled"
                  : "shopping-cart-header-border shopping-cart-active "
              }
              onClick={() => {
                if (this.props.pageIndex > 3) {
                  this.setState({
                    passengerAccordion: !this.state.passengerAccordion,
                  });
                }
              }}
            >
              <div className="row">
                <div className="col-6">
                  <span className="float-left shopping-cart-header-text">
                    <FontAwesomeIcon
                      icon={faUsers}
                      className={
                        this.props.pageIndex <= 3
                          ? "shopping-cart-left-icon mr-2 shopping-cart-disapled"
                          : "shopping-cart-right-icon ml-3"
                      }
                      className=""
                      style={{
                        rotate: "90%",
                      }}
                    />
                    {"  "}
                    Passenger
                  </span>
                </div>
                <div className="col-6">
                  <span className="float-right shopping-cart-header-text">
                    <FontAwesomeIcon
                      icon={
                        this.state.passengerAccordion ? faAngleUp : faAngleDown
                      }
                      className={
                        this.props.pageIndex <= 3
                          ? "shopping-cart-right-icon ml-3 shopping-cart-disapled"
                          : "shopping-cart-right-icon ml-3 shopping-cart-active"
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="row px-0 mx-0">
                <div className="col-12 col-lg-4 px-0 shopping-cart-price"></div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div>
                  <p className="text-left"> Adult X {this.adult}</p>
                  {this.state.adultList}
                </div>
                {this.child !== 0 && (
                  <div>
                    Child X {this.child}
                    {this.state.childList}
                  </div>
                )}
                {this.infant !== 0 && (
                  <div>
                    Infant X {this.infant}
                    {this.state.infantList}
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Card>
          <Row className="mx-1 mt-2 shopping-cart-total-price">
            <div className="col-6 col-sm-6 col-md-5 col-lg-5">
              <span className="float-left shopping-cart-total-price">
                Trip Total
              </span>
            </div>
            <div className="col-6 col-sm-6 col-md-7 col-lg-7">
              <span className="float-right">
                <NumberFormat
                  value={this.total}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={2}
                />
                {"  "}
                {localStorage.getItem("Currency")}
              </span>
            </div>
          </Row>
          <hr />
          {/* <p className="text-left mx-1">
            See Applicable{" "}<a href="#">Fare Rules</a>
          </p> */}
        </Card>
      </div>
    );
  };
  mobileViewHandler = () => {
    return (
      <>
        <div
          style={{
            right: "33px",
            // top: "24% !important", 
            top: "24px",
            position: "absolute",
            zIndex: 10,
          }}
        >
          <button
            onClick={this.showWidgetModel}
            className=" btn btn-success mr-2 btn-sm"
            title="Edit Flight"
          >
            {" "}
            <Edit />
          </button>
          <button className="btn btn-success btn-sm" onClick={this.showModal}>
            <span>
              <ShoppingCartIcon />
            </span>
            <NumberFormat
              value={Math.round(this.total)}
              displayType="text"
              thousandSeparator={true}
            />{" "}
            {localStorage.getItem("Currency")}
          </button>
          <br />
          {/* <p onClick={this.showWidgetModel} 
                  className=" btn"> Edit Flight</p> */}
        </div>
        <Modal
          show={this.state.showDialog}
          onHide={this.showModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Shopping Cart
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.isMulticity ? (
              <div>
                <h4>
                  <b>Flight</b>
                </h4>
                {this.flightDetail.map((tempFlightDetail, index) => (
                  <div>
                    <div className="collection-item">
                      <div className="row">
                        <div className="col-1 align-self-center">
                          <h4>
                            <FontAwesomeIcon
                              className="pl-2 pr-2"
                              icon={faPlane}
                              size="lg"
                            />
                          </h4>
                        </div>
                        <div className="col-3">
                          <h4>{tempFlightDetail.from}</h4>
                          <span style={{ "font-size": "11px" }}>
                            {this.parseDate(tempFlightDetail.departDate)}
                          </span>
                        </div>
                        <div className="col-1 align-self-center">
                          <h4 className="d-flex justify-content-center">
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              size="lg"
                            />
                          </h4>
                        </div>
                        <div className="col-3">
                          <h4>{tempFlightDetail.to}</h4>
                          <span style={{ "font-size": "11px" }}>
                            {this.parseDate(tempFlightDetail.arrivalDate)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-3 float-right collection-item">
                    <h6>
                      {localStorage.getItem("Currency")}{" "}
                      <NumberFormat
                        value={this.flightDetail[0].amount}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                      />{" "}
                    </h6>
                    <span style={{ "font-size": "11px" }}>
                      {this.flightDetail[0].class}
                    </span>
                  </div>
                  <div
                    id="flight0"
                    className="float-left"
                    style={{ display: "none" }}
                  >
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-5">
                        <span>Flight-Fare</span>
                      </div>
                      <div className="col-5">
                        <NumberFormat
                          value={this.flightDetail[0].fare}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />{" "}
                        {localStorage.getItem("Currency")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-5">
                        <span>Flight-Surcharges</span>
                      </div>
                      <div className="col-5">
                        <NumberFormat
                          value={this.flightDetail[0].surcharges}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />{" "}
                        {localStorage.getItem("Currency")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-5">
                        <span>Flight-Taxs</span>
                      </div>
                      <div className="col-5">
                        <NumberFormat
                          value={this.flightDetail[0].tax}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />{" "}
                        {localStorage.getItem("Currency")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-5">
                        <span>Flight-Total</span>
                      </div>
                      <div className="col-5">
                        <NumberFormat
                          value={this.flightDetail[0].amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />{" "}
                        {localStorage.getItem("Currency")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p
                      className="priceDetail float-right"
                      onClick={this.hideShowflighInfo.bind(this, 0)}
                    >
                      Price Detail
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {this.flightDetail.map((tempFlightDetail, index) => (
                  <div>
                    <div className="collection-item">
                      <h4>
                        <b>Flight</b>
                      </h4>
                      <div className="row">
                        <div className="col-1 align-self-center">
                          <h4>
                            <FontAwesomeIcon
                              className="pl-2 pr-2"
                              icon={faPlane}
                              size="lg"
                            />
                          </h4>
                        </div>
                        <div className="col-3">
                          <h4>{tempFlightDetail.from}</h4>
                          <span style={{ "font-size": "11px" }}>
                            {this.parseDate(tempFlightDetail.departDate)}
                          </span>
                        </div>
                        <div className="col-1 align-self-center">
                          <h4 className="d-flex justify-content-center">
                            <FontAwesomeIcon
                              icon={faAngleDoubleRight}
                              size="lg"
                            />
                          </h4>
                        </div>
                        <div className="col-3">
                          <h4>{tempFlightDetail.to}</h4>
                          <span style={{ "font-size": "11px" }}>
                            {this.parseDate(tempFlightDetail.arrivalDate)}
                          </span>
                        </div>
                        <div className="col-3">
                          <h6>
                            {localStorage.getItem("Currency")}{" "}
                            <NumberFormat
                              value={tempFlightDetail.amount}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                          </h6>
                          <span style={{ "font-size": "11px" }}>
                            {tempFlightDetail.class}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p
                            className="priceDetail float-right"
                            onClick={this.hideShowflighInfo.bind(this, index)}
                          >
                            Price Detail
                          </p>
                        </div>
                      </div>
                      <div id={"flight" + index} style={{ display: "none" }}>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Fare</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={tempFlightDetail.fare}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Surcharges</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={tempFlightDetail.surcharges}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Taxs</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={tempFlightDetail.tax}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <span>Flight-Total</span>
                          </div>
                          <div className="col-5">
                            <NumberFormat
                              value={tempFlightDetail.amount}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                            />{" "}
                            {localStorage.getItem("Currency")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {this.state.hotelPrice > 0 &&
              this.state.tourPreference == "flightPlusHotel" && (
                <div
                  className="collection-item"
                  id={"hotel-price-" + this.props.id}
                >
                  <h4>
                    <b>Hotel</b>
                  </h4>
                  <div className="row">
                    <div className="col-6"> {this.getHotelName()}</div>
                    <div className="col-6">
                      <span>
                        <b>Hotel Total : </b>
                        <NumberFormat
                          value={parseFloat(
                            localStorage.getItem("hotelTotalPrice")
                          )}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />{" "}
                        {localStorage.getItem("Currency")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            {this.state.bunldePrice > 0 && this.bundleSummary != null && (
              <div
                className="collection-item"
                id={"bundle-price-" + this.props.id}
              >
                <h4>
                  <b>Bundle and Save</b>
                </h4>
                <div className="row">
                  {this.bundleSummary.map((bundle) => (
                    <div className="col-6">
                      <span>{bundle.name}</span>
                    </div>
                  ))}
                  <div className="col-6">
                    <span>
                      <b> Bundle-Total: </b>
                      <NumberFormat
                        value={this.state.bunldePrice}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                      />{" "}
                      {localStorage.getItem("Currency")}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {this.state.packagePrice > 0 &&
              this.state.tourPreference == "flightPlusPackage" && (
                <div
                  className="collection-item"
                  id={"Package-Total-" + this.props.id}
                >
                  <h4>
                    <b>Package</b>
                  </h4>
                  <div className="row">
                    {this.PackageSummaryData.map((packageItem) => (
                      <div className="col-6">
                        <span>{packageItem.package.name}</span>
                      </div>
                    ))}
                    <div className="col-6">
                      <span>
                        <b> Package-Total: </b>
                        <NumberFormat
                          value={this.state.packagePrice}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                        />{" "}
                        {localStorage.getItem("Currency")}
                      </span>
                    </div>
                  </div>
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div
              className="collection-item"
              id={"total-price-" + this.props.id}
            >
              <b>
                Grand Total :{" "}
                <NumberFormat
                  value={this.total}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={2}
                />{" "}
                {localStorage.getItem("Currency")}
              </b>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  render() {
    if (this.total == 0) {
      return (
        <div>
          <MobileView>
            <Modal
              show={this.state.showWidget}
              onHide={this.showWidgetModel}
              size="xl"
              className="closingWidget"
              centered
            >
              <Modal.Header
                closeButton
                style={{
                  border: "none",
                  backgroundColor: "#808080",
                  zIndex: "",
                }}
              ></Modal.Header>
              <div
                className="container float-right"
                style={{ position: "absolute", marginTop: "100px" }}
              >
                <div className="row">
                  {this.state.showWidget ? (
                    <Widget searchFlightAgain="true" />
                  ) : null}
                </div>
              </div>
            </Modal>

            <div className="float-right mb-3">
              <button
                onClick={this.showWidgetModel}
                className=" btn btn-success mr-3 btn-sm"
              >
                {" "}
                <Edit />
              </button>
              <button
                disabled
                className="btn btn-dark btn-sm"
                onClick={this.showModal}
              >
                <span>
                  <ShoppingCartIcon />
                </span>
                {this.total} {localStorage.getItem("Currency")}
              </button>
            </div>
          </MobileView>
          <BrowserView>
            <Modal
              show={this.state.showWidget}
              onHide={this.showWidgetModel}
              size="xl"
              className="closingWidget"
              style={{ marginTop: "2em" }}
            >
              <Modal.Header
                closeButton
                style={{
                  border: "none",
                  backgroundColor: "#808080",
                  zIndex: "",
                }}
              ></Modal.Header>
              <div
                className="container float-right"
                style={{ position: "absolute", marginTop: "100px" }}
              >
                <div className="row">
                  {this.state.showWidget ? (
                    <Widget searchFlightAgain="true" />
                  ) : null}
                </div>
              </div>
            </Modal>

            <div
              style={{ position: "absolute", top: "-30px", right: "-28%" }}
              className="float-right ml-5"
            >
              <button
                onClick={this.showWidgetModel}
                className=" btn btn-success mr-2"
              >
                {" "}
                <Edit />
              </button>
            </div>
          </BrowserView>
        </div>
      );
    }

    return (
      <div>
        <Modal
          show={this.state.showWidget}
          onHide={this.showWidgetModel}
          size="xl"
          className="closingWidget"
          style={{ marginTop: "2em" }}
        >
          <Modal.Header
            closeButton
            style={{ border: "none", backgroundColor: "#808080", zIndex: "" }}
          ></Modal.Header>
          <div
            className="container"
            style={{ position: "absolute", marginTop: "100px" }}
          >
            <div className="row">
              {this.state.showWidget ? (
                <Widget searchFlightAgain="true" />
              ) : null}
            </div>
          </div>
        </Modal>
        <MobileView className="mt-2">{this.mobileViewHandler()}</MobileView>
        <BrowserView>
          <div
            style={{ position: "relative", top: "-172px", marginLeft: "0px" }}
          >
            <button
              onClick={this.showWidgetModel}
              className=" btn btn-success mr-3"
              title="Edit Flight"
            >
              {" "}
              <Edit />
            </button>
            <button className="btn btn-success">
              <span>
                <ShoppingCartIcon />
              </span>
              <NumberFormat
                value={Math.round(this.total)}
                displayType="text"
                thousandSeparator={true}
              />{" "}
              {localStorage.getItem("Currency")}
            </button>
            <br />
          </div>
          {this.desktopViewHandler()}
        </BrowserView>
      </div>
    );
  }
}

export default ShoppingCart;
