import React, { Component } from 'react';
import './destinationCountry.css';
import queryString from 'query-string';
import { HttpService } from "../../../../services/http/http-service";
import JwPagination from 'jw-react-pagination';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import ProgressLoader from '../../../shared/progress-loading/progress-loader';
class DestinationCountry extends Component {
  constructor(props) {
    super(props);
    this.onChangePage = this.onChangePage.bind(this);
    let search = queryString.parse(this.props.location.search)

    this.state = {
      items: [],
      aboutCountry: {},
      pageOfItems: [],
      isLoaded: false,
      isPackagesExist: false,
      search: search,
    }
  }



  onChangePage(pageOfItems) {

    window.scrollTo({ top: 200, behavior: "smooth" });
    this.setState({ pageOfItems });
  }

  async componentDidMount() {
    this.initCountryData();
  }



  initCountryData() {

    const relativePath2 = "/api/Packages/GetpackageInfo";
    let data = {
      CountryCode: this.state.search.countryCode
    };

    HttpService.postServicePackage(data, relativePath2)
      .then((response) => {

        let items = response.data;
        let allPackage = [];

        if (items != null) {
          for (let i = 0; i < items.list.length; i++) {
            for (let l = 0; l < items.list[i].packages.length; l++) {
              allPackage.push(items.list[i].packages[l]);
            }
          }
          if(allPackage.length>0){
        this.setState({
          aboutCountry:items.country,
          items: allPackage,
          isLoaded: true,
          isPackagesExist: true,
        });
            
          }
          else{
        this.setState({ 
          isLoaded: true,
          isPackagesExist: false,
        });

            }

      document.getElementById("demo").innerHTML = items.country.description.toString();
      document.getElementById("areasOfCountryDescription").innerHTML = items.country.areasOfCountryDescription.toString();
      document.getElementById("attractionDescription").innerHTML = items.country.attractionDescription.toString();
      document.getElementById("highlightDescription").innerHTML = items.country.highlightDescription.toString();
      document.getElementById("whatToPackDescription").innerHTML = items.country.whatToPackDescription.toString();
      document.getElementById("whenToVisitDescription").innerHTML = items.country.whenToVisitDescription.toString();


        }
        else{
        this.setState({
          // aboutCountry:items.country,
          // items: allPackage,
          isLoaded: true,
        });


        }
      


      })
      .catch((error) => {
        // console.log("responseData error2 ");
      });
  }

  showPackageLists = (packagetype) => {
    let path = "/package-lists/" + 33;
    window.location.assign(path);
  }


  showPackageDetails = (packageData) => {
    let search = "?packageName=" + packageData.package.name + "&packageId=" + packageData.package.id;
    let path = "/package-details/" + search;
    window.location.assign(path);
  }


  render() {
    var { isLoaded, items, search } = this.state;
    if (!isLoaded) {
      return <div  className="text-center ">
        
        <ProgressLoader displayMess= {"Loading Destination Country " +search.countryName} />
       

      </div>;

    }
    else {
      return (<> 
 {this.state.isPackagesExist&& <>
      {this.state.items&& <div className="pb-0 pl-2 pr-2 mb-100">
          <img className="img-fluid " src={this.state.items[0].package.imagePath} />
        </div>} 


        <div  class="text-center container ">

          
        <h1 className="text-center special-offer-head">{search.countryName}</h1>
  <div 
              style={{ textAlign: "left" }}
              id="demo"
              className=""
  ></div>
<div className="col-md-12 destination_tab mt-5" style={{border:"1px solid #155724",padding:"0px 10px",borderRadius:"5px 5px 0 0"}}>
    <Tabs defaultActiveKey="Packages" id="left-tabs-example">

<Tab eventKey="highlightDescription" title="Highlight "  > 

{this.state.aboutCountry.highlighImagePath&& <div className="pb-0 pl-2 pr-2 mb-100">
        <img className="img-fluid " src={this.state.aboutCountry.highlighImagePath} />
      </div>} 
 <div 
            style={{ textAlign: "left" }}
            id="highlightDescription"
            className=""
></div>

</Tab>

<Tab eventKey="Packages" title="Packages" className="country_tab">
   
               {/* {this.state.aboutCountry&&  <> </>
                }  */}

        <div class="row row-cols-1 row-cols-md-2 pb-2 mt-3 card__wrap--outer" id="initial" >
          {this.state.pageOfItems.map((item) =>
            <div class="col-lg-4 col-md-6 mb-4 card__wrap--inner" key={item} value={item} >

              <div class="card" style={{ cursor: "pointer !important" }} onClick={() => this.showPackageDetails(item)}>
               
                <img className="img-fluid " style={{ cursor: "pointer !important", maxHeight: "180px" }} src={item.package.imagePath} />
                <div class="card-body pb-1 pt-1">
                  <h5 class="card-title pb-1">{item.package.name}</h5>

                  <p class="card-title pt-0">Duration: {item.package.numberOfDays} day(s)</p>
                </div>
              </div>
            </div>
          )}
        </div>

      <JwPagination pageSize={6} items={this.state.items} onChangePage={this.onChangePage} />



</Tab>
<Tab eventKey="areasOfCountryDescription" title="Areas Of Country "  > 

{this.state.aboutCountry.areasOfCountryImagePath&& <div className="pb-0 pl-2 pr-2 mb-100">
        <img className="img-fluid " src={this.state.aboutCountry.areasOfCountryImagePath} />
      </div>} 
 <div 
            style={{ textAlign: "left" }}
            id="areasOfCountryDescription"
            className="mt-3"
></div>
</Tab> 
 <Tab eventKey="attractionDescription" title="Attraction"  >
   
{this.state.aboutCountry.attractionImagePath&& <div className="pb-0 pl-2 pr-2 mb-100">
        <img className="img-fluid " src={this.state.aboutCountry.attractionImagePath} />
      </div>} 
     <div 
            style={{ textAlign: "left" }}
            id="attractionDescription"
            className="mt-3"
></div>
</Tab> 

 <Tab eventKey="whatToPackDescription" title="What To Pack  "  > 
 
{this.state.aboutCountry.whatToPackImagePath&&
      <div className="pb-0 pl-2 pr-2 mb-100">
              <img className="img-fluid " src={this.state.aboutCountry.whatToPackImagePath} />
            </div>} 
        <div 
            style={{ textAlign: "left" }}
            id="whatToPackDescription"
            className="mt-3" >
        </div>
</Tab>
 <Tab eventKey="whenToVisitDescription" title="When To Visit  "  > 
 
{this.state.aboutCountry.whenToVisitImagePath&& <div className="pb-0 pl-2 pr-2 mb-100">
        <img className="img-fluid " src={this.state.aboutCountry.whenToVisitImagePath} />
      </div>} 
  <div 
            style={{ textAlign: "left" }}
            id="whenToVisitDescription"
            className="mt-3"
></div>
</Tab>
</Tabs> 

  </div>
 </div>
    </> }
    
    
 {!this.state.isPackagesExist&& <> 
 <div  className="text-center  pt-5">
        <p className="h4">Sorry no package found for this destination!</p>
        <a className="btn mt-3 border" href="/">
          Back To Home
        </a>
      </div></>}
    
    
     </>

      );
    }

  }


}

export default DestinationCountry;