import React, { Component } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { HttpService } from "../../../services/http/http-service";
import Modal from "react-bootstrap/Modal";

class FareOptionsLargeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalFlightAmount: 0,
      showModal: false,
    };
    this.modalHeader = null;
    this.modalBody = [];
    this.flightInfo = [];
    this.SearchContext =JSON.parse (localStorage.getItem("searchRequestObj"));
  }
  getSelectedBrandInfoShoppingCode = (
    shoppingBasketHashCode,
    arrivalDate,
    departDate,
    amount,
    fare,
    tax,
    currency,
    type,
    flightInfo
  ) => {
    
    var localFlightInfo = JSON.parse(localStorage.getItem("flightInfo"));
    if(localFlightInfo == null){
      localFlightInfo=[];
    }
    var isMulticity = localStorage.getItem("isMulticity");
    if(isMulticity == "yes"){
      flightInfo.itineraryParts.forEach(element => {
        var currentFlightInfo={
          "amount":amount,
          "class":type,
          "departDate":element.segments[0].departure,
          "arrivalDate":element.segments.pop().arrival,
          "fare":fare,
          "tax":tax,
          "surcharges":null,
          "shoppingBasketHashCode":shoppingBasketHashCode,
        };
        localFlightInfo.push(currentFlightInfo);
      });
      localStorage.setItem("flightInfo",JSON.stringify(localFlightInfo));
    }
    else{
      var currentFlightInfo={
        "amount":amount,
        "class":type,
        "departDate":departDate,
        "arrivalDate":arrivalDate,
        "fare":fare,
        "tax":tax,
        "surcharges":null,
        "shoppingBasketHashCode":shoppingBasketHashCode,
      };
      localFlightInfo.push(currentFlightInfo);
      localStorage.setItem("flightInfo",JSON.stringify(localFlightInfo));
    }

    this.props.getSelectedBrandShoppingCode(
      shoppingBasketHashCode,
      arrivalDate,
      departDate
    );

  };
  viewDailog = (type) => {
    
    this.modalBody = [];
    this.modalHeader = type;
    if (this.flightInfo.length == 0) {
      this.flightInfo = JSON.parse(localStorage.getItem("FlightInformation"));
    }
    this.flightInfo.forEach((element) => {
      if (element.brand == type) {
        this.modalBody.push(
          <div className="collection-item">
            <span>{element.brandAttribiute}</span>
            <span style={{ float: "right" }}>{element.brandValue}</span>
          </div>
        );
      }
    });

    this.setState({ showModal: true });
  };

  closeDialog = () => {
    this.setState({ showModal: false });
  };
  renderBrand() {
    const { brand, flightInformation } = this.props;

    if (this.props.selectedBrand === "Eco") {
      if (brand.brandId !== "EA" && brand.cabinClass === "Economy") {
        return (
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 p-1">
            <div className="fare-option-lg-container">
              <div className="row fare-option-header">
                <div className="col-12">
                  <span className="float-left">{brand.brandLabel} </span>
                  <span className="float-right brand-info-icon">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{ color: "#60ac5d", fontSize: "25px" }}
                      className="pr-2"
                      onClick={this.viewDailog.bind(this, brand.brandLabel)}
                    />
                  </span>
                </div>
              </div>
              <div className="row fare-option-body m-0 mb-3">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 fare-price-lg mt-2">
                  <div className="mb-2">Fare</div>
                  <div className="font-weight-bold">
                    {" "}
                    {brand.fare.alternatives[0][0].amount + " "}
                    {brand.fare.alternatives[0][0].currency}
                  </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 tax-price-lg mt-2">
                  <div className="mb-2">Tax</div>
                  <div  className="font-weight-bold">
                    {" "}
                    {brand.taxes.alternatives[0][0].amount + " "}
                    {brand.taxes.alternatives[0][0].currency}
                  </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 seat-left-lg mt-2">
                  <div className="mb-2">Seat Left</div>
                  <div className="font-weight-bold">{brand.seatsRemaining.count}</div>
                </div>
                <div className="col-12 total-price mt-4">
                  <span className="text-left float-left pt-2">
                    Total {brand.total.alternatives[0][0].amount + " "}
                    {brand.total.alternatives[0][0].currency}
                  </span>
                  <button
                    className="btn float-right submit-fare-option-btn"
                    onClick={() =>
                      this.getSelectedBrandInfoShoppingCode(
                        brand.shoppingBasketHashCode,
                        flightInformation.arrival,
                        flightInformation.departure,
                        brand.total.alternatives[0][0].amount,
                        brand.fare.alternatives[0][0].amount,
                        brand.taxes.alternatives[0][0].amount,
                        brand.total.alternatives[0][0].currency,
                        "Economy",
                        flightInformation
                      )
                    }
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    if (this.props.selectedBrand === "Bus") {
      if (brand.brandId !== "BA" && brand.cabinClass === "Business") {
        return (
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 p-1">
            <div className="fare-option-lg-container">
              <div className="row fare-option-header">
                <div className="col-12">
                  <span className="float-left">{brand.brandLabel}</span>
                  <span className="float-right brand-info-icon">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{ color: "#60ac5d", fontSize: "25px" }}
                      className="pr-2"
                      onClick={this.viewDailog.bind(this, brand.brandLabel)}
                    />
                  </span>
                </div>
              </div>
              <div className="row fare-option-body m-0 mb-3">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 fare-price-lg mt-2">
                  <div className="mb-2">Fare </div>
                  <div className="font-weight-bold">
                    {brand.fare.alternatives[0][0].amount + " "}
                    {brand.fare.alternatives[0][0].currency}
                  </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 tax-price-lg mt-2">
                  <div className="mb-2">Tax</div>
                  <div className="font-weight-bold">
                    {brand.taxes.alternatives[0][0].amount + " "}
                    {brand.taxes.alternatives[0][0].currency}
                  </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 seat-left-lg mt-2">
                  <div className="mb-2">Seat Left</div>
                  <div className="font-weight-bold"> {brand.seatsRemaining.count}</div>
                </div>
                <div className="col-12 total-price mt-4">
                  <span className="text-left float-left pt-2">
                    Total {brand.total.alternatives[0][0].amount + " "}
                    {brand.total.alternatives[0][0].currency}
                  </span>

                  <button
                    className="btn float-right submit-fare-option-btn"
                    onClick={() =>
                      this.getSelectedBrandInfoShoppingCode(
                        brand.shoppingBasketHashCode,
                        flightInformation.arrival,
                        flightInformation.departure,
                        brand.total.alternatives[0][0].amount,
                        brand.fare.alternatives[0][0].amount,
                        brand.taxes.alternatives[0][0].amount,
                        brand.total.alternatives[0][0].currency,
                        "Business"
                      )
                    }
                  >
                    Select 
                  </button>

                  {/* <button
                    className="btn float-right submit-fare-option-btn"
                    onClick={() =>
                      this.getSelectedBrandInfo(brand.shoppingBasketHashCode)
                    }
                  >
                    Select
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    return <></>;
  }
  render() {
    return (
      <>
        <Modal
          show={this.state.showModal}
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#60ac5d", color: "white" }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              {this.modalHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.modalBody}</Modal.Body>
        </Modal>
        {this.renderBrand()}
      </>
    );
  }
}

export default FareOptionsLargeScreen;
