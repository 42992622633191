import React, { Component } from "react";
import HotelInformation from "./hotel-information";
import { HotelSearch } from "./../../models/hotel/hotel-search";
import { Room } from "./../../models/hotel/room";
import { HttpService } from "./../../services/http/http-service";
import ProgressLoader from "../shared/progress-loading/progress-loader";
import { GetNextHotelResult } from "./../../services/hotel/hotel-result-paging";
import SearchHotel from "./hotel-search";
import HotelSort from "./hotel-sort";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ShoppingCart from "../ShoppingCart/shopping-cart";
import BreadCrumb from "../shared/breadcrumb/breadcrumb";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import HotelFilter from "./hotel-filter";

class HotelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hotelList: [],
      totalHotels: 0,
      pageNumber: 0,
      pageIndex: 1,
      isSearchNotFound: false,
      isHotelResultEmpty: false,
      isShowMoreClicked: false,
      hotelPriceRange: null,
      boardTypes: null,
    };
    this.searchHotel = this.searchHotel.bind(this);
    this.sortHotelResult = this.sortHotelResult.bind(this);
  }

  relativePath = "HotelAvailability/Availability";
  componentDidMount() {
    localStorage.removeItem("PackageSummery");
    localStorage.removeItem("passengerDetails");

    //debugger
    window.scrollTo({ top: 0, behavior: "smooth" });
    let sessionId = localStorage.getItem("sessionId");
    // this.relativePath += "?SessionId=" + sessionId;
    let hotelSearchRequest = JSON.parse(
      localStorage.getItem("hotelSearchRequest")
    );

    let hotelSearch = new HotelSearch();
    if (hotelSearchRequest != null) {
      hotelSearch.params.SessionId = sessionId;
      hotelSearch.params.CheckIn = hotelSearchRequest.params.CheckIn;
      hotelSearch.params.CheckOut = hotelSearchRequest.params.CheckOut;
      hotelSearch.params.Destination =
        hotelSearchRequest.params.Destination.value;

      hotelSearch.params.Rooms = [];
      hotelSearchRequest.params.Rooms.forEach((room) => {
        hotelSearch.params.Rooms.push(room.params);
      });
    } else {
      this.props.history.push({
        pathname: "/",
      });
    }

    this.setState({ isLoading: true });
    //debugger
    HttpService.postServiceHotel(hotelSearch.params, this.relativePath)
      .then((response) => {
        //debugger
        this.setState({ isLoading: false });
        const { data } = response;
        // console.log(" data", data);
        // console.log("boardTypes",data.boardTypes);
        this.setState({ boardTypes: data.boardTypes });
        this.setState({ hotelPriceRange: data.priceRange });
        this.setState({ hotelList: data.list });
        this.setState({ totalHotels: data.totalHotels });
        this.setState({ pageNumber: data.numberOfPages });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }
  showHotelInfo(hotelInfo) {
    this.props.history.push({
      pathname: "/hotel-detail",
      state: {
        hotelInformation: hotelInfo,
      },
    });
  }
  async getMoreHotelResult() {
    //debugger
    let pageNumber = this.state.pageIndex + 1;
    await this.setState({ pageIndex: pageNumber });
    let sortBy = "price";
    let sortMode = "increment";
    await this.setState({ isShowMoreClicked: true });
    GetNextHotelResult.getNextHotel(pageNumber, sortBy, sortMode)
      .then((response) => {
        //debugger
        this.setState({ isShowMoreClicked: false });
        this.setState({ isLoading: false });
        const { data } = response;
        const hotelList = [...this.state.hotelList, ...data.list];
        this.setState({ hotelList });
        this.setState({ totalHotels: data.totalHotels });
        this.setState({ pageNumber: data.numberOfPages });
      })
      .catch((error) => {
        this.setState({ isShowMoreClicked: false });
        this.setState({ isLoading: false });
        //debugger
      });
  }
  searchHotel(searchResponse) {
    //debugger
    try {
      const { data } = searchResponse;
      if (data.list != null) {
        this.setState({ isSearchNotFound: false });
        this.setState({ hotelList: data.list });
        this.setState({ totalHotels: data.totalHotels });
        this.setState({ pageNumber: data.numberOfPages });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.setState({ hotelList: [] });
        this.setState({ totalHotels: data.totalHotels });
        this.setState({ pageNumber: data.numberOfPages });
        this.setState({ isSearchNotFound: true });
      }
    } catch (error) {
      //debugger
    }
  }
  sortHotelResult(response) {
    //debugger
    try {
      const { data } = response;
      if (data.list != null) {
        this.setState({ hotelList: data.list });
        this.setState({ totalHotels: data.totalHotels });
        this.setState({ pageNumber: data.numberOfPages });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.setState({ hotelList: [] });
        this.setState({ totalHotels: data.totalHotels });
        this.setState({ pageNumber: data.numberOfPages });
      }
    } catch (error) {
      //debugger
    }
  }
  rendersearchNotFoundMesage() {
    if (this.state.isSearchNotFound) {
      return (
        <div className="row m-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <span>Sorry,search not found please try another</span>
          </div>
        </div>
      );
    }
  }
  renderShowMoreButton() {
    if (this.state.pageNumber > 1) {
      if (this.state.isShowMoreClicked) {
        return (
          <div
            className="spinner-border text-primary"
            role="status"
            id="hotel-search-loader"
          >
            <span className="sr-only">Loading...</span>
          </div>
        );
      } else {
        return (
          <div>
            <button className="btn" onClick={() => this.getMoreHotelResult()}>
              Show more
            </button>
          </div>
        );
      }
    }
  }
  render() {
    <div></div>;
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <ProgressLoader displayMess="Searching Available Hotels" />
        </div>
      );
    }
    if (this.state.hotelList != null && this.state.hotelList.length > 0) {
      return (
        <>
        <div className="text-center">
        <BreadCrumb pageIndex={2} />
        </div>


        <div className="row m-0 p-0 w-100">
            <div className="col-12 col-md-3 order-md-2 m-0">
              <ShoppingCart id={"hotel"} className="m-3" pageIndex={2} />
            </div>
            <div className="col-12 col-md-9 order-md-1 m-0">

          <Row className="text-center w-100">
            {/* <BreadCrumb pageIndex={2} />
            <div className="col-12 col-md-3 order-md-2 m-0 p-0 w-100">
              <ShoppingCart id={"hotel"} className="m-3" pageIndex={2} />
            </div> */}
            <div className="col-12 col-md-12 order-md-1 m-0 p-0 ">
            <div className="sort-hotel-container">
              {/* <div className="row"> */}
                {/* <div className="col-xl-12 col-md-12 col-lg-12  col-sm-12 mt-2"> */}
                  <HotelFilter
                    boardTypes={this.state.boardTypes}
                    hotelPriceRange={this.state.hotelPriceRange}
                    sortHotel={this.sortHotelResult}
                    searchHotel={this.searchHotel}
                  />
                {/* </div> */}
                {/* <div className="col-xl-4  col-md-6 col-lg-4 col-sm-12 mt-3">
                  <SearchHotel searchHotel={this.searchHotel} />
                </div> */}
              {/* </div> */}
            </div>
           
              </div>
            <div className="col-12 col-md-12 order-md-1 m-0 p-0 w-100">
              <div className="m-0 p-0 w-100">
                <div className="text-center hotel-list-container ">
                  <div className="hotel-search-result-header text-left">
                    <h3>Hotel search result :</h3>
                  </div>
                  <div>{this.rendersearchNotFoundMesage()}</div>
                  {this.state.hotelList.map((hotelInfo, key) => (
                    <div
                      onClick={() => this.showHotelInfo(hotelInfo)}
                      key={key}
                    >
                      <HotelInformation hotelInfo={hotelInfo} />
                    </div>
                  ))}
                  {this.renderShowMoreButton()}
                </div>
              </div>
            </div>
          </Row>

              </div>
              </div>

        </>
      );
    }
    return (
      <div className="text-center">
        <h3 className="mt-5 pt-5">
          Unable to find hotel result please try again...
        </h3>
        <a
          className="btn btn-success m-3"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </a>
        <Button className=" m-3  " variant="secondary" href="/">
          Back To Home
        </Button>
      </div>
    );
  }
}

export default HotelList;
