import React, { Component } from "react";
import "../payment-summery/paymentSummery";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap"; 
import $ from "jquery";
class Threed extends Component {
  constructor(props) {
    super(props);
  } 
  async componentDidMount() {
    this.getLocalSummeryDetails();
  }

  getLocalSummeryDetails() {
    let summaryDetails = JSON.parse(localStorage.getItem("summaryDetail"));
    if (summaryDetails) { 
      //  console.log("t3d")
    //   var num = `<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Dear ATOENA MR WEYZERO, </span></span></p><p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">This is to inform you that you have a pending payment. </span></span></p><p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Go to nearest UBE branch office, pay amount 28738.00 to account no. 1421816162332018. </span></span></p><p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Give the FloCash Reference 61414178 to teller. </span></span></p><p><span style="color:#ff0000"><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11px">Important Note: Please make sure that you include reference above so that we can process your payment correctly and without delay. To secure your booking please ensure you make the exact payment within 24 hours of the booking date. Payments for bookings after 24 hours from booking date may not be processed and you will have to make a new booking, which can be subject to availability and a change in price FloCash Customer Service. </span></span></span></p>`;
      $("#htmlview").html(summaryDetails);


    } else {
      var num = `<h1> Error Occured While Redirection </h1>` 
      $("#htmlview").html(num); 
    }
  }

  render() {
    return (
      <div id="htmlview">
      </div>
    );
  }
}

export default Threed;
