export class AirportList {
    static m = {
        timeStamp: "2020-08-03T07:33:24.5270282Z",
        summary: {
            hotelDetail: null,
            flightDetail: {
                travelItinerary: {
                    customerInfo: {
                        contactNumbers: {
                            contactNumber: {
                                locationCode: "UZV",
                                phone: "251-251-0915689502-M",
                                rph: "001",
                                id: "6",
                            },
                        },
                        personName: [
                            {
                                email: {
                                    id: "8",
                                    comment: "@E",
                                    text: "TEDESSES@ETHIOPIANAIRLINES.COM",
                                },
                                title: "MR",
                                givenName: "ETHIO PISEW",
                                surname: "PIA",
                                withInfant: "false",
                                nameNumber: "01.01",
                                nameReference: "ADT",
                                rph: "1",
                                elementId: "pnr-5.1",
                                text: null,
                            },
                        ],
                    },
                    itineraryInfo: {
                        // this.state.flightDetail.itineraryInfo.itineraryPricing.priceQuoteTotals.totalFare.amount
                        itineraryPricing: {
                            priceQuote: [
                                {
                                    miscInformation: {
                                        globalNetRemit: null,
                                        signatureLine: {
                                            text: "UZV ETW 5DSE 0958/03AUG20",
                                            expirationDateTime: "01:58",
                                            source: "SYS",
                                            status: "ACTIVE",
                                        },
                                    },
                                    pricedItinerary: {
                                        airItineraryPricingInfo: {
                                            itinTotalFare: {
                                                baseFare: {
                                                    amount: "1166",
                                                    currencyCode: "ETB",
                                                },
                                                equivFare: null,
                                                taxes: {
                                                    tax: {
                                                        amount: "905",
                                                        taxCode: "XT",
                                                    },
                                                    taxBreakdownCode: [
                                                        {
                                                            taxPaid: "false",
                                                            text: "720YR",
                                                        },
                                                        {
                                                            taxPaid: "false",
                                                            text: "5ET",
                                                        },
                                                        {
                                                            taxPaid: "false",
                                                            text: "180IH",
                                                        },
                                                    ],
                                                },
                                                totalFare: {
                                                    amount: 2071.0,
                                                    currencyCode: "ETB",
                                                },
                                                totals: {
                                                    baseFare: {
                                                        amount: "1166",
                                                        currencyCode: null,
                                                    },
                                                    equivFare: null,
                                                    taxes: {
                                                        tax: {
                                                            amount: "905",
                                                            taxCode: null,
                                                        },
                                                        taxBreakdownCode: [],
                                                    },
                                                    totalFare: {
                                                        amount: 2071.0,
                                                        currencyCode: null,
                                                    },
                                                },
                                            },
                                            passengerTypeQuantity: {
                                                code: "ADT",
                                                quantity: "01",
                                            },
                                            ptC_FareBreakdown: {
                                                endorsements: {
                                                    endorsement: [
                                                        {
                                                            text: "WPRQ$METB$P1ADT",
                                                            type: "PRICING_PARAMETER",
                                                        },
                                                        {
                                                            text:
                                                                "PRIVATE FARE APPLIED - CHECK RULES FOR CORRECT TICKETING",
                                                            type: "WARNING",
                                                        },
                                                        {
                                                            text: "VALIDATING CARRIER SPECIFIED - ET",
                                                            type: "WARNING",
                                                        },
                                                    ],
                                                },
                                                fareBasis: {
                                                    code: "HET1",
                                                },
                                                fareCalculation: {
                                                    text: "BJR ET ADD1166ETB1166END",
                                                },
                                                flightSegment: [
                                                    {
                                                        baggageAllowance: {
                                                            number: "20K",
                                                        },
                                                        fareBasis: {
                                                            code: "HET1",
                                                        },
                                                        marketingAirline: {
                                                            code: "ET",
                                                            flightNumber: "187",
                                                            banner: null,
                                                            resBookDesigCode: null,
                                                        },
                                                        originLocation: {
                                                            locationCode: "BJR",
                                                            terminal: null,
                                                            terminalCode: null,
                                                        },
                                                        validityDates: {
                                                            notValidAfter: "2020-08-26",
                                                            notValidBefore: "2020-08-26",
                                                        },
                                                        connectionInd: "O",
                                                        departureDateTime: "08-26T09:00",
                                                        flightNumber: "187",
                                                        resBookDesigCode: "H",
                                                        segmentNumber: "1",
                                                        status: "OK",
                                                        destinationLocation: null,
                                                        equipment: null,
                                                        marriageGrp: null,
                                                        meal: null,
                                                        operatingAirline: null,
                                                        operatingAirlinePricing: null,
                                                        disclosureCarrier: null,
                                                        updatedArrivalTime: null,
                                                        updatedDepartureTime: null,
                                                        airMilesFlown: null,
                                                        arrivalDateTime: null,
                                                        dayOfWeekInd: null,
                                                        segmentBookedDate: null,
                                                        elapsedTime: null,
                                                        eTicket: null,
                                                        numberInParty: null,
                                                        smokingAllowed: null,
                                                        specialMeal: null,
                                                        stopQuantity: null,
                                                        isPast: null,
                                                        codeShare: null,
                                                        id: null,
                                                    },
                                                    {
                                                        baggageAllowance: null,
                                                        fareBasis: null,
                                                        marketingAirline: null,
                                                        originLocation: {
                                                            locationCode: "ADD",
                                                            terminal: null,
                                                            terminalCode: null,
                                                        },
                                                        validityDates: null,
                                                        connectionInd: null,
                                                        departureDateTime: null,
                                                        flightNumber: null,
                                                        resBookDesigCode: null,
                                                        segmentNumber: null,
                                                        status: null,
                                                        destinationLocation: null,
                                                        equipment: null,
                                                        marriageGrp: null,
                                                        meal: null,
                                                        operatingAirline: null,
                                                        operatingAirlinePricing: null,
                                                        disclosureCarrier: null,
                                                        updatedArrivalTime: null,
                                                        updatedDepartureTime: null,
                                                        airMilesFlown: null,
                                                        arrivalDateTime: null,
                                                        dayOfWeekInd: null,
                                                        segmentBookedDate: null,
                                                        elapsedTime: null,
                                                        eTicket: null,
                                                        numberInParty: null,
                                                        smokingAllowed: null,
                                                        specialMeal: null,
                                                        stopQuantity: null,
                                                        isPast: null,
                                                        codeShare: null,
                                                        id: null,
                                                    },
                                                ],
                                                fareComponent: [
                                                    {
                                                        location: {
                                                            origin: "BJR",
                                                            destination: "ADD",
                                                        },
                                                        dates: {
                                                            departureDateTime: "08-26T09:00",
                                                            arrivalDateTime: "08-26T10:00",
                                                        },
                                                        flightSegmentNumbers: {
                                                            flightSegmentNumber: ["1"],
                                                        },
                                                        fareBasisCode: "HET1",
                                                        fareDirectionality: "FROM",
                                                        amount: "1166",
                                                        ticketDesignator: "",
                                                        governingCarrier: "ET",
                                                        fareComponentNumber: "1",
                                                    },
                                                ],
                                                tourCode: null,
                                            },
                                        },
                                        displayOnly: "false",
                                        inputMessage: "WPRQ¥METB¥P1ADT",
                                        rph: "1",
                                        statusCode: "A",
                                        taxExempt: "false",
                                        validatingCarrier: "ET",
                                        storedDateTime: "2020-08-03T09:58",
                                    },
                                    responseHeader: {
                                        text: [
                                            "FARE - PRICE RETAINED",
                                            "FARE USED TO CALCULATE DISCOUNT",
                                            "FARE NOT GUARANTEED UNTIL TICKETED",
                                        ],
                                    },
                                    priceQuotePlus: {
                                        passengerInfo: {
                                            passengerData: {
                                                nameNumber: "01.01",
                                                text: "PIA/ETHIO PISEW MR",
                                            },
                                            passengerType: "ADT",
                                        },
                                        ticketingInstructionsInfo: "",
                                        domesticIntlInd: "I",
                                        pricingStatus: "S",
                                        verifyFareCalc: "false",
                                        itineraryChanged: "false",
                                        manualFare: "false",
                                        negotiatedFare: "false",
                                        systemIndicator: "S",
                                        nucSuppresion: "false",
                                        subjToGovtApproval: "false",
                                        iT_BT_Fare: "BT",
                                        displayOnly: "false",
                                        tourCode: null,
                                        discountAmount: "0",
                                    },
                                    rph: "1",
                                },
                            ],
                            priceQuoteTotals: {
                                baseFare: {
                                    amount: "1166.00",
                                    currencyCode: null,
                                },
                                equivFare: null,
                                taxes: {
                                    tax: {
                                        amount: "905.00",
                                        taxCode: null,
                                    },
                                    taxBreakdownCode: [],
                                },
                                totalFare: {
                                    amount: 2071.0,
                                    currencyCode: null,
                                },
                            },
                        },
                        reservationItems: {
                            item: [
                                {
                                    flightSegment: {
                                        baggageAllowance: null,
                                        fareBasis: null,
                                        marketingAirline: {
                                            code: "ET",
                                            flightNumber: "0187",
                                            banner: "MARKETED BY ETHIOPIAN AIRLINES",
                                            resBookDesigCode: "H",
                                        },
                                        originLocation: {
                                            locationCode: "BJR",
                                            terminal: null,
                                            terminalCode: null,
                                        },
                                        validityDates: null,
                                        connectionInd: null,
                                        departureDateTime: "2020-08-26T09:00",
                                        flightNumber: "0187",
                                        resBookDesigCode: "H",
                                        segmentNumber: "0001",
                                        status: "HK",
                                        destinationLocation: {
                                            locationCode: "ADD",
                                            terminal: "TERMINAL 1",
                                            terminalCode: "1",
                                        },
                                        equipment: {
                                            airEquipType: "DH8",
                                        },
                                        marriageGrp: null,
                                        meal: {
                                            code: "S",
                                        },
                                        operatingAirline: {
                                            banner: "OPERATED BY ETHIOPIAN AIRLINES",
                                            code: "ET",
                                            flightNumber: "0187",
                                            resBookDesigCode: "H",
                                        },
                                        operatingAirlinePricing: {
                                            code: "ET",
                                        },
                                        disclosureCarrier: {
                                            banner: "ETHIOPIAN AIRLINES",
                                            code: "ET",
                                            dot: "false",
                                        },
                                        updatedArrivalTime: "08-26T10:00",
                                        updatedDepartureTime: "08-26T09:00",
                                        airMilesFlown: "0204",
                                        arrivalDateTime: "08-26T10:00",
                                        dayOfWeekInd: "3",
                                        segmentBookedDate: "2020-08-03T01:58:00",
                                        elapsedTime: "01.00",
                                        eTicket: "true",
                                        numberInParty: "01",
                                        smokingAllowed: "false",
                                        specialMeal: "false",
                                        stopQuantity: "00",
                                        isPast: "false",
                                        codeShare: "false",
                                        id: "3",
                                    },
                                    product: {
                                        productDetails: {
                                            productName: {
                                                type: "AIR",
                                            },
                                            air: {
                                                departureAirport: "BJR",
                                                departureTerminalName: null,
                                                departureTerminalCode: null,
                                                arrivalAirport: "ADD",
                                                arrivalTerminalName: "TERMINAL 1",
                                                arrivalTerminalCode: "1",
                                                equipmentType: "DH8",
                                                marketingAirlineCode: "ET",
                                                marketingFlightNumber: "187",
                                                marketingClassOfService: "H",
                                                marriageGrp2: null,
                                                mealCode: "S",
                                                elapsedTime: "60",
                                                airMilesFlown: "204",
                                                funnelFlight: "false",
                                                changeOfGauge: "false",
                                                disclosureCarrier2: {
                                                    banner: "ETHIOPIAN AIRLINES",
                                                    code: "ET",
                                                    dot: "false",
                                                },
                                                eticket: "true",
                                                departureDateTime: "2020-08-26T09:00:00",
                                                arrivalDateTime: "2020-08-26T10:00:00",
                                                flightNumber: "187",
                                                classOfService: "H",
                                                actionCode: "HK",
                                                numberInParty: "1",
                                                segmentBookedDate: "2020-08-03T01:58:00",
                                                sequence: "1",
                                                segmentAssociationId: "2",
                                            },
                                            productCategory: "AIR",
                                            xmlns: null,
                                        },
                                    },
                                    rph: "1",
                                    ancillaries: null,
                                    seats: null,
                                },
                                {
                                    flightSegment: null,
                                    product: null,
                                    rph: "2",
                                    ancillaries: {
                                        ancillaryService: {
                                            commercialName: "ET HOLIDAY GROUND SERVICE",
                                            rficCode: "B",
                                            rficSubcode: "ETH",
                                            owningCarrierCode: "ET",
                                            vendor: "MMGR",
                                            emdType: "1",
                                            equivalentPrice: {
                                                price: "0",
                                                currency: null,
                                            },
                                            ttlPrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            originalBasePrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            passengerTypeCode: null,
                                            boardPoint: null,
                                            offPoint: null,
                                            totalOriginalBasePrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            totalEquivalentPrice: {
                                                price: "0",
                                                currency: null,
                                            },
                                            totalTTLPrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            bookingSource: "0",
                                            pdcSeat: null,
                                            purchaseTimestamp: null,
                                            groupCode: "99",
                                            ticketUsedForEMDPricing: "N",
                                            taxExemption: "N",
                                            segment: null,
                                            id: "46",
                                            sequenceNumber: "1",
                                            nameNumber: "01.01",
                                            numberOfItems: "1",
                                            actionCode: "HD",
                                            segmentIndicator: "S",
                                            firstTravelDate: null,
                                            lastTravelDate: null,
                                            bookingIndicator: " ",
                                            refundIndicator: "N",
                                            commisionIndicator: "Y",
                                            interlineIndicator: "Y",
                                            feeApplicationIndicator: " ",
                                            refundFormIndicator: null,
                                            ticketingIndicator: "0",
                                            travelPortions: null,
                                            fareGuaranteedIndicator: null,
                                        },
                                    },
                                    seats: null,
                                },
                                {
                                    flightSegment: null,
                                    product: null,
                                    rph: "3",
                                    ancillaries: {
                                        ancillaryService: {
                                            commercialName: "ET HOLIDAY GROUND SERVICE",
                                            rficCode: "B",
                                            rficSubcode: "ETH",
                                            owningCarrierCode: "ET",
                                            vendor: "MMGR",
                                            emdType: "1",
                                            equivalentPrice: {
                                                price: "0",
                                                currency: null,
                                            },
                                            ttlPrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            originalBasePrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            passengerTypeCode: null,
                                            boardPoint: null,
                                            offPoint: null,
                                            totalOriginalBasePrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            totalEquivalentPrice: {
                                                price: "0",
                                                currency: null,
                                            },
                                            totalTTLPrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            bookingSource: "0",
                                            pdcSeat: null,
                                            purchaseTimestamp: null,
                                            groupCode: "99",
                                            ticketUsedForEMDPricing: "N",
                                            taxExemption: "N",
                                            segment: null,
                                            id: "58",
                                            sequenceNumber: "2",
                                            nameNumber: "01.01",
                                            numberOfItems: "1",
                                            actionCode: "HD",
                                            segmentIndicator: "S",
                                            firstTravelDate: null,
                                            lastTravelDate: null,
                                            bookingIndicator: " ",
                                            refundIndicator: "N",
                                            commisionIndicator: "Y",
                                            interlineIndicator: "Y",
                                            feeApplicationIndicator: " ",
                                            refundFormIndicator: null,
                                            ticketingIndicator: "0",
                                            travelPortions: null,
                                            fareGuaranteedIndicator: null,
                                        },
                                    },
                                    seats: null,
                                },
                                {
                                    flightSegment: null,
                                    product: null,
                                    rph: "4",
                                    ancillaries: {
                                        ancillaryService: {
                                            commercialName: "ET HOLIDAY GROUND SERVICE",
                                            rficCode: "B",
                                            rficSubcode: "ETH",
                                            owningCarrierCode: "ET",
                                            vendor: "MMGR",
                                            emdType: "1",
                                            equivalentPrice: {
                                                price: "0",
                                                currency: null,
                                            },
                                            ttlPrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            originalBasePrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            passengerTypeCode: null,
                                            boardPoint: null,
                                            offPoint: null,
                                            totalOriginalBasePrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            totalEquivalentPrice: {
                                                price: "0",
                                                currency: null,
                                            },
                                            totalTTLPrice: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            bookingSource: "0",
                                            pdcSeat: null,
                                            purchaseTimestamp: null,
                                            groupCode: "99",
                                            ticketUsedForEMDPricing: "N",
                                            taxExemption: "N",
                                            segment: null,
                                            id: "60",
                                            sequenceNumber: "3",
                                            nameNumber: "01.01",
                                            numberOfItems: "1",
                                            actionCode: "HD",
                                            segmentIndicator: "S",
                                            firstTravelDate: null,
                                            lastTravelDate: null,
                                            bookingIndicator: " ",
                                            refundIndicator: "N",
                                            commisionIndicator: "Y",
                                            interlineIndicator: "Y",
                                            feeApplicationIndicator: " ",
                                            refundFormIndicator: null,
                                            ticketingIndicator: "0",
                                            travelPortions: null,
                                            fareGuaranteedIndicator: null,
                                        },
                                    },
                                    seats: null,
                                },
                            ],
                        },
                        ticketing: {
                            flightTickets: [
                                {
                                    rph: "01",
                                    ticketTimeLimit: null,
                                    eTicketNumber: "0712126859091",
                                },
                            ],
                            emdNumber: {
                                rph: "02",
                                ticketTimeLimit: null,
                                eTicketNumber: null,
                            },
                        },
                    },
                    itineraryRef: {
                        source: {
                            aaA_PseudoCityCode: "UZV",
                            createDateTime: "2020-08-03T01:58",
                            creationAgent: "DSE",
                            homePseudoCityCode: "ETW",
                            pseudoCityCode: "A0A0",
                            receivedFrom: "SSW",
                            lastUpdateDateTime: "2020-08-03T02:33",
                            sequenceNumber: "21",
                        },
                        airExtras: "true",
                        id: "ZUHOXW",
                        inhibitCode: "U",
                        partitionID: "ET",
                        primeHostID: "ET",
                    },
                    remarkInfo: {
                        remark: [
                            {
                                text: "FARE/NEG/HET1",
                                rph: "001",
                                type: "Historical",
                                id: "19",
                            },
                            {
                                text: "FARE/NEG/AMT/1ADT/1166/905/2071/ETB",
                                rph: "002",
                                type: "Historical",
                                id: "20",
                            },
                            {
                                text: "DEAL-BNPL FEE",
                                rph: "003",
                                type: "Historical",
                                id: "21",
                            },
                            {
                                text: "OFFERING-BNPL 24HRS",
                                rph: "004",
                                type: "Historical",
                                id: "22",
                            },
                            {
                                text: "ON HOLD FEE",
                                rph: "005",
                                type: "Historical",
                                id: "23",
                            },
                            {
                                text: "SSWMOD-DT/2020/08/03-06-58/IP/52.154.66.122",
                                rph: "006",
                                type: "Historical",
                                id: "24",
                            },
                            {
                                text: "SSWREF/38CC497F5634CB26DD7AD9BCEC2FA6A5/SSWVLC2974/4",
                                rph: "007",
                                type: "Historical",
                                id: "25",
                            },
                            {
                                text: "ON HOLD",
                                rph: "008",
                                type: "Historical",
                                id: "28",
                            },
                            {
                                text: "SENT CONFIRMATION EMAIL",
                                rph: "009",
                                type: "Historical",
                                id: "34",
                            },
                            {
                                text: "AUTH-ADYEN/VI1111/03AUG/01411596438044951761",
                                rph: "010",
                                type: "Historical",
                                id: "35",
                            },
                            {
                                text: "AUTH-APV/012010/00/ETB18600.3525",
                                rph: "011",
                                type: "Historical",
                                id: "36",
                            },
                            {
                                text: "AUTH-CSC SUPPLIED/",
                                rph: "012",
                                type: "Historical",
                                id: "37",
                            },
                            {
                                text: "AUTH-AVS NOT SUPPORTED/",
                                rph: "013",
                                type: "Historical",
                                id: "38",
                            },
                            {
                                text: "RISK MGNT/ACCEPT/480/98/5312489738016004304157",
                                rph: "014",
                                type: "Historical",
                                id: "39",
                            },
                            {
                                text: "XXAUTH/012010   *Z/VI1111",
                                rph: "015",
                                type: "General",
                                id: "40",
                            },
                            {
                                text: "AUTH-ADYEN/VI1111/03AUG/01311596439920371860",
                                rph: "016",
                                type: "Historical",
                                id: "48",
                            },
                            {
                                text: "AUTH-APV/009838/00/ETB18600.3525",
                                rph: "017",
                                type: "Historical",
                                id: "49",
                            },
                            {
                                text: "AUTH-CSC SUPPLIED/",
                                rph: "018",
                                type: "Historical",
                                id: "50",
                            },
                            {
                                text: "AUTH-AVS NOT SUPPORTED/",
                                rph: "019",
                                type: "Historical",
                                id: "51",
                            },
                            {
                                text: "RISK MGNT/ACCEPT/480/98/5312489738016034351041",
                                rph: "020",
                                type: "Historical",
                                id: "52",
                            },
                            {
                                text: "AUTH-ADYEN/VI1111/03AUG/01311596439982052875",
                                rph: "021",
                                type: "Historical",
                                id: "53",
                            },
                            {
                                text: "AUTH-APV/096355/00/ETB18600.3525",
                                rph: "022",
                                type: "Historical",
                                id: "54",
                            },
                            {
                                text: "AUTH-CSC SUPPLIED/",
                                rph: "023",
                                type: "Historical",
                                id: "55",
                            },
                            {
                                text: "AUTH-AVS NOT SUPPORTED/",
                                rph: "024",
                                type: "Historical",
                                id: "56",
                            },
                            {
                                text: "RISK MGNT/ACCEPT/480/98/5312489738016030811627",
                                rph: "025",
                                type: "Historical",
                                id: "57",
                            },
                        ],
                    },
                    specialServiceInfo: [
                        {
                            service: {
                                text: "ET NN1 BJRADD0187H26AUG/EF",
                                ssR_Code: "SSR",
                                ssR_Type: "BRND",
                                personName: null,
                            },
                            rph: "001",
                            type: "AFX",
                            id: "4",
                        },
                        {
                            service: {
                                text: "ET HK1/DB/16JUN1996/M/PIA/ETHIO/PISEW",
                                ssR_Code: "SSR",
                                ssR_Type: "DOCS",
                                personName: {
                                    email: null,
                                    givenName: null,
                                    surname: null,
                                    withInfant: null,
                                    nameNumber: "01.01",
                                    nameReference: null,
                                    rph: null,
                                    elementId: null,
                                    text: "PIA/ETHIO PISEW MR",
                                },
                            },
                            rph: "002",
                            type: "AFX",
                            id: "10",
                        },
                        {
                            service: {
                                text: "ET HK1 BJRADD0187H26AUG/0712126859091C1",
                                ssR_Code: "SSR",
                                ssR_Type: "TKNE",
                                personName: {
                                    email: null,
                                    givenName: null,
                                    surname: null,
                                    withInfant: null,
                                    nameNumber: "01.01",
                                    nameReference: null,
                                    rph: null,
                                    elementId: null,
                                    text: "PIA/ETHIO PISEW MR",
                                },
                            },
                            rph: "003",
                            type: "AFX",
                            id: "41",
                        },
                    ],
                    openReservationElements: {
                        openReservationElement: [
                            {
                                serviceRequest: {
                                    freeText: "/EF",
                                    fullText: "BRND ET NN1 BJRADD0187H26AUG/EF",
                                    actionCode: "NN",
                                    airlineCode: "ET",
                                    code: "BRND",
                                    serviceCount: "1",
                                    serviceType: "SSR",
                                    ssrType: "AFX",
                                    travelDocument: null,
                                },
                                segmentAssociation: {
                                    airSegment: {
                                        carrierCode: "ET",
                                        flightNumber: "0187",
                                        departureDate: "2020-08-26",
                                        boardPoint: "BJR",
                                        offPoint: "ADD",
                                        classOfService: "H",
                                    },
                                    id: "3",
                                    segmentAssociationId: "2",
                                },
                                id: "4",
                                type: "SRVC",
                                elementId: "pnr-4",
                                xmlns: null,
                                nameAssociation: null,
                                email2: null,
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: {
                                    freeText: "/DB/16JUN1996/M/PIA/ETHIO/PISEW",
                                    fullText: "DOCS ET HK1/DB/16JUN1996/M/PIA/ETHIO/PISEW",
                                    actionCode: "HK",
                                    airlineCode: "ET",
                                    code: "DOCS",
                                    serviceCount: "1",
                                    serviceType: "SSR",
                                    ssrType: "AFX",
                                    travelDocument: {
                                        type: "DB",
                                        dateOfBirth: "16JUN1996",
                                        gender: "M",
                                        lastName: "PIA",
                                        firstName: "ETHIO",
                                        infant: "false",
                                        hasDocumentData: "false",
                                    },
                                },
                                segmentAssociation: null,
                                id: "10",
                                type: "SRVC",
                                elementId: "pnr-10",
                                xmlns: null,
                                nameAssociation: {
                                    lastName: "PIA",
                                    firstName: "ETHIO PISEW MR",
                                    nameRefNumber: null,
                                },
                                email2: null,
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: {
                                    freeText: "/0712126859091C1",
                                    fullText: "TKNE ET HK1 BJRADD0187H26AUG/0712126859091C1",
                                    actionCode: "HK",
                                    airlineCode: "ET",
                                    code: "TKNE",
                                    serviceCount: "1",
                                    serviceType: "SSR",
                                    ssrType: "AFX",
                                    travelDocument: null,
                                },
                                segmentAssociation: {
                                    airSegment: {
                                        carrierCode: "ET",
                                        flightNumber: "0187",
                                        departureDate: "2020-08-26",
                                        boardPoint: "BJR",
                                        offPoint: "ADD",
                                        classOfService: "H",
                                    },
                                    id: "3",
                                    segmentAssociationId: "2",
                                },
                                id: "41",
                                type: "SRVC",
                                elementId: "pnr-41",
                                xmlns: null,
                                nameAssociation: {
                                    lastName: "PIA",
                                    firstName: "ETHIO PISEW MR",
                                    nameRefNumber: null,
                                },
                                email2: null,
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "3",
                                type: null,
                                elementId: "pnr-or-3",
                                xmlns: null,
                                nameAssociation: null,
                                email2: null,
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "3",
                                type: null,
                                elementId: "pnr-or-3",
                                xmlns: null,
                                nameAssociation: null,
                                email2: null,
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "8",
                                type: "PSG_DETAILS_MAIL",
                                elementId: "pnr-8",
                                xmlns: null,
                                nameAssociation: null,
                                email2: {
                                    address: "TEDESSES@ETHIOPIANAIRLINES.COM",
                                    comment: "@E",
                                },
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "9",
                                type: "PSG_DETAILS_MAIL",
                                elementId: "pnr-9",
                                xmlns: null,
                                nameAssociation: {
                                    lastName: "PIA",
                                    firstName: "ETHIO PISEW MR",
                                    nameRefNumber: null,
                                },
                                email2: {
                                    address: "TEDESSES@ETHIOPIANAIRLINES.COM",
                                    comment: "@E",
                                },
                                ancillaryProduct: null,
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "84b1fbc4-dcf7-48de-84d6-d88ff2d3369a",
                                type: "APO",
                                elementId: "pnr-or-84b1fbc4-dcf7-48de-84d6-d88ff2d3369a",
                                xmlns: null,
                                nameAssociation: null,
                                email2: null,
                                ancillaryProduct: {
                                    xmlData: {
                                        ancillaryServiceData: {
                                            nameAssociationList: {
                                                nameAssociationTag: {
                                                    lastName: "PIA",
                                                    firstName: "ETHIO PISEW MR",
                                                    referenceId: "1",
                                                },
                                            },
                                            segmentAssociationList: null,
                                            commercialName: "ET HOLIDAY GROUND SERVICE",
                                            rficCode: "B",
                                            rficSubcode: "ETH",
                                            owningCarrierCode: "ET",
                                            vendor: "MMGR",
                                            emdType: "1",
                                            segmentNumber: null,
                                            equivalentPrice2: null,
                                            ttlPrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            portionOfTravelIndicator: null,
                                            originalBasePrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            refundIndicator: "N",
                                            commisionIndicator: "Y",
                                            interlineIndicator: "Y",
                                            feeApplicationIndicator: "",
                                            passengerTypeCode: null,
                                            boardPoint: null,
                                            offPoint: null,
                                            totalOriginalBasePrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            totalEquivalentPrice2: null,
                                            totalTTLPrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            numberOfItems: "1",
                                            actionCode: "HD",
                                            segmentIndicator: "S",
                                            refundFormIndicator: null,
                                            bookingSource: "0",
                                            ticketingIndicator: "0",
                                            pdcSeat: null,
                                            firstTravelDate: null,
                                            lastTravelDate: null,
                                            purchaseTimestamp: null,
                                            groupCode: "99",
                                            ticketUsedForEMDPricing: "N",
                                            paperDocRequired: "N",
                                            emdConsummedAtIssuance: "Y",
                                            taxExemption: "N",
                                            acsCount: "0",
                                            segment2: null,
                                            id: "46",
                                            bagWeight: null,
                                            fareGuaranteedIndicator: null,
                                            travelPortions2: null,
                                        },
                                    },
                                    id: "1",
                                },
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "273a09bd-92d6-4a46-843a-d6799399f3d3",
                                type: "APO",
                                elementId: "pnr-or-273a09bd-92d6-4a46-843a-d6799399f3d3",
                                xmlns: null,
                                nameAssociation: null,
                                email2: null,
                                ancillaryProduct: {
                                    xmlData: {
                                        ancillaryServiceData: {
                                            nameAssociationList: {
                                                nameAssociationTag: {
                                                    lastName: "PIA",
                                                    firstName: "ETHIO PISEW MR",
                                                    referenceId: "1",
                                                },
                                            },
                                            segmentAssociationList: null,
                                            commercialName: "ET HOLIDAY GROUND SERVICE",
                                            rficCode: "B",
                                            rficSubcode: "ETH",
                                            owningCarrierCode: "ET",
                                            vendor: "MMGR",
                                            emdType: "1",
                                            segmentNumber: null,
                                            equivalentPrice2: null,
                                            ttlPrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            portionOfTravelIndicator: null,
                                            originalBasePrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            refundIndicator: "N",
                                            commisionIndicator: "Y",
                                            interlineIndicator: "Y",
                                            feeApplicationIndicator: "",
                                            passengerTypeCode: null,
                                            boardPoint: null,
                                            offPoint: null,
                                            totalOriginalBasePrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            totalEquivalentPrice2: null,
                                            totalTTLPrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            numberOfItems: "1",
                                            actionCode: "HD",
                                            segmentIndicator: "S",
                                            refundFormIndicator: null,
                                            bookingSource: "0",
                                            ticketingIndicator: "0",
                                            pdcSeat: null,
                                            firstTravelDate: null,
                                            lastTravelDate: null,
                                            purchaseTimestamp: null,
                                            groupCode: "99",
                                            ticketUsedForEMDPricing: "N",
                                            paperDocRequired: "N",
                                            emdConsummedAtIssuance: "Y",
                                            taxExemption: "N",
                                            acsCount: "0",
                                            segment2: null,
                                            id: "58",
                                            bagWeight: null,
                                            fareGuaranteedIndicator: null,
                                            travelPortions2: null,
                                        },
                                    },
                                    id: "2",
                                },
                            },
                            {
                                serviceRequest: null,
                                segmentAssociation: null,
                                id: "ac40a863-8070-4ad7-9f06-f16351d523f6",
                                type: "APO",
                                elementId: "pnr-or-ac40a863-8070-4ad7-9f06-f16351d523f6",
                                xmlns: null,
                                nameAssociation: null,
                                email2: null,
                                ancillaryProduct: {
                                    xmlData: {
                                        ancillaryServiceData: {
                                            nameAssociationList: {
                                                nameAssociationTag: {
                                                    lastName: "PIA",
                                                    firstName: "ETHIO PISEW MR",
                                                    referenceId: "1",
                                                },
                                            },
                                            segmentAssociationList: null,
                                            commercialName: "ET HOLIDAY GROUND SERVICE",
                                            rficCode: "B",
                                            rficSubcode: "ETH",
                                            owningCarrierCode: "ET",
                                            vendor: "MMGR",
                                            emdType: "1",
                                            segmentNumber: null,
                                            equivalentPrice2: null,
                                            ttlPrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            portionOfTravelIndicator: null,
                                            originalBasePrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            refundIndicator: "N",
                                            commisionIndicator: "Y",
                                            interlineIndicator: "Y",
                                            feeApplicationIndicator: "",
                                            passengerTypeCode: null,
                                            boardPoint: null,
                                            offPoint: null,
                                            totalOriginalBasePrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            totalEquivalentPrice2: null,
                                            totalTTLPrice2: {
                                                price: "0.0",
                                                currency: "ETB",
                                            },
                                            numberOfItems: "1",
                                            actionCode: "HD",
                                            segmentIndicator: "S",
                                            refundFormIndicator: null,
                                            bookingSource: "0",
                                            ticketingIndicator: "0",
                                            pdcSeat: null,
                                            firstTravelDate: null,
                                            lastTravelDate: null,
                                            purchaseTimestamp: null,
                                            groupCode: "99",
                                            ticketUsedForEMDPricing: "N",
                                            paperDocRequired: "N",
                                            emdConsummedAtIssuance: "Y",
                                            taxExemption: "N",
                                            acsCount: "0",
                                            segment2: null,
                                            id: "60",
                                            bagWeight: null,
                                            fareGuaranteedIndicator: null,
                                            travelPortions2: null,
                                        },
                                    },
                                    id: "3",
                                },
                            },
                        ],
                    },
                },
            },
            packageDetail: {
                package: {
                    name: "Wonchi Crater Lake and Tiya",
                    numberOfDays: 2,
                    packageTypeId: 20,
                    packageType: {
                        name: "Day Tour",
                        packageCategory: "Holiday Type",
                        categoryCode: 1,
                        imagePath:
                            "https://packagebuildertest.azurewebsites.net/Images/PackageType/5852838e-0244-4e4f-9353-c2927105064c_trans.jpg",
                        id: 20,
                        description: "Package that has 1 day",
                        status: 0,
                    },
                    productType: 0,
                    isChildAllowed: "Yes",
                    packageValidStartDate: "2020-03-19T00:00:00",
                    packageValidEndDate: "2020-12-31T00:00:00",
                    packageBookingValidDateFrom: "2020-03-19T00:00:00",
                    packageBookingValidDateTo: "2020-12-31T00:00:00",
                    cancellationPolicy:
                        "\tDate of cancellation                                                             Applicable Penalty\r\no\tMore than 30 days before arrival:                                       No fees\r\no\tBetween 29 and 21 days before the date of arrival:          25% of the total value\r\no\tBetween 20 and 08 days before the date of arrival:          50% of the total value\r\no\tLess than 07 days before the date of arrival:                    100% of the total value\r\n",
                    origin: null,
                    destinationId: 1,
                    city: null,
                    destination: "ADD",
                    specialNote:
                        "Important Note: The below periods are high tourism season for Ethiopia and thus adequate prior booking is required                   \r\n                                                \r\n1.\tSep.21 to 30, during MESKEL FESTIVAL at all major tourist destinations\r\n2.\tJan.13 to 25, during EPIPHANY FESTIVAL, at all major tourist destinations\r\n3.\tEither in late January or early February, during AU SUMMIT, only in Addis\r\n",
                    contactDetailOnArrival: null,
                    imagePath:
                        "https://packagebuilderprod.azurewebsites.net/Images/Packages/cce8a020-17c0-49b6-b401-deea57c824be_1920x603pxl-Tiya-Web-banner-RETH-0619.jpg",
                    packageStatus: "Active",
                    hotelRate: 0.0,
                    isHotelIncluded: false,
                    id: 81,
                    description:
                        "Wonchi Crater Lake is the beautiful legacy of a once powerful volcano located within a mountain range between the towns of Ambo and Woliso. The beautiful lake is situated at an altitude of about 3000m above sea level. Wonchi contains hot springs, waterfalls and breathtaking views of valleys and stunning scenery. On the island in the middle of the lake there is an ancient monastery named Cherkos. Wonchi also has indigenous forest reserves. The surrounding beauty mesmerizes visitors to Wonchi!\r\nTIYA STELAE FIELD is an important stelae cluster at the south end of Tiya village, and a Unesco World Heritage listed site; Tiya contains 41 stelae engraved with unique symbols including swords, believed to be a mass burial site dating back to the 12th century. \r\n\r\n",
                    status: 0,
                },
                price: {
                    min: 1,
                    max: 1,
                    childRate: 50.0,
                    amount: 575.0,
                    currencyID: 7,
                    currency: {
                        code: "ETB",
                        name: null,
                        description: null,
                        id: 0,
                        status: 0,
                    },
                    id: 175,
                    name: null,
                    description: null,
                    status: 0,
                },
                packageHotelDetail: null,
                itinerariesList: [],
                excludes: [
                    {
                        id: 79,
                        exclude:
                            " \tAny other expenses which are not mentioned under package includes section ",
                        packageId: 81,
                    },
                ],
                includes: [
                    {
                        id: 295,
                        include:
                            " \t1 Night accommodation cost on Twin/Double occupancy basis",
                        packageId: 81,
                    },
                ],
                emd: null,
                fullName: null,
                transactionDate: "0001-01-01T00:00:00",
                email: null,
                totalAmount: 16529.3525,
                bookingDate: "0001-01-01T00:00:00",
                bookingEndDate: null,
            },
            multiPackages: [
                {
                    package: {
                        name: "Wonchi Crater Lake and Tiya",
                        numberOfDays: 2,
                        packageTypeId: 20,
                        packageType: {
                            name: "Day Tour",
                            packageCategory: "Holiday Type",
                            categoryCode: 1,
                            imagePath:
                                "https://packagebuildertest.azurewebsites.net/Images/PackageType/5852838e-0244-4e4f-9353-c2927105064c_trans.jpg",
                            id: 20,
                            description: "Package that has 1 day",
                            status: 0,
                        },
                        productType: 0,
                        isChildAllowed: "Yes",
                        packageValidStartDate: "2020-03-19T00:00:00",
                        packageValidEndDate: "2020-12-31T00:00:00",
                        packageBookingValidDateFrom: "2020-03-19T00:00:00",
                        packageBookingValidDateTo: "2020-12-31T00:00:00",
                        cancellationPolicy:
                            "\tDate of cancellation                                                             Applicable Penalty\r\no\tMore than 30 days before arrival:                                       No fees\r\no\tBetween 29 and 21 days before the date of arrival:          25% of the total value\r\no\tBetween 20 and 08 days before the date of arrival:          50% of the total value\r\no\tLess than 07 days before the date of arrival:                    100% of the total value\r\n",
                        origin: null,
                        destinationId: 1,
                        city: null,
                        destination: "ADD",
                        specialNote:
                            "Important Note: The below periods are high tourism season for Ethiopia and thus adequate prior booking is required                   \r\n                                                \r\n1.\tSep.21 to 30, during MESKEL FESTIVAL at all major tourist destinations\r\n2.\tJan.13 to 25, during EPIPHANY FESTIVAL, at all major tourist destinations\r\n3.\tEither in late January or early February, during AU SUMMIT, only in Addis\r\n",
                        contactDetailOnArrival: null,
                        imagePath:
                            "https://packagebuilderprod.azurewebsites.net/Images/Packages/cce8a020-17c0-49b6-b401-deea57c824be_1920x603pxl-Tiya-Web-banner-RETH-0619.jpg",
                        packageStatus: "Active",
                        hotelRate: 0.0,
                        isHotelIncluded: false,
                        id: 81,
                        description:
                            "Wonchi Crater Lake is the beautiful legacy of a once powerful volcano located within a mountain range between the towns of Ambo and Woliso. The beautiful lake is situated at an altitude of about 3000m above sea level. Wonchi contains hot springs, waterfalls and breathtaking views of valleys and stunning scenery. On the island in the middle of the lake there is an ancient monastery named Cherkos. Wonchi also has indigenous forest reserves. The surrounding beauty mesmerizes visitors to Wonchi!\r\nTIYA STELAE FIELD is an important stelae cluster at the south end of Tiya village, and a Unesco World Heritage listed site; Tiya contains 41 stelae engraved with unique symbols including swords, believed to be a mass burial site dating back to the 12th century. \r\n\r\n",
                        status: 0,
                    },
                    price: {
                        min: 1,
                        max: 1,
                        childRate: 50.0,
                        amount: 575.0,
                        currencyID: 7,
                        currency: {
                            code: "ETB",
                            name: null,
                            description: null,
                            id: 0,
                            status: 0,
                        },
                        id: 175,
                        name: null,
                        description: null,
                        status: 0,
                    },
                    packageHotelDetail: null,
                    itinerariesList: [],
                    excludes: [
                        {
                            id: 79,
                            exclude:
                                " \tAny other expenses which are not mentioned under package includes section ",
                            packageId: 81,
                        },
                    ],
                    includes: [
                        {
                            id: 295,
                            include:
                                " \t1 Night accommodation cost on Twin/Double occupancy basis",
                            packageId: 81,
                        },
                    ],
                    emd: null,
                    fullName: null,
                    transactionDate: "0001-01-01T00:00:00",
                    email: null,
                    totalAmount: 16529.3525,
                    bookingDate: "0001-01-01T00:00:00",
                    bookingEndDate: null,
                },
            ],
            floCashOrderResponse: null,
            cellpointResponse: null,
            error: null,
            redirectHtml: null,
            earlierAmount: 0.0,
            latestAmount: 0.0,
            paymentMethod: null,
        },
        statusAPI: "SUCCESS",
        messageAPI:
            "The booking is Confirmed Please find the attachment on your email for more detail",
        errorMessege: null,
        isOkResponse: false,
        exceptionDetail: null,
        request: null,
        response: null,
        ex: null,
        errorList: [
            "Message=>TPF",
            "Type=>ApplicationError",
            "INVALID AMOUNT - CONTAINED IN HEADER INFO",
        ],
    };
    static getAirports() {
        return JSON.parse(localStorage.getItem("airports"));
    }
    static getAll = [
        { value: "Aarhus (AAR), Denmark", label: "Aarhus (AAR), Denmark" },
        {
            value: "Aberdeen Dyce (ABZ)), United Kingdom",
            label: "Aberdeen Dyce (ABZ)), United Kingdom",
        },
        { value: "Abha (AHB), Saudi Arabia", label: "Abha (AHB), Saudi Arabia" },
        {
            value: "Abidjan (ABJ), Côte d'Ivoire",
            label: "Abidjan (ABJ), Côte d'Ivoire",
        },
        { value: "Abuja (ABV), Nigeria", label: "Abuja (ABV), Nigeria" },
        { value: "Accra (ACC), Ghana", label: "Accra (ACC), Ghana" },
        {
            value: "Addis Ababa (ADD), Ethiopia",
            label: "Addis Ababa (ADD), Ethiopia",
        },
        {
            value: "Adelaide (ADL), Australia",
            label: "Adelaide (ADL), Australia",
        },
        {
            value: "Albany Intl (ALB), United States",
            label: "Albany Intl (ALB), United States",
        },
        { value: "Alicante (ALC), Spain", label: "Alicante (ALC), Spain" },
        { value: "Amman (AMM), Jordan", label: "Amman (AMM), Jordan" },
        {
            value: "Amsterdam (AMS), Netherland",
            label: "Amsterdam (AMS), Netherland",
        },
        {
            value: "Antananarivo (TNR), Madagascar",
            label: "Antananarivo (TNR), Madagascar",
        },
        { value: "Aomori(AOJ), Japan", label: "Aomori(AOJ), Japan" },
        {
            value: "Arba Minch (AMH), Ethiopia",
            label: "Arba Minch (AMH), Ethiopia",
        },
        { value: "Asmara (ASM), Eritrea", label: "Asmara (ASM), Eritrea" },
        { value: "Assosa (ASO), Ethiopia", label: "Assosa (ASO), Ethiopia" },
        { value: "Athens (ATH), Greece", label: "Athens (ATH), Greece" },
        {
            value: "Atlanta (ATL), United States",
            label: "Atlanta (ATL), United States",
        },
        {
            value: "Auckland Airport (AKL), New Zealand",
            label: "Auckland Airport (AKL), New Zealand",
        },
        {
            value: "Austin (AUS), United States",
            label: "Austin (AUS), United States",
        },
        {
            value: "Avalon AU(AVV), Australia",
            label: "Avalon AU(AVV), Australia",
        },
        { value: "Axum (AXU), Ethiopia", label: "Axum (AXU), Ethiopia" },
        {
            value: "Ayers Rock(AYQ), Australia ",
            label: "Ayers Rock(AYQ), Australia ",
        },
        {
            value: "Bahar Dar (BJR), Ethiopia",
            label: "Bahar Dar (BJR), Ethiopia",
        },
        { value: "Bahrain (BAH), Bahrain", label: "Bahrain (BAH), Bahrain" },
        { value: "Ballina(BNK), Australia", label: "Ballina(BNK), Australia" },
        { value: "Bamako (BKO), Mali", label: "Bamako (BKO), Mali" },
        { value: "Bandung (BDO), Indonesia", label: "Bandung (BDO), Indonesia" },
        {
            value: "Bangkok Suvarnabhumi (BKK), Thailand",
            label: "Bangkok Suvarnabhumi (BKK), Thailand",
        },
        {
            value: "Bangui (BGF), Central African Republic",
            label: "Bangui (BGF), Central African Republic",
        },
        { value: "Barcelona (BCN), Spain", label: "Barcelona (BCN), Spain" },
        { value: "Beijing (BJS), China", label: "Beijing (BJS), China" },
        { value: "Beijing (PEK), China", label: "Beijing (PEK), China" },
        { value: "Beirut (BEY), Lebanon", label: "Beirut (BEY), Lebanon" },
        {
            value: "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil",
            label: "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil",
        },
        { value: "Bergen (BGO), Norway", label: "Bergen (BGO), Norway" },
        { value: "Bergenz (XGZ), Austria", label: "Bergenz (XGZ), Austria" },
        {
            value: "Berlin Tegel (TXL), Germany",
            label: "Berlin Tegel (TXL), Germany",
        },
        { value: "Bilbao (BIO), Spain", label: "Bilbao (BIO), Spain" },
        { value: "Billund (BLL), Denmark", label: "Billund (BLL), Denmark" },
        {
            value: "Birmingham (BHX) , United Kingdom",
            label: "Birmingham (BHX) , United Kingdom",
        },
        { value: "Blantyre (BLZ), Malawi", label: "Blantyre (BLZ), Malawi" },
        {
            value: "Boston (BOS), United States",
            label: "Boston (BOS), United States",
        },
        {
            value: "Boulder (BLD), United States",
            label: "Boulder (BLD), United States",
        },
        { value: "Brasilia (BSB), Brazil", label: "Brasilia (BSB), Brazil" },
        {
            value: "Brazzaville (BZV), Republic of Congo",
            label: "Brazzaville (BZV), Republic of Congo",
        },
        { value: "Bremen (BRE), Germany", label: "Bremen (BRE), Germany" },
        {
            value: "Brisbane (BNE), Australia",
            label: "Brisbane (BNE), Australia",
        },
        { value: "Brussels (BRU), Belgium", label: "Brussels (BRU), Belgium" },
        { value: "Bucharest (OTP), Romania", label: "Bucharest (OTP), Romania" },
        {
            value: "Budapest, Liszt Ferenc Airport (BUD), Hungary",
            label: "Budapest, Liszt Ferenc Airport (BUD), Hungary",
        },
        {
            value: "Buenos Aires (EZE), Argentina",
            label: "Buenos Aires (EZE), Argentina",
        },
        {
            value: "Buffalo (BUF), United States",
            label: "Buffalo (BUF), United States",
        },
        { value: "Bujumbura (BJM), Burundi", label: "Bujumbura (BJM), Burundi" },
        {
            value: "Burlington (BTV), United States",
            label: "Burlington (BTV), United States",
        },
        { value: "Cairo (CAI), Egypt", label: "Cairo (CAI), Egypt" },
        {
            value: "Calgary Intl AB (YYC), Canada",
            label: "Calgary Intl AB (YYC), Canada",
        },
        {
            value: "Campo Grande International Airport (CGR), Brazil",
            label: "Campo Grande International Airport (CGR), Brazil",
        },
        {
            value: "Cape Town (CPT), South Africa",
            label: "Cape Town (CPT), South Africa",
        },
        {
            value: "Cardiff (CWL) , United Kingdom",
            label: "Cardiff (CWL) , United Kingdom",
        },
        { value: "Changsha (CSX), China", label: "Changsha (CSX), China" },
        {
            value: "Charleston (CHS), United States",
            label: "Charleston (CHS), United States",
        },
        {
            value: "Charleston (CRW), United States",
            label: "Charleston (CRW), United States",
        },
        {
            value: "Charlotte (CLT), United States",
            label: "Charlotte (CLT), United States",
        },
        {
            value: "Charlottesville (CHO), United States",
            label: "Charlottesville (CHO), United States",
        },
        { value: "Chengdu (CTU), China", label: "Chengdu (CTU), China" },
        {
            value: "Chiang Mai (CNX), Thailand",
            label: "Chiang Mai (CNX), Thailand",
        },
        {
            value: "Chicago Ohare (ORD), United States",
            label: "Chicago Ohare (ORD), United States",
        },
        { value: "Chongqing (CKG), China", label: "Chongqing (CKG), China" },
        {
            value: "Christchurch Airport (CHC), New Zealand",
            label: "Christchurch Airport (CHC), New Zealand",
        },
        {
            value: "Cincinnati (CVG), United States",
            label: "Cincinnati (CVG), United States",
        },
        {
            value: "Cleveland (CLE), United States",
            label: "Cleveland (CLE), United States",
        },
        {
            value: "Columbia (CAE), United States",
            label: "Columbia (CAE), United States",
        },
        {
            value: "Columbus (CMH), United States",
            label: "Columbus (CMH), United States",
        },
        { value: "Conakry (CKY), Guinea", label: "Conakry (CKY), Guinea" },
        {
            value: "Copenhagen (CPH), Denmark",
            label: "Copenhagen (CPH), Denmark",
        },
        { value: "Coruna (LCG), Spain", label: "Coruna (LCG), Spain" },
        { value: "Cotonou (COO), Benin", label: "Cotonou (COO), Benin" },
        { value: "Cuiaba (CGB), Brazil", label: "Cuiaba (CGB), Brazil" },
        { value: "Curitiba (CWB), Brazil", label: "Curitiba (CWB), Brazil" },
        {
            value: "Dakar Blaise Diagne Airport (DSS), Senegal",
            label: "Dakar Blaise Diagne Airport (DSS), Senegal",
        },
        { value: "Dakar(DKR), Senegal", label: "Dakar(DKR), Senegal" },
        { value: "Dalian (DLC), China", label: "Dalian (DLC), China" },
        {
            value: "Dallas FT Worth , TX (DFW), United States",
            label: "Dallas FT Worth , TX (DFW), United States",
        },
        {
            value: "Dammam (DMM), Saudi Arabia",
            label: "Dammam (DMM), Saudi Arabia",
        },
        {
            value: "Dar Es Salaam (DAR), Tanzania",
            label: "Dar Es Salaam (DAR), Tanzania",
        },
        { value: "Darwin(DRW), Australia", label: "Darwin(DRW), Australia" },
        {
            value: "Dayton (DAY), United States",
            label: "Dayton (DAY), United States",
        },
        { value: "Delhi (DEL), India", label: "Delhi (DEL), India" },
        {
            value: "Dembidollo (DEM), Ethiopia",
            label: "Dembidollo (DEM), Ethiopia",
        },
        {
            value: "Denpasar Bali (DPS), Indonesia",
            label: "Denpasar Bali (DPS), Indonesia",
        },
        {
            value: "Denver (DEN), United States",
            label: "Denver (DEN), United States",
        },
        {
            value: "Detroit (DTW), United States",
            label: "Detroit (DTW), United States",
        },
        {
            value: "Dire Dawa (DIR), Ethiopia",
            label: "Dire Dawa (DIR), Ethiopia",
        },
        { value: "Djibouti (JIB), Djibouti", label: "Djibouti (JIB), Djibouti" },
        { value: "Doha (DOH), Qatar", label: "Doha (DOH), Qatar" },
        { value: "Douala (DLA), Cameroon", label: "Douala (DLA), Cameroon" },
        { value: "Dresden (DRS), Germany", label: "Dresden (DRS), Germany" },
        { value: "Dubai (DXB), UAE", label: "Dubai (DXB), UAE" },
        {
            value: "Dubai Al Maktoum (DWC), UAE",
            label: "Dubai Al Maktoum (DWC), UAE",
        },
        { value: "Dublin (DUB), Ireland", label: "Dublin (DUB), Ireland" },
        {
            value: "Durban (DUR), South Africa",
            label: "Durban (DUR), South Africa",
        },
        {
            value: "Dusseldorf (DUS), Germany",
            label: "Dusseldorf (DUS), Germany",
        },
        {
            value: "Edinburgh (EDI) , United Kingdom",
            label: "Edinburgh (EDI) , United Kingdom",
        },
        {
            value: "Edmonton Intl AB (YEG), Canada",
            label: "Edmonton Intl AB (YEG), Canada",
        },
        {
            value: "El Paso, TX (ELP), United States",
            label: "El Paso, TX (ELP), United States",
        },
        { value: "Entebbe (EBB), Uganda", label: "Entebbe (EBB), Uganda" },
        { value: "Enugu (ENU), Nigeria", label: "Enugu (ENU), Nigeria" },
        {
            value: "Florianopolis (FLN) , Brazil",
            label: "Florianopolis (FLN) , Brazil",
        },
        { value: "Fortaleza (FOR), Brazil", label: "Fortaleza (FOR), Brazil" },
        { value: "Frankfurt (FRA), Germany", label: "Frankfurt (FRA), Germany" },
        {
            value: "Freetown (FNA), Metropolitan Area,Sierra Leone",
            label: "Freetown (FNA), Metropolitan Area,Sierra Leone",
        },
        {
            value: "Friedrichshafen (FDH), Germany",
            label: "Friedrichshafen (FDH), Germany",
        },
        {
            value: "Ft Lauderdale (FLL), United States",
            label: "Ft Lauderdale (FLL), United States",
        },
        { value: "Fukuoka (FUK), Japan", label: "Fukuoka (FUK), Japan" },
        { value: "Funchal (FNC), Portugal", label: "Funchal (FNC), Portugal" },
        { value: "Gaborone (GBE), Botswana", label: "Gaborone (GBE), Botswana" },
        { value: "Gambella (GMB), Ethiopia", label: "Gambella (GMB), Ethiopia" },
        {
            value: "Garoua International Airport (GOU), Cameroon",
            label: "Garoua International Airport (GOU), Cameroon",
        },
        {
            value: "Gassim (ELQ), Saudi Arabia",
            label: "Gassim (ELQ), Saudi Arabia",
        },
        {
            value: "Geneva (GVA), Switzerland",
            label: "Geneva (GVA), Switzerland",
        },
        {
            value: "Glasgow (GLA), United Kingdom",
            label: "Glasgow (GLA), United Kingdom",
        },
        { value: "GOA (GOI), INDIA", label: "GOA (GOI), INDIA" },
        {
            value: "Goba, Bale - Goba (GOB), Ethiopia",
            label: "Goba, Bale - Goba (GOB), Ethiopia",
        },
        { value: "Gode (GDE), Ethiopia", label: "Gode (GDE), Ethiopia" },
        {
            value: "Goiania, Santa Genoveva Airport (GYN), Brazil",
            label: "Goiania, Santa Genoveva Airport (GYN), Brazil",
        },
        {
            value: "Goma (GOM), Democratic Republic of Congo",
            label: "Goma (GOM), Democratic Republic of Congo",
        },
        { value: "Gondar (GDQ), Ethiopia", label: "Gondar (GDQ), Ethiopia" },
        { value: "Gothenburg (GOT), Sweden", label: "Gothenburg (GOT), Sweden" },
        {
            value: "Grand Rapids (GRR), United States",
            label: "Grand Rapids (GRR), United States",
        },
        { value: "Graz (GRZ), Austria", label: "Graz (GRZ), Austria" },
        {
            value: "Sboro (GSO), United States",
            label: "Sboro (GSO), United States",
        },
        {
            value: "Bosaso (BSA), Somalia",
            label: "Bosaso (BSA), Somalia",
        },
        {
            value: "Ville Spartanburg  (GSP), United States",
            label: "Ville Spartanburg  (GSP), United States",
        },
        { value: "Guangzhou (CAN), China", label: "Guangzhou (CAN), China" },
        { value: "Haikou (HAK), China", label: "Haikou (HAK), China" },
        { value: "Hali (HAS), Saudi Arabia", label: "Hali (HAS), Saudi Arabia" },
        { value: "Halifax (YHZ), Canada", label: "Halifax (YHZ), Canada" },
        { value: "Hamburg (HAM), Germany", label: "Hamburg (HAM), Germany" },
        {
            value: "Hamilton Island(HTI), Australia",
            label: "Hamilton Island(HTI), Australia",
        },
        { value: "Hannover (HAJ), Germany", label: "Hannover (HAJ), Germany" },
        { value: "Hanoi(HAN), Vietnam", label: "Hanoi(HAN), Vietnam" },
        { value: "Harare (HRE), Zimbabwe", label: "Harare (HRE), Zimbabwe" },
        {
            value: "Hargeisa (HGA), Somaliland",
            label: "Hargeisa (HGA), Somaliland",
        },
        {
            value: "Harrisburg (MDT), United States",
            label: "Harrisburg (MDT), United States",
        },
        {
            value: "Hartford Sprngfld, CT (BDL), United States",
            label: "Hartford Sprngfld, CT (BDL), United States",
        },
        { value: "Hawassa (AWA), Ethiopia", label: "Hawassa (AWA), Ethiopia" },
        { value: "Helsinki (HEL), Finland", label: "Helsinki (HEL), Finland" },
        {
            value: "Ho Chi Minh City(SGN), Vietnam",
            label: "Ho Chi Minh City(SGN), Vietnam",
        },
        { value: "Hobart(HBA), Australia ", label: "Hobart(HBA), Australia " },
        { value: "Hong Kong (HKG), China", label: "Hong Kong (HKG), China" },
        {
            value: "Honolulu (HNL), United States",
            label: "Honolulu (HNL), United States",
        },
        {
            value: "Houston (HOU), United States",
            label: "Houston (HOU), United States",
        },
        {
            value: "Houston (IAH), United States",
            label: "Houston (IAH), United States",
        },
        { value: "Humera (HUE), Ethiopia", label: "Humera (HUE), Ethiopia" },
        {
            value: "Huntsville (HSV), United States",
            label: "Huntsville (HSV), United States",
        },
        { value: "Hyderabad(HYD), India", label: "Hyderabad(HYD), India" },
        { value: "Ibiza (IBZ), Spain", label: "Ibiza (IBZ), Spain" },
        { value: "Iguassu (IGU), Brazil", label: "Iguassu (IGU), Brazil" },
        {
            value: "Indianapolis (IND), United States",
            label: "Indianapolis (IND), United States",
        },
        { value: "Innsbruck (IOB), Austria", label: "Innsbruck (IOB), Austria" },
        {
            value: "Istanbul Ataturk (IST), Turkey",
            label: "Istanbul Ataturk (IST), Turkey",
        },
        {
            value: "Jacksonville (JAX), United States",
            label: "Jacksonville (JAX), United States",
        },
        { value: "Jakarta (CGK), Indonesia", label: "Jakarta (CGK), Indonesia" },
        { value: "Jakarta(JKT), Indonesia", label: "Jakarta(JKT), Indonesia" },
        {
            value: "Jazan (GIZ), Saudi Arabia",
            label: "Jazan (GIZ), Saudi Arabia",
        },
        {
            value: "Jeddah (JED), Saudi Arabia",
            label: "Jeddah (JED), Saudi Arabia",
        },
        { value: "Jijiga (JIJ), Ethiopia", label: "Jijiga (JIJ), Ethiopia" },
        { value: "Jimma (JIM), Ethiopia", label: "Jimma (JIM), Ethiopia" },
        { value: "Jinka (BCO), Ethiopia", label: "Jinka (BCO), Ethiopia" },
        {
            value: "Johannesburg (JNB), South Africa",
            label: "Johannesburg (JNB), South Africa",
        },
        {
            value: "Juazeiro Norte (JDO), Brazil",
            label: "Juazeiro Norte (JDO), Brazil",
        },
        { value: "Juba (JUB), South Sudan", label: "Juba (JUB), South Sudan" },
        {
            value: "Kabri Dar (ABK), Ethiopia",
            label: "Kabri Dar (ABK), Ethiopia",
        },
        { value: "Kaduna (KAD), Nigeria", label: "Kaduna (KAD), Nigeria" },
        { value: "Kano (KAN), Nigeria", label: "Kano (KAN), Nigeria" },
        {
            value: "Kansas City (MCI), United States",
            label: "Kansas City (MCI), United States",
        },
        { value: "Khartoum (KRT), Sudan", label: "Khartoum (KRT), Sudan" },
        { value: "Kigali (KGL), Rwanda", label: "Kigali (KGL), Rwanda" },
        {
            value: "Kilimanjaro (JRO), Tanzania",
            label: "Kilimanjaro (JRO), Tanzania",
        },
        {
            value: "Kinshasa Ndjili (FIH), Democratic Republic of Congo",
            label: "Kinshasa Ndjili (FIH), Democratic Republic of Congo",
        },
        {
            value: "Kisangani (FKI), Democratic Republic of Congo",
            label: "Kisangani (FKI), Democratic Republic of Congo",
        },
        {
            value: "Klagenfurt (KGV) , Austria",
            label: "Klagenfurt (KGV) , Austria",
        },
        {
            value: "Knoxville (TYS), United States",
            label: "Knoxville (TYS), United States",
        },
        {
            value: "Koh Samui (USM), Thailand ",
            label: "Koh Samui (USM), Thailand ",
        },
        {
            value: "kombolcha (DSE), Ethiopia",
            label: "kombolcha (DSE), Ethiopia",
        },
        {
            value: "Kota Kinabalu (BKI), Malaysia",
            label: "Kota Kinabalu (BKI), Malaysia",
        },
        {
            value: "Kristiansand (KRS), Norway",
            label: "Kristiansand (KRS), Norway",
        },
        {
            value: "Kuala Lumpur (KUL), Malaysia",
            label: "Kuala Lumpur (KUL), Malaysia",
        },
        { value: "Kuching (KCH), Malaysia", label: "Kuching (KCH), Malaysia" },
        { value: "Kunming (KMG), China", label: "Kunming (KMG), China" },
        { value: "Kuwait (KWI), Kuwait", label: "Kuwait (KWI), Kuwait" },
        { value: "Lagos (LOS), Nigeria", label: "Lagos (LOS), Nigeria" },
        {
            value: "Lalibella (LLI), Ethiopia",
            label: "Lalibella (LLI), Ethiopia",
        },
        { value: "Langkawi (LGK), Malaysia", label: "Langkawi (LGK), Malaysia" },
        { value: "Larnaca (LCA), Cyprus", label: "Larnaca (LCA), Cyprus" },
        {
            value: "Las Vegas (LAS), United States",
            label: "Las Vegas (LAS), United States",
        },
        {
            value: "Launceston(LST) , Australia",
            label: "Launceston(LST) , Australia",
        },
        {
            value: "Leeds Bradford (LBA), United Kingdom",
            label: "Leeds Bradford (LBA), United Kingdom",
        },
        {
            value: "Leipzig Halle (LEJ), Germany",
            label: "Leipzig Halle (LEJ), Germany",
        },
        { value: "Libreville (LBV), Gabon", label: "Libreville (LBV), Gabon" },
        { value: "Lilongwe (LLW), Malawi", label: "Lilongwe (LLW), Malawi" },
        { value: "Linz (LNZ), Austria", label: "Linz (LNZ), Austria" },
        { value: "Lisbon (LIS), Portugal", label: "Lisbon (LIS), Portugal" },
        { value: "Lome (LFW), Togo", label: "Lome (LFW), Togo" },
        {
            value: "London (LON), United Kingdom",
            label: "London (LON), United Kingdom",
        },
        {
            value: "London Heathrow (LHR), United Kingdom",
            label: "London Heathrow (LHR), United Kingdom",
        },
        { value: "London On (YXU), CANADA", label: "London On (YXU), CANADA" },
        {
            value: "Londrina Airport (LDB), Brazil",
            label: "Londrina Airport (LDB), Brazil",
        },
        {
            value: "Los Angeles (LAX), United States",
            label: "Los Angeles (LAX), United States",
        },
        {
            value: "Louisville (SDF), United States",
            label: "Louisville (SDF), United States",
        },
        { value: "Luanda (LAD), Angola", label: "Luanda (LAD), Angola" },
        {
            value: "Lubumbashi (FBM), Democratic Republic of Congo",
            label: "Lubumbashi (FBM), Democratic Republic of Congo",
        },
        { value: "Lulea (LLA), Sweden", label: "Lulea (LLA), Sweden" },
        { value: "Lusaka (LUN), Zambia", label: "Lusaka (LUN), Zambia" },
        {
            value: "Lyon, Satolaos Saint Exupery Airport (LYS), France",
            label: "Lyon, Satolaos Saint Exupery Airport (LYS), France",
        },
        {
            value: "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil",
            label: "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil",
        },
        { value: "Madrid (MAD), Spain", label: "Madrid (MAD), Spain" },
        {
            value: "Mahe Island (SEZ), Seychelles",
            label: "Mahe Island (SEZ), Seychelles",
        },
        {
            value: "Malabo (SSG), Equatorial Guinea",
            label: "Malabo (SSG), Equatorial Guinea",
        },
        { value: "Malmo (MMA), Sweden", label: "Malmo (MMA), Sweden" },
        { value: "Manado (MDC), Indonesia", label: "Manado (MDC), Indonesia" },
        {
            value: "Manchester(MAN), United Kingdom",
            label: "Manchester(MAN), United Kingdom",
        },
        {
            value: "Manila (MNL), Philippines",
            label: "Manila (MNL), Philippines",
        },
        { value: "Maputo (MPM), Mozambique", label: "Maputo (MPM), Mozambique" },
        {
            value: "Marseille, Marignane Airport(MRS), France",
            label: "Marseille, Marignane Airport(MRS), France",
        },
        {
            value: "Mauritius (MRU), Mauritius",
            label: "Mauritius (MRU), Mauritius",
        },
        {
            value: "Mbuji Mayi (MJM), Democratic Republic of Congo",
            label: "Mbuji Mayi (MJM), Democratic Republic of Congo",
        },
        {
            value: "Medan Kuala Namu(KNO), Indonesia",
            label: "Medan Kuala Namu(KNO), Indonesia",
        },
        {
            value: "Medina (MED), Saudi Arabia",
            label: "Medina (MED), Saudi Arabia",
        },
        { value: "Mekelle (MQX), Ethiopia", label: "Mekelle (MQX), Ethiopia" },
        {
            value: "Melbourne (MEL), Australia",
            label: "Melbourne (MEL), Australia",
        },
        {
            value: "Memphis(MEM), United States",
            label: "Memphis(MEM), United States",
        },
        {
            value: "Miami (MIA), United States",
            label: "Miami (MIA), United States",
        },
        {
            value: "Milan Malpensa (MXP), Italy",
            label: "Milan Malpensa (MXP), Italy",
        },
        {
            value: "Minneapolis (MSP), United States",
            label: "Minneapolis (MSP), United States",
        },
        { value: "Mombassa (MBA), Kenya", label: "Mombassa (MBA), Kenya" },
        { value: "Moncton (YQM), Canada", label: "Moncton (YQM), Canada" },
        {
            value: "Monrovia (MLW), Republic of Liberia",
            label: "Monrovia (MLW), Republic of Liberia",
        },
        {
            value: "MONT TREMBLANT (YTM), Canada",
            label: "MONT TREMBLANT (YTM), Canada",
        },
        {
            value: "Montreal Trudeau (YUL), Canada",
            label: "Montreal Trudeau (YUL), Canada",
        },
        { value: "Moroni (HAH), Comoros", label: "Moroni (HAH), Comoros" },
        { value: "Moscow (MOW), Russia", label: "Moscow (MOW), Russia" },
        { value: "Muenster (FMO), Germany", label: "Muenster (FMO), Germany" },
        { value: "Mumbai (BOM), India", label: "Mumbai (BOM), India" },
        { value: "Munich (MUC), Germany", label: "Munich (MUC), Germany" },
        { value: "Muscat (MCT), Oman", label: "Muscat (MCT), Oman" },
        { value: "Nagoya (NGO), Japan", label: "Nagoya (NGO), Japan" },
        {
            value: "Nairobi Kenyatta (NBO), Kenya",
            label: "Nairobi Kenyatta (NBO), Kenya",
        },
        {
            value: "Nashville (BNA), United States",
            label: "Nashville (BNA), United States",
        },
        {
            value: "Natal International Airport (NAT), Brazil",
            label: "Natal International Airport (NAT), Brazil",
        },
        {
            value: "Navegantes, Victor Konder Airport (NVT), Brazil",
            label: "Navegantes, Victor Konder Airport (NVT), Brazil",
        },
        { value: "N'djamena (NDJ), Chad", label: "N'djamena (NDJ), Chad" },
        { value: "Ndola (NLA), Zambia", label: "Ndola (NLA), Zambia" },
        {
            value: "New Orleans (MSY), United States",
            label: "New Orleans (MSY), United States",
        },
        {
            value: "NEW YORK (JFK), United States",
            label: "NEW YORK (JFK), United States",
        },
        {
            value: "New York (LGA), United States",
            label: "New York (LGA), United States",
        },
        {
            value: "Newark (EWR), United States",
            label: "Newark (EWR), United States",
        },
        {
            value: "Newark (NYC), United States",
            label: "Newark (NYC), United States",
        },
        { value: "Niamey (NIM), Niger", label: "Niamey (NIM), Niger" },
        {
            value: "Norfolk VA Beach (ORF), United States",
            label: "Norfolk VA Beach (ORF), United States",
        },
        {
            value: "Nosy Be (NOS), Madagascar",
            label: "Nosy Be (NOS), Madagascar",
        },
        { value: "Nuremberg (NUE), Germany", label: "Nuremberg (NUE), Germany" },
        {
            value: "Oakland (OAK), United States",
            label: "Oakland (OAK), United States",
        },
        { value: "Okinawa (OKA), Japan", label: "Okinawa (OKA), Japan" },
        {
            value: "Oklahoma City (OKC), United States",
            label: "Oklahoma City (OKC), United States",
        },
        {
            value: "Orange County (SNA), United States",
            label: "Orange County (SNA), United States",
        },
        {
            value: "Orlando (MCO), United States",
            label: "Orlando (MCO), United States",
        },
        { value: "Osaka Itami (ITM), Japan", label: "Osaka Itami (ITM), Japan" },
        {
            value: "Osaka Kansai (KIX), Japan",
            label: "Osaka Kansai (KIX), Japan",
        },
        { value: "Oslo (OSL), Norway", label: "Oslo (OSL), Norway" },
        {
            value: "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ",
            label: "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ",
        },
        { value: "Ottawa On (YOW), Canada", label: "Ottawa On (YOW), Canada" },
        {
            value: "Ouagadougou (OUA), Burkina Faso",
            label: "Ouagadougou (OUA), Burkina Faso",
        },
        {
            value: "Palembang (PLM), Indonesia",
            label: "Palembang (PLM), Indonesia",
        },
        {
            value: "Palma De Mallorca (PMI), Spain",
            label: "Palma De Mallorca (PMI), Spain",
        },
        {
            value: "Paris De Gaulle (CDG), France",
            label: "Paris De Gaulle (CDG), France",
        },
        {
            value: "Pekanbaru (PKU), Indonesia",
            label: "Pekanbaru (PKU), Indonesia",
        },
        { value: "Penang (PEN), Malaysia", label: "Penang (PEN), Malaysia" },
        { value: "Perth (PER), Australia", label: "Perth (PER), Australia" },
        {
            value: "Philadelphia (PHL), United States",
            label: "Philadelphia (PHL), United States",
        },
        {
            value: "Phoenix (PHX), United States",
            label: "Phoenix (PHX), United States",
        },
        { value: "Phuket (HKT), Thailand", label: "Phuket (HKT), Thailand" },
        {
            value: "Pittsburgh (PIT), United States",
            label: "Pittsburgh (PIT), United States",
        },
        {
            value: "Pointe Noire (PNR), Republic of Congo",
            label: "Pointe Noire (PNR), Republic of Congo",
        },
        {
            value: "Portland ME (PWM), United States",
            label: "Portland ME (PWM), United States",
        },
        {
            value: "Portland OR (PDX), United States",
            label: "Portland OR (PDX), United States",
        },
        {
            value: "Porto Alegre (POA), Brazil",
            label: "Porto Alegre (POA), Brazil",
        },
        {
            value: "Porto Portugal (OPO), Portugal",
            label: "Porto Portugal (OPO), Portugal",
        },
        {
            value: "Porto Santo (PXO), Portugal",
            label: "Porto Santo (PXO), Portugal",
        },
        {
            value: "Prague - Ruzyne Airport(PRG), Czech Republic",
            label: "Prague - Ruzyne Airport(PRG), Czech Republic",
        },
        {
            value: "Praya Lombok (LOP), Indonesia",
            label: "Praya Lombok (LOP), Indonesia",
        },
        {
            value: "Providence (PVD), United States",
            label: "Providence (PVD), United States",
        },
        { value: "Pusan (PUS), South Korea", label: "Pusan (PUS), South Korea" },
        {
            value: "Qaisumah (AQI), Saudi Arabia",
            label: "Qaisumah (AQI), Saudi Arabia",
        },
        { value: "Québec (YQB), Canada", label: "Québec (YQB), Canada" },
        {
            value: "Railway Germany (QYG), Germany",
            label: "Railway Germany (QYG), Germany",
        },
        {
            value: "Raleigh Durham (RDU), United States",
            label: "Raleigh Durham (RDU), United States",
        },
        { value: "Recife (REC), Brazil", label: "Recife (REC), Brazil" },
        { value: "Regina (YQR), Canada", label: "Regina (YQR), Canada" },
        {
            value: "Richmond (RIC), United States",
            label: "Richmond (RIC), United States",
        },
        {
            value: "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil",
            label: "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil",
        },
        {
            value: "Rio Janeiro Gig (GIG), Brazil",
            label: "Rio Janeiro Gig (GIG), Brazil",
        },
        {
            value: "Riyadh (RUH), Saudi Arabia",
            label: "Riyadh (RUH), Saudi Arabia",
        },
        {
            value: "Roanoke (ROA), United States",
            label: "Roanoke (ROA), United States",
        },
        {
            value: "Rochester (ROC), United States",
            label: "Rochester (ROC), United States",
        },
        { value: "Rome (ROM), Italy", label: "Rome (ROM), Italy" },
        {
            value: "Rome Fiumicino (FCO), Italy",
            label: "Rome Fiumicino (FCO), Italy",
        },
        {
            value: "Rotterdam (RTM), Netherlands",
            label: "Rotterdam (RTM), Netherlands",
        },
        {
            value: "Sacramento (SMF), United States",
            label: "Sacramento (SMF), United States",
        },
        { value: "Salvador (SSA), Brazil", label: "Salvador (SSA), Brazil" },
        { value: "Salzburg (SZG), Austria", label: "Salzburg (SZG), Austria" },
        {
            value: "San Antonio (SAT), United States",
            label: "San Antonio (SAT), United States",
        },
        {
            value: "San Diego (SAN), United States",
            label: "San Diego (SAN), United States",
        },
        {
            value: "San Francisco (SFO), United States",
            label: "San Francisco (SFO), United States",
        },
        {
            value: "Santa Maria Azors (SMA), Portugal",
            label: "Santa Maria Azors (SMA), Portugal",
        },
        { value: "Sao Paulo (GRU), Brazil", label: "Sao Paulo (GRU), Brazil" },
        { value: "Sao Paulo (SAO), Brazil", label: "Sao Paulo (SAO), Brazil" },
        {
            value: "Sapporo Chitose (CTS), Japan",
            label: "Sapporo Chitose (CTS), Japan",
        },
        {
            value: "Saskatoon SK (YXE), Canada",
            label: "Saskatoon SK (YXE), Canada",
        },
        {
            value: "Savannah (SAV), United States",
            label: "Savannah (SAV), United States",
        },
        {
            value: "Seattle Tacoma (SEA), United States",
            label: "Seattle Tacoma (SEA), United States",
        },
        {
            value: "Semarang (SRG), Indonesia",
            label: "Semarang (SRG), Indonesia",
        },
        { value: "Semera (SZE), Ethiopia", label: "Semera (SZE), Ethiopia" },
        { value: "Seoul (ICN), South Korea", label: "Seoul (ICN), South Korea" },
        { value: "Seoul (SEL), South Korea", label: "Seoul (SEL), South Korea" },
        { value: "Shanghai (PVG), China", label: "Shanghai (PVG), China" },
        { value: "Shanghai (SHA), China", label: "Shanghai (SHA), China" },
        { value: "Shantou (SWA), China", label: "Shantou (SWA), China" },
        { value: "Shenzhen (SZX), China", label: "Shenzhen (SZX), China" },
        { value: "Shire (SHC), Ethiopia", label: "Shire (SHC), Ethiopia" },
        {
            value: "Singapore (SIN), Singapore",
            label: "Singapore (SIN), Singapore",
        },
        { value: "Skelleftea (SFT), Sweden", label: "Skelleftea (SFT), Sweden" },
        {
            value: "Sofia Airport (SOF), Bulgaria",
            label: "Sofia Airport (SOF), Bulgaria",
        },
        {
            value: "ST Johns NF (YYT), Canada",
            label: "ST Johns NF (YYT), Canada",
        },
        {
            value: "St. Louis (STL), United States",
            label: "St. Louis (STL), United States",
        },
        {
            value: "St. Polten (POK), Austria",
            label: "St. Polten (POK), Austria",
        },
        {
            value: "State College (SCE), United States",
            label: "State College (SCE), United States",
        },
        { value: "Stavanger(SVG), Norway", label: "Stavanger(SVG), Norway" },
        { value: "Stockholm (STO), Sweden", label: "Stockholm (STO), Sweden" },
        {
            value: "Stockholm Arlanda (ARN), Sweden",
            label: "Stockholm Arlanda (ARN), Sweden",
        },
        { value: "Stuttgart (STR), Germany", label: "Stuttgart (STR), Germany" },
        {
            value: "Sultan Hasanuddin (UPG), Indonesia",
            label: "Sultan Hasanuddin (UPG), Indonesia",
        },
        {
            value: "Sunshine Coast(MCY), Australia",
            label: "Sunshine Coast(MCY), Australia",
        },
        {
            value: "Surabaya (SUB), Indonesia",
            label: "Surabaya (SUB), Indonesia",
        },
        { value: "Sydney (SYD), Australia", label: "Sydney (SYD), Australia" },
        {
            value: "Syracuse (SYR), United States",
            label: "Syracuse (SYR), United States",
        },
        {
            value: "Tabuk (TUU), Saudi Arabia",
            label: "Tabuk (TUU), Saudi Arabia",
        },
        { value: "Taif (TIF), Saudi Arabia", label: "Taif (TIF), Saudi Arabia" },
        {
            value: "Tampa (TPA), United States",
            label: "Tampa (TPA), United States",
        },
        { value: "Tel Aviv (TLV), Israel", label: "Tel Aviv (TLV), Israel" },
        { value: "Tete (TET), Mozambique", label: "Tete (TET), Mozambique" },
        {
            value: "The Hague (HAG), Netherlands",
            label: "The Hague (HAG), Netherlands",
        },
        { value: "Tokyo (TYO), Japan", label: "Tokyo (TYO), Japan" },
        {
            value: "Tokyo Haneda (HND), Japan",
            label: "Tokyo Haneda (HND), Japan",
        },
        {
            value: "Tokyo Narita (NRT), Japan",
            label: "Tokyo Narita (NRT), Japan",
        },
        { value: "Toronto (YTO), Canada", label: "Toronto (YTO), Canada" },
        { value: "Toronto (YYZ), Canada", label: "Toronto (YYZ), Canada" },
        { value: "Toulouse (TLS), France", label: "Toulouse (TLS), France" },
        {
            value: "Townsville(TSV), Australia",
            label: "Townsville(TSV), Australia",
        },
        {
            value: "Trondheim (TRD), Norway",
            label: "Trondheim (TRD), Norway"
        },
        {
            value: "Turku (TKU), Finland",
            label: "Turku (TKU), Finland"
        },
        {
            value: "Umea (UME), Sweden",
            label: "Umea (UME), Sweden"
        },
        {
            value: "Vaasa (VAA), Finland",
            label: "Vaasa (VAA), Finland"
        },
        {
            value: "Valencia (VLC), Spain",
            label: "Valencia (VLC), Spain"
        },
        {
            value: "Vancouver BC (YVR), Canada",
            label: "Vancouver BC (YVR), Canada",
        },
        {
            value: "Victoria (YYJ), Canada",
            label: "Victoria (YYJ), Canada"
        },
        {
            value: "Victoria Falls (VFA), Zimbabwe",
            label: "Victoria Falls (VFA), Zimbabwe",
        },
        {
            value: "Vienna (VIE), Austria",
            label: "Vienna (VIE), Austria"
        },
        {
            value: "Vigo (VGO), Spain",
            label: "Vigo (VGO), Spain"
        },
        {
            value: "Vilnius, Vilnius Airport(VNO), Lithuania",
            label: "Vilnius, Vilnius Airport(VNO), Lithuania",
        },
        {
            value: "Warsaw, Chopin Okecie Airport (WAW), Poland",
            label: "Warsaw, Chopin Okecie Airport (WAW), Poland",
        },
        {
            value: "Washington (WAS), United States",
            label: "Washington (WAS), United States",
        },
        {
            value: "Washington Dulles (IAD), United States",
            label: "Washington Dulles (IAD), United States",
        },
        {
            value: "Windhoek (WDH), Namibia",
            label: "Windhoek (WDH), Namibia"
        },
        {
            value: "Winnipeg MB (YWG), Canada",
            label: "Winnipeg MB (YWG), Canada",
        },
        {
            value: "Wuhan (WUH), China",
            label: "Wuhan (WUH), China"
        },
        {
            value: "Xiamen (XMN), China",
            label: "Xiamen (XMN), China"
        },
        {
            value: "Yaounde Nsimalen (NSI), Cameroon",
            label: "Yaounde Nsimalen (NSI), Cameroon",
        },
        {
            value: "Yogyakarta (JOG), Indonesia",
            label: "Yogyakarta (JOG), Indonesia",
        },
        {
            value: "Yundum Airport (BJL), Gambia",
            label: "Yundum Airport (BJL), Gambia",
        },
        {
            value: "Zanzibar (ZNZ), Tanzania",
            label: "Zanzibar (ZNZ), Tanzania"
        },
        {
            value: "Zurich (ZRH), Switzerland",
            label: "Zurich (ZRH), Switzerland",
        },
    ];
}
