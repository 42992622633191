
//#8520 Master Data service

export class DataService {


  static airportsFrom = {
    "Aarhus (AAR), Denmark": null,

    "Aberdeen Dyce (ABZ)), United Kingdom": null,

    "Abha (AHB), Saudi Arabia": null,

    "Abidjan (ABJ), Côte d'Ivoire": null,

    //"Abuja (ABV), Nigeria": null,

    //"Accra (ACC), Ghana": null,

    "Addis Ababa (ADD), Ethiopia": null,

    "Adelaide (ADL), Australia": null,

    "Albany Intl (ALB), United States": null,

    "Alicante (ALC), Spain": null,

    "Amman (AMM), Jordan": null,

    "Amsterdam (AMS), Netherland": null,

    "Antananarivo (TNR), Madagascar": null,

    "Aomori(AOJ), Japan": null,

    "Arba Minch (AMH), Ethiopia": null,

    "Asmara (ASM), Eritrea": null,

    "Assosa (ASO), Ethiopia": null,

    "Athens (ATH), Greece": null,

    "Atlanta (ATL), United States": null,

    "Auckland Airport (AKL), New Zealand": null,

    "Austin (AUS), United States": null,

    "Avalon AU(AVV), Australia": null,

    "Axum (AXU), Ethiopia": null,

    "Ayers Rock(AYQ), Australia ": null,

    "Bahar Dar (BJR), Ethiopia": null,

    "Bahrain (BAH), Bahrain": null,

    "Ballina(BNK), Australia": null,

    "Bamako (BKO), Mali": null,

    "Bandung (BDO), Indonesia": null,

    "Bangkok Suvarnabhumi (BKK), Thailand": null,

    "Bangui (BGF), Central African Republic": null,

    "Barcelona (BCN), Spain": null,

    "Beijing (BJS), China": null,

    "Beijing (PEK), China": null,

    "Beirut (BEY), Lebanon": null,

    "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil": null,

    "Bergen (BGO), Norway": null,

    "Bergenz (XGZ), Austria": null,

    "Berlin Tegel (TXL), Germany": null,

    "Bilbao (BIO), Spain": null,

    "Billund (BLL), Denmark": null,

    "Birmingham (BHX) , United Kingdom": null,

    "Blantyre (BLZ), Malawi": null,

    "Boston (BOS), United States": null,

    "Boulder (BLD), United States": null,

    "Brasilia (BSB), Brazil": null,

    "Brazzaville (BZV), Republic of Congo": null,

    "Bremen (BRE), Germany": null,

    "Brisbane (BNE), Australia": null,

    "Brussels (BRU), Belgium": null,

    "Bucharest (OTP), Romania": null,

    "Budapest, Liszt Ferenc Airport (BUD), Hungary": null,

    "Buenos Aires (EZE), Argentina": null,

    "Buffalo (BUF), United States": null,

    "Bujumbura (BJM), Burundi": null,

    "Burlington (BTV), United States": null,

    "Cairo (CAI), Egypt": null,

    "Calgary Intl AB (YYC), Canada": null,

    "Campo Grande International Airport (CGR), Brazil": null,

    "Cape Town (CPT), South Africa": null,

    "Cardiff (CWL) , United Kingdom": null,

    "Changsha (CSX), China": null,

    "Charleston (CHS), United States": null,

    "Charleston (CRW), United States": null,

    "Charlotte (CLT), United States": null,

    "Charlottesville (CHO), United States": null,

    "Chengdu (CTU), China": null,

    "Chiang Mai (CNX), Thailand": null,

    "Chicago Ohare (ORD), United States": null,

    "Chongqing (CKG), China": null,

    "Christchurch Airport (CHC), New Zealand": null,

    "Cincinnati (CVG), United States": null,

    "Cleveland (CLE), United States": null,

    "Columbia (CAE), United States": null,

    "Columbus (CMH), United States": null,

    "Conakry (CKY), Guinea": null,

    "Copenhagen (CPH), Denmark": null,

    "Coruna (LCG), Spain": null,

    "Cotonou (COO), Benin": null,

    "Cuiaba (CGB), Brazil": null,

    "Curitiba (CWB), Brazil": null,

    "Dakar Blaise Diagne Airport (DSS), Senegal": null,

    "Dakar(DKR), Senegal": null,

    "Dalian (DLC), China": null,

    "Dallas FT Worth , TX (DFW), United States": null,

    "Dammam (DMM), Saudi Arabia": null,

    "Dar Es Salaam (DAR), Tanzania": null,

    "Darwin(DRW), Australia": null,

    "Dayton (DAY), United States": null,

    "Delhi (DEL), India": null,

    "Dembidollo (DEM), Ethiopia": null,

    "Denpasar Bali (DPS), Indonesia": null,

    "Denver (DEN), United States": null,

    "Detroit (DTW), United States": null,

    "Dire Dawa (DIR), Ethiopia": null,

    "Djibouti (JIB), Djibouti": null,

    "Doha (DOH), Qatar": null,

    "Douala (DLA), Cameroon": null,

    "Dresden (DRS), Germany": null,

    "Dubai (DXB), UAE": null,

    "Dubai Al Maktoum (DWC), UAE": null,

    "Dublin (DUB), Ireland": null,

    "Durban (DUR), South Africa": null,

    "Dusseldorf (DUS), Germany": null,

    "Edinburgh (EDI) , United Kingdom": null,

    "Edmonton Intl AB (YEG), Canada": null,

    "El Paso, TX (ELP), United States": null,

    "Entebbe (EBB), Uganda": null,

    //"Enugu (ENU), Nigeria": null,

    "Florianopolis (FLN) , Brazil": null,

    "Fortaleza (FOR), Brazil": null,

    "Frankfurt (FRA), Germany": null,

    "Freetown (FNA), Metropolitan Area,Sierra Leone": null,

    "Friedrichshafen (FDH), Germany": null,

    "Ft Lauderdale (FLL), United States": null,

    "Fukuoka (FUK), Japan": null,

    "Funchal (FNC), Portugal": null,

    "Gaborone (GBE), Botswana": null,

    "Gambella (GMB), Ethiopia": null,

    "Garoua International Airport (GOU), Cameroon": null,

    "Gassim (ELQ), Saudi Arabia": null,

    "Geneva (GVA), Switzerland": null,

    "Glasgow (GLA), United Kingdom": null,

    "GOA (GOI), INDIA": null,

    "Goba, Bale - Goba (GOB), Ethiopia": null,

    "Gode (GDE), Ethiopia": null,

    "Goiania, Santa Genoveva Airport (GYN), Brazil": null,

    "Goma (GOM), Democratic Republic of Congo": null,

    "Gondar (GDQ), Ethiopia": null,

    "Gothenburg (GOT), Sweden": null,

    "Grand Rapids (GRR), United States": null,

    "Graz (GRZ), Austria": null,

    "#60ac5dsboro (GSO), United States": null,

    "#60ac5dville Spartanburg (GSP), United States": null,

    "Guangzhou (CAN), China": null,

    "Haikou (HAK), China": null,

    "Hali (HAS), Saudi Arabia": null,

    "Halifax (YHZ), Canada": null,

    "Hamburg (HAM), Germany": null,

    "Hamilton Island(HTI), Australia": null,

    "Hannover (HAJ), Germany": null,

    "Hanoi(HAN), Vietnam": null,

    "Harare (HRE), Zimbabwe": null,

    "Hargeisa (HGA), Somaliland": null,

    "Harrisburg (MDT), United States": null,

    "Hartford Sprngfld, CT (BDL), United States": null,

    "Hawassa (AWA), Ethiopia": null,

    "Helsinki (HEL), Finland": null,

    "Ho Chi Minh City(SGN), Vietnam": null,

    "Hobart(HBA), Australia ": null,

    "Hong Kong (HKG), China": null,

    "Honolulu (HNL), United States": null,

    "Houston (HOU), United States": null,

    "Houston (IAH), United States": null,

    "Humera (HUE), Ethiopia": null,

    "Huntsville (HSV), United States": null,

    "Hyderabad(HYD), India": null,

    "Ibiza (IBZ), Spain": null,

    "Iguassu (IGU), Brazil": null,

    "Indianapolis (IND), United States": null,

    "Innsbruck (IOB), Austria": null,

    "Istanbul Ataturk (IST), Turkey": null,

    "Jacksonville (JAX), United States": null,

    "Jakarta (CGK), Indonesia": null,

    "Jakarta(JKT), Indonesia": null,

    "Jazan (GIZ), Saudi Arabia": null,

    "Jeddah (JED), Saudi Arabia": null,

    "Jijiga (JIJ), Ethiopia": null,

    "Jimma (JIM), Ethiopia": null,

    "Jinka (BCO), Ethiopia": null,

    "Johannesburg (JNB), South Africa": null,

    "Juazeiro Norte (JDO), Brazil": null,

    "Juba (JUB), South Sudan": null,

    "Kabri Dar (ABK), Ethiopia": null,

    //"Kaduna (KAD), Nigeria": null,

    //"Kano (KAN), Nigeria": null,

    "Kansas City (MCI), United States": null,

    "Khartoum (KRT), Sudan": null,

    "Kigali (KGL), Rwanda": null,

    "Kilimanjaro (JRO), Tanzania": null,

    "Kinshasa Ndjili (FIH), Democratic Republic of Congo": null,

    "Kisangani (FKI), Democratic Republic of Congo": null,

    "Klagenfurt (KGV) , Austria": null,

    "Knoxville (TYS), United States": null,

    "Koh Samui (USM), Thailand ": null,

    "kombolcha (DSE), Ethiopia": null,

    "Kota Kinabalu (BKI), Malaysia": null,

    "Kristiansand (KRS), Norway": null,

    "Kuala Lumpur (KUL), Malaysia": null,

    "Kuching (KCH), Malaysia": null,

    "Kunming (KMG), China": null,

    "Kuwait (KWI), Kuwait": null,

    //"Lagos (LOS), Nigeria": null,

    "Lalibella (LLI), Ethiopia": null,

    "Langkawi (LGK), Malaysia": null,

    "Larnaca (LCA), Cyprus": null,

    "Las Vegas (LAS), United States": null,

    "Launceston(LST) , Australia": null,

    "Leeds Bradford (LBA), United Kingdom": null,

    "Leipzig Halle (LEJ), Germany": null,

    "Libreville (LBV), Gabon": null,

    "Lilongwe (LLW), Malawi": null,

    "Linz (LNZ), Austria": null,

    "Lisbon (LIS), Portugal": null,

    "Lome (LFW), Togo": null,

    "London (LON), United Kingdom": null,

    "London Heathrow (LHR), United Kingdom": null,

    "London On (YXU), CANADA": null,

    "Londrina Airport (LDB), Brazil": null,

    "Los Angeles (LAX), United States": null,

    "Louisville (SDF), United States": null,

    "Luanda (LAD), Angola": null,

    "Lubumbashi (FBM), Democratic Republic of Congo": null,

    "Lulea (LLA), Sweden": null,

    "Lusaka (LUN), Zambia": null,

    "Lyon, Satolaos Saint Exupery Airport (LYS), France": null,

    "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil": null,

    "Madrid (MAD), Spain": null,

    "Mahe Island (SEZ), Seychelles": null,

    "Malabo (SSG), Equatorial Guinea": null,

    "Malmo (MMA), Sweden": null,

    "Manado (MDC), Indonesia": null,

    "Manchester(MAN), United Kingdom": null,

    "Manila (MNL), Philippines": null,

    "Maputo (MPM), Mozambique": null,

    "Marseille, Marignane Airport(MRS), France": null,

    "Mauritius (MRU), Mauritius": null,

    "Mbuji Mayi (MJM), Democratic Republic of Congo": null,

    "Medan Kuala Namu(KNO), Indonesia": null,

    "Medina (MED), Saudi Arabia": null,

    "Mekelle (MQX), Ethiopia": null,

    "Melbourne (MEL), Australia": null,

    "Memphis(MEM), United States": null,

    "Miami (MIA), United States": null,

    "Milan Malpensa (MXP), Italy": null,

    "Minneapolis (MSP), United States": null,

    "Mombassa (MBA), Kenya": null,

    "Moncton (YQM), Canada": null,

    "Monrovia (MLW), Republic of Liberia": null,

    "MONT TREMBLANT (YTM), Canada": null,

    "Montreal Trudeau (YUL), Canada": null,

    "Moroni (HAH), Comoros": null,

    "Moscow (MOW), Russia": null,

    "Muenster (FMO), Germany": null,

    "Mumbai (BOM), India": null,

    "Munich (MUC), Germany": null,

    "Muscat (MCT), Oman": null,

    "Nagoya (NGO), Japan": null,

    "Nairobi Kenyatta (NBO), Kenya": null,

    "Nashville (BNA), United States": null,

    "Natal International Airport (NAT), Brazil": null,

    "Navegantes, Victor Konder Airport (NVT), Brazil": null,

    "N'djamena (NDJ), Chad": null,

    "Ndola (NLA), Zambia": null,

    "New Orleans (MSY), United States": null,

    "NEW YORK (JFK), United States": null,

    "New York (LGA), United States": null,

    "Newark (EWR), United States": null,

    "Newark (NYC), United States": null,

    "Niamey (NIM), Niger": null,

    "Norfolk VA Beach (ORF), United States": null,

    "Nosy Be (NOS), Madagascar": null,

    "Nuremberg (NUE), Germany": null,

    "Oakland (OAK), United States": null,

    "Okinawa (OKA), Japan": null,

    "Oklahoma City (OKC), United States": null,

    "Orange County (SNA), United States": null,

    "Orlando (MCO), United States": null,

    "Osaka Itami (ITM), Japan": null,

    "Osaka Kansai (KIX), Japan": null,

    "Oslo (OSL), Norway": null,

    "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ": null,

    "Ottawa On (YOW), Canada": null,

    "Ouagadougou (OUA), Burkina Faso": null,

    "Palembang (PLM), Indonesia": null,

    "Palma De Mallorca (PMI), Spain": null,

    "Paris De Gaulle (CDG), France": null,

    "Pekanbaru (PKU), Indonesia": null,

    "Penang (PEN), Malaysia": null,

    "Perth (PER), Australia": null,

    "Philadelphia (PHL), United States": null,

    "Phoenix (PHX), United States": null,

    "Phuket (HKT), Thailand": null,

    "Pittsburgh (PIT), United States": null,

    "Pointe Noire (PNR), Republic of Congo": null,

    "Portland ME (PWM), United States": null,

    "Portland OR (PDX), United States": null,

    "Porto Alegre (POA), Brazil": null,

    "Porto Portugal (OPO), Portugal": null,

    "Porto Santo (PXO), Portugal": null,

    "Prague - Ruzyne Airport(PRG), Czech Republic": null,

    "Praya Lombok (LOP), Indonesia": null,

    "Providence (PVD), United States": null,

    "Pusan (PUS), South Korea": null,

    "Qaisumah (AQI), Saudi Arabia": null,

    "Québec (YQB), Canada": null,

    "Railway Germany (QYG), Germany": null,

    "Raleigh Durham (RDU), United States": null,

    "Recife (REC), Brazil": null,

    "Regina (YQR), Canada": null,

    "Richmond (RIC), United States": null,

    "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil": null,

    "Rio Janeiro Gig (GIG), Brazil": null,

    "Riyadh (RUH), Saudi Arabia": null,

    "Roanoke (ROA), United States": null,

    "Rochester (ROC), United States": null,

    "Rome (ROM), Italy": null,

    "Rome Fiumicino (FCO), Italy": null,

    "Rotterdam (RTM), Netherlands": null,

    "Sacramento (SMF), United States": null,

    "Salvador (SSA), Brazil": null,

    "Salzburg (SZG), Austria": null,

    "San Antonio (SAT), United States": null,

    "San Diego (SAN), United States": null,

    "San Francisco (SFO), United States": null,

    "Santa Maria Azors (SMA), Portugal": null,

    "Sao Paulo (GRU), Brazil": null,

    "Sao Paulo (SAO), Brazil": null,

    "Sapporo Chitose (CTS), Japan": null,

    "Saskatoon SK (YXE), Canada": null,

    "Savannah (SAV), United States": null,

    "Seattle Tacoma (SEA), United States": null,

    "Semarang (SRG), Indonesia": null,

    "Semera (SZE), Ethiopia": null,

    "Seoul (ICN), South Korea": null,

    "Seoul (SEL), South Korea": null,

    "Shanghai (PVG), China": null,

    "Shanghai (SHA), China": null,

    "Shantou (SWA), China": null,

    "Shenzhen (SZX), China": null,

    "Shire (SHC), Ethiopia": null,

    "Singapore (SIN), Singapore": null,

    "Skelleftea (SFT), Sweden": null,

    "Sofia Airport (SOF), Bulgaria": null,

    "ST Johns NF (YYT), Canada": null,

    "St. Louis (STL), United States": null,

    "St. Polten (POK), Austria": null,

    "State College (SCE), United States": null,

    "Stavanger(SVG), Norway": null,

    "Stockholm (STO), Sweden": null,

    "Stockholm Arlanda (ARN), Sweden": null,

    "Stuttgart (STR), Germany": null,

    "Sultan Hasanuddin (UPG), Indonesia": null,

    "Sunshine Coast(MCY), Australia": null,

    "Surabaya (SUB), Indonesia": null,

    "Sydney (SYD), Australia": null,

    "Syracuse (SYR), United States": null,

    "Tabuk (TUU), Saudi Arabia": null,

    "Taif (TIF), Saudi Arabia": null,

    "Tampa (TPA), United States": null,

    "Tel Aviv (TLV), Israel": null,

    "Tete (TET), Mozambique": null,

    "The Hague (HAG), Netherlands": null,

    "Tokyo (TYO), Japan": null,

    "Tokyo Haneda (HND), Japan": null,

    "Tokyo Narita (NRT), Japan": null,

    "Toronto (YTO), Canada": null,

    "Toronto (YYZ), Canada": null,

    "Toulouse (TLS), France": null,

    "Townsville(TSV), Australia": null,

    "Trondheim (TRD), Norway": null,

    "Turku (TKU), Finland": null,

    "Umea (UME), Sweden": null,

    "Vaasa (VAA), Finland": null,

    "Valencia (VLC), Spain": null,

    "Vancouver BC (YVR), Canada": null,

    "Victoria (YYJ), Canada": null,

    "Victoria Falls (VFA), Zimbabwe": null,

    "Vienna (VIE), Austria": null,

    "Vigo (VGO), Spain": null,

    "Vilnius, Vilnius Airport(VNO), Lithuania": null,

    "Warsaw, Chopin Okecie Airport (WAW), Poland": null,

    "Washington (WAS), United States": null,

    "Washington Dulles (IAD), United States": null,

    "Windhoek (WDH), Namibia": null,

    "Winnipeg MB (YWG), Canada": null,

    "Wuhan (WUH), China": null,

    "Xiamen (XMN), China": null,

    "Yaounde Nsimalen (NSI), Cameroon": null,

    "Yogyakarta (JOG), Indonesia": null,

    "Yundum Airport (BJL), Gambia": null,

    "Zanzibar (ZNZ), Tanzania": null,

    "Zurich (ZRH), Switzerland": null,
  };

  static airports = {
    "Aarhus (AAR), Denmark": null,

    "Aberdeen Dyce (ABZ)), United Kingdom": null,

    "Abha (AHB), Saudi Arabia": null,

    "Abidjan (ABJ), Côte d'Ivoire": null,

    "Abuja (ABV), Nigeria": null,

    "Accra (ACC), Ghana": null,

    "Addis Ababa (ADD), Ethiopia": null,

    "Adelaide (ADL), Australia": null,

    "Albany Intl (ALB), United States": null,

    "Alicante (ALC), Spain": null,

    "Amman (AMM), Jordan": null,

    "Amsterdam (AMS), Netherland": null,

    "Antananarivo (TNR), Madagascar": null,

    "Aomori(AOJ), Japan": null,

    "Arba Minch (AMH), Ethiopia": null,

    "Asmara (ASM), Eritrea": null,

    "Assosa (ASO), Ethiopia": null,

    "Athens (ATH), Greece": null,

    "Atlanta (ATL), United States": null,

    "Auckland Airport (AKL), New Zealand": null,

    "Austin (AUS), United States": null,

    "Avalon AU(AVV), Australia": null,

    "Axum (AXU), Ethiopia": null,

    "Ayers Rock(AYQ), Australia ": null,

    "Bahar Dar (BJR), Ethiopia": null,

    "Bahrain (BAH), Bahrain": null,

    "Ballina(BNK), Australia": null,

    "Bamako (BKO), Mali": null,

    "Bandung (BDO), Indonesia": null,

    "Bangkok Suvarnabhumi (BKK), Thailand": null,

    "Bangui (BGF), Central African Republic": null,

    "Barcelona (BCN), Spain": null,

    "Beijing (BJS), China": null,

    "Beijing (PEK), China": null,

    "Beirut (BEY), Lebanon": null,

    "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil": null,

    "Bergen (BGO), Norway": null,

    "Bergenz (XGZ), Austria": null,

    "Berlin Tegel (TXL), Germany": null,

    "Bilbao (BIO), Spain": null,

    "Billund (BLL), Denmark": null,

    "Birmingham (BHX) , United Kingdom": null,

    "Blantyre (BLZ), Malawi": null,

    "Boston (BOS), United States": null,

    "Boulder (BLD), United States": null,

    "Brasilia (BSB), Brazil": null,

    "Brazzaville (BZV), Republic of Congo": null,

    "Bremen (BRE), Germany": null,

    "Brisbane (BNE), Australia": null,

    "Brussels (BRU), Belgium": null,

    "Bucharest (OTP), Romania": null,

    "Budapest, Liszt Ferenc Airport (BUD), Hungary": null,

    "Buenos Aires (EZE), Argentina": null,

    "Buffalo (BUF), United States": null,

    "Bujumbura (BJM), Burundi": null,

    "Burlington (BTV), United States": null,

    "Cairo (CAI), Egypt": null,

    "Calgary Intl AB (YYC), Canada": null,

    "Campo Grande International Airport (CGR), Brazil": null,

    "Cape Town (CPT), South Africa": null,

    "Cardiff (CWL) , United Kingdom": null,

    "Changsha (CSX), China": null,

    "Charleston (CHS), United States": null,

    "Charleston (CRW), United States": null,

    "Charlotte (CLT), United States": null,

    "Charlottesville (CHO), United States": null,

    "Chengdu (CTU), China": null,

    "Chiang Mai (CNX), Thailand": null,

    "Chicago Ohare (ORD), United States": null,

    "Chongqing (CKG), China": null,

    "Christchurch Airport (CHC), New Zealand": null,

    "Cincinnati (CVG), United States": null,

    "Cleveland (CLE), United States": null,

    "Columbia (CAE), United States": null,

    "Columbus (CMH), United States": null,

    "Conakry (CKY), Guinea": null,

    "Copenhagen (CPH), Denmark": null,

    "Coruna (LCG), Spain": null,

    "Cotonou (COO), Benin": null,

    "Cuiaba (CGB), Brazil": null,

    "Curitiba (CWB), Brazil": null,

    "Dakar Blaise Diagne Airport (DSS), Senegal": null,

    "Dakar(DKR), Senegal": null,

    "Dalian (DLC), China": null,

    "Dallas FT Worth , TX (DFW), United States": null,

    "Dammam (DMM), Saudi Arabia": null,

    "Dar Es Salaam (DAR), Tanzania": null,

    "Darwin(DRW), Australia": null,

    "Dayton (DAY), United States": null,

    "Delhi (DEL), India": null,

    "Dembidollo (DEM), Ethiopia": null,

    "Denpasar Bali (DPS), Indonesia": null,

    "Denver (DEN), United States": null,

    "Detroit (DTW), United States": null,

    "Dire Dawa (DIR), Ethiopia": null,

    "Djibouti (JIB), Djibouti": null,

    "Doha (DOH), Qatar": null,

    "Douala (DLA), Cameroon": null,

    "Dresden (DRS), Germany": null,

    "Dubai (DXB), UAE": null,

    "Dubai Al Maktoum (DWC), UAE": null,

    "Dublin (DUB), Ireland": null,

    "Durban (DUR), South Africa": null,

    "Dusseldorf (DUS), Germany": null,

    "Edinburgh (EDI) , United Kingdom": null,

    "Edmonton Intl AB (YEG), Canada": null,

    "El Paso, TX (ELP), United States": null,

    "Entebbe (EBB), Uganda": null,

    "Enugu (ENU), Nigeria": null,

    "Florianopolis (FLN) , Brazil": null,

    "Fortaleza (FOR), Brazil": null,

    "Frankfurt (FRA), Germany": null,

    "Freetown (FNA), Metropolitan Area,Sierra Leone": null,

    "Friedrichshafen (FDH), Germany": null,

    "Ft Lauderdale (FLL), United States": null,

    "Fukuoka (FUK), Japan": null,

    "Funchal (FNC), Portugal": null,

    "Gaborone (GBE), Botswana": null,

    "Gambella (GMB), Ethiopia": null,

    "Garoua International Airport (GOU), Cameroon": null,

    "Gassim (ELQ), Saudi Arabia": null,

    "Geneva (GVA), Switzerland": null,

    "Glasgow (GLA), United Kingdom": null,

    "GOA (GOI), INDIA": null,

    "Goba, Bale - Goba (GOB), Ethiopia": null,

    "Gode (GDE), Ethiopia": null,

    "Goiania, Santa Genoveva Airport (GYN), Brazil": null,

    "Goma (GOM), Democratic Republic of Congo": null,

    "Gondar (GDQ), Ethiopia": null,

    "Gothenburg (GOT), Sweden": null,

    "Grand Rapids (GRR), United States": null,

    "Graz (GRZ), Austria": null,

    "#60ac5dsboro (GSO), United States": null,

    "#60ac5dville Spartanburg (GSP), United States": null,

    "Guangzhou (CAN), China": null,

    "Haikou (HAK), China": null,

    "Hali (HAS), Saudi Arabia": null,

    "Halifax (YHZ), Canada": null,

    "Hamburg (HAM), Germany": null,

    "Hamilton Island(HTI), Australia": null,

    "Hannover (HAJ), Germany": null,

    "Hanoi(HAN), Vietnam": null,

    "Harare (HRE), Zimbabwe": null,

    "Hargeisa (HGA), Somaliland": null,

    "Harrisburg (MDT), United States": null,

    "Hartford Sprngfld, CT (BDL), United States": null,

    "Hawassa (AWA), Ethiopia": null,

    "Helsinki (HEL), Finland": null,

    "Ho Chi Minh City(SGN), Vietnam": null,

    "Hobart(HBA), Australia ": null,

    "Hong Kong (HKG), China": null,

    "Honolulu (HNL), United States": null,

    "Houston (HOU), United States": null,

    "Houston (IAH), United States": null,

    "Humera (HUE), Ethiopia": null,

    "Huntsville (HSV), United States": null,

    "Hyderabad(HYD), India": null,

    "Ibiza (IBZ), Spain": null,

    "Iguassu (IGU), Brazil": null,

    "Indianapolis (IND), United States": null,

    "Innsbruck (IOB), Austria": null,

    "Istanbul Ataturk (IST), Turkey": null,

    "Jacksonville (JAX), United States": null,

    "Jakarta (CGK), Indonesia": null,

    "Jakarta(JKT), Indonesia": null,

    "Jazan (GIZ), Saudi Arabia": null,

    "Jeddah (JED), Saudi Arabia": null,

    "Jijiga (JIJ), Ethiopia": null,

    "Jimma (JIM), Ethiopia": null,

    "Jinka (BCO), Ethiopia": null,

    "Johannesburg (JNB), South Africa": null,

    "Juazeiro Norte (JDO), Brazil": null,

    "Juba (JUB), South Sudan": null,

    "Kabri Dar (ABK), Ethiopia": null,

    "Kaduna (KAD), Nigeria": null,

    "Kano (KAN), Nigeria": null,

    "Kansas City (MCI), United States": null,

    "Khartoum (KRT), Sudan": null,

    "Kigali (KGL), Rwanda": null,

    "Kilimanjaro (JRO), Tanzania": null,

    "Kinshasa Ndjili (FIH), Democratic Republic of Congo": null,

    "Kisangani (FKI), Democratic Republic of Congo": null,

    "Klagenfurt (KGV) , Austria": null,

    "Knoxville (TYS), United States": null,

    "Koh Samui (USM), Thailand ": null,

    "kombolcha (DSE), Ethiopia": null,

    "Kota Kinabalu (BKI), Malaysia": null,

    "Kristiansand (KRS), Norway": null,

    "Kuala Lumpur (KUL), Malaysia": null,

    "Kuching (KCH), Malaysia": null,

    "Kunming (KMG), China": null,

    "Kuwait (KWI), Kuwait": null,

    "Lagos (LOS), Nigeria": null,

    "Lalibella (LLI), Ethiopia": null,

    "Langkawi (LGK), Malaysia": null,

    "Larnaca (LCA), Cyprus": null,

    "Las Vegas (LAS), United States": null,

    "Launceston(LST) , Australia": null,

    "Leeds Bradford (LBA), United Kingdom": null,

    "Leipzig Halle (LEJ), Germany": null,

    "Libreville (LBV), Gabon": null,

    "Lilongwe (LLW), Malawi": null,

    "Linz (LNZ), Austria": null,

    "Lisbon (LIS), Portugal": null,

    "Lome (LFW), Togo": null,

    "London (LON), United Kingdom": null,

    "London Heathrow (LHR), United Kingdom": null,

    "London On (YXU), CANADA": null,

    "Londrina Airport (LDB), Brazil": null,

    "Los Angeles (LAX), United States": null,

    "Louisville (SDF), United States": null,

    "Luanda (LAD), Angola": null,

    "Lubumbashi (FBM), Democratic Republic of Congo": null,

    "Lulea (LLA), Sweden": null,

    "Lusaka (LUN), Zambia": null,

    "Lyon, Satolaos Saint Exupery Airport (LYS), France": null,

    "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil": null,

    "Madrid (MAD), Spain": null,

    "Mahe Island (SEZ), Seychelles": null,

    "Malabo (SSG), Equatorial Guinea": null,

    "Malmo (MMA), Sweden": null,

    "Manado (MDC), Indonesia": null,

    "Manchester(MAN), United Kingdom": null,

    "Manila (MNL), Philippines": null,

    "Maputo (MPM), Mozambique": null,

    "Marseille, Marignane Airport(MRS), France": null,

    "Mauritius (MRU), Mauritius": null,

    "Mbuji Mayi (MJM), Democratic Republic of Congo": null,

    "Medan Kuala Namu(KNO), Indonesia": null,

    "Medina (MED), Saudi Arabia": null,

    "Mekelle (MQX), Ethiopia": null,

    "Melbourne (MEL), Australia": null,

    "Memphis(MEM), United States": null,

    "Miami (MIA), United States": null,

    "Milan Malpensa (MXP), Italy": null,

    "Minneapolis (MSP), United States": null,

    "Mombassa (MBA), Kenya": null,

    "Moncton (YQM), Canada": null,

    "Monrovia (MLW), Republic of Liberia": null,

    "MONT TREMBLANT (YTM), Canada": null,

    "Montreal Trudeau (YUL), Canada": null,

    "Moroni (HAH), Comoros": null,

    "Moscow (MOW), Russia": null,

    "Muenster (FMO), Germany": null,

    "Mumbai (BOM), India": null,

    "Munich (MUC), Germany": null,

    "Muscat (MCT), Oman": null,

    "Nagoya (NGO), Japan": null,

    "Nairobi Kenyatta (NBO), Kenya": null,

    "Nashville (BNA), United States": null,

    "Natal International Airport (NAT), Brazil": null,

    "Navegantes, Victor Konder Airport (NVT), Brazil": null,

    "N'djamena (NDJ), Chad": null,

    "Ndola (NLA), Zambia": null,

    "New Orleans (MSY), United States": null,

    "NEW YORK (JFK), United States": null,

    "New York (LGA), United States": null,

    "Newark (EWR), United States": null,

    "Newark (NYC), United States": null,

    "Niamey (NIM), Niger": null,

    "Norfolk VA Beach (ORF), United States": null,

    "Nosy Be (NOS), Madagascar": null,

    "Nuremberg (NUE), Germany": null,

    "Oakland (OAK), United States": null,

    "Okinawa (OKA), Japan": null,

    "Oklahoma City (OKC), United States": null,

    "Orange County (SNA), United States": null,

    "Orlando (MCO), United States": null,

    "Osaka Itami (ITM), Japan": null,

    "Osaka Kansai (KIX), Japan": null,

    "Oslo (OSL), Norway": null,

    "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ": null,

    "Ottawa On (YOW), Canada": null,

    "Ouagadougou (OUA), Burkina Faso": null,

    "Palembang (PLM), Indonesia": null,

    "Palma De Mallorca (PMI), Spain": null,

    "Paris De Gaulle (CDG), France": null,

    "Pekanbaru (PKU), Indonesia": null,

    "Penang (PEN), Malaysia": null,

    "Perth (PER), Australia": null,

    "Philadelphia (PHL), United States": null,

    "Phoenix (PHX), United States": null,

    "Phuket (HKT), Thailand": null,

    "Pittsburgh (PIT), United States": null,

    "Pointe Noire (PNR), Republic of Congo": null,

    "Portland ME (PWM), United States": null,

    "Portland OR (PDX), United States": null,

    "Porto Alegre (POA), Brazil": null,

    "Porto Portugal (OPO), Portugal": null,

    "Porto Santo (PXO), Portugal": null,

    "Prague - Ruzyne Airport(PRG), Czech Republic": null,

    "Praya Lombok (LOP), Indonesia": null,

    "Providence (PVD), United States": null,

    "Pusan (PUS), South Korea": null,

    "Qaisumah (AQI), Saudi Arabia": null,

    "Québec (YQB), Canada": null,

    "Railway Germany (QYG), Germany": null,

    "Raleigh Durham (RDU), United States": null,

    "Recife (REC), Brazil": null,

    "Regina (YQR), Canada": null,

    "Richmond (RIC), United States": null,

    "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil": null,

    "Rio Janeiro Gig (GIG), Brazil": null,

    "Riyadh (RUH), Saudi Arabia": null,

    "Roanoke (ROA), United States": null,

    "Rochester (ROC), United States": null,

    "Rome (ROM), Italy": null,

    "Rome Fiumicino (FCO), Italy": null,

    "Rotterdam (RTM), Netherlands": null,

    "Sacramento (SMF), United States": null,

    "Salvador (SSA), Brazil": null,

    "Salzburg (SZG), Austria": null,

    "San Antonio (SAT), United States": null,

    "San Diego (SAN), United States": null,

    "San Francisco (SFO), United States": null,

    "Santa Maria Azors (SMA), Portugal": null,

    "Sao Paulo (GRU), Brazil": null,

    "Sao Paulo (SAO), Brazil": null,

    "Sapporo Chitose (CTS), Japan": null,

    "Saskatoon SK (YXE), Canada": null,

    "Savannah (SAV), United States": null,

    "Seattle Tacoma (SEA), United States": null,

    "Semarang (SRG), Indonesia": null,

    "Semera (SZE), Ethiopia": null,

    "Seoul (ICN), South Korea": null,

    "Seoul (SEL), South Korea": null,

    "Shanghai (PVG), China": null,

    "Shanghai (SHA), China": null,

    "Shantou (SWA), China": null,

    "Shenzhen (SZX), China": null,

    "Shire (SHC), Ethiopia": null,

    "Singapore (SIN), Singapore": null,

    "Skelleftea (SFT), Sweden": null,

    "Sofia Airport (SOF), Bulgaria": null,

    "ST Johns NF (YYT), Canada": null,

    "St. Louis (STL), United States": null,

    "St. Polten (POK), Austria": null,

    "State College (SCE), United States": null,

    "Stavanger(SVG), Norway": null,

    "Stockholm (STO), Sweden": null,

    "Stockholm Arlanda (ARN), Sweden": null,

    "Stuttgart (STR), Germany": null,

    "Sultan Hasanuddin (UPG), Indonesia": null,

    "Sunshine Coast(MCY), Australia": null,

    "Surabaya (SUB), Indonesia": null,

    "Sydney (SYD), Australia": null,

    "Syracuse (SYR), United States": null,

    "Tabuk (TUU), Saudi Arabia": null,

    "Taif (TIF), Saudi Arabia": null,

    "Tampa (TPA), United States": null,

    "Tel Aviv (TLV), Israel": null,

    "Tete (TET), Mozambique": null,

    "The Hague (HAG), Netherlands": null,

    "Tokyo (TYO), Japan": null,

    "Tokyo Haneda (HND), Japan": null,

    "Tokyo Narita (NRT), Japan": null,

    "Toronto (YTO), Canada": null,

    "Toronto (YYZ), Canada": null,

    "Toulouse (TLS), France": null,

    "Townsville(TSV), Australia": null,

    "Trondheim (TRD), Norway": null,

    "Turku (TKU), Finland": null,

    "Umea (UME), Sweden": null,

    "Vaasa (VAA), Finland": null,

    "Valencia (VLC), Spain": null,

    "Vancouver BC (YVR), Canada": null,

    "Victoria (YYJ), Canada": null,

    "Victoria Falls (VFA), Zimbabwe": null,

    "Vienna (VIE), Austria": null,

    "Vigo (VGO), Spain": null,

    "Vilnius, Vilnius Airport(VNO), Lithuania": null,

    "Warsaw, Chopin Okecie Airport (WAW), Poland": null,

    "Washington (WAS), United States": null,

    "Washington Dulles (IAD), United States": null,

    "Windhoek (WDH), Namibia": null,

    "Winnipeg MB (YWG), Canada": null,

    "Wuhan (WUH), China": null,

    "Xiamen (XMN), China": null,

    "Yaounde Nsimalen (NSI), Cameroon": null,

    "Yogyakarta (JOG), Indonesia": null,

    "Yundum Airport (BJL), Gambia": null,

    "Zanzibar (ZNZ), Tanzania": null,

    "Zurich (ZRH), Switzerland": null,
  };



  static fullairportsCollection = [
    "Aarhus (AAR), Denmark",
    "Aberdeen Dyce (ABZ)), United Kingdom",
    "Abha (AHB), Saudi Arabia",
    "Abidjan (ABJ), Côte d'Ivoire",
    "Abuja (ABV), Nigeria",
    "Accra (ACC), Ghana",
    "Addis Ababa (ADD), Ethiopia",
    "Adelaide (ADL), Australia",
    "Albany Intl (ALB), United States",
    "Alicante (ALC), Spain",
    "Amman (AMM), Jordan",
    "Amsterdam (AMS), Netherland",
    "Antananarivo (TNR), Madagascar",
    "Aomori(AOJ), Japan",
    "Arba Minch (AMH), Ethiopia",
    "Asmara (ASM), Eritrea",
    "Assosa (ASO), Ethiopia",
    "Athens (ATH), Greece",
    "Atlanta (ATL), United States",
    "Auckland Airport (AKL), New Zealand",
    "Austin (AUS), United States",
    "Avalon AU(AVV), Australia",
    "Axum (AXU), Ethiopia",
    "Ayers Rock(AYQ), Australia ",
    "Bahar Dar (BJR), Ethiopia",
    "Bahrain (BAH), Bahrain",
    "Ballina(BNK), Australia",
    "Bamako (BKO), Mali",
    "Bandung (BDO), Indonesia",
    "Bangkok Suvarnabhumi (BKK), Thailand",
    "Bangui (BGF), Central African Republic",
    "Barcelona (BCN), Spain",
    "Beijing (BJS), China",
    "Beijing (PEK), China",
    "Beirut (BEY), Lebanon",
    "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil",
    "Bergen (BGO), Norway",
    "Bergenz (XGZ), Austria",
    "Berlin Tegel (TXL), Germany",
    "Bilbao (BIO), Spain",
    "Billund (BLL), Denmark",
    "Birmingham (BHX) , United Kingdom",
    "Blantyre (BLZ), Malawi",
    "Boston (BOS), United States",
    "Boulder (BLD), United States",
    "Brasilia (BSB), Brazil",
    "Brazzaville (BZV), Republic of Congo",
    "Bremen (BRE), Germany",
    "Brisbane (BNE), Australia",
    "Brussels (BRU), Belgium",
    "Bucharest (OTP), Romania",
    "Budapest, Liszt Ferenc Airport (BUD), Hungary",
    "Buenos Aires (EZE), Argentina",
    "Buffalo (BUF), United States",
    "Bujumbura (BJM), Burundi",
    "Burlington (BTV), United States",
    "Cairo (CAI), Egypt",
    "Calgary Intl AB (YYC), Canada",
    "Campo Grande International Airport (CGR), Brazil",
    "Cape Town (CPT), South Africa",
    "Cardiff (CWL) , United Kingdom",
    "Changsha (CSX), China",
    "Charleston (CHS), United States",
    "Charleston (CRW), United States",
    "Charlotte (CLT), United States",
    "Charlottesville (CHO), United States",
    "Chengdu (CTU), China",
    "Chiang Mai (CNX), Thailand",
    "Chicago Ohare (ORD), United States",
    "Chongqing (CKG), China",
    "Christchurch Airport (CHC), New Zealand",
    "Cincinnati (CVG), United States",
    "Cleveland (CLE), United States",
    "Columbia (CAE), United States",
    "Columbus (CMH), United States",
    "Conakry (CKY), Guinea",
    "Copenhagen (CPH), Denmark",
    "Coruna (LCG), Spain",
    "Cotonou (COO), Benin",
    "Cuiaba (CGB), Brazil",
    "Curitiba (CWB), Brazil",
    "Dakar Blaise Diagne Airport (DSS), Senegal",
    "Dakar(DKR), Senegal",
    "Dalian (DLC), China",
    "Dallas FT Worth , TX (DFW), United States",
    "Dammam (DMM), Saudi Arabia",
    "Dar Es Salaam (DAR), Tanzania",
    "Darwin(DRW), Australia",
    "Dayton (DAY), United States",
    "Delhi (DEL), India",
    "Dembidollo (DEM), Ethiopia",
    "Denpasar Bali (DPS), Indonesia",
    "Denver (DEN), United States",
    "Detroit (DTW), United States",
    "Dire Dawa (DIR), Ethiopia",
    "Djibouti (JIB), Djibouti",
    "Doha (DOH), Qatar",
    "Douala (DLA), Cameroon",
    "Dresden (DRS), Germany",
    "Dubai (DXB), UAE",
    "Dubai Al Maktoum (DWC), UAE",
    "Dublin (DUB), Ireland",
    "Durban (DUR), South Africa",
    "Dusseldorf (DUS), Germany",
    "Edinburgh (EDI) , United Kingdom",
    "Edmonton Intl AB (YEG), Canada",
    "El Paso, TX (ELP), United States",
    "Entebbe (EBB), Uganda",
    "Enugu (ENU), Nigeria",
    "Florianopolis (FLN) , Brazil",
    "Fortaleza (FOR), Brazil",
    "Frankfurt (FRA), Germany",
    "Freetown (FNA), Metropolitan Area,Sierra Leone",
    "Friedrichshafen (FDH), Germany",
    "Ft Lauderdale (FLL), United States",
    "Fukuoka (FUK), Japan",
    "Funchal (FNC), Portugal",
    "Gaborone (GBE), Botswana",
    "Gambella (GMB), Ethiopia",
    "Garoua International Airport (GOU), Cameroon",
    "Gassim (ELQ), Saudi Arabia",
    "Geneva (GVA), Switzerland",
    "Glasgow (GLA), United Kingdom",
    "GOA (GOI), INDIA",
    "Goba, Bale - Goba (GOB), Ethiopia",
    "Gode (GDE), Ethiopia",
    "Goiania, Santa Genoveva Airport (GYN), Brazil",
    "Goma (GOM), Democratic Republic of Congo",
    "Gondar (GDQ), Ethiopia",
    "Gothenburg (GOT), Sweden",
    "Grand Rapids (GRR), United States",
    "Graz (GRZ), Austria",
    "#60ac5dsboro (GSO), United States",
    "#60ac5dville Spartanburg  (GSP), United States",
    "Guangzhou (CAN), China",
    "Haikou (HAK), China",
    "Hali (HAS), Saudi Arabia",
    "Halifax (YHZ), Canada",
    "Hamburg (HAM), Germany",
    "Hamilton Island(HTI), Australia",
    "Hannover (HAJ), Germany",
    "Hanoi(HAN), Vietnam",
    "Harare (HRE), Zimbabwe",
    "Hargeisa (HGA), Somaliland",
    "Harrisburg (MDT), United States",
    "Hartford Sprngfld, CT (BDL), United States",
    "Hawassa (AWA), Ethiopia",
    "Helsinki (HEL), Finland",
    "Ho Chi Minh City(SGN), Vietnam",
    "Hobart(HBA), Australia ",
    "Hong Kong (HKG), China",
    "Honolulu (HNL), United States",
    "Houston (HOU), United States",
    "Houston (IAH), United States",
    "Humera (HUE), Ethiopia",
    "Huntsville (HSV), United States",
    "Hyderabad(HYD), India",
    "Ibiza (IBZ), Spain",
    "Iguassu (IGU), Brazil",
    "Indianapolis (IND), United States",
    "Innsbruck (IOB), Austria",
    "Istanbul Ataturk (IST), Turkey",
    "Jacksonville (JAX), United States",
    "Jakarta (CGK), Indonesia",
    "Jakarta(JKT), Indonesia",
    "Jazan (GIZ), Saudi Arabia",
    "Jeddah (JED), Saudi Arabia",
    "Jijiga (JIJ), Ethiopia",
    "Jimma (JIM), Ethiopia",
    "Jinka (BCO), Ethiopia",
    "Johannesburg (JNB), South Africa",
    "Juazeiro Norte (JDO), Brazil",
    "Juba (JUB), South Sudan",
    "Kabri Dar (ABK), Ethiopia",
    "Kaduna (KAD), Nigeria",
    "Kano (KAN), Nigeria",
    "Kansas City (MCI), United States",
    "Khartoum (KRT), Sudan",
    "Kigali (KGL), Rwanda",
    "Kilimanjaro (JRO), Tanzania",
    "Kinshasa Ndjili (FIH), Democratic Republic of Congo",
    "Kisangani (FKI), Democratic Republic of Congo",
    "Klagenfurt (KGV) , Austria",
    "Knoxville (TYS), United States",
    "Koh Samui (USM), Thailand ",
    "kombolcha (DSE), Ethiopia",
    "Kota Kinabalu (BKI), Malaysia",
    "Kristiansand (KRS), Norway",
    "Kuala Lumpur (KUL), Malaysia",
    "Kuching (KCH), Malaysia",
    "Kunming (KMG), China",
    "Kuwait (KWI), Kuwait",
    "Lagos (LOS), Nigeria",
    "Lalibella (LLI), Ethiopia",
    "Langkawi (LGK), Malaysia",
    "Larnaca (LCA), Cyprus",
    "Las Vegas (LAS), United States",
    "Launceston(LST) , Australia",
    "Leeds Bradford (LBA), United Kingdom",
    "Leipzig Halle (LEJ), Germany",
    "Libreville (LBV), Gabon",
    "Lilongwe (LLW), Malawi",
    "Linz (LNZ), Austria",
    "Lisbon (LIS), Portugal",
    "Lome (LFW), Togo",
    "London (LON), United Kingdom",
    "London Heathrow (LHR), United Kingdom",
    "London On (YXU), CANADA",
    "Londrina Airport (LDB), Brazil",
    "Los Angeles (LAX), United States",
    "Louisville (SDF), United States",
    "Luanda (LAD), Angola",
    "Lubumbashi (FBM), Democratic Republic of Congo",
    "Lulea (LLA), Sweden",
    "Lusaka (LUN), Zambia",
    "Lyon, Satolaos Saint Exupery Airport (LYS), France",
    "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil",
    "Madrid (MAD), Spain",
    "Mahe Island (SEZ), Seychelles",
    "Malabo (SSG), Equatorial Guinea",
    "Malmo (MMA), Sweden",
    "Manado (MDC), Indonesia",
    "Manchester(MAN), United Kingdom",
    "Manila (MNL), Philippines",
    "Maputo (MPM), Mozambique",
    "Marseille, Marignane Airport(MRS), France",
    "Mauritius (MRU), Mauritius",
    "Mbuji Mayi (MJM), Democratic Republic of Congo",
    "Medan Kuala Namu(KNO), Indonesia",
    "Medina (MED), Saudi Arabia",
    "Mekelle (MQX), Ethiopia",
    "Melbourne (MEL), Australia",
    "Memphis(MEM), United States",
    "Miami (MIA), United States",
    "Milan Malpensa (MXP), Italy",
    "Minneapolis (MSP), United States",
    "Mombassa (MBA), Kenya",
    "Moncton (YQM), Canada",
    "Monrovia (MLW), Republic of Liberia",
    "MONT TREMBLANT (YTM), Canada",
    "Montreal Trudeau (YUL), Canada",
    "Moroni (HAH), Comoros",
    "Moscow (MOW), Russia",
    "Muenster (FMO), Germany",
    "Mumbai (BOM), India",
    "Munich (MUC), Germany",
    "Muscat (MCT), Oman",
    "Nagoya (NGO), Japan",
    "Nairobi Kenyatta (NBO), Kenya",
    "Nashville (BNA), United States",
    "Natal International Airport (NAT), Brazil",
    "Navegantes, Victor Konder Airport (NVT), Brazil",
    "N'djamena (NDJ), Chad",
    "Ndola (NLA), Zambia",
    "New Orleans (MSY), United States",
    "NEW YORK (JFK), United States",
    "New York (LGA), United States",
    "Newark (EWR), United States",
    "Newark (NYC), United States",
    "Niamey (NIM), Niger",
    "Norfolk VA Beach (ORF), United States",
    "Nosy Be (NOS), Madagascar",
    "Nuremberg (NUE), Germany",
    "Oakland (OAK), United States",
    "Okinawa (OKA), Japan",
    "Oklahoma City (OKC), United States",
    "Orange County (SNA), United States",
    "Orlando (MCO), United States",
    "Osaka Itami (ITM), Japan",
    "Osaka Kansai (KIX), Japan",
    "Oslo (OSL), Norway",
    "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ",
    "Ottawa On (YOW), Canada",
    "Ouagadougou (OUA), Burkina Faso",
    "Palembang (PLM), Indonesia",
    "Palma De Mallorca (PMI), Spain",
    "Paris De Gaulle (CDG), France",
    "Pekanbaru (PKU), Indonesia",
    "Penang (PEN), Malaysia",
    "Perth (PER), Australia",
    "Philadelphia (PHL), United States",
    "Phoenix (PHX), United States",
    "Phuket (HKT), Thailand",
    "Pittsburgh (PIT), United States",
    "Pointe Noire (PNR), Republic of Congo",
    "Portland ME (PWM), United States",
    "Portland OR (PDX), United States",
    "Porto Alegre (POA), Brazil",
    "Porto Portugal (OPO), Portugal",
    "Porto Santo (PXO), Portugal",
    "Prague - Ruzyne Airport(PRG), Czech Republic",
    "Praya Lombok (LOP), Indonesia",
    "Providence (PVD), United States",
    "Pusan (PUS), South Korea",
    "Qaisumah (AQI), Saudi Arabia",
    "Québec (YQB), Canada",
    "Railway Germany (QYG), Germany",
    "Raleigh Durham (RDU), United States",
    "Recife (REC), Brazil",
    "Regina (YQR), Canada",
    "Richmond (RIC), United States",
    "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil",
    "Rio Janeiro Gig (GIG), Brazil",
    "Riyadh (RUH), Saudi Arabia",
    "Roanoke (ROA), United States",
    "Rochester (ROC), United States",
    "Rome (ROM), Italy",
    "Rome Fiumicino (FCO), Italy",
    "Rotterdam (RTM), Netherlands",
    "Sacramento (SMF), United States",
    "Salvador (SSA), Brazil",
    "Salzburg (SZG), Austria",
    "San Antonio (SAT), United States",
    "San Diego (SAN), United States",
    "San Francisco (SFO), United States",
    "Santa Maria Azors (SMA), Portugal",
    "Sao Paulo (GRU), Brazil",
    "Sao Paulo (SAO), Brazil",
    "Sapporo Chitose (CTS), Japan",
    "Saskatoon SK (YXE), Canada",
    "Savannah (SAV), United States",
    "Seattle Tacoma (SEA), United States",
    "Semarang (SRG), Indonesia",
    "Semera (SZE), Ethiopia",
    "Seoul (ICN), South Korea",
    "Seoul (SEL), South Korea",
    "Shanghai (PVG), China",
    "Shanghai (SHA), China",
    "Shantou (SWA), China",
    "Shenzhen (SZX), China",
    "Shire (SHC), Ethiopia",
    "Singapore (SIN), Singapore",
    "Skelleftea (SFT), Sweden",
    "Sofia Airport (SOF), Bulgaria",
    "ST Johns NF (YYT), Canada",
    "St. Louis (STL), United States",
    "St. Polten (POK), Austria",
    "State College (SCE), United States",
    "Stavanger(SVG), Norway",
    "Stockholm (STO), Sweden",
    "Stockholm Arlanda (ARN), Sweden",
    "Stuttgart (STR), Germany",
    "Sultan Hasanuddin (UPG), Indonesia",
    "Sunshine Coast(MCY), Australia",
    "Surabaya (SUB), Indonesia",
    "Sydney (SYD), Australia",
    "Syracuse (SYR), United States",
    "Tabuk (TUU), Saudi Arabia",
    "Taif (TIF), Saudi Arabia",
    "Tampa (TPA), United States",
    "Tel Aviv (TLV), Israel",
    "Tete (TET), Mozambique",
    "The Hague (HAG), Netherlands",
    "Tokyo (TYO), Japan",
    "Tokyo Haneda (HND), Japan",
    "Tokyo Narita (NRT), Japan",
    "Toronto (YTO), Canada",
    "Toronto (YYZ), Canada",
    "Toulouse (TLS), France",
    "Townsville(TSV), Australia",
    "Trondheim (TRD), Norway",
    "Turku (TKU), Finland",
    "Umea (UME), Sweden",
    "Vaasa (VAA), Finland",
    "Valencia (VLC), Spain",
    "Vancouver BC (YVR), Canada",
    "Victoria (YYJ), Canada",
    "Victoria Falls (VFA), Zimbabwe",
    "Vienna (VIE), Austria",
    "Vigo (VGO), Spain",
    "Vilnius, Vilnius Airport(VNO), Lithuania",
    "Warsaw, Chopin Okecie Airport (WAW), Poland",
    "Washington (WAS), United States",
    "Washington Dulles (IAD), United States",
    "Windhoek (WDH), Namibia",
    "Winnipeg MB (YWG), Canada",
    "Wuhan (WUH), China",
    "Xiamen (XMN), China",
    "Yaounde Nsimalen (NSI), Cameroon",
    "Yogyakarta (JOG), Indonesia",
    "Yundum Airport (BJL), Gambia",
    "Zanzibar (ZNZ), Tanzania",
    "Zurich (ZRH), Switzerland"
  ];

  static countriesCode = [
    {
      "code": "7840",
      "name": "Abkhazia"
    },
    {
      "code": "93",
      "name": "Afghanistan"
    },
    {
      "code": "355",
      "name": "Albania"
    },
    {
      "code": "213",
      "name": "Algeria"
    },
    {
      "code": "1684",
      "name": "American Samoa"
    },
    {
      "code": "376",
      "name": "Andorra"
    },
    {
      "code": "244",
      "name": "Angola"
    },
    {
      "code": "1264",
      "name": "Anguilla"
    },
    {
      "code": "1268",
      "name": "Antigua and Barbuda"
    },
    {
      "code": "54",
      "name": "Argentina"
    },
    {
      "code": "374",
      "name": "Armenia"
    },
    {
      "code": "297",
      "name": "Aruba"
    },
    {
      "code": "247",
      "name": "Ascension"
    },
    {
      "code": "61",
      "name": "Australia"
    },
    {
      "code": "672",
      "name": "Australian External Territories"
    },
    {
      "code": "43",
      "name": "Austria"
    },
    {
      "code": "994",
      "name": "Azerbaijan"
    },
    {
      "code": "1242",
      "name": "Bahamas"
    },
    {
      "code": "973",
      "name": "Bahrain"
    },
    {
      "code": "880",
      "name": "Bangladesh"
    },
    {
      "code": "1246",
      "name": "Barbados"
    },
    {
      "code": "1268",
      "name": "Barbuda"
    },
    {
      "code": "375",
      "name": "Belarus"
    },
    {
      "code": "32",
      "name": "Belgium"
    },
    {
      "code": "501",
      "name": "Belize"
    },
    {
      "code": "229",
      "name": "Benin"
    },
    {
      "code": "1441",
      "name": "Bermuda"
    },
    {
      "code": "975",
      "name": "Bhutan"
    },
    {
      "code": "591",
      "name": "Bolivia"
    },
    {
      "code": "387",
      "name": "Bosnia and Herzegovina"
    },
    {
      "code": "267",
      "name": "Botswana"
    },
    {
      "code": "55",
      "name": "Brazil"
    },
    {
      "code": "246",
      "name": "British Indian Ocean Territory"
    },
    {
      "code": "1284",
      "name": "British Virgin Islands"
    },
    {
      "code": "673",
      "name": "Brunei"
    },
    {
      "code": "359",
      "name": "Bulgaria"
    },
    {
      "code": "226",
      "name": "Burkina Faso"
    },
    {
      "code": "257",
      "name": "Burundi"
    },
    {
      "code": "855",
      "name": "Cambodia"
    },
    {
      "code": "237",
      "name": "Cameroon"
    },
    {
      "code": "1",
      "name": "Canada"
    },
    {
      "code": "238",
      "name": "Cape Verde"
    },
    {
      "code": " 345",
      "name": "Cayman Islands"
    },
    {
      "code": "236",
      "name": "Central African Republic"
    },
    {
      "code": "235",
      "name": "Chad"
    },
    {
      "code": "56",
      "name": "Chile"
    },
    {
      "code": "86",
      "name": "China"
    },
    {
      "code": "61",
      "name": "Christmas Island"
    },
    {
      "code": "61",
      "name": "Cocos-Keeling Islands"
    },
    {
      "code": "57",
      "name": "Colombia"
    },
    {
      "code": "269",
      "name": "Comoros"
    },
    {
      "code": "242",
      "name": "Congo"
    },
    {
      "code": "243",
      "name": "Congo, Dem. Rep. of (Zaire)"
    },
    {
      "code": "682",
      "name": "Cook Islands"
    },
    {
      "code": "506",
      "name": "Costa Rica"
    },
    {
      "code": "385",
      "name": "Croatia"
    },
    {
      "code": "53",
      "name": "Cuba"
    },
    {
      "code": "599",
      "name": "Curacao"
    },
    {
      "code": "537",
      "name": "Cyprus"
    },
    {
      "code": "420",
      "name": "Czech Republic"
    },
    {
      "code": "45",
      "name": "Denmark"
    },
    {
      "code": "246",
      "name": "Diego Garcia"
    },
    {
      "code": "253",
      "name": "Djibouti"
    },
    {
      "code": "1767",
      "name": "Dominica"
    },
    {
      "code": "1809",
      "name": "Dominican Republic"
    },
    {
      "code": "670",
      "name": "East Timor"
    },
    {
      "code": "56",
      "name": "Easter Island"
    },
    {
      "code": "593",
      "name": "Ecuador"
    },
    {
      "code": "20",
      "name": "Egypt"
    },
    {
      "code": "503",
      "name": "El Salvador"
    },
    {
      "code": "240",
      "name": "Equatorial Guinea"
    },
    {
      "code": "291",
      "name": "Eritrea"
    },
    {
      "code": "372",
      "name": "Estonia"
    },
    {
      "code": "251",
      "name": "Ethiopia"
    },
    {
      "code": "500",
      "name": "Falkland Islands"
    },
    {
      "code": "298",
      "name": "Faroe Islands"
    },
    {
      "code": "679",
      "name": "Fiji"
    },
    {
      "code": "358",
      "name": "Finland"
    },
    {
      "code": "33",
      "name": "France"
    },
    {
      "code": "596",
      "name": "French Antilles"
    },
    {
      "code": "594",
      "name": "French Guiana"
    },
    {
      "code": "689",
      "name": "French Polynesia"
    },
    {
      "code": "241",
      "name": "Gabon"
    },
    {
      "code": "220",
      "name": "Gambia"
    },
    {
      "code": "995",
      "name": "Georgia"
    },
    {
      "code": "49",
      "name": "Germany"
    },
    {
      "code": "233",
      "name": "Ghana"
    },
    {
      "code": "350",
      "name": "Gibraltar"
    },
    {
      "code": "30",
      "name": "Greece"
    },
    {
      "code": "299",
      "name": "#60ac5dland"
    },
    {
      "code": "1 473",
      "name": "Grenada"
    },
    {
      "code": "590",
      "name": "Guadeloupe"
    },
    {
      "code": "1671",
      "name": "Guam"
    },
    {
      "code": "502",
      "name": "Guatemala"
    },
    {
      "code": "224",
      "name": "Guinea"
    },
    {
      "code": "245",
      "name": "Guinea-Bissau"
    },
    {
      "code": "595",
      "name": "Guyana"
    },
    {
      "code": "509",
      "name": "Haiti"
    },
    {
      "code": "504",
      "name": "Honduras"
    },
    {
      "code": "852",
      "name": "Hong Kong SAR China"
    },
    {
      "code": "36",
      "name": "Hungary"
    },
    {
      "code": "354",
      "name": "Iceland"
    },
    {
      "code": "91",
      "name": "India"
    },
    {
      "code": "62",
      "name": "Indonesia"
    },
    {
      "code": "98",
      "name": "Iran"
    },
    {
      "code": "964",
      "name": "Iraq"
    },
    {
      "code": "353",
      "name": "Ireland"
    },
    {
      "code": "972",
      "name": "Israel"
    },
    {
      "code": "39",
      "name": "Italy"
    },
    {
      "code": "225",
      "name": "Ivory Coast"
    },
    {
      "code": "1876",
      "name": "Jamaica"
    },
    {
      "code": "81",
      "name": "Japan"
    },
    {
      "code": "962",
      "name": "Jordan"
    },
    {
      "code": "7",
      "name": "Kazakhstan"
    },
    {
      "code": "254",
      "name": "Kenya"
    },
    {
      "code": "686",
      "name": "Kiribati"
    },
    {
      "code": "965",
      "name": "Kuwait"
    },
    {
      "code": "996",
      "name": "Kyrgyzstan"
    },
    {
      "code": "856",
      "name": "Laos"
    },
    {
      "code": "371",
      "name": "Latvia"
    },
    {
      "code": "961",
      "name": "Lebanon"
    },
    {
      "code": "266",
      "name": "Lesotho"
    },
    {
      "code": "231",
      "name": "Liberia"
    },
    {
      "code": "218",
      "name": "Libya"
    },
    {
      "code": "423",
      "name": "Liechtenstein"
    },
    {
      "code": "370",
      "name": "Lithuania"
    },
    {
      "code": "352",
      "name": "Luxembourg"
    },
    {
      "code": "853",
      "name": "Macau SAR China"
    },
    {
      "code": "389",
      "name": "Macedonia"
    },
    {
      "code": "261",
      "name": "Madagascar"
    },
    {
      "code": "265",
      "name": "Malawi"
    },
    {
      "code": "60",
      "name": "Malaysia"
    },
    {
      "code": "960",
      "name": "Maldives"
    },
    {
      "code": "223",
      "name": "Mali"
    },
    {
      "code": "356",
      "name": "Malta"
    },
    {
      "code": "692",
      "name": "Marshall Islands"
    },
    {
      "code": "596",
      "name": "Martinique"
    },
    {
      "code": "222",
      "name": "Mauritania"
    },
    {
      "code": "230",
      "name": "Mauritius"
    },
    {
      "code": "262",
      "name": "Mayotte"
    },
    {
      "code": "52",
      "name": "Mexico"
    },
    {
      "code": "691",
      "name": "Micronesia"
    },
    {
      "code": "1808",
      "name": "Midway Island"
    },
    {
      "code": "373",
      "name": "Moldova"
    },
    {
      "code": "377",
      "name": "Monaco"
    },
    {
      "code": "976",
      "name": "Mongolia"
    },
    {
      "code": "382",
      "name": "Montenegro"
    },
    {
      "code": "1664",
      "name": "Montserrat"
    },
    {
      "code": "212",
      "name": "Morocco"
    },
    {
      "code": "95",
      "name": "Myanmar"
    },
    {
      "code": "264",
      "name": "Namibia"
    },
    {
      "code": "674",
      "name": "Nauru"
    },
    {
      "code": "977",
      "name": "Nepal"
    },
    {
      "code": "31",
      "name": "Netherlands"
    },
    {
      "code": "599",
      "name": "Netherlands Antilles"
    },
    {
      "code": "1869",
      "name": "Nevis"
    },
    {
      "code": "687",
      "name": "New Caledonia"
    },
    {
      "code": "64",
      "name": "New Zealand"
    },
    {
      "code": "505",
      "name": "Nicaragua"
    },
    {
      "code": "227",
      "name": "Niger"
    },
    {
      "code": "234",
      "name": "Nigeria"
    },
    {
      "code": "683",
      "name": "Niue"
    },
    {
      "code": "672",
      "name": "Norfolk Island"
    },
    {
      "code": "850",
      "name": "North Korea"
    },
    {
      "code": "1670",
      "name": "Northern Mariana Islands"
    },
    {
      "code": "47",
      "name": "Norway"
    },
    {
      "code": "968",
      "name": "Oman"
    },
    {
      "code": "92",
      "name": "Pakistan"
    },
    {
      "code": "680",
      "name": "Palau"
    },
    {
      "code": "970",
      "name": "Palestinian Territory"
    },
    {
      "code": "507",
      "name": "Panama"
    },
    {
      "code": "675",
      "name": "Papua New Guinea"
    },
    {
      "code": "595",
      "name": "Paraguay"
    },
    {
      "code": "51",
      "name": "Peru"
    },
    {
      "code": "63",
      "name": "Philippines"
    },
    {
      "code": "48",
      "name": "Poland"
    },
    {
      "code": "351",
      "name": "Portugal"
    },
    {
      "code": "1787",
      "name": "Puerto Rico"
    },
    {
      "code": "974",
      "name": "Qatar"
    },
    {
      "code": "262",
      "name": "Reunion"
    },
    {
      "code": "40",
      "name": "Romania"
    },
    {
      "code": "7",
      "name": "Russia"
    },
    {
      "code": "250",
      "name": "Rwanda"
    },
    {
      "code": "685",
      "name": "Samoa"
    },
    {
      "code": "378",
      "name": "San Marino"
    },
    {
      "code": "966",
      "name": "Saudi Arabia"
    },
    {
      "code": "221",
      "name": "Senegal"
    },
    {
      "code": "381",
      "name": "Serbia"
    },
    {
      "code": "248",
      "name": "Seychelles"
    },
    {
      "code": "232",
      "name": "Sierra Leone"
    },
    {
      "code": "65",
      "name": "Singapore"
    },
    {
      "code": "421",
      "name": "Slovakia"
    },
    {
      "code": "386",
      "name": "Slovenia"
    },
    {
      "code": "677",
      "name": "Solomon Islands"
    },
    {
      "code": "27",
      "name": "South Africa"
    },
    {
      "code": "500",
      "name": "South Georgia and the South Sandwich Islands"
    },
    {
      "code": "82",
      "name": "South Korea"
    },
    {
      "code": "34",
      "name": "Spain"
    },
    {
      "code": "94",
      "name": "Sri Lanka"
    },
    {
      "code": "249",
      "name": "Sudan"
    },
    {
      "code": "597",
      "name": "Suriname"
    },
    {
      "code": "268",
      "name": "Swaziland"
    },
    {
      "code": "46",
      "name": "Sweden"
    },
    {
      "code": "41",
      "name": "Switzerland"
    },
    {
      "code": "963",
      "name": "Syria"
    },
    {
      "code": "886",
      "name": "Taiwan"
    },
    {
      "code": "992",
      "name": "Tajikistan"
    },
    {
      "code": "255",
      "name": "Tanzania"
    },
    {
      "code": "66",
      "name": "Thailand"
    },
    {
      "code": "670",
      "name": "Timor Leste"
    },
    {
      "code": "228",
      "name": "Togo"
    },
    {
      "code": "690",
      "name": "Tokelau"
    },
    {
      "code": "676",
      "name": "Tonga"
    },
    {
      "code": "1868",
      "name": "Trinidad and Tobago"
    },
    {
      "code": "216",
      "name": "Tunisia"
    },
    {
      "code": "90",
      "name": "Turkey"
    },
    {
      "code": "993",
      "name": "Turkmenistan"
    },
    {
      "code": "1649",
      "name": "Turks and Caicos Islands"
    },
    {
      "code": "688",
      "name": "Tuvalu"
    },
    {
      "code": "1340",
      "name": "U.S. Virgin Islands"
    },
    {
      "code": "256",
      "name": "Uganda"
    },
    {
      "code": "380",
      "name": "Ukraine"
    },
    {
      "code": "971",
      "name": "United Arab Emirates"
    },
    {
      "code": "44",
      "name": "United Kingdom"
    },
    {
      "code": "1",
      "name": "United States"
    },
    {
      "code": "598",
      "name": "Uruguay"
    },
    {
      "code": "998",
      "name": "Uzbekistan"
    },
    {
      "code": "678",
      "name": "Vanuatu"
    },
    {
      "code": "58",
      "name": "Venezuela"
    },
    {
      "code": "84",
      "name": "Vietnam"
    },
    {
      "code": "1 808",
      "name": "Wake Island"
    },
    {
      "code": "681",
      "name": "Wallis and Futuna"
    },
    {
      "code": "967",
      "name": "Yemen"
    },
    {
      "code": "260",
      "name": "Zambia"
    },
    {
      "code": "255",
      "name": "Zanzibar"
    },
    {
      "code": "263",
      "name": "Zimbabwe"
    }
  ];

  static countryListWithCode = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
  ];




  static getFullCityName(cityCode) {
    for (let i of this.fullairportsCollection) {
      if (i.includes(cityCode)) {
        return i;
      }
    }
  }
  static getCityCode(cityName) {
    var cityCode = cityName.substring(cityName.indexOf(')') - 3, cityName.indexOf(')'));
    return cityCode;
  }

  static getCountyList() {
    return this.countryListWithCode;
  }


}
