import React, { Component } from 'react'; 
import ImageGallery from 'react-image-gallery';
import "./gallaryImages.css";
class GallaryImagesSlide extends Component {
constructor(props){
 super(props);
    this.state = {
        images:[]
      };
} 
async componentDidMount(){
    if(this.props){  
 var images=[]
 if(this.props.imageType=="hotelImageList"){
     for(let i=0;i<this.props.post.length;i++){
         images.push({ 
                    original:"http://photos.hotelbeds.com/giata/bigger/"+this.props.post[i].path,
                    thumbnail: "http://photos.hotelbeds.com/giata/bigger/"+this.props.post[i].path
                 } )
     }
 }
 else{

    for(let i=0;i<this.props.post.length;i++){
        images.push({ 
                   original:this.props.post[i],
                   thumbnail: this.props.post[i]
                } )
    }
 } 
 this.setState({images:images})
    }
}

render() {
return(  
   <>  
   { (this.state.images.length <= 0)&& <h4>No Image Attached </h4> }
   {(this.state.images.length >0)&&<> 
     <ImageGallery items={this.state.images}  />
    </>
}
    </>
)  }
}

export default GallaryImagesSlide;