import React, { Component } from 'react';
class CampainTermsCondition extends Component {
    render() {
        return (
            <div> <p><strong>F</strong><strong>R</strong><strong>E</strong><strong>E PRIZE DRAW TERMS AND CONDITIONS</strong><strong>&nbsp;</strong></p>
                <ol type="1">
                    <li>By entering the prize draw you are agreeing to these prize draw terms and conditions.</li>
                    <li>The prize draw is being run by Ethiopian Airlines Group (hereinafter referred to as &ldquo;Ethiopian Holidays&rdquo;)<strong>&nbsp;</strong></li>
                </ol>
                <p><strong><span>Eligibility to enter</span></strong></p>
                <ol start="3" type="1">
                    <li>The prize draw is open to entrants over 18 years of age.</li>
                    <li>In entering the prize draw, you confirm that you are eligible to do so and eligible to claim any&nbsp;prize you may win. Ethiopian Holidays may require you to provide proof that you are eligible to enter the prize draw. </li>
                    <li>A maximum of one entry per individual is permitted.</li>
                    <li>Ethiopian Holidays employees and their immediate family members, and any person directly associated with administration of the prize draw are not eligible to participate.&nbsp;</li>
                </ol>
                <p><strong><span>How to enter</span></strong></p>
                <ol start="7" type="1">
                    <li>The prize draw will end on Midday on 5 February 2023. Entries after that time and date will not be included in the draw.</li>
                    <li>To&nbsp; &nbsp;enter&nbsp; &nbsp;the&nbsp; &nbsp;prize&nbsp; &nbsp;draw&nbsp; &nbsp;simply&nbsp; &nbsp;subscribe&nbsp; &nbsp;to&nbsp; &nbsp;our&nbsp; &nbsp;mailing&nbsp; &nbsp;list&nbsp; &nbsp;via&nbsp; &nbsp;our&nbsp; &nbsp;website: <a data-sf-ec-immutable=""
                        href="https://www.ethiopianholidays.com">https://www.ethiopianholidays.com</a></li>
                    <li>Comment or like Ethiopian Holidays Facebook Page (only one sentence required to enter) <a data-sf-ec-immutable="" href="https://www.facebook.com/EthiopianHolidays/">https://www.facebook.com/EthiopianHolidays/</a></li>
                    <li>Follow Ethiopian Holidays Instagram account and comment on a post for that account (only one sentence required to enter) <a data-sf-ec-immutable="" href="https://www.instagram.com/ethiopian_holidays/">https://www.instagram.com/ethiopian_holidays/</a></li>
                    <li
                    ><a data-sf-ec-immutable="" name="_Hlk61246987">Ethiopian Holidays will not accept any responsibility if contact      details provided are incomplete or inaccurate. Ethiopian Holidays reserves      the right to disqualify you if your conduct is contrary to the spirit or      intention of the prize draw or if you breach these terms and conditions.</a></li>
                </ol>

                <p><strong><span>T</span></strong><strong><span>he</span></strong><strong><span></span></strong><strong><span>p</span></strong><strong><span>r</span></strong><strong><span>i</span></strong><strong><span>z</span></strong><strong><span>e</span></strong></p>
                <ol
                    start="12" type="1">
                    <li>The prize will be two round trip economy class ticket from any Ethiopian Airlines destination to Addis Ababa with two nights stay at 5* Ethiopian Skylight Hotel including dinner the first day or &nbsp;medium size Ethiopian airlines aircraft model
                        for runner ups .</li>
                    <li>The winner will be chosen by random draw performed by computer process</li>
                    <li>The prize is non-exchangeable, non-transferable and no cash alternatives will be offered.</li>
                    <li>We reserve the right to substitute prizes with another prize of equal value if circumstances beyond our control make it necessary to do so.</li>
                    <li>The decision of Ethiopian Holidays regarding any aspect of the prize draw is final and binding and no correspondence will be entered about it.</li>
                </ol>

                <p><strong><span>W</span></strong><strong><span>i</span></strong><strong><span>nne</span></strong><strong><span>r announcement</span></strong></p>
                <ol
                    start="17" type="1">
                    <li>The winner will be notified after 06 February 2023 via the email provided during subscription.</li>
                    <li>If the winner does not respond to Ethiopian Holiday within 14 days of being notified they will lose their right to the prize, and Ethiopian Holidays reserves the right to choose and notify a new winner.</li>
                </ol>

                <p><strong><span>Receipt of the prize</span></strong></p>
                <ol start="19" type="1">
                    <li>Please allow 3 days to process the voucher for ticket and hotel.</li>
                    <li>Winner shall provide all required documents to issue the ticket.</li>
                    <li>Winner is responsible for visa and other related costs.</li>
                </ol>

                <p><strong><span>Data protection and publicity</span></strong></p>
                <ol start="22" type="1">
                    <li>You consent to any personal information you provide in entering the prize draw being used by Ethiopian Holidays for the purposes of administering the prize draw, and for those purposes as defined within our privacy notice.</li>
                    <li data-list="2"
                        data-level="1">All entrants may apply for details of the winning participant by contacting us at &nbsp;<span><a data-sf-ec-immutable="" href="mailto:ETH@ethiopianairlines.com">ETH@ethiopianairlines.com</a></span></li>
                    <li
                    >The winner agrees to the release of their first name and place of work with their pictures to any other prize draw participants if requested via Ethiopian Holidays.</li>
                    <li>An announcement of the winners first name and place of work will be made via Ethiopian Holidays&rsquo; Social Media sites.</li>
                    <li>All personal information shall be used in accordance with <a data-sf-ec-immutable="" href="https://www.ethiopianairlines.com/aa/privacy-policy">https://www.ethiopianairlines.com/aa/privacy-policy</a> </li>
                </ol>

                <p><strong><span>Limitation of Liability</span></strong></p>
                <ol start="27" type="1">
                    <li>Ethiopian Holidays will not in any circumstances be responsible or liable to compensate the winner or accept any liability for any damage, loss, injury, death or disappointment as a result of either participating in the prize draw or being
                        selected for a prize.</li>
                </ol>

                <p><strong><span>G</span></strong><strong><span>ene</span></strong><strong><span>r</span></strong><strong><span>a</span></strong><strong><span>l</span></strong></p>
                <ol
                    start="28" type="1">
                    <li>Ethiopian Holidays reserves the right to cancel the prize draw or amend these terms and conditions at any time, without prior notice.</li>
                    <li>The prize draw and these terms and conditions will be governed by Ethiopian law and any&nbsp;disputes will be subject to the exclusive jurisdiction of the courts of Ethiopia.</li>
                    <li>You acknowledge that this promotion is in no way sponsored, endorsed, administered by, or associated with Facebook, Instagram, or Twitter.</li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>

        );


    }


}

export default CampainTermsCondition;