import React, { Component } from "react";
import "./paymentFormCreditCard.css";
import CreditCardForm from "./creditCardForm.jsx";
import TermsAndCondition from "../../shared/termsAndCondition";

import {
  Modal,Alert,
  Spinner,
  Card,
  Form,
  InputGroup,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// export const faCalendar: IconDefinition;
import {
  faMapSigns,
  faUser,
  faCreditCard,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";

import { HttpService } from "../../../services/http/http-service";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import ProgressLoader from "../../shared/progress-loading/progress-loader";
import ShoppingCart from "../../ShoppingCart/shopping-cart";
import BreadCrumb from "../../shared/breadcrumb/breadcrumb";
import { CountryList } from "../../shared/master-data/country-list/country-list";
import FareRule from "../../shared/fareRule/fareRule";

//#8220 Doesn’t proceed to payment instruction page for alternative payment options
//#8227		"Unless it is a response from the API i.e. cancelled / rejected
//#8266		alternative payment option
// #8285 agree button
class PaymentFormCreditCard extends Component {
  constructor(props) {
    super(props);
    let tourPreference = localStorage.getItem("tourPreference");

    this.state = {
      agreeTermsCommand: false,
      validated: false,
      activePaymenyOption: "CreditCard",
      tourPreference: tourPreference,
      agreeTerms: false,
      agreeTermsCellPoint: false,
      showModal: false,
      isLoaded: false,
      isCreditCard: true,
      isCellPoint: false,
      initLocalPayment: {},
      FloCashPaymentOptions: [],
      CellPointPaymentOptions: [],
      localPaymentResponseMessage: null,
      paymentLoading: false,
      isLoadingPayment: false,

      FirstName: "",
      LastName: "",
      PostalCode: "",
      ResidenceCountry: "",
      AddressTwo: "",
      BillingAddress: "",
    };
  }

  async componentDidMount() {
    window.scrollTo({ top: 50, behavior: "smooth" });
    // if (true) {
    if (this.state.tourPreference === "flightPlusPackage") {
      this.getPaymentOptionsCellPoint();
      this.getPaymentOptionsFlowCash();
    }
  }

  handleSubmit = (event) => {
    if (this.state.isCellPoint === true) {
      const form = event.currentTarget;
      // console.log("event");
      // console.log(event);
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        event.stopPropagation();
        this.submitLocalPayment(this.state.initLocalPayment);
      }
      this.setState({ validated: true });
    } else {
      this.submitLocalPayment(this.state.initLocalPayment);
    }
  };

  submitLocalPayment(paymentInfo) {
    this.setState({
      isLoadingPayment: true,
      localPaymentResponseMessage: null,
    });

    // console.log("sending paymentInfo");
    // console.log(paymentInfo);

    let submitApiRelativePath = "/Payment/MakePayment";

    // let summaryApiRelativePath = "/Payment/api/PaymentService/Approved3D";

    let passengerDetails = JSON.parse(localStorage.getItem("passengerDetails"));
    let sessionId = localStorage.getItem("sessionId");

    let paymentInformationRequestObject;
    if (this.state.isCellPoint === false) {
      paymentInformationRequestObject = {
        sessionId: sessionId,
        CardCountry: paymentInfo.countries.countryShortCode, // "KE",
        PaymentMethod: "FLOCASH",
        FloCashPaymentId: paymentInfo.paymentID,
        PhoneNumber:
          passengerDetails.data.passengers.contact.phones[0].countryCode +
          passengerDetails.data.passengers.contact.phones[0].number, //PhoneNo
      };
    } else {
      paymentInformationRequestObject = {
        sessionId: sessionId,
        PaymentMethod: "CELLPOINT",
        CellPoint: {
          initializepayment: {
            transaction: {},
            clientinfo: {
              mobile: {
                operatorid: "60900",
                countryid:
                  passengerDetails.data.passengers.contact.phones[0]
                    .countryCode,
                text: passengerDetails.data.passengers.contact.phones[0].number,
              },
              email: passengerDetails.data.passengers.contact.emails[0],
            },
            billingData: {
              fullName: {
                text: this.state.FirstName + " " + this.state.LastName,
              },
              street: {
                text: this.state.BillingAddress,
              },
              PostalCode: {
                text: this.state.PostalCode,
              },
              State: {
                text: this.state.ResidenceCountry, // "Ethiopia"
              },
              City: {
                text: this.state.AddressTwo, //"Addis"
              },
              CountryId: "" + paymentInfo.countries.countryId, //"605"
            },
          },
          PaymentId: "" + paymentInfo.id,
        },
      };
    }

    // console.log("sending local payment");
    // console.log(paymentInformationRequestObject);
    //debugger

    HttpService.postServiceDefualt(
      paymentInformationRequestObject,
      submitApiRelativePath
    )
      .then((response) => {
        //debugger
        // console.log("response");
        // console.log(response);

        this.setState({ isLoadingPayment: false });
        let paymentApiResponse = response.data;

        if (
          paymentApiResponse.statusAPI == "SUCCESS" ||
          paymentApiResponse.statusAPI == "success"
        ) {
          this.setState({ localPaymentResponseMessage: "success" });
          localStorage.setItem(
            "summaryDetail",
            JSON.stringify(paymentApiResponse.summary)
          );
          window.location.replace("/Payment-Instruction");
        } else if (
          paymentApiResponse.statusAPI == "REDIRECT" ||
          paymentApiResponse.statusAPI == "redirect"
        ) {
          this.setState({ localPaymentResponseMessage: "REDIRECTING" });
          window.location.replace(paymentApiResponse.summary.redirectHtml);
        } else if (paymentApiResponse.statusAPI == "ERROR") {
          this.setState({
            localPaymentResponseMessage:
              " ERROR: Unable to process payment, Please try again",
          });
        } else if (paymentApiResponse.statusAPI == "WARNING") {
          this.setState({
            localPaymentResponseMessage:
              "WARNING: Unable to process payment, Please try again",
          });
        } else {
          this.setState({
            localPaymentResponseMessage:
              "Unable to proceed with the booking, Please start the booking again!",
          });
        }
      })
      .catch((error) => {
        this.setState({
          localPaymentResponseMessage:
            " ERROR: Unable to process payment, Please try again",
        });
        // console.log("responseData error2 ");
      });
  }

  getPaymentOptionsFlowCash() {
    let searchRequestObj = JSON.parse(localStorage.getItem("searchRequestObj"));

    // console.log("get Payment Options FloCash");
    const FloCashPaymentOptionPath =
      "/api/FloCashPaymentOption/ByDestination?DestinationCode=" +
      searchRequestObj.itineraryParts[0].from.code;
    HttpService.getServicePayment(FloCashPaymentOptionPath)
      .then((response) => {
        this.setState({ isLoading: false });
        let paymentApiResponse = response.data;
        // console.log(" flow cash ress");
        // console.log(paymentApiResponse);

        if (
          paymentApiResponse.statusAPI === "SUCCESS" ||
          paymentApiResponse.statusAPI === "Success" ||
          paymentApiResponse.statusAPI === "success"
        ) {
          // console.log("success flow cash");
          this.setState({
            FloCashPaymentOptions:
              paymentApiResponse.flocashPaymentOptionsResponses,
          });
        }
      })
      .catch((error) => {
        // console.log("responseData error2 ");
      });
  }

  getPaymentOptionsCellPoint() {
    let searchRequestObj = JSON.parse(localStorage.getItem("searchRequestObj"));
    // console.log("get Payment Options CellPoint");
    const CellPointPaymentOptionPath =
      "/api/CellPoint/ByDestination?DestinationCode=" +
      searchRequestObj.itineraryParts[0].from.code;
    // const CellPointPaymentOptionPath = "/MasterDataAPI/api/CellPoint/ByDestination?DestinationCode=DXB";
    HttpService.getServicePayment(CellPointPaymentOptionPath)
      .then((response) => {
        this.setState({ isLoading: false });
        let paymentApiResponse = response.data;
        // console.log(" CellPoint res");
        // console.log(paymentApiResponse);
        if (
          paymentApiResponse.statusAPI === "SUCCESS" ||
          paymentApiResponse.statusAPI === "Success" ||
          paymentApiResponse.statusAPI === "success"
        ) {
          this.setState({
            CellPointPaymentOptions:
              paymentApiResponse.cellPointPaymentOptionsResponses,
          });
        }
      })
      .catch((error) => {
        // console.log("responseData error2 ");
      });
  }

  render() {
    var { validated } = this.state;

    const countriesList = [
      { name: "Afghanistan", code: "AF" },
      { name: "Åland Islands", code: "AX" },
      { name: "Albania", code: "AL" },
      { name: "Algeria", code: "DZ" },
      { name: "American Samoa", code: "AS" },
      { name: "AndorrA", code: "AD" },
      { name: "Angola", code: "AO" },
      { name: "Anguilla", code: "AI" },
      { name: "Antarctica", code: "AQ" },
      { name: "Antigua and Barbuda", code: "AG" },
      { name: "Argentina", code: "AR" },
      { name: "Armenia", code: "AM" },
      { name: "Aruba", code: "AW" },
      { name: "Australia", code: "AU" },
      { name: "Austria", code: "AT" },
      { name: "Azerbaijan", code: "AZ" },
      { name: "Bahamas", code: "BS" },
      { name: "Bahrain", code: "BH" },
      { name: "Bangladesh", code: "BD" },
      { name: "Barbados", code: "BB" },
      { name: "Belarus", code: "BY" },
      { name: "Belgium", code: "BE" },
      { name: "Belize", code: "BZ" },
      { name: "Benin", code: "BJ" },
      { name: "Bermuda", code: "BM" },
      { name: "Bhutan", code: "BT" },
      { name: "Bolivia", code: "BO" },
      { name: "Bosnia and Herzegovina", code: "BA" },
      { name: "Botswana", code: "BW" },
      { name: "Bouvet Island", code: "BV" },
      { name: "Brazil", code: "BR" },
      { name: "British Indian Ocean Territory", code: "IO" },
      { name: "Brunei Darussalam", code: "BN" },
      { name: "Bulgaria", code: "BG" },
      { name: "Burkina Faso", code: "BF" },
      { name: "Burundi", code: "BI" },
      { name: "Cambodia", code: "KH" },
      { name: "Cameroon", code: "CM" },
      { name: "Canada", code: "CA" },
      { name: "Cape Verde", code: "CV" },
      { name: "Cayman Islands", code: "KY" },
      { name: "Central African Republic", code: "CF" },
      { name: "Chad", code: "TD" },
      { name: "Chile", code: "CL" },
      { name: "China", code: "CN" },
      { name: "Christmas Island", code: "CX" },
      { name: "Cocos (Keeling) Islands", code: "CC" },
      { name: "Colombia", code: "CO" },
      { name: "Comoros", code: "KM" },
      { name: "Congo", code: "CG" },
      { name: "Congo, The Democratic Republic of the", code: "CD" },
      { name: "Cook Islands", code: "CK" },
      { name: "Costa Rica", code: "CR" },
      { name: "Cote D'Ivoire", code: "CI" },
      { name: "Croatia", code: "HR" },
      { name: "Cuba", code: "CU" },
      { name: "Cyprus", code: "CY" },
      { name: "Czech Republic", code: "CZ" },
      { name: "Denmark", code: "DK" },
      { name: "Djibouti", code: "DJ" },
      { name: "Dominica", code: "DM" },
      { name: "Dominican Republic", code: "DO" },
      { name: "Ecuador", code: "EC" },
      { name: "Egypt", code: "EG" },
      { name: "El Salvador", code: "SV" },
      { name: "Equatorial Guinea", code: "GQ" },
      { name: "Eritrea", code: "ER" },
      { name: "Estonia", code: "EE" },
      { name: "Ethiopia", code: "ET" },
      { name: "Falkland Islands (Malvinas)", code: "FK" },
      { name: "Faroe Islands", code: "FO" },
      { name: "Fiji", code: "FJ" },
      { name: "Finland", code: "FI" },
      { name: "France", code: "FR" },
      { name: "French Guiana", code: "GF" },
      { name: "French Polynesia", code: "PF" },
      { name: "French Southern Territories", code: "TF" },
      { name: "Gabon", code: "GA" },
      { name: "Gambia", code: "GM" },
      { name: "Georgia", code: "GE" },
      { name: "Germany", code: "DE" },
      { name: "Ghana", code: "GH" },
      { name: "Gibraltar", code: "GI" },
      { name: "Greece", code: "GR" },
      { name: "Greenland", code: "GL" },
      { name: "Grenada", code: "GD" },
      { name: "Guadeloupe", code: "GP" },
      { name: "Guam", code: "GU" },
      { name: "Guatemala", code: "GT" },
      { name: "Guernsey", code: "GG" },
      { name: "Guinea", code: "GN" },
      { name: "Guinea-Bissau", code: "GW" },
      { name: "Guyana", code: "GY" },
      { name: "Haiti", code: "HT" },
      { name: "Heard Island and Mcdonald Islands", code: "HM" },
      { name: "Holy See (Vatican City State)", code: "VA" },
      { name: "Honduras", code: "HN" },
      { name: "Hong Kong", code: "HK" },
      { name: "Hungary", code: "HU" },
      { name: "Iceland", code: "IS" },
      { name: "India", code: "IN" },
      { name: "Indonesia", code: "ID" },
      { name: "Iran, Islamic Republic Of", code: "IR" },
      { name: "Iraq", code: "IQ" },
      { name: "Ireland", code: "IE" },
      { name: "Isle of Man", code: "IM" },
      { name: "Israel", code: "IL" },
      { name: "Italy", code: "IT" },
      { name: "Jamaica", code: "JM" },
      { name: "Japan", code: "JP" },
      { name: "Jersey", code: "JE" },
      { name: "Jordan", code: "JO" },
      { name: "Kazakhstan", code: "KZ" },
      { name: "Kenya", code: "KE" },
      { name: "Kiribati", code: "KI" },
      { name: "Korea, Democratic People'S Republic of", code: "KP" },
      { name: "Korea, Republic of", code: "KR" },
      { name: "Kuwait", code: "KW" },
      { name: "Kyrgyzstan", code: "KG" },
      { name: "Lao People'S Democratic Republic", code: "LA" },
      { name: "Latvia", code: "LV" },
      { name: "Lebanon", code: "LB" },
      { name: "Lesotho", code: "LS" },
      { name: "Liberia", code: "LR" },
      { name: "Libyan Arab Jamahiriya", code: "LY" },
      { name: "Liechtenstein", code: "LI" },
      { name: "Lithuania", code: "LT" },
      { name: "Luxembourg", code: "LU" },
      { name: "Macao", code: "MO" },
      { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
      { name: "Madagascar", code: "MG" },
      { name: "Malawi", code: "MW" },
      { name: "Malaysia", code: "MY" },
      { name: "Maldives", code: "MV" },
      { name: "Mali", code: "ML" },
      { name: "Malta", code: "MT" },
      { name: "Marshall Islands", code: "MH" },
      { name: "Martinique", code: "MQ" },
      { name: "Mauritania", code: "MR" },
      { name: "Mauritius", code: "MU" },
      { name: "Mayotte", code: "YT" },
      { name: "Mexico", code: "MX" },
      { name: "Micronesia, Federated States of", code: "FM" },
      { name: "Moldova, Republic of", code: "MD" },
      { name: "Monaco", code: "MC" },
      { name: "Mongolia", code: "MN" },
      { name: "Montserrat", code: "MS" },
      { name: "Morocco", code: "MA" },
      { name: "Mozambique", code: "MZ" },
      { name: "Myanmar", code: "MM" },
      { name: "Namibia", code: "NA" },
      { name: "Nauru", code: "NR" },
      { name: "Nepal", code: "NP" },
      { name: "Netherlands", code: "NL" },
      { name: "Netherlands Antilles", code: "AN" },
      { name: "New Caledonia", code: "NC" },
      { name: "New Zealand", code: "NZ" },
      { name: "Nicaragua", code: "NI" },
      { name: "Niger", code: "NE" },
      { name: "Nigeria", code: "NG" },
      { name: "Niue", code: "NU" },
      { name: "Norfolk Island", code: "NF" },
      { name: "Northern Mariana Islands", code: "MP" },
      { name: "Norway", code: "NO" },
      { name: "Oman", code: "OM" },
      { name: "Pakistan", code: "PK" },
      { name: "Palau", code: "PW" },
      { name: "Palestinian Territory, Occupied", code: "PS" },
      { name: "Panama", code: "PA" },
      { name: "Papua New Guinea", code: "PG" },
      { name: "Paraguay", code: "PY" },
      { name: "Peru", code: "PE" },
      { name: "Philippines", code: "PH" },
      { name: "Pitcairn", code: "PN" },
      { name: "Poland", code: "PL" },
      { name: "Portugal", code: "PT" },
      { name: "Puerto Rico", code: "PR" },
      { name: "Qatar", code: "QA" },
      { name: "Reunion", code: "RE" },
      { name: "Romania", code: "RO" },
      { name: "Russian Federation", code: "RU" },
      { name: "RWANDA", code: "RW" },
      { name: "Saint Helena", code: "SH" },
      { name: "Saint Kitts and Nevis", code: "KN" },
      { name: "Saint Lucia", code: "LC" },
      { name: "Saint Pierre and Miquelon", code: "PM" },
      { name: "Saint Vincent and the Grenadines", code: "VC" },
      { name: "Samoa", code: "WS" },
      { name: "San Marino", code: "SM" },
      { name: "Sao Tome and Principe", code: "ST" },
      { name: "Saudi Arabia", code: "SA" },
      { name: "Senegal", code: "SN" },
      { name: "Serbia and Montenegro", code: "CS" },
      { name: "Seychelles", code: "SC" },
      { name: "Sierra Leone", code: "SL" },
      { name: "Singapore", code: "SG" },
      { name: "Slovakia", code: "SK" },
      { name: "Slovenia", code: "SI" },
      { name: "Solomon Islands", code: "SB" },
      { name: "Somalia", code: "SO" },
      { name: "South Africa", code: "ZA" },
      { name: "South Georgia and the South Sandwich Islands", code: "GS" },
      { name: "Spain", code: "ES" },
      { name: "Sri Lanka", code: "LK" },
      { name: "Sudan", code: "SD" },
      { name: "Suriname", code: "SR" },
      { name: "Svalbard and Jan Mayen", code: "SJ" },
      { name: "Swaziland", code: "SZ" },
      { name: "Sweden", code: "SE" },
      { name: "Switzerland", code: "CH" },
      { name: "Syrian Arab Republic", code: "SY" },
      { name: "Taiwan, Province of China", code: "TW" },
      { name: "Tajikistan", code: "TJ" },
      { name: "Tanzania, United Republic of", code: "TZ" },
      { name: "Thailand", code: "TH" },
      { name: "Timor-Leste", code: "TL" },
      { name: "Togo", code: "TG" },
      { name: "Tokelau", code: "TK" },
      { name: "Tonga", code: "TO" },
      { name: "Trinidad and Tobago", code: "TT" },
      { name: "Tunisia", code: "TN" },
      { name: "Turkey", code: "TR" },
      { name: "Turkmenistan", code: "TM" },
      { name: "Turks and Caicos Islands", code: "TC" },
      { name: "Tuvalu", code: "TV" },
      { name: "Uganda", code: "UG" },
      { name: "Ukraine", code: "UA" },
      { name: "United Arab Emirates", code: "AE" },
      { name: "United Kingdom", code: "GB" },
      { name: "United States", code: "US" },
      { name: "United States Minor Outlying Islands", code: "UM" },
      { name: "Uruguay", code: "UY" },
      { name: "Uzbekistan", code: "UZ" },
      { name: "Vanuatu", code: "VU" },
      { name: "Venezuela", code: "VE" },
      { name: "Viet Nam", code: "VN" },
      { name: "Virgin Islands, British", code: "VG" },
      { name: "Virgin Islands, U.S.", code: "VI" },
      { name: "Wallis and Futuna", code: "WF" },
      { name: "Western Sahara", code: "EH" },
      { name: "Yemen", code: "YE" },
      { name: "Zambia", code: "ZM" },
      { name: "Zimbabwe", code: "ZW" },
    ];

    let formErrors = { ...this.state.formErrors };
    var { initLocalPayment, paymentLoading } = this.state;

    var {
      isCreditCard,
      FloCashPaymentOptions,
      CellPointPaymentOptions,
      initLocalPayment,
    } = this.state;
    if (this.state.isLoadingPayment) {
      return <ProgressLoader displayMess="Processing Payment"></ProgressLoader>;
    } else {
      return (
        <>
          <BreadCrumb pageIndex={5} />
          <div className="row m-0 p-0">
            <div className="col-12 col-md-3 order-md-2 m-0">
              <ShoppingCart className="m-3" pageIndex={4} />
            </div>
            <div className="col-12 col-md-9 order-md-1 m-0">
              <div className="container">
                <div className="shadow p-3 mb-5 mt-1 bg-white rounded">
                  <h3>
                    <strong> Payment Options</strong>
                  </h3>
                  <Row>
                    <p  className="pl-3 float-left text-muted">
                     Select one from the below payment option(s) 
                  </p>
                    </Row>
                  <Row>
                    <div className="  rounded pl-3 m-2 mr-2 ml-2 ">
                      <FontAwesomeIcon
                      className={(this.state.activePaymenyOption==("CreditCard"))?"activePaymenyOption  pr-2 mr-2 ml-2":"pr-2 mr-2 ml-2"}
                       
                        onClick={() =>
                          this.setState({activePaymenyOption:"CreditCard",
                            isCreditCard: true,
                            isLoadingPayment: false,
                            // agreeTerms: false,
                            isCellPoint: false,
                          })
                        }
                        icon={faCreditCard}
                        size="4x"
                        style={{
                          height: "50px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          borderTop: "1px solid green",
                        }}
                      />
                    </div>
                    {FloCashPaymentOptions.map((item,i) => (
                      <div
                        className={(this.state.activePaymenyOption==("fc"+i))?"activePaymenyOptaion  pl-3 m-2 mr-2 ml-2 ":"pl-3 m-2 mr-2 ml-2"}
                        onClick={() =>
                          this.setState({ activePaymenyOption:"fc"+i,
                            initLocalPayment: item,
                            isCreditCard: false,
                            isLoadingPayment: false,
                            isCellPoint: false,
                            agreeTerms: false,
                          })
                        }
                      >
                        <img
                          src={item.logoURI}
                          style={{
                            height: "50px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            borderTop: "1px solid green",
                          }}
                          class="img-fluid shadow " 
                          className={(this.state.activePaymenyOption==("fc"+i))?"activePaymenyOption img-fluid shadow ":"img-fluid shadow "}
                       
                          alt="Responsive image"
                        />
                      </div>
                    ))}

                    {CellPointPaymentOptions.map((item,i) => (
                      <div
                      className={(this.state.activePaymenyOption==("cp"+i))?"activePaymenyOption2  pl-3 m-2 mr-2 ml-2 ":"pl-3 m-2 mr-2 ml-2"}
                        onClick={() =>
                          this.setState({activePaymenyOption:"cp"+i,
                            initLocalPayment: item,
                            isCreditCard: false,
                            isLoadingPayment: false,
                            isCellPoint: true,
                            agreeTermsCellPoint: false,
                          })
                        }
                      >
                        <img
                          src={item.logoURI}
                          style={{
                            height: "50px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            borderTop: "1px solid green",
                          }} 
                          className={(this.state.activePaymenyOption==("cp"+i))?"activePaymenyOption  img-fluid shadow":"img-fluid shadow"}
                      
                          alt="Responsive image"
                        />
                      </div>
                    ))}
                  </Row>
                </div>

                {!isCreditCard && (
                  <Card className=" shadow pl-2 pr-0 pb-0  pt-2 mb-2 bg-white rounded">
                    <Card.Header as="h5">
                      {" "}
                      <strong className="pt-0 float-left">
                        {" "}
                        {initLocalPayment.paymentName}
                      </strong>
                    </Card.Header>
                    {this.state.localPaymentResponseMessage && (
                      <Card.Header as="p">
                        {" "}
                        <strong
                          className="pt-0 float-left "
                          style={{ color: "red" }}
                        >
                          {this.state.localPaymentResponseMessage}
                        </strong>
                      </Card.Header>
                    )}
                    <Card.Body>
                      <div className="row">
                        <div className="pr-3 mr-2">
                          <img
                            src={initLocalPayment.logoURI}
                            style={{ height: "70px", cursor: "pointer" }}
                            className="pt-0 float-left img-fluid"
                            alt="Responsive image"
                          />
                        </div>
                        <div>
                          <Card.Title>
                            <h1>
                              Pay Using{" "}
                              <strong> {initLocalPayment.paymentName}</strong>
                            </h1>
                          </Card.Title>
                        </div>
                      </div>
                      <Card.Text>
                        {/* With supporting text below as a natural lead-in to additional
                    content. */}
                        {this.state.isCellPoint && (
                          <Form
                            noValidate
                            validated={validated}
                            onSubmit={this.handleSubmit}
                            className=" shadow pl-1 pr-1 pb-3  pt-2 mb-2 bg-white rounded"
                          >
                            <Form.Row>
                              <h4 className="pt-0 float-left">
                                Billing Information
                              </h4>
                            </Form.Row>
                            {/* <br /> */}
                            <Form.Row>
                              <Form.Group as={Col} md="4" controlId="FirstName">
                                {/* <Form.Label className="d-flex">First Name*</Form.Label> */}
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependFirstName">
                                      <FontAwesomeIcon
                                        className="pr-2"
                                        icon={faUser}
                                        size="lg"
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    required
                                    minLength="2"
                                    aria-describedby="inputGroupPrependFirstName"
                                    type="text"
                                    placeholder="First name*"
                                    defaultValue=""
                                    value={this.state.FirstName}
                                    onChange={(e) =>
                                      this.setState({
                                        FirstName: e.target.value,
                                      })
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please input a valid First Name
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} md="4" controlId="LastName">
                                {/* <Form.Label className="d-flex">Last Name*</Form.Label> */}
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependLastName">
                                      <FontAwesomeIcon
                                        className="pr-2"
                                        icon={faUser}
                                        size="lg"
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    required
                                    minLength="2"
                                    type="text"
                                    placeholder="Last name*"
                                    aria-describedby="inputGroupPrependLastName"
                                    defaultValue=""
                                    value={this.state.LastName}
                                    onChange={(e) =>
                                      this.setState({
                                        LastName: e.target.value,
                                      })
                                    }
                                  />
                                  {/* {(LastName.length < 3 && LastName.length > 0) && (
                <span className="errorMessage d-flex" style={{ position: "absolute" }}>minimum 3 characaters required</span>
            )} */}
                                  <Form.Control.Feedback type="invalid">
                                    Please input a valid Last Name
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="4"
                                controlId="ResidenceCountry"
                              >
                                {/* <Form.Label className="d-flex">Residence Country</Form.Label> */}
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependResidenceCountry">
                                      <FontAwesomeIcon
                                        className="pr-2"
                                        icon={faMapSigns}
                                        size="lg"
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    as="select"
                                    defaultValue=""
                                    required
                                    aria-describedby="inputGroupPrependResidenceCountry"
                                    value={this.state.ResidenceCountry}
                                    onChange={(e) =>
                                      this.setState({
                                        ResidenceCountry: e.target.value,
                                      })
                                    }
                                    onSelect={(value) =>
                                      this.setState({ ResidenceCountry: value })
                                    }
                                  >
                                    <option value="">
                                      Select Residence Country*
                                    </option>
                                    {countriesList.map((country) => (
                                      <option value={country.name}>
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid Residence Country.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="4"
                                controlId="PostalCode"
                              >
                                {/* <Form.Label className="d-flex">Postal Code*</Form.Label> */}

                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependPostalCode">
                                      <FontAwesomeIcon
                                        className="pr-2"
                                        icon={faLocationArrow}
                                        size="lg"
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    type="text"
                                    placeholder="Postal Code*"
                                    required
                                    minLength="2"
                                    aria-describedby="inputGroupPrependPostalCode"
                                    value={this.state.PostalCode}
                                    onChange={(e) =>
                                      this.setState({
                                        PostalCode: e.target.value,
                                      })
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a Postal Code.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="4"
                                controlId="BillingAddress"
                              >
                                {/* <Form.Label className="d-flex">Billing Address</Form.Label> */}
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependBillingAddress">
                                      <FontAwesomeIcon
                                        className="pr-2"
                                        icon={faLocationArrow}
                                        size="lg"
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    type="text"
                                    placeholder="Billing Address*"
                                    required
                                    aria-describedby="inputGroupPrependBillingAddress"
                                    value={this.state.BillingAddress}
                                    onChange={(e) =>
                                      this.setState({
                                        BillingAddress: e.target.value,
                                      })
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid Input.
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="4"
                                controlId="AddressTwo"
                              >
                                {/* <Form.Label className="d-flex">Address Two (Optional)</Form.Label> */}

                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrependAddressTwo">
                                      <FontAwesomeIcon
                                        className="pr-2"
                                        icon={faMapSigns}
                                        size="lg"
                                      />
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control
                                    type="text"
                                    aria-describedby="inputGroupPrependAddressTwo"
                                    placeholder="Address Two(optional)"
                                    aria-describedby="inputGroupPrependCVC"
                                    value={this.state.AddressTwo}
                                    onChange={(e) =>
                                      this.setState({
                                        AddressTwo: e.target.value,
                                      })
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Form.Row>
                            {/* >Pay Now</Button>
                             */}

                            <Row className="pl-5">
                              <FareRule firstLoad={false} />
                              <Col style={{ textAlign: "left" }} sm={6}>
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={this.state.agreeTermsCellPoint}
                                  onChange={(value) =>
                                    this.setState({
                                      agreeTermsCellPoint:
                                        document.getElementById(
                                          "defaultCheckLocalCellPoint"
                                        ).checked,
                                    })
                                  }
                                  id="defaultCheckLocalCellPoint"
                                />
                                <label
                                  className="form-check-label"
                                  for="defaultCheckLocalCellPoint"
                                >
                                  I agree to the{" "}
                                </label>
                                <Card.Link
                                  onClick={() =>
                                    this.setState({ showModal: true })
                                  }
                                  style={{ color: "green", cursor: "pointer" }}
                                >
                                  {" "}
                                  Terms and Condition{" "}
                                </Card.Link>
                              </Col>
                              <Col style={{ textAlign: "right" }} sm={6}>
                                {this.state.isLoadingPayment && (
                                  <Button variant="success" disabled>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Making Payment...
                                  </Button>
                                )}
                                {this.state.isLoadingPayment === false && (
                                  <>
                                    {this.state.agreeTermsCellPoint ===
                                      true && (
                                      <Button
                                        type="submit"
                                        className="btn btn-success"
                                        variant="success"
                                      >
                                        <strong>Pay Now</strong>
                                      </Button>
                                    )}
                                    {this.state.agreeTermsCellPoint ===
                                      false && (
                                      <Button
                                        type="submit"
                                        className="btn btn-success"
                                        variant="success"
                                        disabled
                                      >
                                        <strong>Pay Now</strong>
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Card.Text>
                    </Card.Body>
                    {this.state.isCellPoint === false && (
                      <Card.Footer>
                        <Row className="pl-5">
                          <FareRule firstLoad={false} />
                          <br />
                        </Row>
                        <Row className="pl-5">
                        <Col style={{ textAlign: "left" }} sm={12}>
                        {this.state.agreeTermsCommand &&
                                <Alert variant="danger">
                                  Please agree the terms and condition to make payment.

                                </Alert>
                        }  
                          </Col>
                          <Col style={{ textAlign: "left" }} sm={12}>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={this.state.agreeTerms}
                              // checked="false"
                              onChange={(value) => {
                                this.setState({ agreeTermsCommand:false,
                                  agreeTerms:
                                    document.getElementById("defaultCheckLocal")
                                      .checked,
                                });
                              }}
                              id="defaultCheckLocal"
                            />
                            <label
                              class="form-check-label"
                              for="defaultCheckLocal"
                            >
                              I agree to the{" "}
                            </label>
                            <Card.Link
                              onClick={() => this.setState({ showModal: true})}
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              {" "}
                              Terms and Condition{" "}
                            </Card.Link>
                          </Col>
                          <Col style={{ textAlign: "left" }} className="mt-2" sm={12}>
                            {this.state.isLoadingPayment && (
                              <Button variant="success" disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Payment Loading...
                              </Button>
                            )}

                            {this.state.isLoadingPayment === false && (
                              <>
                                {this.state.agreeTerms === true && (
                                  <button
                                    type="button"
                                    class="btn btn-success"
                                    variant="success"
                                    onClick={() =>
                                      this.submitLocalPayment(initLocalPayment)
                                    }
                                  >
                                    <strong>Pay Now</strong>
                                  </button>
                                )}
                                {this.state.agreeTerms === false && (
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    variant="success" 
                                    onClick={() =>
                                       this.setState({agreeTermsCommand:true})
                                    }
                                  >
                                    <strong>Pay Now</strong>
                                  </button>
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      </Card.Footer>
                    )}
                  </Card>
                )}
                {isCreditCard && <CreditCardForm />}

                <Modal
                  scrollable={true}
                  size="lg"
                  aria-labelledby="example-custom-modal-styling-title"
                  show={this.state.showModal}
                  onHide={() => this.setState({ showModal: false })}
                >
                  <Modal.Header closeButton className="p-1 pl-3 pt-0">
                    <Modal.Title>Terms and Condition</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <TermsAndCondition></TermsAndCondition>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default PaymentFormCreditCard;
