import React, { Component } from "react";
import $ from "jquery";
import "./trip-planner.css";
import {
   faCheck, faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { CountryList } from "../shared/master-data/country-list/country-list";
import { SingleDatePicker } from "react-dates";
import { HttpService } from "../../services/http/http-service";
import ProgressLoader from "../shared/progress-loading/progress-loader";
import {    Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import MataDecorator from "../shared/meta-decorator/meta-helmet";
//12574 Trip planner age select overlap 

class TripPlanner extends Component {
   constructor(props) {
      super(props);

     let currentpageURL=window.location.href 
      this.state = { 
         pageURL:currentpageURL,
         agreePromotion: false,
         holidayType: [],
         date: null,
         isLoading: false,
         isErrorOccured: false,
         invalidGoogleRecapthcha:true
      };
      this.fieldsetNo = 1;
   }
   validateForm = () => {
      
      return false;
   }

   async componentDidMount() {
       var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;
      var fieldsetNo = 1;
      var tempHolidayType = JSON.parse(
         localStorage.getItem("packageCatagories")
      );
      if (tempHolidayType) {
         this.setState({
            holidayType: tempHolidayType[0]
         });
      }
      else {
         window.location.assign("/");
      }
      setProgressBar(current);

      $(".next").click(function () {
         
         if (fieldsetNo == 1) {
            $('#age_error').hide();
            var formIsInValid = false;
            if (document.getElementById("full_name").value == "") {
               document
                  .getElementById("full_name")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }
            if (document.getElementById("email").value == "" || !validateEmail(document.getElementById("email").value)) {
               document
                  .getElementById("email")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }

            var nationality = document.querySelector("#nationality" + "  div.css-1uccc91-singleValue");

            if (nationality == null) {
               var nationality1 = document.querySelector("#nationality" + "  div.css-yk16xz-control");
               nationality1.style.borderColor = "red";
               formIsInValid = true;
            }

            if (
               document.getElementById("phone_no").value == "" ||
               document.getElementById("phone_no").value.length < 10 ||
               document.getElementById("phone_no").value == null
            ) {
               document
                  .getElementById("phone_no")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }
            if (
               document.getElementById("number_traver").value == "") {
               document
                  .getElementById("number_traver")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }
            if (document.querySelector('.travel_age:checked') == null) {
               $('#age_error').show();
               document.getElementById("age_error").innerHTML = "(please select age inverval)";
               formIsInValid = true;

            }
            else {
               var checkboxChecked = $('input:checkbox.travel_age:checked').length
               var numberofTravler = document.getElementById("number_traver").value
               if (checkboxChecked > numberofTravler) {
                  $('#age_error').show();
                  document.getElementById("age_error").innerHTML = "(The age selected is greater than the number of traveler)";
                  formIsInValid = true;
               }
            }
            if (formIsInValid) {
               return false;
            }
            else {
               fieldsetNo = 2;
            }
         }
         else if (fieldsetNo == 2) {
            var formIsInValid = false;
            var malaria_free = document.querySelector("#malaria_free" + "  div.css-1uccc91-singleValue");

            if (malaria_free == null) {
               var malaria_free1 = document.querySelector("#malaria_free" + "  div.css-yk16xz-control");
               malaria_free1.style.borderColor = "red";
               formIsInValid = true;
            }

            var first_trip = document.querySelector("#first_trip" + "  div.css-1uccc91-singleValue");
            if (first_trip == null) {
               var thisDiv = document.querySelector("#first_trip" + "  div.css-yk16xz-control");
               thisDiv.style.borderColor = "red";
               formIsInValid = true;
            }
            var prefer_fly = document.querySelector("#prefer_fly" + "  div.css-1uccc91-singleValue");
            if (prefer_fly == null) {
               var thisDiv = document.querySelector("#prefer_fly" + "  div.css-yk16xz-control");
               thisDiv.style.borderColor = "red";
               formIsInValid = true;
            }
            var travel_style = document.querySelector("#travel_style" + "  div.css-1uccc91-singleValue");
            if (travel_style == null) {
               var thisDiv = document.querySelector("#travel_style" + "  div.css-yk16xz-control");
               thisDiv.style.borderColor = "red";
               formIsInValid = true;
            }
            
            var destination = document.querySelector("#destination" + "  div.css-1rhbuit-multiValue");
            if (destination == null) {
               var thisDiv = document.querySelector("#destination" + "  div.css-yk16xz-control");
               thisDiv.style.borderColor = "red";
               formIsInValid = true;
            }
            var vacation_style = document.querySelector("#vacation_style" + "  div.css-1uccc91-singleValue");
            if (vacation_style == null) {
               var thisDiv = document.querySelector("#vacation_style" + "  div.css-yk16xz-control");
               thisDiv.style.borderColor = "red";
               formIsInValid = true;
            }
            if (formIsInValid) {
               return false;
            }
            else {
               fieldsetNo = 3;
            }

         }
         else if (fieldsetNo == 3) {
            var formIsInValid = false;
            if (document.getElementById("estimated_budget").value == "") {
               document
                  .getElementById("estimated_budget")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }
            if (document.getElementById("estimated_budget").value == "") {
               document
                  .getElementById("estimated_budget")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }
            if (document.getElementById("estimated_date").value == "") {
               document
                  .getElementById("estimated_date")
                  .classList.add("is-invalid");
               formIsInValid = true;
            }
            var currency = document.querySelector("#currency" + "  div.css-1uccc91-singleValue");

            if (currency == null) {
               var thisDiv = document.querySelector("#currency" + "  div.css-yk16xz-control");
               thisDiv.style.borderColor = "red";
               formIsInValid = true;
            }
            if (formIsInValid) {
               return false;
            }
            else {
               fieldsetNo = 4;
            }
         }
         current_fs = $(this).parent();
         next_fs = $(this).parent().next();

         //Add Class Active
         $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

         //show the next fieldset
         next_fs.show();
         //hide the current fieldset with style
         current_fs.animate({ opacity: 0 }, {
            step: function (now) {
               // for making fielset appear animation
               opacity = 1 - now;

               current_fs.css({
                  'display': 'none',
                  'position': 'relative'
               });
               next_fs.css({ 'opacity': opacity });
            },
            duration: 800
         });
         setProgressBar(++current);
      });

      $(".previous").click(function () {
         --fieldsetNo;
         current_fs = $(this).parent();
         previous_fs = $(this).parent().prev();

         //Remove class active
         $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

         //show the previous fieldset
         previous_fs.show();

         //hide the current fieldset with style
         current_fs.animate({ opacity: 0 }, {
            step: function (now) {
               // for making fielset appear animation
               opacity = 1 - now;

               current_fs.css({
                  'display': 'none',
                  'position': 'relative'
               });
               previous_fs.css({ 'opacity': opacity });
            },
            duration: 800
         });
         setProgressBar(--current);
      });

      function setProgressBar(curStep) {
         var percent = parseFloat(100 / steps) * curStep;
         percent = percent.toFixed();
         $(".progress-bar")
            .css("width", percent + "%")
      }
      
      function validateEmail(elementValue) {
         var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
         return emailPattern.test(elementValue);
      }
   }
   submitTirpPlanne = () => {
      
      this.setState({ isLoading: true, isErrorOccured: false });
      var full_name = document.getElementById("full_name").value;
      var email = document.getElementById("email").value;
      var nationality = document.querySelector("#nationality" + "  div.css-1uccc91-singleValue").textContent;
      var currency = document.querySelector("#currency" + "  div.css-1uccc91-singleValue").textContent;
      var phone_no = document.getElementById("phone_no").value;
      var number_traver = document.getElementById("number_traver").value;
      var age_list = document.querySelectorAll('.travel_age:checked');
      var malaria_free = document.querySelector("#malaria_free" + "  div.css-1uccc91-singleValue").textContent;
      var first_trip = document.querySelector("#first_trip" + "  div.css-1uccc91-singleValue").textContent;
      var prefer_fly = document.querySelector("#prefer_fly" + "  div.css-1uccc91-singleValue").textContent;
      var travel_style = document.querySelector("#travel_style" + "  div.css-1uccc91-singleValue").textContent;
      var destination = document.querySelectorAll(
         "#destination" + "  div.css-1rhbuit-multiValue"
      );
      var vacation_style = document.querySelector("#vacation_style" + "  div.css-1uccc91-singleValue").textContent;
      var estimated_budget = document.getElementById("estimated_budget").value;
      var estimated_date = document.getElementById("estimated_date").value;
      var additional_info = document.getElementById("additional_info").value;
      var tripPlannerData = {
         Currency:currency,
         name: full_name,
         email: email,
         nationality: nationality,
         phoneNumber: phone_no,
         ageOfTravlers: [],
         destinations: [],
         numberTraver: parseInt(number_traver),
         isMalariaFree: (malaria_free === "Yes"),
         isFirstTripToAfrica: (first_trip === "Yes"),
         class: prefer_fly,
         vacationStyle: travel_style,
         packageType: vacation_style,
         budget: estimated_budget,
         departure: estimated_date,
         additionalInfo: additional_info
      };
      age_list.forEach(function (age) {
         tripPlannerData.ageOfTravlers.push(age.value);
      })
      destination.forEach(function (des) {
         tripPlannerData.destinations.push(des.textContent);
      });
      HttpService.postServiceTripPlanner(tripPlannerData)
         .then((response) => {
            if (this.state.agreePromotion) {
              this.submitSubscriptioin();
          }
            this.setState({ isLoading: false });
         })
         .catch((error) => {
            document.getElementById("response-message-header").innerHTML = "Error";
            document.getElementById("response-message-body").innerHTML = "Error occured please realod a page and try again!!";
            this.setState({ isLoading: false, isErrorOccured: true });
         });

   }


   submitSubscriptioin() {
      var requestBody = {
          Email: this.state.Email,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
      };
      this.setState({ isLoading: true, modalView: false });
      HttpService.postEmail(requestBody)
          .then((response) => {
            this.setState({ isLoading: false });
          })
          .catch((error) => {
              this.setState({
                  emailModal: true,
                  modalText: "Error occured, try again",
                  isLoading: false,
              });
          });
  
  }
  

   render() {
      let loading = null;
      if (this.state.isLoading) {
         loading = (
            <div className="Loading-div">
               <div className="Loading">
                  <ProgressLoader displayMess="Saving Your Trip Plane!" />
               </div>
            </div>
         );
      }
      let customStyles = {
         option: (provided, state) => ({
            ...provided,
            "text-align": "left",
            color: state.isSelected ? "white" : "black",
            width: state.selectProps.width,
            backgroundColor: state.isSelected ? "#4caf50" : "white",
            "&:hover": {
               backgroundColor: "#4caf50",
               color: "white",
            },
         }),
      };
      var holidayTypeOption = [{
         label: "What Is Your Travel Style?",
         options: []
      }];
      var holidayOptionTemp = [];
      
      if (this.state.holidayType.length != 0) {
         this.state.holidayType.packageTypes.forEach(element => {
            holidayOptionTemp.push(
               {
                  value: element.name,
                  label: element.name
               }
            )
         });
         holidayTypeOption = [{
            label: "What Is Your Travel Style?",
            options: holidayOptionTemp
         }];
      }
      var booleanOptionMalaria = [{
         label: "Is Malaria-Free Important To You?",
         options: [
            {
               value: "Yes",
               label: "Yes"
            },
            {
               value: "No",
               label: "No"
            }]
      }
      ]
      var booleanOptionFirstTrip = [{
         label: "Is This Your First Trip To Africa?",
         options: [
            {
               value: "Yes",
               label: "Yes"
            },
            {
               value: "No",
               label: "No"
            }]
      }
      ]
      var classOption = [{
         label: "How  Would You Prefer To Fly?",
         options: [
            {
               value: "Economy",
               label: "Economy"
            },
            {
               value: "Business",
               label: "Business"
            }
         ]
      }
      ]

      var travelStyleOption = [{
         value: "Moderate",
         label: "Moderate (3 – 4 star)"
      },
      {
         value: "Premium",
         label: "Premium (5 star)"
      },
      {
         value: "Deluxe",
         label: "Deluxe (5 star +)"
      }
      ]
      var countryListNationality = [{
         label: "Your Nationality",
         options: CountryList.getAllList
      }];
      var  currencyList = [{
         label: "Your Currency",
         options: CountryList.getAllCurrency
      }];

      var countryListDestination = [{
         label: "Your Destination",
         options: CountryList.getAllDestination
      }];
      return (
         <div className="container-fluid">
            {loading}
            
        <MataDecorator 
         metaInfo={
           { 
        title:"Ethiopian Holidays | Trip Planner",
        description:"By planning and booking your entire vacation with Ethiopian Holidays Enjoy an amazing journey With best African Airlines.",
      //   image:packageDetails.package.imagePath,
        url:this.state.pageURL,
      //   twitterCard:packageDetails.package.imagePath,
      //   imageAlt:packageDetails.package.name,
            }
          }  
        /> 
            <div className="row justify-content-center">
               <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                  <div className="card cardTrip px-0 pt-4 pb-0 mt-3 mb-3">
                     <h2 id="heading">Ethiopian Holidays Trip Planner</h2>
                     <p>Fill all form field to go to next step</p>
                     <ul id="progressbar">
                        <li className="active" id="contact"><strong>Contact Information</strong></li>
                        <li id="travel_detail"><strong>Travel Detail</strong></li>
                        <li id="booking"><strong>Bookig Information</strong></li>
                        <li id="confirm"><strong>Finish</strong></li>
                     </ul>
                     <form id="msform">
                        <fieldset>
                           <div className="form-card">
                              <div className="row">
                                 <div className="col-12 col-md-7">
                                    <h2 className="fs-title">General Contact Information:</h2>
                                    <hr style={{ border: "1px solid #4caf50" }} />
                                 </div>
                              </div>
                              <div>
                                 <label className="form-label form-label-top form-label-auto">
                                    Your Contact Information
                        </label>
                                 <div className="row">
                                    <div className="col-md-6 col-12">
                                       <input
                                          className="form-textbox mb-2"
                                          type="text"
                                          onChange={function (e) {
                                             if (!e.target.value.match(/[A-Za-z]/g)) {
                                                e.target.value = "";
                                             }
                                          }}
                                          onFocus={function (event) {
                                             event.target.classList.remove("is-invalid");
                                             event.target.classList.remove("is-valid");
                                          }}
                                          onKeyDown={function (e) {
                                             var keynum;
                                             if (window.event) {
                                                keynum = e.keyCode;
                                             } else if (e.which) {
                                                keynum = e.which;
                                             }

                                             let isValid =
                                                (keynum <= 90 && keynum >= 65) ||
                                                keynum == 8 ||
                                                keynum == 9 ||
                                                keynum == 13 ||
                                                keynum == 32 ||
                                                keynum == 36 ||
                                                keynum == 37 ||
                                                keynum == 38 ||
                                                keynum == 39 ||
                                                keynum == 40;
                                             if (!isValid) {
                                                e.preventDefault();
                                             }
                                          }}
                                          //required
                                          placeholder="Your Name *"
                                          id="full_name"
                                       />
                                    </div>

   
                                    <div className="col-md-6 col-12">
                                       <input
                                          type="email"
                                          className="form-textbox  mb-2"
                                          style={{ width: "310px" }}
                                          size="310"
                                          placeholder="Your Email *"
                                          id="email"
                                          onFocus={function (event) {
                                             event.target.classList.remove("is-invalid");
                                             event.target.classList.remove("is-valid");
                                          }}
                                       />
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-md-6 col-12">

                                       <PhoneInput
                                          inputProps={{
                                             class: "form-textbox display-7",
                                             //required: true,
                                             id: "phone_no",
                                             placeholder: "             Your Phone Number *"
                                          }}
                                          onFocus={function (event) {
                                             event.target.classList.remove("is-invalid");
                                             event.target.classList.remove("is-valid");
                                          }}
                                       />

                                    </div>
                                    <div className="col-md-6 col-12">
                                       <div className="row">
                                          <div className="col-12" style={{zIndex:100}}>
                                          <Select
                                          options={countryListNationality}
                                          isSearchable={true}
                                          styles={customStyles}
                                          className="mb-2"
                                          placeholder="Your Nationality *"
                                          id="nationality"
                                          onFocus={function (event) {
                                             var thisClass = document.querySelector("#nationality" + "  div.css-yk16xz-control");
                                             thisClass.style.borderColor = "#cccccc";
                                          }}

                                       />
                                          </div>
                                       </div>

                                    </div>

                                 </div>
                              </div>
                              <div>
                                 <label className="form-label form-label-top form-label-auto">
                                    Traveler Information
                        </label>
                                 <input
                                    className="form-textbox mb-2"
                                    type="text"
                                    placeholder="Number of Travelers*"
                                    onKeyDown={function (e) {
                                       var keynum;
                                       if (window.event) {
                                          keynum = e.keyCode;
                                       } else if (e.which) {
                                          keynum = e.which;
                                       }
                                       let isValid =
                                          (keynum <= 57 && keynum >= 48) ||
                                          (keynum <= 105 && keynum >= 96) ||
                                          keynum == 46 ||
                                          keynum == 8 ||
                                          keynum == 37 ||
                                          keynum == 39

                                       if (!isValid) {
                                          e.preventDefault();
                                       }
                                    }}
                                    id="number_traver"
                                    onFocus={function (event) {
                                       event.target.classList.remove("is-invalid");
                                       event.target.classList.remove("is-valid");
                                    }}
                                 />
                              </div>
                              <div>
                                 <label className="form-label form-label-top form-label-auto">
                                    Age of Travelers *<p id="age_error" style={{ color: "red", display: "none" }}>(please select age inverval)</p>
                                 </label>
                                 <div className="row">
                                 <div className="col-md-4 col-12">
                                       <div className="custom-control  custom-checkbox">
                                       <label className="main" for="infant">Infant(0-2)
                                       <input type="checkbox" value="Infant(0-2)" class=" travel_age" id="infant" />
                                       <span className="geekmark"></span> 
                                       </label>
                                       </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                    <div className="custom-control custom-checkbox">
                                       <label className="main" for="child">Child(2-12)
                                       <input type="checkbox" value="Child(2-12)" class=" travel_age" id="child" />
                                          <span className="geekmark"></span> 
                                       </label>
                                     </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                    <div className="custom-control custom-checkbox">
                                       <label className="main" for="teen">Teen(13-19)
                                       <input type="checkbox" value="Teen(13-19)" className="custom-control-input travel_age" id="teen" />
                                       <span className="geekmark"></span> 
                                       </label>
                                       </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-4 col-12">
                                       <div className="custom-control custom-checkbox">
                                       <label className="main" for="young_adult">Young Adult(20-35)
                                       <input type="checkbox" value="Young Adult(20-35)" className="custom-control-input travel_age" id="young_adult" />
                                          <span className="geekmark"></span>
                                       </label>
                                       </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                       <div className="custom-control custom-checkbox">
                                       <label className="main" for="settled">Settled(36-50)
                                       <input type="checkbox" value="Settled(36-50)" class=" travel_age" id="settled" />
                                       <span className="geekmark"></span> 
                                       </label>
                                       </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                       <div className="custom-control custom-checkbox">
                                       <label className="main" for="mature">Mature(51-65)
                                       <input type="checkbox" value="Mature(51-65)" className=" travel_age" id="mature" />
                                          <span className="geekmark"></span> 
                                       </label>
                                       </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-4 col-12">
                                       <div className="custom-control custom-checkbox">
                                       <label className="main" for="retired">Retired(65+)
                                       <input type="checkbox" value="Retired(65+)" className="custom-control-input travel_age" id="retired" />
                                          <span className="geekmark"></span> 
                                       </label>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <input type="button" name="next" className="mt-3 next action-button float-right" value="Next" />
                        </fieldset>
                        <fieldset>
                           <div className="form-card">
                              <div className="row">
                                 <div className="col-12 col-md-7">
                                    <h2 className="fs-title">Travel Detail :</h2>
                                    <hr style={{ border: "1px solid #4caf50" }} />
                                 </div>
                              </div>
                              <div>
                                 <label className="form-label form-label-top form-label-auto">
                                    Travel Experince
                        </label>
                              </div>
                              <div className="row">
                                 <div className="col-md-6 col-sm-12">
                                 <div className="row">
                                          <div className="col-12" style={{zIndex:106}}>
                                          <Select
                                       options={booleanOptionMalaria}
                                       styles={customStyles}
                                       isSearchable={true}
                                       placeholder="Is Malaria-Free Important To You?"
                                       className="mb-2"
                                       id="malaria_free"
                                       onFocus={function (event) {
                                          var thisClass = document.querySelector("#malaria_free" + "  div.css-yk16xz-control");
                                          thisClass.style.borderColor = "#cccccc";
                                       }}
                                    />
                                             </div>

                                             </div>

                                 </div>
                                 <div className="col-md-6 col-sm-12">
                                 <div className="row">
                                          <div className="col-12" style={{zIndex:105}}>
                                          <Select
                                       options={booleanOptionFirstTrip}
                                       styles={customStyles}
                                       isSearchable={true}
                                       placeholder="Is This Your First Trip To Africa?"
                                       className="mb-2"
                                       id="first_trip"
                                       onFocus={function (event) {
                                          var thisClass = document.querySelector("#first_trip" + "  div.css-yk16xz-control");
                                          thisClass.style.borderColor = "#cccccc";
                                       }}
                                    />
                                             </div>
                                             </div>

                                 </div>
                                 <div className="col-md-6 col-sm-12">
                                 <div className="row">
                                          <div className="col-12" style={{zIndex:104}}>
                                          <Select
                                       options={classOption}
                                       styles={customStyles}
                                       isSearchable={true}
                                       placeholder="How  Would You Prefer To Fly?"
                                       className="mb-2"
                                       id="prefer_fly"
                                       onFocus={function (event) {
                                          var thisClass = document.querySelector("#prefer_fly" + "  div.css-yk16xz-control");
                                          thisClass.style.borderColor = "#cccccc";
                                       }}
                                    />
                                             </div>
                                             </div>
       
                                 </div>
                                 <div className="col-md-6 col-sm-12">
                                 <div className="row">
                                          <div className="col-12" style={{zIndex:103}}>
                                          <Select
                                       options={holidayTypeOption}
                                       styles={customStyles}
                                       isSearchable={true}
                                       placeholder="What Is Your Travel Style?"
                                       className="mb-2"
                                       id="travel_style"
                                       onFocus={function (event) {
                                          var thisClass = document.querySelector("#travel_style" + "  div.css-yk16xz-control");
                                          thisClass.style.borderColor = "#cccccc";
                                       }}
                                    />
                                             </div>
                                             </div>

                                 </div>
                              </div>
                              <div>

                                 <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                       <div className="row">
                                          <div className="col-12" style={{zIndex:102}}>
                                          <label className="form-label form-label-top form-label-auto">
                                          Destination(s)
                        </label>
                                       <Select
                                          options={countryListDestination}
                                          isSearchable={true}
                                          isMulti
                                          styles={customStyles}
                                          className="mb-2"
                                          id="destination"
                                          onFocus={function (event) {
                                             var thisClass = document.querySelector("#destination" + "  div.css-yk16xz-control");
                                             thisClass.style.borderColor = "#cccccc";
                                          }}

                                       />
                                          </div>

                                       </div>
           
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                          <div className="col-12" style={{zIndex:100}}>
                                          <label className="form-label form-label-top form-label-auto">
                                          Vacation Style
                        </label>
                                       <Select
                                          options={travelStyleOption}
                                          styles={customStyles}
                                          isSearchable={true}
                                          className="mb-2"
                                          id="vacation_style"
                                          onFocus={function (event) {
                                             var thisClass = document.querySelector("#vacation_style" + "  div.css-yk16xz-control");
                                             thisClass.style.borderColor = "#cccccc";
                                          }}
                                       />
                                             </div>
                                             </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                           <input type="button" name="previous" className="previous_button previous mt-3 float-left" value="Previous" />
                           <input type="button" name="next" className="next action-button mt-3 float-right" value="Next" />
                        </fieldset>
                        <fieldset>
                           <div className="form-card">
                              <div className="row">
                                 <div className="col-12 col-md-7">
                                    <h2 className="fs-title">Booking Information :</h2>
                                    <hr style={{ border: "1px solid #4caf50" }} />
                                 </div>
                              </div>
                              <div>
                                 <label className="form-label form-label-top form-label-auto">
                                    Deparutre and Budget
                        </label>
                                 <div className="row">
                                    <div className="col-md-4 col-lg-6 col-sm-12">
                                       <SingleDatePicker
                                          noBorder={true}
                                          numberOfMonths={1}
                                          className="form-textbox mb-2"
                                          readOnly={true}
                                          date={this.state.date} // momentPropTypes.momentObj or null
                                          onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                                          focused={this.state.focused} // PropTypes.bool
                                          placeholder="Estimated Date Of Travel"// "EX: March 2020"
                                          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                          id="estimated_date" // PropTypes.string.isRequired,
                                          onFocus={function (event) {
                                             event.target.classList.remove("is-invalid");
                                             event.target.classList.remove("is-valid");
                                          }}
                                       />

                                    </div>
                                    <div className="col-md-4 col-lg-6 col-sm-12">

                                       <input type="text" className="form-textbox mb-2" placeholder="Estimated Budget Per Person"
                                          id="estimated_budget"
                                          onFocus={function (event) {
                                             event.target.classList.remove("is-invalid");
                                             event.target.classList.remove("is-valid");
                                          }} />
                                    </div>
                                                  
                                    <div className="col-md-6 col-12">
                                       <div className="row">
                                          <div className="col-12" style={{zIndex:100}}>
                                          <Select
                                          options={currencyList}
                                          isSearchable={true}
                                          styles={customStyles}
                                          className="mb-2"
                                          placeholder="Currency"
                                          id="currency"
                                          onFocus={function (event) {
                                             var thisClass = document.querySelector("#currency" + "  div.css-yk16xz-control");
                                             thisClass.style.borderColor = "#cccccc";
                                          }}

                                       />
                                          </div>
                                       </div>

                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <label className="form-label form-label-top form-label-auto">
                                    Additional Information
                        </label>
                                 <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                       <textarea className="form-control mb-2" rows="4" cols="100" id="additional_info"></textarea>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <div className="row">

<Form.Row className="p-2" style={{ textAlign: "left" }}>

<div   className="m-2 ml-5">
    <input
        className="form-check-input"
        type="checkbox"
        onChange={(value) =>
            this.setState({ agreePromotion: !this.state.agreePromotion })
        }
        id="defaultCheck1"
    />
    <label class="form-check-label" for="defaultCheck1">
        I would like to recieve newsfeed and promotion from Ethiopian Holidays
</label>
</div>

</Form.Row>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-12 col-md-6">
                                    <ReCAPTCHA
                                    sitekey="6LdZQ0gaAAAAANe9P3E4PEKYLzouQnLVq07FmjFx"// prod
                                    // sitekey="6Le_REgaAAAAAHZgFupnXrz7QYnyEyxJb-t0D4P5"// test
                                    onChange={()=>{
                                       this.setState({invalidGoogleRecapthcha:false})
                                    }}
                                    />
                                 </div>
                              </div>
                           </div>
                           <input type="button" name="previous" className="previous_button previous mt-3 float-left" value="Previous" />
                           <input type="button" name="next"  disabled={this.state.invalidGoogleRecapthcha} className="next action-button mt-3 float-right" onClick={this.submitTirpPlanne} value="Submit"/>
                        </fieldset>
                        <fieldset>
                           <div className="form-card">
                              <br /><br />
                              <h2 className="text-center" id="response-message-header"><strong>SUCCESS !</strong></h2>
                              <br />
                              <div className="row justify-content-center d-flex">
                                 {this.state.isErrorOccured ?
                                    <FontAwesomeIcon
                                       icon={faTimes}
                                       style={{ color: "red" }}
                                       className="lg fit-image"
                                    />
                                    : <FontAwesomeIcon
                                       icon={faCheck}
                                       className="lg fit-image "
                                    />}

                              </div>
                              <br /><br />
                              <div className="row justify-content-center">
                                 <div className="col-12 text-center">
                                    <h5 className="text-center" id="response-message-body">You Have Successfully Send Your Trip Plane</h5>
                                 </div>
                              </div>
                           </div>
                        </fieldset>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default TripPlanner;
