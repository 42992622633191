import React, { Component } from "react";
class FlightNotFoundException extends Component {
  state = {};
  render() {
    return (
      <div className="pt-5">
        <p className="h4">Sorry no schedule found for your search criteria</p>
        <a className="btn mt-3 border" href="/">
          Try again
        </a>
      </div>
    );
  }
}

export default FlightNotFoundException;
