import "./search-package.css";
import React, { Component } from "react";
import queryString from "query-string"; 
import JwPagination from "jw-react-pagination";
import { HttpService } from "../../../services/http/http-service";

import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Card, Modal, Label, Row, Col, Button, CardGroup, CardDeck } from 'react-bootstrap';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LazyLoad from 'react-lazyload';
import PackageDetails from "./../Holidays-type/package-details/package-details";
import ProgressLoader from "../../shared/progress-loading/progress-loader";
class PackageSearchLists extends Component {
  constructor(props) {
    super(props);
    // console.log("this.props");
    this.onChangePage = this.onChangePage.bind(this);
    let search = queryString.parse(this.props.location.search);
    this.state = {
      showModal: false,
      showModalData: {},
      items: [],
      pageOfItems: [],
      isLoaded: false,
      search: search,
    };
  }

  onChangePage(pageOfItems) {
    window.scrollTo({ top: 50, behavior: "smooth" });
    this.setState({ pageOfItems });
  }

  async componentDidMount() {
    this.getPackageList();
  }

  getPackageList() {
    
    // const relativePath2 = "/api/Packages/ByContent";

    var { isLoaded, items, search } = this.state;
    const relativePath2 = "/api/Packages/ByNameList?Name="+search.search;
    let countryCode2 = "ET";
    if (search.search) {
      countryCode2 = "ET";
    } else {
      countryCode2 = "ET";
    }
    let data = {
      SearchBy: search.SearchType?search.SearchType:"Type",
      Id:47,// this.props.match.params.packageTypeId,
      CountryCode:"ET" ,//countryCode2,
    };
 
    HttpService.getServicePackage(relativePath2)
      .then((response) => {
        // console.log("response")
        // console.log(response)
        let responseData = response.data;
        this.setState({
          items: responseData.packagesList,
          isLoaded: true,
        });
      })
      .catch((error) => {
        // console.log("responseData error2 ");
      });
  }

  showPackageDetails = (packageData) => {
    let search =
      "?packageName=" +
      packageData.package.name +
      "&packageId=" +
      packageData.package.id;
    let path = "/package-details/" + search;
    window.location.assign(path);
  };

  render() {
    var { isLoaded, items, search } = this.state;
    if (!isLoaded) {
      return (
        <div className="mt-5 pt-5"> 
        <ProgressLoader displayMess= {"Searching Packages " +search.search} /> 
        </div>
      );
    } else {
      return (
               <> 
                <div className=" container pt-5 pb-3  ">

              <h4  className="text-center   mv-3 "> <span className="text-center  text-muted"> Showing results for </span>"{search.search}" in Ethiopian Holiday Packages</h4>
        

                  {(items.length<1) && 
                                    <Card.Header style={{ textAlign: "left" }} className="d-flex mh-0    mv-5  success"   variant="success">
                                  <h2><strong className="text-muted">Sorry, we couldn't find any package matching "{search.search}"</strong>
                                  </h2>
                              </Card.Header>
                  }


                {(items.length>=1) &&
                        <>  
                           <Card.Header style={{ textAlign: "left" }} className="d-flex mh-0    mv-5 success"   variant="success">
                        <h5  ><strong>Search results matching "{search.search}"</strong>
                        </h5>
                    </Card.Header>
                            {this.state.pageOfItems.map((item) =>
                                <>    
                                <LazyLoad height={200} offset={100}>
                                    <Card className="text-center rounded2  pt-0 pb-0 shadow bg-white" style={{ borderColor: (this.state.selectedPackage === item.package.id) ? "green" : "white", }} >
                                        <Card.Body className="text-center  mb-0 p-0 ">
                                            <Row >
                                                <Col sm={4}> 
                                                        <LazyLoadImage  className="card-img rounded2"  style={{ cursor: "pointer !important", maxHeight: "150px" }}
                                                      alt={""} 
                                                      effect="blur"
                                                      src={item.package.imagePath} />
                                                 
                                                </Col>
                                                <Col sm={5}>
                                                    <Card.Body className="pt-3 pr-3 pl-2" style={{ textAlign: "left", }}>
                                                        <Card.Subtitle className="mb-2 shorttitle"><strong>{item.package.name}</strong>
                                                        </Card.Subtitle>
                                                        <Card.Text className="shortDesc  ">
                                                            {item.package.description&&<p   >{parse(item.package.description)}</p>} 
                                                        </Card.Text>
                                                        <Card.Link className="cardLink" onClick={() => this.setState({ showModal: true, showModalData: item })} style={{ color: "green" , cursor:"pointer !important"}}>Highlights</Card.Link>
                                                        <Card.Link className="cardLink" >
                                                            {(this.state.selectedPackage !== item.package.id) &&
                                                                <button type="button" onClick={() => {
                                                                    this.selectPackageIndex(item);
                                                                }
                                                                } class="btn  btn-outline-success btn-sm float-right" 
                                                                onClick={() => this.showPackageDetails(item)}>View More
                                                                 </button>
                                                            }
                                                            {(this.state.selectedPackage === item.package.id) &&
                                                                <button type="button" onClick={() => this.setState({ selectedPackage: null })} class="btn btn-outline-success rounded2 btn-sm float-right">
                                                                    <span className="">  <FontAwesomeIcon size="lg" icon={faCaretUp} />
                                                                    </span>
                                                                </button>}
                                                        </Card.Link>
                                                    </Card.Body>
                                                </Col>
                                                <Col className="p-3 pl-1" style={{ borderLeft: "1px solid green" }} sm={3}>



                                                    <div className="row d-flex pl-3" style={{ textAlign: "left" }}>
                                                          <div className="col-6 col-md-12 col-sm-12"><Card.Subtitle className="mb-2"><strong>{item.package.caption}</strong></Card.Subtitle>
                                                        </div>
                                                        <div className="col-6 col-sm-12"><Card.Subtitle className="mb-2 text-muted">Duration:</Card.Subtitle>
                                                        </div>
                                                        <div className="col-6 col-sm-12"><Card.Subtitle className="mb-2"><strong>{item.package.numberOfDays} Day(s)</strong>  </Card.Subtitle>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </Card.Body> 
                                    </Card>
                                    <br />
                                    
                           </LazyLoad>
                                </>
                            )

                            }
                        </>
                    }


                <JwPagination
                              pageSize={10}
                              items={items}
                              onChangePage={this.onChangePage}
                            />

                <Modal
                    scrollable={true}
                    size="xl"
                    aria-labelledby="example-custom-modal-styling-title"
                    show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header closeButton className="p-1 pl-3 pt-0">
                        <Modal.Title >Package Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PackageDetails packageDetails={this.state.showModalData} />
                    </Modal.Body>
                </Modal>
 

          </div>
        </>
      );
    }
  }
}

export default PackageSearchLists;
