import React, { Component } from "react";
class FlightSelectionException extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="pt-5">
        <p>Sorry unable to process your request please try again</p>
        <a className="btn mt-3 border" href="/">
          Try again
        </a>
      </div>
    );
  }
}

export default FlightSelectionException;
