import React, { Component } from "react";
import "../payment-summery/paymentSummery";
import { Link } from "react-router-dom";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { HttpService } from "../../../services/http/http-service";

//#7792 destination committed
class PaymentResponse extends Component {
  constructor(props) {
    super(props);
    this.state = { paymentResponse: "",error :false, message: "" };
  }

  async componentDidMount() {
    this.getResponse();
  }

  getResponse() {
    let paymentType=localStorage.getItem("paymentType");
    let pathname = window.location.pathname;
    if (pathname === "/payment-3d") {
      this.setState({ paymentResponse: "Payment 3D", message: "Payment 3D Loading..." });
     
    } else if (pathname === "/payment-approved") {
       this.setState({
        paymentResponse: "Payment Approved",
        message:
          "The transaction is approved and completed successfully. Please check your email to see all documents are well received!",
      }); 
      if(paymentType=="3d"){ 
      this.goToSummary();
      }
    } else if (pathname === "/payment-cancelled") {
      this.setState({
        paymentResponse: "Payment Cancelled",
        message:
          " Unfortunately, Payment has been terminated. Please check your email to see if all documents are well received. If not, please contact our ET Holidays with + 251 116 65 6666 (international charges might apply).        ",
      });
    } else if (pathname === "/payment-declined") {
      this.setState({
        paymentResponse: "Payment Declined",
        message:
          "Unfortunately, the transaction didn’t go through successfully. Please contact your bank or use an alternate card!",
      });
    } else if (pathname === "/payment-default") {
      this.setState({ paymentResponse: "Payment Default", message: "" });
    } else if (pathname === "/payment-error") {
      this.setState({
        paymentResponse: "Payment Error",
        message:
          " An error occurred while processing the transaction. Please consider to use alternate card or contact your bank. Otherwise, please communicate our ET Holidays agents with + 251 116 65 6666 (international charges might apply).        ",
      });
    } else if (pathname === "/payment-pending") {
      this.setState({
        paymentResponse: "Payment Pending",
        message:
          "  Your payment is being checked. If you don’t get your travel voucher in the next 6 hours by e-mail, please contact our ET Holidays with + 251 116 65 6666 (international charges might apply).",
      });
    } else if (pathname === "/payment-expired") {
      this.setState({
        paymentResponse: "Payment Expired",
        message:
          "Unfortunately, the transaction didn’t go through successfully due to expired card. Please contact your bank or use alternate card!",
      });
    } else {
      this.setState({ paymentResponse: "Unexpected Error" });
    }
  }
  goToSummary() {
    let sessionId = localStorage.getItem("sessionId");
    let approvalPath = "/Payment/Approved3D";

    HttpService.postServiceDefualt(
      {
        sessionId: sessionId,
      },
      approvalPath
    )
      .then((response) => {
        
        this.setState({ isLoadingPayment: false });
        let paymentApiResponse = response.data;
        // console.log("paymentApiResponse",paymentApiResponse);
        if (
          paymentApiResponse.statusAPI == "SUCCESS" ||
          paymentApiResponse.statusAPI == "success"||
          paymentApiResponse.statusAPI == "Success"
        ) {
          localStorage.setItem("paylocal", JSON.stringify(paymentApiResponse));
          localStorage.setItem(
            "summaryDetails",
            JSON.stringify(paymentApiResponse.summary)
          );
          window.location.replace("/payment-summary-details");
        } else if (paymentApiResponse.statusAPI == "ERROR") {
          localStorage.removeItem( "summaryDetails")
          this.setState({
          error:true,
           message: paymentApiResponse.messageAPI,
            ResponseMessage:
              " ERROR: Unable to process payment, Please check the card details you entered and try again",
          });
        }
        else{
          this.setState({
          error:true,
           message: paymentApiResponse.messageAPI,
            ResponseMessage:
              " Error occured payment",
          });

        }
      })
      .catch((error) => {
        this.setState({
          error:true,
          ResponseMessage:  " ERROR: Unable to process payment, Please check the card details you entered and try again",
          message: " ERROR: Unable to process payment, Please check the card details you entered and try again",
           
        });
      });
  }
  render() {
    return (<>


     {(this.state.error) &&<>
<div className="p-2 container pt-0 text-center ">

        <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
        <Card.Header
          style={{ textAlign: "left" }}
          className="d-flex p-0 pl-3 success"
          style={{}}
          variant="success"
        >
        <h4 className="text-center pt-0 pb-0">Error Occured </h4>{" "}
        </Card.Header>
        <Card.Body style={{ textAlign: "left" }} id="demo" className="">
          <div className="container">
            <br />
            <h6 className="payment-status-msg ">
              <p> {this.state.message} </p>
            </h6>
            </div>
            </Card.Body>
            </Card>
         
         </div>   </>
     }
     {(!this.state.error) &&
     
     <div className="p-2 container pt-0 text-center ">
        <h4 className="text-center pt-0 pb-0">Payment Response </h4>
        {this.state.paymentResponse === "Payment Approved" && (
          <div className="ml-2  row" style={{ textAlign: "left" }}>
            <p>Thank you for your booking!</p>
            <p>
              {/* *ngIf="TourTypePreference == 'flightPlusHotel'" */} Your
              travel ticket and hotel booking are now ready. Please check your
              E-mail for more information.
            </p>
            {/* *ngIf="TourTypePreference == 'flightPlusPackage'" */}
            {/* <p>  Your travel ticket and package are now ready. Please check your E-mail for more information. </p> */}
            <p>
              For any further information please contact us via E-mail:
              ETHolidays@ethiopianairlines.com or Phone: +251 16179900
            </p>
          </div>
        )}

        <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
          <Card.Header
            style={{ textAlign: "left" }}
            className="d-flex p-0 pl-a3 success"
            style={{}}
            variant="success"
          >
            <h5>{this.state.paymentResponse}</h5>{" "}
          </Card.Header>
          <Card.Body style={{ textAlign: "left" }} id="demo" className="">
            <div className="container">
              <br />
              <h6 className="payment-status-msg ">
                <p> {this.state.message} </p>
              </h6>
              {this.state.paymentResponse === "Payment Approved" && (
                <>
                  <div className="center-align">
                    <Spinner animation="border" />
                    <p>loading summary Page</p>
                  </div>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
        <br />

        {this.state.paymentResponse !== "Payment Approved" && (
          <Row className="pl-5">
            <Col style={{ textAlign: "right" }} sm={12}>
              <Link to={"/"}>
                <button type="button" className="btn btn-success btn-sm">
                  <strong className="pr-5 pl-5 "> Back Home</strong>
                </button>
              </Link>
            </Col>
          </Row>
        )}
      </div>}
      </>
    );
  }
}

export default PaymentResponse;
