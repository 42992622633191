import React from 'react'; 
import "./flightalternative.css" ;

import { Dropdown, Button} from 'react-bootstrap';
import Tabs from 'react-responsive-tabs';

import Breadcrumb from 'react-bootstrap/Breadcrumb'


// IMPORTANT you need to include the default styles
import 'react-responsive-tabs/styles.css';
import { isMobile } from 'react-device-detect';
 
const presidents = [
  { name: 'George Washington', biography: '...' },
  { name: 'Theodore Roosevelt', biography: '...' },
  { name: 'George Washington', biography: '...' },
  { name: 'Theodore Roosevelt', biography: '...' },
  { name: 'George Washington', biography: '...' },
  { name: 'Theodore Roosevelt', biography: '...' },
  { name: 'George Washington', biography: '...' },
  { name: 'Theodore Roosevelt', biography: '...' },
];


const m= JSON.parse(localStorage.getItem("ethiopianPackages"));
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class FlightAlternative extends React.Component {
    constructor(props) {
        super(props); 
  //  var isAlternativeFlights1;
  //   if (localStorage.getItem("flightInfo")=="null"){ 
  //       isAlternativeFlights1= true ; 
  //       }
  //    else{
  //       isAlternativeFlights1= false ; 
  //     }

  this.state = {
    selectedOption: "mesfin bali",
    searchRequest:{},
    flightalternateDay:null,
    flightIndex:0,
    isAlternativeFlights: false ,
    AlternativeFlightsDayClicked: false ,
    searchRequestObj:[],
    flightInfo:null
  };
}

async componentDidMount(){ 

    await this.setState({ flightIndex:0});
    await this.setState({ AlternativeFlightsDayClicked: false});
    await  localStorage.setItem("AlternativeFlightsDayClicked",false) ;
  let test = await JSON.parse(localStorage.getItem("searchRequestObj"));
  let flightInfo = await JSON.parse(localStorage.getItem("flightInfo"));
  if (test != null && test != "undefined") {
     await this.setState({ searchRequestObj: test }); 
      // console.log("searchRequestObj: test",test);
    // searchRequestObjectFromLS = JSON.parse(
    //   localStorage.getItem("searchRequestObj")
    // );
  }


  if (flightInfo!=null){ 
    await this.setState({ flightInfo: flightInfo});
   }

  if (localStorage.getItem("flightInfo")== "null" ){ 
    await this.setState({ isAlternativeFlights: true });

   }

    if (this.props.flightalternateDay!= null) {
  await  this.setState({
      flightalternateDay: this.props.flightalternateDay,
      });
    }
    
     var m= await JSON.parse(localStorage.getItem('searchRequestObj' )) 
// console.log("flightalternateDay flightalternateDay",this.props.flightalternateDay);

 this.setState({searchRequest:m})
}

 getTabs=()=> {
  return presidents.map((president, index) => ({
    title: president.name,
    getContent: () => president.biography,
    /* Optional parameters */
    key: index,
    // panelClassName: 'panel',
    tabClassName: 'tab',
    // transform: true,
    // showMore: true,
    // transformWidth: 800,
  }));
}



changeFlightDate=(fDate)=>{
     localStorage.setItem("AlternativeFlightsDayClicked",true) ;
  // console.log("fDate", fDate)
//   awardBooking: false
// cabinClass: 3
// itineraryParts[0].when: {date: "2021-06-26"}
var fNewDate= new Date(fDate)
var req={}
req=this.state.searchRequest
var dMonth=fNewDate.getMonth()+1
var NDate=fNewDate.getFullYear()+"-"+dMonth+"-"+fNewDate.getDate();

req.itineraryParts[this.props.flightIndex].when= {date: NDate} 

localStorage.setItem('searchRequestObj', JSON.stringify(req));
if(this.state.flightInfo){
 var shoppingCode=this.state.flightInfo[0].shoppingBasketHashCode;
  var arrivalDate=this.state.flightInfo[0].arrivalDate;
   var departDate=this.state.flightInfo[0].departDate;
  // 1 altenative page
  this.props.getSelectedBrandShoppingCode(shoppingCode, arrivalDate, departDate);
}
else{
window.location.reload();
}


}
parseTime = (minute) => {
  let hours = parseInt(minute / 60);
  let minutes = minute % 60;
  return <span>{hours + "hrs " + minutes + " mins"}</span>;
};
 
parseDateTime=(d)=>{
  let gettDate= new Date(d)
 let m= gettDate.getTime()
  return m;
}

isAlternativeDisabled = (altDay,b) => {
  if(altDay.amount==0){
    return true;
  }
  if(altDay.date==0){
    return true;
  }
  var currentDate=this.parseDateTime(this.props.itineraryParts[this.props.flightIndex].when.date);
  var nextDate;
  var prevDate;
  var altDate=this.parseDateTime(altDay.date); 
  if(this.props.flightIndex+1<this.props.itineraryParts.length){
    nextDate=this.parseDateTime(this.props.itineraryParts[this.props.flightIndex+1].when.date);
    if(nextDate<altDate){
      return true 
      }
      else{ 
        return false
      }
  }
  else{
    prevDate=this.parseDateTime(this.props.itineraryParts[this.props.flightIndex-1].when.date);
    if(prevDate>altDate){
      return true 
      }
      else{ 
        return false
      }
  }
        return false
}
isEqual = (a,b) => {
  // console.log("is equal?")
  // console.log(a.props.children)
  // console.log(b.props.children)
  if(a.props.children==b.props.children){
  return true 
  }
  else{ 
    return false
  }
}
parseDate = (dateString) => {
  let options = {
    month: "short",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
  };
  let date = new Date(dateString);
  let newDate = date.toLocaleString("en-GB", options);
  return <span>{newDate}</span>;
};


  handleChange = selectedOption => {
    this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
  };
 
  render() {
    const { selectedOption } = this.state;

    return (<>
    
    {/* {this.state.isAlternativeFlights&& */}
    <div className="container">  

 
{isMobile&&<>
      { (this.state.flightalternateDay)&&
<Dropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    Alternative Dates  
  </Dropdown.Toggle>

  <Dropdown.Menu> <>
  {this.props.flightalternateDay.map((m)=><>
{(this.props.isOneWay)&&<>
    <Dropdown.Item   className="dropdownItem"  
     onClick={()=>{this.changeFlightDate(m.date)}} 
     disabled={m.amount==0}  
     > 
      {this.parseDate(m.date)}
      </Dropdown.Item>
  </>}
  
{(!this.props.isOneWay)&&<>

    {(this.props.itineraryParts)&&<>
   {/* {(this.parseDateTime(this.props.itineraryParts[1].when.date)>= this.parseDateTime(m.date))&& */}
   
   <Dropdown.Item className="dropdownItem" 
   onClick={()=>{this.changeFlightDate(m.date)}}
   disabled={m.amount==0}  
   >
    
     <span> {this.parseDate(m.date)} - {(this.props.itineraryParts)&& <span> { this.parseDate(this.props.itineraryParts[1].when.date)}</span> } </span> 
       </Dropdown.Item>
       
       {/* } */}
      </>
       }
  </>}
  </>)
  } 
  </>
  </Dropdown.Menu>
</Dropdown>}  
      

</>}


     
{!isMobile&&<>

     { (this.state.flightalternateDay)&&
     
      <Breadcrumb className="  "> <>
      {this.props.flightalternateDay.map((m)=>
      <>
      
      {(this.props.isOneWay)&&<>
       
   <Breadcrumb.Item as="Col" 
    className="dropdownItem"
     active={(this.parseDateTime(this.props.itineraryParts[0].when.date)== this.parseDateTime(m.date))?true:false} 
     >
   
        <Button   disabled={m.amount==0}  onClick={()=>{this.changeFlightDate(m.date)}}
    //  className={((this.parseDateTime(this.state.searchRequestObj.itineraryParts[0].when.date)== this.parseDateTime(m.date))?"font-weight-bold borderBottomactive text-success pr-4 pl-4 m-0":"borderBottom m-0 pr-4 pl-4" } 
     className={(this.isEqual(this.parseDate(this.state.searchRequestObj.itineraryParts[0].when.date),this.parseDate(m.date)))?"font-weight-bold borderBottomactive text-success pr-4 pl-4 m-0":"borderBottom m-0 pr-4 pl-4" } 
    active={(this.parseDateTime(this.state.searchRequestObj.itineraryParts[0].when.date)== this.parseDateTime(m.date))?true:false} 
     variant="outline-secondary">
     <span > 
      {/* <span> {this.parseDateTime(this.props.itineraryParts[0].when.date)}</span>:  */}
      <span> {this.parseDate(m.date)}</span>: 
      <br /> 
      <span> { m.currency}</span><span> {m.amount}</span>
     </span>
       </Button>{' '}
         
         </Breadcrumb.Item>
  </>}
  
{(!this.props.isOneWay)&&<>

    {(this.props.itineraryParts)&&
    <>
   {/* {(this.parseDateTime(this.props.itineraryParts[1].when.date)>= this.parseDateTime(m.date))&& */}
   
   <Breadcrumb.Item as="Col"  className="dropdownItem" >
   
    <Button 
    //  m.amount==0
     disabled={(this.isAlternativeDisabled(m,""))?true:false} 
     onClick={()=>{this.changeFlightDate(m.date)}}
     className={(this.isEqual(this.parseDate(this.props.itineraryParts[this.props.flightIndex].when.date),this.parseDate(m.date)))?"font-weight-bold borderBottomactive text-success pr-4 pl-4 m-0":"borderBottom m-0 pr-4 pl-4" } 
    //  className={(this.parseDateTime(this.state.searchRequestObj.itineraryParts[0].when.date)== this.parseDateTime(m.date))?"font-weight-bold borderBottomactive text-success pr-4 pl-4 m-0":"borderBottom m-0 pr-4 pl-4" } 
    // active={(this.parseDateTime(this.props.itineraryParts[0].when.date)== this.parseDateTime(m.date))?true:false} 
     variant="outline-secondary">
     <span > 
      <span> {this.parseDate(m.date)}</span>:
       {/* {this.props.flightIndex}:{this.props.itineraryParts.length} */}
      <br /> 
      <span> { m.currency}</span><span> {m.amount}  </span>
     </span>
       </Button>{' '} 

     </Breadcrumb.Item>
     {/* } */}
      </> 
       }
  </>} 
   </>
    
    )}
  </>
</Breadcrumb>
  }
  </>
  }

  {/* <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
      </div>
      
       {/* } */}
     </>
    );
  }
}

export default FlightAlternative;