import React, { Component } from "react";
import "./destination.css";
import { Link } from "react-router-dom";
//#7808 dynamic menu commited again
import { HttpService } from "../../../../services/http/http-service";

class Destination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            ethiopianPackages: [],
            isLoaded: true,
        };
        
    }

    async componentDidMount() {

        

        var continents = localStorage.getItem("Destinations");
        var etPackages = JSON.parse(localStorage.getItem("ethiopianPackages"));

        //debugger

        if (continents) {

            if (continents.expiresInDays) {

                if (Date.now() > continents.expiresInDays) {
                    // get sliders from api
                    this.getDestinations();
                }

                this.setState({
                    items: continents.data
                })
            }
            else {
                this.getDestinations();
            }
               
        }
        else {
            this.getDestinations();
        }

        if (etPackages) {

            if (etPackages.expiresInDays) {
                if (Date.now() > etPackages.expiresInDays) {
                    // get sliders from api
                    this.getEthiopianPackages();
                }

                this.setState({
                    ethiopianPackages: etPackages.packages
                })
            }
            else
                this.getEthiopianPackages();

        }
        else {
            this.getEthiopianPackages();
        }

    }

    getDestinations() {
       
        const relativePath = "/MasterDatas/GetMasterDatas?SearchType=CONTINENT";
        HttpService.getServicePackage(relativePath)
            .then((response) => {
                let responseData = response.data;

                this.setState({
                    items: responseData.continents,
                    isLoaded: true,
                });

                let timeNow = Date.now();             

                localStorage.setItem(
                    "Destinations",
                    JSON.stringify({
                        lastRefershed: timeNow,
                        expiresInDays: timeNow + 1000 * 60 * 60 * 24 * 3, // 3 days
                        data: responseData.continents                        
                    })
                );

              
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    getEthiopianPackages() {

        const relativePath2 = "/api/Packages/GetpackageInfo";

        HttpService.postServicePackage({ CountryCode: "ET" }, relativePath2)
            .then((response) => {
                this.setState({ isLoading: false });

                let responseData = response.data;
                let timeNow = Date.now();

                //debugger

                localStorage.setItem(
                    "ethiopianPackages",
                    JSON.stringify({
                        packages: responseData.list,
                        lastRefreshed: timeNow,
                        expiresInDays: timeNow + 1000 * 60 * 60 * 24 * 3 // 3 days
                    })
                );

                this.setState({
                    ethiopianPackages: responseData.list,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    showDestination = (continentName, currentDestinations) => {
  
        localStorage.setItem(
            "currentDestinations",
            JSON.stringify(currentDestinations)
        );
        let path = "/destination/" + continentName;

        window.location.replace(path);
    };

    showCountry = (countryCode) => {        
        let search = "?countryCode=" + countryCode;
        let path = "/country/" +  search;

        window.location.replace(path);
    };

    showPackageTypeInEthio = (
        countryCode,
        item
    ) => {
        //debugger
        localStorage.setItem("item", JSON.stringify(item));

        let packageTypeId = item.packages[0].package.packageTypeId;
        let packageTypeName = item.packages[0].package.packageType.name;
        let params =
            "?packageTypeId=" + packageTypeId +
            "&packageTypeName=" + packageTypeName +
            "&countryCode=" + countryCode + 
            "&countryName=Ethiopia" +
            "&SearchType=COUNTRYCODE";

      
       
        //  /54/?countryName=Ethiopia&countryCode=ET&packageTypeId=54&packageTypeName=Nature%20and%20Adventure%20Tours&SearchType=COUNTRYCODE

        let path = "/package-lists/" + packageTypeId  + params;

        window.location.assign(path);
    };

    render() {
        var { isLoaded, items, ethiopianPackages } = this.state;
        if (!isLoaded) {
            return <div>Loading top destinations...</div>;
        } else {
            return (
                <div className=" positionsCont">
                    <div className=" pl-5 pr-5">
                        <div
                            className="row row-cols-2  pl-0 "
                            style={{ backgroundColor: "white" }}
                        >
                            <div className="col-2 pl-0 " style={{ minWidth: "220px" }}>
                                <ul className="list-group d-flex list-group-flush ">
                                    <li className="list-group-item pb-0 d-flex">
                                        <strong>Ethiopia</strong>
                                    </li>
                                    {ethiopianPackages && ethiopianPackages.map((item2, key) => (
                                        <li
                                            style={{cursor: "pointer"} }
                                            key={key}
                                            className="list-group-item pt-1 pb-0  d-flex listgroups"
                                            onClick={() =>
                                                this.showPackageTypeInEthio(
                                                    "ET",
                                                    item2
                                                )
                                            }
                                        >
                                            {item2.packageType}
                                            {item2.id}
                                        </li>
                                    ))}

                                    <li
                                        className="list-group-item seeALlList d-flex"
                                        onClick={() => this.showCountry("ET")}
                                    >
                                        <strong className="green success">See All</strong>
                                    </li>
                                </ul>
                            </div>

                            {items.map((item, key) => (
                                <div
                                    className="col-2 pl-0"
                                    style={{ maxWidth: "180px" }}
                                    key={key}
                                >
                                    <ul className="list-group d-flex list-group-flush">
                                        <li className="list-group-item pb-0 d-flex">
                                            <strong>{item.name}</strong>
                                        </li>
                                        {item.countries.map((item2, key) => (
                                            <li
                                                key={key}
                                                className="list-group-item pt-1 pb-0  d-flex listgroups"
                                                onClick={() => this.showCountry(item2.code)}
                                            >
                                                {item2.name}
                                            </li>
                                        ))}

                                        <li
                                            className="list-group-item  d-flex seeALlList"
                                            destination={item}
                                            onClick={() => this.showDestination(item.name, item)}
                                        >
                                            <strong className="green success">See All</strong>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                            {/* </>} */}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Destination;
