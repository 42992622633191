import React, { Component } from 'react';
// import './top-destinations.css';
import { Link } from 'react-router-dom';
import { HttpService } from "../../../services/http/http-service";
import { Carousel, Button, Card, Accordion, Collapse, Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';
// #8263 Mobile - FAQ page hidden contents 
class FAQ extends Component {
    constructor(props) {
        super(props);
        let FAQdata = [
            {
                "faqId": 1,
                "question": "What can I do if there is an issues with my ET Holidays booking?",
                "answer": "If you are having any difficulties with your booking, please contact us by calling +251 116179900 or by emailing ETHolidays@ethiopianairlines.com .",
                "ispublished": true
            },

            {
                "faqId": 2,
                "question": "What can I do if I lost my booking reference?",
                "answer": "Please check your emails for the confirmation that you were sent after booking. If you still cannot find this, please send an email to ETHolidays@ethiopianairlines.com  , confirming your departure date and the lead name on your booking. Please also include a contact number, just in case any further information is needed from you to find your booking. Your confirmation will then be sent to you again with all the details of your booking.",
                "ispublished": true
            },
            {
                "faqId": 3,
                "question": "Can I change/cancel my booking?",
                "answer": "All changes are subject to ET Holidays Standard Booking Conditions. If you would like to change/cancel your booking, the best thing to do is call us on +251 116179900 or email us at ETHolidays@ethiopianairlines.com  and discuss your options.",
                "ispublished": true
            },
            {
                "faqId": 4,
                "question": "How I can get transfer?",
                "answer": "first it will be checked our website and if not we will contact our service providers and give you a service you want.",
                "ispublished": true
            },
            {
                "faqId": 5,
                "question": "what are the available packages Ethiopian Holidays offers?",
                "answer": "Ethiopian Holidays offers different packages to different destinations. Please refer www.ethiopianholidays.com ",
                "ispublished": true
            },
            {
                "faqId": 6,
                "question": "How I can get the packages not available on Ethiopian Holidays packages?",
                "answer": " Ethiopian Holidays can contact service providers at each of destinations and provide you the possible package options. So, you will be expected to provide number of passengers travelling together, age of child)if any, meal type, travel date, hotel type(3,4,5*), meal type( breakfast, lunch  or dinner) and email to ETHolidays1@ethiopianairlines.com",
                "ispublished": true
            },

            {
                "faqId": 7,
                "question": "How do I know if my booking is successful?",
                "answer": "If you get an error message, your booking is unlikely to have been successful. When you make a booking and it has been confirmed, your ET Holidays booking documents will automatically be sent to you, to the email address that you have provided. If you are not sure whether your booking was successful for another reason please don�t just make another booking as you may end up getting charged twice. If you are unsure if your booking has gone through please E-mail a member of the Support Team ETHolidays@ethiopianairlines.com , who will be able to check your details and confirm.",
                "ispublished": true
            },
            {
                "faqId": 8,
                "question": " Can I physically go to Ethiopian Holidays and buy from them?",
                "answer": "Yes. Ethiopian Holidays found at Ethiopian Airport Enterprise head office building, 1st floor",
                "ispublished": true
            },
            {
                "faqId": 9,
                "question": "How I can work with as selling partner?",
                "answer": " A travel agency with IATA member can contact ETHolidays@ethiopianairlines.com  and sign agreement . Then, briefing and training will be given which enables the travel agency to sell Ethiopian Holidays on commission bases.",
                "ispublished": true
            },
            {
                "faqId": 11,
                "question": "How I can get family room?",
                "answer": "first it will be checked our website and if not we will contact our service providers and give you a service you want.",
                "ispublished": true
            },
            {
                "faqId": 12,
                "question": "Can I pay in Ethiopian birr?",
                "answer": "If you base in Ethiopia, yes you can pay in Ethiopian birr or other hard currencies ",
                "ispublished": true
            },
            {
                "faqId": 13,
                "question": "How the Hotel knows my payment confirmation?",
                "answer": "you will show them the voucher Ethiopian Holidays will give you.",
                "ispublished": true
            },


        ];
        this.state = {
            items: FAQdata,
            isLoaded: true,
        }

    }

    //   async componentDidMount() {

    //       DataService
    //   }



    render() {
        var { isLoaded, items } = this.state;
        if (!isLoaded) {
            return <div>Loading Frequently Asked Questions...{isLoaded}</div>;
        }
        else {
            return (
                <div className="container p-1 pb-5 pt-2">
                    <div>

                        <div className="pb-0 ">
                            <Card className="shadow">
                                <Card.Header className="d-flex">FAQ</Card.Header>

                                <Card.Body>
                                    <Card.Title> Frequently Asked Questions</Card.Title>

                                    <Accordion defaultActiveKey="0">
                                        {items.map((activity) =>

                                            <Card className="d-flex shadow m-1" >
                                                <Accordion.Toggle className="d-flex  shadow" style={{ backgroundColor: "white", color: "black" }} as={Card.Header} eventKey={activity.faqId}>
                                                    <span className="pr-2">  <FontAwesomeIcon size="lg" icon={faCaretDown} />
                                                    </span> {activity.question}
                                                </Accordion.Toggle>
                                                <Accordion.Collapse className="shadow-sm mb-4" eventKey={activity.faqId} style={{ borderLeft: "solid 2px green ", borderTopWidth: "20px !important", borderTop: "solid 2px green " }}>
                                                    <Card.Body className="d-flex " style={{ textAlign: "left" }} >{activity.answer}</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )}
                                    </Accordion>

                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>

            );
        }

    }


}

export default FAQ;