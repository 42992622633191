import React, { Component } from "react";
import "./hotel.css";
import HotelRoom from "./hotel-room";
import HotelAmenity from "./hotel-amenity";
import HotelRating from "./hotel-rating";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ShoppingCart from "../ShoppingCart/shopping-cart";
import BreadCrumb from "../shared/breadcrumb/breadcrumb";
import GallaryImagesSlide from "./../shared/gallary/GallaryImagesSlide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { isMobile } from "react-device-detect";

class HotelDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelDetail: null,
      settings: {
        dots: false,
        infinite: true,
        speed: 100,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (this.props.location.state !== undefined) {
      this.setState({
        hotelDetail: this.props.location.state.hotelInformation,
      });
      localStorage.setItem(
        "hotelDetails",
        JSON.stringify(this.props.location.state.hotelInformation)
      );
    } else {
      this.props.history.push({
        pathname: "/hotel",
      });
    }
  }
  render() {
    //debugger

    if (this.state.hotelDetail != null) {
      return (
        <>
          <div className="text-center">
          <BreadCrumb pageIndex={2} />
          </div>
          <div className="row m-0 p-0 w-100">
            <div className="col-12 col-md-3 order-md-2 m-0">
              <ShoppingCart id={"hotel"} className="m-3" pageIndex={2} />
            </div>
            <div className="col-12 col-md-9 order-md-1 m-0">
              <div className="text-center   hotel-detail">
                <div className="row m-0 hotel-room-container mt-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-left pb-2">
                    <a className="btn btn-success mb-3 " href="/hotel">
                      {isMobile&&"Back"} {!isMobile&&"Back To  Hotel Lists"}
                    </a> 
                  </div>
                </div>
                <div className="row m-0 text-left">
                  <div className="col-12">
                    <h3 className="hotel-name">
                      {this.state.hotelDetail.hotel.name}
                      <span>
                        <HotelRating
                          hotelRating={
                            this.state.hotelDetail.hotel.categoryCode
                          }
                        />
                      </span>
                    </h3>
                  </div>
                  <div className="col-12">
                    <span className="mb-5 ">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ color: "red", fontSize: "16px" }}
                      />
                    </span>{" "}
                    {this.state.hotelDetail.content.address.content}
                  </div>
                </div>

                {this.state.hotelDetail.content.images && (
                  <GallaryImagesSlide
                    className="m-5"
                    post={this.state.hotelDetail.content.images}
                    imageType={"hotelImageList"}
                  ></GallaryImagesSlide>
                )}

                <br />

                <div className="row m-0 text-left">
                  <div className="col-12">
                    <h3 className="hotel-name">Hotel Information</h3>
                  </div>
                  <div className="col-12">
                    <p>{this.state.hotelDetail.content.description.content}</p>
                  </div>
                </div>

                {/* <div className="row m-0 text-left pt-2 pb-2">
            <div className="col-2">Overview</div>
            <div className="col-2">Rooms</div>
            <div className="col-2">Amenties</div>
            <div className="col-2">Location</div>
          </div> */}

                <div className="row m-0 hotel-gallery-container">
                  {/* <Slider {...this.state.settings}> */}
                  {/* <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 hotel-gallery-img"
              style={{
                backgroundImage:
                  "url(" +
                  window.location.origin +
                  "/assets/image/special-offer/axum.jpg" +
                  ")",
              }}
            ></div> */}
                  {/* </Slider> */}
                </div>
                <div className="row m-0 hotel-room-container mt-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-left pb-2">
                    <h3>Choose your room</h3>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                    {this.state.hotelDetail.hotel.rooms.map((room, key) => (
                      <div key={key}>
                        <HotelRoom
                          roomInfo={room}
                          localCurrency={
                            this.state.hotelDetail.hotel.localCurrency
                          }
                          index={key}
                          hotelName={this.state.hotelDetail.hotel.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row m-0 text-left">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-left pb-2">
                    <h3>Hotel Amenities</h3>
                  </div>
                  {this.state.hotelDetail.content.facilities.map(
                    (facility, facilityKey) => (
                      <HotelAmenity facilty={facility} key={facilityKey} />
                    )
                  )}
                </div>
              </div>
              {this.state.hotelDetail.content.facilities.map(
                (facility, facilityKey) => (
                  <HotelAmenity facilty={facility} key={facilityKey} />
                )
              )}
            </div>
          </div>
 
                 </>
      );
    } else {
      return (
        <>
          <button
            className="btn btn-success mr-8"
            style={{ right: 100, position: "absolute" }}
          >
            <span>
              <ShoppingCartIcon />
            </span>
            {localStorage.getItem("totalFlighAmount")}
          </button>
          {/* <h4
            style={{
              float: "left",
              color: "#2e7c31",
              fontSize: "20px",
            }}
          >
            Flight{"  "}
            <span>
              <ArrowRightIcon />
            </span>
            Hotel{" "}
            <span>
              <ArrowRightIcon />
            </span>
            Hotel Detail{" "}
            <span>
              <ArrowRightIcon />
            </span>
          </h4> */}
          <br />
        </>
      );
    }
    return <></>;
  }
}

export default HotelDetail;
