class FlightSearch {
  params = {
    cabinClass: 3,
    awardBooking: false,
    searchType: 1,
    promoCodes: null,
    itineraryParts: [],
    passengers: null,
  };
}

export default FlightSearch;
