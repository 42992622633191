export class FlierOption {
  static getAll = [
    { value: "ET", label: "ShebaMiles (ET)" },
    { value: "AC", label: "Aeroplan (AC)" },
    { value: "NZ", label: "Airpoints (NZ)" },
    { value: "O6", label: "Amigo (O6)" },
    { value: "NH", label: "ANA Mileage Club (NH)" },
    { value: "OZ", label: "Asiana Club (OZ)" },
    { value: "KP", label: "ASKY Club (KP)" },
    { value: "CM", label: "ConnectMiles (CM)" },
    { value: "MS", label: "VaniEgyptAir Plus (MS)lla" },
    { value: "SK", label: "EuroBonus (SK)" },
    { value: "AI", label: "Flying Returns (AI)" },
    {
      value: "BR",
      label: "BR",
    },
    { value: "SQ", label: "KrisFlyer (SQ)" },
    { value: "AV", label: "LifeMiles (AV)" },
    { value: "UA", label: "MileagePlus (UA)" },
    { value: "A3", label: "Miles & Bonus (A3)" },
    { value: "LH", label: "Miles & More (LH)" },
    { value: "TK", label: "Miles & Smiles (TK)" },
    { value: "CA", label: "PhoenixMiles (CA)" },
    { value: "TG", label: "Royal Orchid Plus (TG)" },
    { value: "TP", label: "Victoria (TP)" },
    { value: "SA", label: "Voyager (SA)" },
  ];
}
