import React, { Component } from "react";
import FareOptionsLargeScreen from "./fare-options-lg";
import {
  faPlane,
  faAngleDown,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlightHeader from "../flight-header";
class FlightInfoLargeScreen extends Component {
  constructor(props) {
    super(props);
    // console.log("this.props.flightalternateDay FlightInfoLargeScreen", this.props.flightalternateDay)
    let BClass=3;
    var searchRequestObjMain =JSON.parse (localStorage.getItem("searchRequestObj"));
    var searchRequestObj =searchRequestObjMain.itineraryParts;
    if(searchRequestObjMain){ 
      if(searchRequestObjMain.cabinClass){ 
        BClass=searchRequestObjMain.cabinClass
      }
    }
    this.state = {
      flightInfoList: [],
      selectedKey: null,
      showFareOptions: false,
      //   isEconomyBrandSelected: true,
      //   isBusinessBrandSelected: false,
      brandClass:BClass,
      selectedBrand: "Eco",
    };
     var tempSearchRequestObj = [];
     searchRequestObj.forEach(element => {
      tempSearchRequestObj.push(element.from.code);
      tempSearchRequestObj.push(element.to.code);
    });
     this.searchRequest = tempSearchRequestObj.filter((item, index, array) => {
      return array.indexOf(item) === index
    });
  }

  async componentDidMount(){
    // await console.log("this.props.flightalternateDay FlightInfoLargeScreen", this.props.flightalternateDay)
    // this.Optionss(this.props.flightalternateDay);// console.log("componentDidMount this.props.flightalternateDay",this.props.flightalternateDay)
    // var m= await this.setState({
    //     dateOptions:this.props.flightalternateDay})
    // // this.setState({dateOptions: daysofFlight})
    // this.Optionss(m);
 
}
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + "mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  getSelectedBrandInfoShoppingCode = (
    shoppingCode,
    arrivalDate,
    departDate
  ) => {
    
    this.props.onSearchContextClicked(shoppingCode, arrivalDate, departDate);
  };
  async showFareBrands(key, brand) {
    
    let fareDisplayStatus = document.getElementById("fare-option-row" + key)
      .style.display;
    if (
      brand === this.state.selectedBrand &&
      fareDisplayStatus !== "" &&
      fareDisplayStatus !== "none"
    ) {
      document
        .getElementById("fare-option-row" + key)
        .setAttribute("style", "display:none !important");
    } else {
      await this.setState({ selectedBrand: brand });

      let fareOptionsElmList = document.getElementsByClassName(
        "fare-options-row"
      );
      for (let i = 0; i < fareOptionsElmList.length; i++) {
        fareOptionsElmList[i].setAttribute("style", "display:none !important");
      }

      document
        .getElementById("fare-option-row" + key)
        .setAttribute("style", "display:flex !important");
    }
  }
  render() {
    
    return (
      <div className="flight-info-lg-container">
        <div className="row m-0">

          <FlightHeader 
          flightIndex={this.props.flightIndex}  
          flightalternateDay={this.props.flightalternateDay}
          onSearchContextClickedHeader={this.getSelectedBrandInfoShoppingCode}
          
          />
          {/* <h5>
            Select Your Departure Flight from Addis Ababa (ADD), Ethiopia to
            Dubai (DXB), UAE
          </h5> */}
        </div> 
        <div className="row flight-info-header m-0">
          <div className="col-md-4 col-lg-5 ">Time</div>
          <div className="col-md-3 col-lg-3 ">
            {" "}
            <FontAwesomeIcon
              icon={faClock}
              style={{
                color: "#545b62",
                fontSize: "25px",
                top: "2px",
                position: "relative",
              }}
              className="pr-2"
            />
            Duration
          </div>
         {(this.state.brandClass!=2)&& <div className="col-md-2 col-lg-2 ">Economy</div>}
        {(this.state.brandClass!=1)&&<div className="col-md-3 col-lg-2 ">Business</div>}  
        </div>
        {/* <ul>
          {this.props.flightalternateDay.map(()=><li>mmm</li>
          )}
        </ul> */}
        {this.props.flightInfo.map((flightInfo, key) => (
          <div key={key}>
            <div className="row flight-info-body">
              <div className="col-md-4 col-lg-5" style={{ fontSize: "14px" }}>
                <span className="pr-2">
                  {" "}
                  {this.parseDate(flightInfo.departure)}
                </span>{" "}
                <FontAwesomeIcon
                  icon={faPlane}
                  style={{ color: "#adb5bd", fontSize: "18px" }}
                  className="pr-2"
                />{" "}
                <span> {this.parseDate(flightInfo.arrival)}</span>
              </div>
              <div className="col-md-3 col-lg-3" style={{ fontSize: "14px" }}>
                {" "}
                {this.parseTime(flightInfo.duration)}
                {flightInfo.itineraryPart.stops != 0?<p><b><span style={{color:" #ac0000"}}>{flightInfo.itineraryPart.stops} Stop</span></b> (
                        {
                    flightInfo.itineraryPart.segments.map((flightSegement)=>
                    <>
                    {
                      flightSegement.flight.stopAirports.map((stopAirport,key)=>
                      <>
                      {stopAirport.airport +","}
                      </>
                      )
                    }
                    {this.searchRequest.includes(flightSegement.destination)?null:flightSegement.destination+","}
                    </>
                    )
                  }
                  )</p>:<p>Non-Stop</p>}
              </div>
              { flightInfo.echeepPrice!="N/A" && <div className="col-6 col-sm-6 col-md-2 col-lg-2">
                <button
                  className="btn select-eco-fare-option-btn"
                  onClick={() => this.showFareBrands(key, "Eco")}
                >
                  {" "}
                  <div>
                    From{" "}
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{
                        color: "#adb5bd",
                        fontSize: "30px",
                        position: "relative",
                        top: "7px",
                      }}
                      className="pr-2"
                    />
                  </div>{" "}
                  <div className="cheap-price-eco">
                    {flightInfo.echeepPrice}
                  </div>
                </button>
              </div>
               }
               { flightInfo.bcheepPrice !="N/A" && <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                <button
                  className={"btn select-bus-fare-option-btn"}
                  onClick={() => this.showFareBrands(key, "Bus")}
                >
                  {" "}
                  <div>
                    From{" "}
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{
                        color: "#adb5bd",
                        fontSize: "30px",
                        position: "relative",
                        top: "7px",
                      }}
                      className="pr-2"
                      id={"select-bus-fare-option-btn-dropdown" + key}
                    />
                  </div>{" "}
                  <div className="cheap-price-bus">
                    {flightInfo.bcheepPrice}
                  </div>
                </button>
              </div>
              }
            </div>

            <div
              className={`row m-0 fare-options-row ${
                key === this.state.selectedKey ? "active" : ""
              }`}
              id={"fare-option-row" + key}
            >
              {flightInfo.brandOffers.map((brand, keyTwo) => (
                <FareOptionsLargeScreen
                  brand={brand}
                  selectedBrand={this.state.selectedBrand}
                  getSelectedBrandShoppingCode={
                    this.getSelectedBrandInfoShoppingCode
                  }
                  flightInformation={flightInfo}
                  key={keyTwo}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default FlightInfoLargeScreen;
