import React, { Component } from "react";
import "./breadcrumb.css";

class BreadCrumb extends Component {
  constructor(props) {
    super(props);
    this.toruPreference = localStorage.getItem("selectedPreference");
  }
  render() {
    if (this.toruPreference == "hotel") {
      return (
        <div className="container-fluid bread_crumb ml-5">
          <div className="row justify-content-center ml-5">
            <div className="col-12 text-center mt-5 ml-5">
              <div className="card cardTrip pb-0">
                <br />
                <br />
                <ul className="progressbar progressbarhotel">
                  <span className="progressbar_lines">
                    <li className="active first_progressbar">
                      <strong className="progressbar-text">Flight </strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 2
                          ? "second_progressbar active"
                          : "second_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Hotel</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 3
                          ? "third_progressbar active"
                          : "third_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Passenger</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 4
                          ? "forth_progressbar active"
                          : "forth_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Summary</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 5
                          ? "fifth_progressbar active"
                          : "fifth_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Payment</strong>
                    </li>
                  </span>
                  <li
                    className={
                      this.props.pageIndex >= 6
                        ? "sixth_progressbar active"
                        : "sixth_progressbar"
                    }
                  >
                    <strong className="progressbar-text">Confirmation</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.toruPreference == "package") {
      return (
        <div className="container-fluid bread_crumb ml-5">
          <div className="row justify-content-center ml-5">
            <div className="col-12 text-center p-0">
              <div className="card cardTrip px-0 mt-5 ml-5">
                <br />
                <br />
                <ul className="progressbar progressbarhotel">
                  <span className="progressbar_lines">
                    <li className="active first_progressbar">
                      <strong className="progressbar-text">Flight </strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 2
                          ? "second_progressbar active"
                          : "second_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Package</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 3
                          ? "third_progressbar active"
                          : "third_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Passenger</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 4
                          ? "forth_progressbar active"
                          : "forth_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Summary</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 5
                          ? "fifth_progressbar active"
                          : "fifth_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Payment</strong>
                    </li>
                  </span>
                  <li
                    className={
                      this.props.pageIndex >= 6
                        ? "sixth_progressbar active"
                        : "sixth_progressbar"
                    }
                  >
                    <strong className="progressbar-text">Confirmation</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid bread_crumb ml-5">
          <div className="row justify-content-center ml-5">
            <div className="col-12 text-center p-0">
              <div className="card cardTrip px-0  mt-5 ml-5">
                <br />
                <br />
                <ul className="progressbar progressbarbundle">
                  <span className="progressbar_lines">
                    <li className="active first_progressbar">
                      <strong className="progressbar-text">Flight </strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 2
                          ? "second_progressbar active"
                          : "second_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Hotel</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 3
                          ? "third_progressbar active"
                          : "third_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Activity</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 4
                          ? "forth_progressbar active"
                          : "forth_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Passenger</strong>
                    </li>
                    <li
                      className={
                        this.props.pageIndex >= 5
                          ? "fifth_progressbar active"
                          : "fifth_progressbar"
                      }
                    >
                      <strong className="progressbar-text">Payment</strong>
                    </li>
                  </span>
                  <li
                    className={
                      this.props.pageIndex >= 6
                        ? "sixth_progressbar active"
                        : "sixth_progressbar"
                    }
                  >
                    <strong className="progressbar-text">Confirmation</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default BreadCrumb;
