import React from "react";

class ChildrenInput extends React.Component {
  render() {
    return (
      <div className="col-6">
        <span>Child {this.props.index + 1} Age</span>
        <br />
        <select
          className="select-dropdown"
          id={`Children-${this.props.index}-Room-${this.props.room}`}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="7">6</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
        </select>
      </div>
    );
  }
}
export default ChildrenInput;
