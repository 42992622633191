import React, { Component } from "react";
class FlightSearchException extends Component {
  state = {};
  render() {
    return (
      <div className="pt-5">
        <p className="h4">Sorry! We are unable to search flight at the moment. Please try again later.</p>
        <a className="btn mt-3 border" href="/">
          Okay
        </a>
      </div>
    );
  }
}

export default FlightSearchException;
