import React, { Component } from "react";
import "./Campaign.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HttpService } from "../../../services/http/http-service";
//#10784 Preload Key reload
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Widget from "../../home/widget/widget";
import CampainWidget from "./campaign-wedget";
import {
    isBrowser,
    isMobile
} from "react-device-detect";
// import { Parallax  } from 'react-parallax';
import Figure from 'react-bootstrap/Figure'
class Campaign extends Component {
    constructor(props) {
        super(props);
        let cards = ["/assets/image/campaign/card1.jpg", "/assets/image/campaign/card2.jpg", "/assets/image/campaign/card3.jpg", "/assets/image/campaign/card4.jpg"]

        this.state = {
            cards: cards,
            isLoaded: false,
            items: [],
        };
    }

    async componentDidMount() {
        this.getDestinations();
    }

    getDestinations() {
        // console.log("get cam pack list   "  );
        const relativePath = "Availability/GetSpecialPackages";
        HttpService.getServicePackage(relativePath)
            .then((response) => {
                let responseData = response.data;
                // console.log("responseData   ",responseData);
                if (responseData.statusAPI == "SUCCESS") {
                    this.setState({
                        items: responseData.packagesList,
                        isLoaded: true,
                    });

                }
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    showPackageDetails = (packageData) => {
        let search =
            "?packageName=" +
            packageData.package.name +
            "&packageId=" +
            packageData.package.id;
        let path = "/package-details/" + search;
        window.location.assign(path);
    };

    render() {
        // var { items } = this.state;
        // console.log("items");
        // console.log(items);
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            cssEase: "linear",
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (<>


            <div >
                {/* <img className="img-fluid "  src={window.location.origin + '/assets/image/slider/AddisTour.jpg'} /> */}
                {/* <img className="img-fluid "   src={
                          window.location.origin +
                          "/assets/image/campaign/banner.jpg"
                        } /> */}

                <Figure>
                    <Figure.Image
                        alt={"packageDetails.package.caption"}
                        src={
                            window.location.origin +
                            "/assets/image/campaign/banner.jpg"
                        }
                    />


                </Figure>

            </div>

            <CampainWidget />
            {/* <div className="special-offer  mb-5 mt-2"></div> */}

            <div className="container mt-5">
                <p className="text-center special-offer-head-campaign mt-5">Celebrate the Valentine's Day at Ethiopian Skylight Hotel</p>
                <hr style={{ width: "13%" }}></hr>

                <div className="">
                    <div className="">
                        {(this.state.cards.length > 0) && (
                            <>

                                <Slider {...settings}>
                                    {this.state.cards.map((item, key) => (
                                        <div className="   p-3" key={item} value={item}>
                                            <div className="card p-0 m-0 shadow roundedHalf">

                                                <LazyLoad height={200} offset={100}>


                                                    <div className=" " >

                                                        <LazyLoadImage className="roundedHalf card-img   img-fluid" style={{ cursor: "pointer !important", maxHeight: "180px" }}

                                                            alt={""}
                                                            effect="blur"
                                                            src={
                                                                window.location.origin + item
                                                            }
                                                        /></div>

                                                </LazyLoad>

                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/*
            <div className="container mt-5 ">
                <p className="text-center special-offer-head-campaign mt-3">Visit our website </p>
                <p className="text-center  mt-3">
                    <a
                        className="links white-text"
                        href="https://www.ethiopianholidays.com"
                        target="_blank"
                    >  https://www.ethiopianholidays.com
                    </a>
                </p>
                <hr style={{ width: "13%" }}></hr>
            </div>*/}


            {(this.state.items.length > 0) &&
                <div className="container mt-5 ">
                    <p className="text-center special-offer-head-campaign mt-3">Explore Most Visted Ethiopian Package</p>
                    <hr style={{ width: "13%" }}></hr>

                    <div className="">
                        <div className="">
                            {(this.state.items) && (
                                <div className="row">

                                    {/* <Slider {...settings}> */}
                                    {this.state.items.map((item) => (
                                        <div className=" col-sm-6 col-md-6 col-lg-4 p-3" key={item} value={item}>
                                            <div className="card shadow roundedHalf" style={{ cursor: "pointer" }}>

                                                <LazyLoad height={200} offset={100}
                                                    onClick={() => {
                                                        // this.showDestination(item.name, item)
                                                    }
                                                    }>


                                                    <div className=" " >

                                                        <LazyLoadImage className="roundedHalf card-img   img-fluid" style={{ cursor: "pointer !important", maxHeight: "150px" }}
                                                            onClick={() => {
                                                                // this.showDestination(item.name, item) 
                                                                this.showPackageDetails(item)
                                                            }
                                                            }
                                                            alt={""}
                                                            effect="blur"
                                                            src={item.package.imagePath}
                                                        />
                                                    </div>

                                                </LazyLoad>
                                                <div className="text-center card-body pt-1 pb-3">
                                                    <h5 className="card-title">{item.package.name}</h5>
                                                    <p className="card-title">{item.package.numberOfDays}{" "}Days</p>


                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* </Slider> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }

        </>);
    }
}

export default Campaign;
