export class SpecialRequetOption {

  static getAll = [
    { value: "BLND", label: "Blind Passenger" },
    { value: "DEAF", label: "Deaf Passenger" },
    { value: "MAAS", label: "Meet and Assist" },
    { value: "MEDA", label: "Medical Case" },
    {
      value: "WCHR",
      label: "Wheelchair/Passanger can walk up stairs",
    },
    {
      value: "WCHS",
      label: "Wheelchair/Passanger can walk to seat",
    },
    {
      value: "WCHC",
      label: "Wheelchair/Passanger must be carried",
    },
  ];
}
