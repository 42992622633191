import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
// import { PassengerInformation } from "../../../models/hotel/hotel-search";

class ChildInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOfBirth: null,
      foucsed: false,
      showFlier: false,
      showRequest: false,
    };
  }
  showFlier = () => {
    let temp = !this.state.showFlier;
    this.setState({ showFlier: temp });
  };
  showRequest = () => {
    let temp = !this.state.showRequest;
    this.setState({ showRequest: temp });
  };
  render() {
    const optionsFlier = [
      { value: "ShebaMiles (ET)", label: "ShebaMiles (ET)" },
      { value: "Aeroplan (AC)", label: "Aeroplan (AC)" },
      { value: "Airpoints (NZ)", label: "Airpoints (NZ)" },
      { value: "Amigo (O6)", label: "Amigo (O6)" },
      { value: "ANA Mileage Club (NH)", label: "ANA Mileage Club (NH)" },
      { value: "Asiana Club (OZ)", label: "Asiana Club (OZ)" },
      { value: "ASKY Club (KP)", label: "ASKY Club (KP)" },
      { value: "ConnectMiles (CM)", label: "ConnectMiles (CM)" },
      { value: "EgyptAir Plus (MS)", label: "VaniEgyptAir Plus (MS)lla" },
      { value: "EuroBonus (SK)", label: "EuroBonus (SK)" },
      { value: "Flying Returns (AI)", label: "Flying Returns (AI)" },
      {
        value: "Infinity MileageLands (BR)",
        label: "Infinity MileageLands (BR)",
      },
      { value: "KrisFlyer (SQ)", label: "KrisFlyer (SQ)" },
      { value: "LifeMiles (AV)", label: "LifeMiles (AV)" },
      { value: "MileagePlus (UA)", label: "MileagePlus (UA)" },
      { value: "Miles & Bonus (A3)", label: "Miles & Bonus (A3)" },
      { value: "Miles & More (LH)", label: "Miles & More (LH)" },
      { value: "Miles & Smiles (TK)", label: "Miles & Smiles (TK)" },
      { value: "PhoenixMiles (CA)", label: "PhoenixMiles (CA)" },
      { value: "Royal Orchid Plus (TG)", label: "Royal Orchid Plus (TG)" },
      { value: "Victoria (TP)", label: "Victoria (TP)" },
      { value: "Voyager (SA)", label: "Voyager (SA)" },
    ];
    const optionsMeal = [
      { value: "Asian Vegeterian Meal", label: "Asian Vegeterian Meal" },
      { value: "KrisFlyer (SQ)", label: "KrisFlyer (SQ)" },
      { value: "LifeMiles (AV)", label: "LifeMiles (AV)" },
      { value: "MileagePlus (UA)", label: "MileagePlus (UA)" },
      { value: "Miles & Bonus (A3)", label: "Miles & Bonus (A3)" },
      { value: "Miles & More (LH)", label: "Miles & More (LH)" },
      { value: "Miles & Smiles (TK)", label: "Miles & Smiles (TK)" },
      { value: "PhoenixMiles (CA)", label: "PhoenixMiles (CA)" },
      { value: "Royal Orchid Plus (TG)", label: "Royal Orchid Plus (TG)" },
      { value: "Bland Meal", label: "Bland Meal" },
      { value: "Diabetic Meal", label: "Diabetic Meal" },
      { value: "Fruit Platter Meal", label: "Fruit Platter Meal" },
      { value: "Gluten Intolerant Meal", label: "Gluten Intolerant Meal" },
      { value: "Hindu Meal", label: "Hindu Meal" },
      { value: "Kosher Meal", label: "Kosher Meal" },

      { value: "Low Calorie Meal", label: "Low Calorie Meal" },
      { value: "Low Fat Meal", label: "Low Fat Meal" },
      { value: "Low Salt Meal", label: "Low Salt Meal" },
      { value: "Moslem Meal", label: "Moslem Meal" },
      { value: "Non-Lactose Meal", label: "Non-Lactose Meal" },
      { value: "No Salt Meat", label: "No Salt Meat" },
      { value: "Raw Vegeterian Meal", label: "Raw Vegeterian Meal" },
      { value: "Sea Food Meal", label: "Sea Food Meal" },
      { value: "Western Vegeterain Meal", label: "Western Vegeterain Meal" },
      { value: "No special meal", label: "No special meal" },
    ];
    const optionsSperialRequest = [
      { value: "Blind Passenger", label: "Blind Passenger" },
      { value: "Deaf Passenger", label: "Deaf Passenger" },
      { value: "Meet and Assist", label: "Meet and Assist" },
      { value: "Medical Case", label: "Medical Case" },
      {
        value: "Wheelchair/Passanger can walk up stairs",
        label: "Wheelchair/Passanger can walk up stairs",
      },
      {
        value: "Wheelchair/Passanger can walk to seat",
        label: "Wheelchair/Passanger can walk to seat",
      },
      {
        value: "Wheelchair/Passanger must be carried",
        label: "Wheelchair/Passanger must be carried",
      },
    ];
    let flierButton = null;
    let requestButton = null;
    let requestInput = null;
    let flierInput = null;
    let customStyles = {
      option: (provided, state) => ({
        ...provided,
        "text-align": "left",
        color: state.isSelected ? "white" : "black",
        width: state.selectProps.width,
        backgroundColor: state.isSelected ? "#4caf50" : "white",
        "&:hover": {
          backgroundColor: "#4caf50",
          color: "white",
        },
      }),
    };
    if (!this.state.showFlier) {
      flierButton = (
        <span style={{ float: "left" }}>
          <AddIcon className="addFlight" onClick={this.showFlier} />
          <span style={{ color: "#72728c" }}>ADD FREQUENT FLIER</span>
        </span>
      );
      flierInput = null;
    } else {
      flierButton = (
        <span style={{ float: "left" }}>
          <RemoveIcon className="minusFlight" onClick={this.showFlier} />
          <span style={{ color: "#72728c" }}>REMOVE FREQUENT FLIER</span>
        </span>
      );
      flierInput = (
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <label for="program" className=" mbr-fonts-style display-7">
              Frequent Flier Program{" "}
            </label>
          </div>
          <div>
            <Select
              options={optionsFlier}
              id={`child_flier_${this.props.index + 1}`}
              isSearchable={true}
              styles={customStyles}
            />
          </div>
          <label>Frequent Flier Number</label>
          <input
            type="text"
            className="form-control display-7"
            id={`child_flier_number_${this.props.index + 1}`}
          />
        </div>
      );
    }
    if (!this.state.showRequest) {
      requestButton = (
        <span style={{ float: "right" }}>
          <AddIcon className="addFlight" onClick={this.showRequest} />
          <span style={{ color: "#72728c" }}> ADD SPECIAL REQUEST</span>
        </span>
      );
      requestInput = null;
    } else {
      requestButton = (
        <span style={{ float: "right" }}>
          <RemoveIcon className="minusFlight" onClick={this.showRequest} />
          <span style={{ color: "#72728c" }}> REMOVE SPECIAL REQUEST</span>
        </span>
      );
      requestInput = (
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <label for="program" className=" mbr-fonts-style display-7">
              Meal Preference{" "}
            </label>
          </div>
          <div>
            <Select
              options={optionsMeal}
              id={`child_meal_${this.props.index + 1}`}
              isSearchable={true}
              styles={customStyles}
            />
          </div>
          <div className="row">
            <label>Special Request</label>
          </div>
          <div>
            <Select
              options={optionsSperialRequest}
              isSearchable={true}
              id={`child_special_${this.props.index + 1}`}
              isMulti
              styles={customStyles}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <hr style={{ border: "1px solid #4caf50" }} />
        <ul className="form-section page-section">
          <li className="form-line" data-type="control_fullname" id="id_4">
            <label
              className="form-label form-label-top form-label-auto"
              id="label_4"
              for="first_4"
            >
              Full Name For Child {this.props.index + 1}
            </label>
            <div id="cid_4" className="form-input-wide" data-layout="full">
              <div data-wrapper-react="true" className="extended">
                <span
                  className="form-sub-label-container"
                  style={{ "vertical-align": "top" }}
                  data-input-type="first"
                >
                  <input
                    type="text"
                    id={`child_first_name_${this.props.index + 1}`}
                    className="form-textbox"
                    placeholder="First Name *"
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                      event.target.classList.remove("is-valid");
                    }}
                  />
                  {/* <label
                    className="form-sub-label"
                    for={`child_first_name_${this.props.index + 1}`}
                    style={{ "min-height": "13px" }}
                    aria-hidden="false"
                  >
                    First Name *
                  </label> */}
                </span>
                <span
                  className="form-sub-label-container"
                  style={{ "vertical-align": "top" }}
                  data-input-type="middle"
                >
                  <input
                    type="text"
                    id={`child_middle_name_${this.props.index + 1}`}
                    name="q4_studentName[middle]"
                    className="form-textbox"
                    placeholder="Middle Name"
                  />
                </span>
                <span
                  className="form-sub-label-container"
                  style={{ "vertical-align": "top" }}
                  data-input-type="last"
                >
                  <input
                    type="text"
                    id={`child_last_name_${this.props.index + 1}`}
                    name="q4_studentName[last]"
                    className="form-textbox"
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                      event.target.classList.remove("is-valid");
                    }}
                    placeholder="Last Name *"
                  />
                </span>
              </div>
            </div>
          </li>
          <li
            className="form-line form-line-column form-col-2"
            data-type="control_dropdown"
            id="id_3"
          >
            <div id="cid_3" className="form-input-wide" data-layout="half">
              <select
                required
                className="form-dropdown"
                id={`child_gender_${this.props.index + 1}`}
                style={{ width: "310px;" }}
                onFocus={function (event) {
                  event.target.classList.remove("is-invalid");
                  event.target.classList.remove("is-valid");
                }}
              >
                <option selected disabled value="">
                  Gender *
                </option>
                <option value="Male">Male</option>
                <option value="Female"> Female </option>
              </select>
            </div>
          </li>
          <li
            className="form-line form-line-column form-col-1"
            data-type="control_birthdate"
            id="id_24"
          >
            <div id="cid_24" className="form-input-wide" data-layout="full">
              <div data-wrapper-react="true">
                <span
                  className="form-sub-label-container"
                  style={{ "vertical-align": "top" }}
                >
                  <select
                    required
                    id={`child_month_${this.props.index + 1}`}
                    className="form-dropdown"
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                      event.target.classList.remove("is-valid");
                    }}
                    title="Month of Birth"
                  >
                    <option selected disabled value="">
                      Month *
                    </option>
                    <option value="01"> January </option>
                    <option value="02"> February </option>
                    <option value="03"> March </option>
                    <option value="04"> April </option>
                    <option value="05"> May </option>
                    <option value="06"> June </option>
                    <option value="07"> July </option>
                    <option value="08"> August </option>
                    <option value="09"> September </option>
                    <option value="10"> October </option>
                    <option value="11"> November </option>
                    <option value="12"> December </option>
                  </select>
                  <label
                    className="form-sub-label"
                    id="adult_year_0"
                    id="sublabel_24_year"
                    style={{ "min-height": "13px" }}
                    aria-hidden="false"
                  >
                    Birth Month
                  </label>
                </span>
                <span
                  className="form-sub-label-container"
                  style={{ "vertical-align": "top" }}
                >
                  <select
                    required
                    id={`child_day_${this.props.index + 1}`}
                    className="form-dropdown"
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                      event.target.classList.remove("is-valid");
                    }}
                    title="Day of Birth"
                  >
                    <option selected disabled value="">
                      Day *
                    </option>
                    <option value="1"> 1 </option>
                    <option value="2"> 2 </option>
                    <option value="3"> 3 </option>
                    <option value="4"> 4 </option>
                    <option value="5"> 5 </option>
                    <option value="6"> 6 </option>
                    <option value="7"> 7 </option>
                    <option value="8"> 8 </option>
                    <option value="9"> 9 </option>
                    <option value="10"> 10 </option>
                    <option value="11"> 11 </option>
                    <option value="12"> 12 </option>
                    <option value="13"> 13 </option>
                    <option value="14"> 14 </option>
                    <option value="15"> 15 </option>
                    <option value="16"> 16 </option>
                    <option value="17"> 17 </option>
                    <option value="18"> 18 </option>
                    <option value="19"> 19 </option>
                    <option value="20"> 20 </option>
                    <option value="21"> 21 </option>
                    <option value="22"> 22 </option>
                    <option value="23"> 23 </option>
                    <option value="24"> 24 </option>
                    <option value="25"> 25 </option>
                    <option value="26"> 26 </option>
                    <option value="27"> 27 </option>
                    <option value="28"> 28 </option>
                    <option value="29"> 29 </option>
                    <option value="30"> 30 </option>
                    <option value="31"> 31 </option>
                  </select>
                  <label
                    className="form-sub-label"
                    id="adult_year_0"
                    id="sublabel_24_year"
                    style={{ "min-height": "13px" }}
                    aria-hidden="false"
                  >
                    Birth Day
                  </label>
                </span>
                <span
                  className="form-sub-label-container"
                  style={{ "vertical-align": "top" }}
                >
                  <select
                    required
                    id={`child_year_${this.props.index + 1}`}
                    className="form-dropdown"
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                      event.target.classList.remove("is-valid");
                    }}
                    title="Year of Birth"
                  >
                    <option selected disabled value="">
                      Year *
                    </option>
                    <option value="2021"> 2021 </option>
                    <option value="2020"> 2020 </option>
                    <option value="2019"> 2019 </option>
                    <option value="2018"> 2018 </option>
                    <option value="2017"> 2017 </option>
                    <option value="2016"> 2016 </option>
                    <option value="2015"> 2015 </option>
                    <option value="2014"> 2014 </option>
                    <option value="2013"> 2013 </option>
                    <option value="2012"> 2012 </option>
                    <option value="2011"> 2011 </option>
                  </select>
                  <label
                    className="form-sub-label"
                    id="adult_year_0"
                    id="sublabel_24_year"
                    style={{ "min-height": "13px" }}
                    aria-hidden="false"
                  >
                    Birth Year
                  </label>
                </span>
              </div>
            </div>
          </li>
          <li
            className="form-line form-line-column form-col-3"
            data-type="control_email"
            id="id_6"
          >
            <label className="form-label form-label-top" id="label_6">
              {flierButton}
            </label>
            <div id="cid_6" className="form-input-wide" data-layout="half">
              {flierInput}
            </div>
          </li>
          <li
            className="form-line form-line-column form-col-4"
            data-type="control_phone"
            id="id_27"
          >
            <label className="form-label form-label-top" id="label_27">
              {requestButton}
            </label>
            <div id="cid_27" className="form-input-wide" data-layout="half">
              {requestInput}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default ChildInput;
