import React, { Component, Fragment } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./slider.css";

import Figure from 'react-bootstrap/Figure'
import { isBrowser, isMobile } from "react-device-detect";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { HttpService } from "../../../services/http/http-service";
import parse from 'html-react-parser';
class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sliders: []
        };
    }

    async componentDidMount() {

        //debugger
        let homeSliders = JSON.parse(localStorage.getItem("HomeSlider"));
        //debugger
        if (homeSliders) {
            // console.log("sliders  homeSliders ",homeSliders);

            if (homeSliders.expiresInDays) {
                if (Date.now() > homeSliders.expiresInDays) {
                    this.getBannerList();
                }
            }
            else {
                this.getBannerList();
            }

            this.setState({
                sliders: homeSliders.sliders,
            });

        } else {
            this.getBannerList();
        }
    }

    getBannerList() {

        const relativePath = "MasterDatas/GetHomeBanners";

        var { isLoaded, items, search } = this.state;

        HttpService.getServicePackage(relativePath)
            .then((response) => {
                //debugger
                let responseData = response.data;

                this.setState({
                    isLoaded: true,
                });

                let timeNow = Date.now();
                if (Array.isArray(responseData)) {
                    localStorage.setItem(
                        "HomeSlider",
                        JSON.stringify({
                            sliders: responseData,
                            lastRefreshed: timeNow,
                            expiresInDays: timeNow + 1000 * 60 * 60 * 24 * 3 // 3 days
                        })
                    );

                    this.setState({
                        sliders: responseData,
                    });

                }
            })
            .catch((error) => {
                 console.log("Error getting banners: " + error);
            });
    }

    render() {
        return (
            <Fragment>
                {((isBrowser) && (this.state.sliders.length > 0)) &&
                    <Carousel>
                        {this.state.sliders.map((slider) =>
                            <Carousel.Item key={slider.id}>

                                <LazyLoadImage
                                    alt={slider.bannerCaption ? parse(slider.bannerCaption) : ""}
                                    effect="blur"
                                    src={slider.imagePath ? slider.imagePath : ""} />
                                <Carousel.Caption className="Homecarousel-caption">
                                    {slider.bannerCaption && parse(slider.bannerCaption)}
                                </Carousel.Caption>
                            </Carousel.Item>)}

                    </Carousel>
                }
            </Fragment>

        );
    }
}

export default Slider;
