import React, { Component } from 'react';
class TermsAndCondition extends Component {
    render() {
        return (
            <div>

                <h4>Ethiopian Holidays Terms and Conditions</h4>
                <p>Ethiopian Holidays is pleased to render you the online reservation services for your holiday and business trip to Ethiopia and beyond with Ethiopian flights in accordance with the conditions stipulated hereunder.</p>
                <h5>Bookings</h5>
                <p>Bookings can be made for adult passengers and children and infants traveling with accompanying adults. For the transportation of incapacitated passengers, expectant mothers and unaccompanied children, necessary formalities have to be completed and the airline's consent has to be secured ahead of time.</p>
                <h5>Age and Responsibility</h5>
                <p>You represent that you are of sufficient legal age to use this service, and you possess the legal right to create binding obligations for any liability you may incur as a result of the use of this service. You are financially responsible for all uses of this service by you and those using your login information.</p>
                <h5>Price conditions</h5>
                <p>Prices quoted include all taxes, except where taxes are collected locally. Please note that all quotes remain subject to changes and are only guaranteed when the booking has been paid.</p>
                <h5>Payment and Ticket delivery Options</h5>
                <p>If you wish to make payment by credit card and click on the "Pay with credit card" option, your credit card will be charged automatically at time of internet booking, and an electronic ticket will be issued instantly. Payment on the web with credit card is not permitted for passengers other than the card holder unless the card holder is traveling with passenger that he/she has paid for. For other payments please contact the local Ethiopian airlines office in person. You should carry the credit card used at the time of booking when coming for boarding as it will be required for verification. </p>
                <p>All tickets to be paid at Ethiopian Airlines ticket offices (with a pay at agency option) must be collected within 48hrs of making a booking by contacting the local Ethiopian airlines office in your area. Tickets not collected within this period will be automatically cancelled. Note: If you decline to use our services and request for a complete refund your ticket will be cancelled and you will be subject to a service charge of 10 USD in addition to other applicable penalties imposed on your ticket and ground service.</p>
                <h5>Currency Conversion</h5>
                <p>Currency rates are based on several publicly available sources. We do not authorize the use of this information for any other purposes, except for personal use, and prohibit to the maximum extent allowable the resale, redistribution, and use of this information for commercial purposes. We believe in the accuracy of the information supplied by this application, but we do not warrant or guarantee such accuracy. </p>
                <h5>Cancellation</h5>
                <p>By making a reservation, you accept and agree to the relevant cancellation and no-show policy of the service provider. The general cancellation and no-show policy of each service provider is made available on our website, during the reservation procedure and in the confirmation email.</p>
                <p> Late payment, wrong bank, debit or credit card details, invalid credit/debit cards or insufficient funds are for your own risk and account and you shall not be entitled to any refund of any (non-refundable) prepaid amount unless the accommodation agrees or allows otherwise under its (pre)payment and cancellation policy.</p>
                <h5>Passport and other documents </h5>
                <p>Please ensure you have a passport valid for the duration of your trip. Ethiopian airlines shall not be held responsible on issues pertaining to denied boarding due to incomplete travel document and visa.</p>
                <h5>Modification of Terms</h5>
                <p>
                    Modification of Terms
                    Ethiopian reserves the right to change these terms and conditions at any time without notice or liability. You are responsible for regularly reviewing these terms and conditions. Continued use of Ethiopian on-line booking system following any such changes shall constitute your acceptance of such changes.
</p>
                <h5>Check-in/Reporting time</h5>
                <p>The time shown on flight coupons is the departure time of the aircraft. In order to perform check-in operations in due time, the passenger is requested to report at the airport check-in counter 2 and half hours prior to flights departure.</p>
                <p>
                    The time shown on flight coupons is the departure time of the aircraft. In order to perform check-in operations in due time, the passenger is requested to report at the airport check-in counter 2 and half hours prior to flights departure.
                    Passengers to/from the U.S.A are required to report at least 3 hours prior to flight departure. Passengers who fail to report within the above time limit shall lose the right to embark, even if they hold a reservation and Ethiopian will take no responsibility in such cases.
</p>
                <h5>Reconfirmation</h5>
                <p>
                    Reconfirmation is not mandatory for Ethiopian airlines except when the trip is wholly within the domestic Ethiopian route. Customers whose itinerary is wholly within the domestic break their journey for more than 72 hours are required to reconfirm their flight.
</p>
                <h5>Privacy Statement</h5>
                <p>We are responsible for the processing of any personal information you provide to this Website and we are notified where required in each country under the relevant data protection legislation of that country. We take our responsibilities regarding the protection of personal information very seriously. By using our Website you consent to this use. </p>



            </div>

        );


    }


}

export default TermsAndCondition;