import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { HttpService } from "./../../services/http/http-service";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import FormControl from 'react-bootstrap/FormControl'
import Select from 'react-select'
import SearchHotel from "./hotel-search";
import "./hotel.css";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

class HotelFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPriceSortLoading: false,
      isStartSortLoading: false,
      isNearbySortLoading: false,
      isBoardTypesSortLoading:false ,
      boardTypesSortMode: "decrement",
      priceSortMode: "decrement",
      starSortMode: "decrement",
      nearbySortMode: "decrement",
      hotelPriceRange:[],
      hotelStarLists:[],
      hotelboardTypes:[],
      
      selectedPriceRange:null,
      selectedStar:null,
      selectedBoardType:null,
      currency:null
    };
  }

  async componentDidMount() {
    let curr=await localStorage.getItem("Currency")
    await this.setState({currency:curr});
    
    if(this.props.hotelPriceRange){
      let totalRangeDifferece=parseInt(this.props.hotelPriceRange.to)-parseInt(this.props.hotelPriceRange.from)
      let iterete= 10;
      let itereteRatio=parseInt(totalRangeDifferece/iterete);
      let ranges=[];
      var currentFrom=parseInt(this.props.hotelPriceRange.from);
      var currentTo=parseInt(this.props.hotelPriceRange.from)+itereteRatio;

      for(let i=0;i<iterete;i++){
        ranges.push({"from":currentFrom,"to":currentTo,"value":currentTo,"label":this.state.currency+" "+currentFrom+" - " +this.state.currency+" "+ currentTo})
        currentFrom=currentTo
        currentTo=currentFrom+itereteRatio
      }
        await this.setState({hotelPriceRange:ranges})
    }
      let stars=[]
      for(let i=0;i<5;i++){
        let starNo=i+1;
        let startName=(starNo>1)?"stars":"star"
        stars.push({ "value":starNo,"label":""+starNo+" " +startName}) 
      }
      
      // console.log("this.props.boardTypes",this.props.boardTypes)
      let boardTypes=[]
  //  let boardTypes=[{"label":"room only","value":"room only"}, {"label":"full board","value":"full board"}, {"label":"half board","value":"half board"}, {"label":"bed","value":"bed"}, {"label":"breakfast","value":"breakfast"}]
      if(this.props.boardTypes){
              let typeData=this.props.boardTypes;
            for(let i=0;i<typeData.length;i++){
                boardTypes.push({"label":typeData[i] ,"value":typeData[i] },)
            }
        // console.log("boardTypes in",boardTypes)

        }
        await this.setState({hotelStarLists:stars})
        await this.setState({hotelboardTypes:boardTypes})


  }
  
  async sortHotel(sortBy, sortMode,min,max) { 
      let boardPath;
      let starPath;
      let pricePath;
       
    if(this.state.selectedBoardType){
        boardPath="&BoardType="+this.state.selectedBoardType.value+ ""
    }
    else{
      // boardPath="BoardType="+null
      boardPath=""
    } 
    if(this.state.selectedStar){
      starPath="&StarType="+this.state.selectedStar.value+""; 
      }
      else{
        starPath= ""; 
        // starPath="StarType="+null+""; 
      }   
    if(this.state.selectedPriceRange){
      pricePath="&HotelPriceFrom="+this.state.selectedPriceRange.from+"&HotelPriceTo="+this.state.selectedPriceRange.to+""
    }
    else{
      pricePath= ""
      // pricePath="HotelPriceFrom="+0+"&HotelPriceTo="+0+""
    }


    //debugger
    let relativePath = "HotelAvailability/CacheAvailability";
    relativePath +=
      "?SessionId=" +localStorage.getItem("sessionId") +"&PageNumber=1"
    relativePath += starPath+pricePath+boardPath


    // if(sortBy=="bourdType"){
    //   relativePath +=
    //     "?SessionId=" +localStorage.getItem("sessionId") +"&PageNumber=1&BoardType="+min+ ""

    // }
    // if(sortBy=="price"){
    //   relativePath +=
    //     "?SessionId=" +localStorage.getItem("sessionId") +"&PageNumber=1&HotelPriceFrom="+min+"&HotelPriceTo="+max+""

    // }
    // if(sortBy=="star"){
    //   relativePath +=
    //     "?SessionId=" +localStorage.getItem("sessionId") +"&PageNumber=1&StarType="+min+""; 
    // } 

    if (sortBy === "star") {
      await this.setState({ isStartSortLoading: true });
      await this.setState({ isPriceSortLoading: false }); 
      await this.setState({ isBoardTypesSortLoading: false }); 
    }
    if (sortBy === "price") {
      await this.setState({ isPriceSortLoading: true });
      await this.setState({ isStartSortLoading: false }); 
      await this.setState({ isBoardTypesSortLoading: false }); 
    } 
    if (sortBy === "bourdType") {
      await this.setState({ isBoardTypesSortLoading: true });
      await this.setState({ isPriceSortLoading: false }); 
      await this.setState({ isStartSortLoading: false }); 
    } 
    let sortResponse = await HttpService.getServiceHotel(relativePath);
    if (sortBy === "price") {
      if (sortMode === "decrement") {
        await this.setState({ priceSortMode: "increment" });
      } else {
        await this.setState({ priceSortMode: "decrement" });
      }
    }
    if (sortBy === "star") {
      if (sortMode === "decrement") {
        await this.setState({ starSortMode: "increment" });
      } else {
        await this.setState({ starSortMode: "decrement" });
      }
    } 
    if (sortBy === "bourdType") {
      if (sortMode === "decrement") {
        await this.setState({ boardTypesSortMode: "increment" });
      } else {
        await this.setState({ boardTypesSortMode: "decrement" });
      }
    } 
    await this.setState({ isPriceSortLoading: false });
    await this.setState({ isStartSortLoading: false }); 
    await this.setState({ isBoardTypesSortLoading: false }); 
    
    this.props.sortHotel(sortResponse); 
  }

  handleChange = async selectedOption => { 
    // console.log("selectedOption",selectedOption) 
    await this.setState({ selectedPriceRange:selectedOption }); 
    await this.setState({ selectedOption }); 
    this.sortHotel("price", "decrement",selectedOption.from,selectedOption.to)
  };

  handleBoardTypesChange = async selectedOption => {
    // console.log("selectedOption",selectedOption) 
    await this.setState({ selectedBoardType:selectedOption }); 
    await this.setState({ selectedOption }); 
    this.sortHotel("bourdType", "decrement",selectedOption.value,"BourdType")
  };

  handleChangeStar = async selectedOption => { 
    // console.log("selectedOption",selectedOption) 
    await this.setState({ selectedStar:selectedOption }); 
    await this.setState({ selectedOption }); 
    this.sortHotel("star", "decrement",selectedOption.value,"Star") 
  };

  renderSortPriceButton() {
    if (this.state.isPriceSortLoading) {
      return (
        <div
          className="spinner-border text-primary "
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (
      this.state.isPriceSortLoading === false &&
      this.state.priceSortMode === "increment"
    ) {
      return ( 

        <Select  className="btn selectOptions   "
        // placeholder="Price"   
        placeholder={(this.state.selectedPriceRange)?this.state.selectedPriceRange.label:"Price" }  
         title="Price" options={this.state.hotelPriceRange} 
        onChange={this.handleChange} />
 
      );
    }
    return ( 
  <Select  className="btn selectOptions  "
    // placeholder="Price"   
    placeholder={(this.state.selectedPriceRange)?this.state.selectedPriceRange.label:"Price" }     
    title="Price" options={this.state.hotelPriceRange} 
  onChange={this.handleChange} />

    );
  }
  renderSortboardTypes() {
    if (this.state.isBoardTypesSortLoading) {
      return (
        <div
          className="spinner-border text-primary "
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (
      this.state.isBoardTypesSortLoading === false &&
      this.state.boardTypesSortMode === "increment"
    ) {
      return ( 
    
  <Select  className="btn selectOptions " 
  // placeholder="Board Types"     
    placeholder={(this.state.selectedBoardType)?this.state.selectedBoardType.label:"Board Types" }   
    options={this.state.hotelboardTypes}
     
  onChange={this.handleBoardTypesChange} />

      );
    }
    return (   
  <Select  className="btn selectOptions  "
    // placeholder="Board Types"    
    placeholder={(this.state.selectedBoardType)?this.state.selectedBoardType.label:"Board Types" }  
      options={this.state.hotelboardTypes}
     
  onChange={this.handleBoardTypesChange} />

    );
  }
  renderSortStarButton() {
    if (this.state.isStartSortLoading) {
      return (
        <div
          className="spinner-border text-primary "
          role="status"
          id="hotel-search-loader"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (
      this.state.isStartSortLoading === false &&
      this.state.starSortMode === "increment"
    ) {
      return ( 
    
  <Select  className="btn selectOptions  " 
  placeholder={(this.state.selectedStar)?this.state.selectedStar.label:"Stars"}   
     options={this.state.hotelStarLists}
     
  onChange={this.handleChangeStar} />

      );
    }
    return (   
  <Select  className="btn selectOptions " 
   placeholder={(this.state.selectedStar)?this.state.selectedStar.label:"Stars"}  
      options={this.state.hotelStarLists}
     
  onChange={this.handleChangeStar} />

    );
  }


  render() {
    return (<>
      <div className="row mt-2 ">
          <span className="sort-hotel-label">{"Filter"}</span>
          </div>
      <div className="row m-0 filterBox">
        
        <div className="col-xl-3  col-sm-12 col-md-4 col-lg-4  ">
           {this.renderSortPriceButton()} 
          </div>
          
        <div className=" col-xl-3  col-sm-12 col-md-4 col-lg-4   ">
           {this.renderSortStarButton()}
          </div>
        <div className="col-xl-2  col-sm-12 col-md-4 col-lg-4   ">   
         {this.renderSortboardTypes()} 
          </div>
        <div className=" col-xl-4  col-sm-12 col-md-12 col-lg-12 hotelSearcharea"  >  
          <SearchHotel searchHotel={this.props.searchHotel} />
          </div>
        {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0"> */}
          {/* <span className="sort-hotel-label">Filter : </span> */}
          {/* {this.renderSortPriceButton()} */}
          {/* {this.renderSortStarButton()} */}
          {/* {this.renderSortboardTypes()} */}
          
          {/* <SearchHotel searchHotel={this.props.searchHotel} /> */}
          {/* {this.renderSortlocationButton()} */}
        {/* </div> */}
      </div>
   </> );
  }
}

export default HotelFilter;
