import React, { Component } from "react";
import "./packageEnquire.css";

import { HttpService } from "../../../services/http/http-service";

import {
  Modal,
  Alert,
  Spinner,
  Form,
  InputGroup,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCity,
  faFlag,
  faUser,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import Autocomplete from "react-autocomplete";

import FormControl from "react-bootstrap/FormControl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ReCAPTCHA from "react-google-recaptcha";

// #8222		"This is not the correct enquiry form
// #8250		"package inquiry form
// #8254		Mobile - package enquiry form distortion
// #9230 Package inquiry form Enhancement

class PackageEnquire extends Component {
  constructor(props) {
    super(props);
    let search = queryString.parse(this.props.location.search);
    this.state = {
      search: search,
      validated: false,
      isLoadingPayment: false,
      FirstName: null,
      LastName: null,
      phone: null,
      Email: null,
      // Nationality: null,
      countriesCode: null,
      Telephone: null,
      showModal: false,
      Passengers: 1,
      Adult: 1,
      Child: 0,
      Infant: 0,
      AdultText: "Adult",
      ChildText: "Child",
      InfantText: "Infant",
      showPassengerSelecter: false,
      passengerText: "1 Adult ,0 Child ,0 Infant",
      // Date: null,
      DepartureDate: null,
      ReturnDate: null,
      FlexibleDate: null,
      ResponseMessage: null,
      // Airport: null,
      SpecialRequirements: null,
      PackageName: null,

      Comment: null,

      agreePromotion: false,
      invalidGoogleRecapthcha: true,
    };
  }

  openDepartureDate = () => {
    const CustomDatepicker = ({ value, onClick }) => (
      <>
        <FormControl
          value={value === "" ? " Departure Date* " : value}
          name="departureDate"
          id="departureDate"
          readOnly
          className="trip"
          required
          placeholderText=" Departure Date* "
          aria-label="Departure Date"
          aria-describedby="Departure Date"
          onClick={onClick}
        />
      </>
    );
    return (
      <DatePicker
        required
        disabledKeyboardNavigation
        id="departureDate"
        placeholderText=" Departure Date* "
        selected={
          this.state.DepartureDate === null ? null : this.state.DepartureDate
        }
        onChange={(value) => {
          this.setState({
            DepartureDate: value,
          });
        }}
        onDateChange={(DepartureDate) => this.setState({ DepartureDate })}
        minDate={new Date()}
        customInput={<CustomDatepicker />}
        name="departureDate"
        dateFormat="MM/dd/yyyy"
      />
    );
  };

  openReturnDate = () => {
    const CustomDatepicker = ({ value, onClick }) => (
      <>
        <FormControl
          value={value === "" ? " Return Date* " : value}
          name="returnDate"
          id="returnDate"
          readOnly
          className="trip"
          required
          placeholderText=" Return Date* "
          aria-label="ReturnDate"
          aria-describedby="ReturnDate"
          onClick={onClick}
        />
      </>
    );
    return (
      <DatePicker
        required
        disabledKeyboardNavigation
        id="returnDate"
        placeholderText=" Return Date* "
        selected={this.state.ReturnDate === null ? null : this.state.ReturnDate}
        onChange={(value) => {
          this.setState({
            ReturnDate: value,
          });
        }}
        onDateChange={(ReturnDate) => this.setState({ ReturnDate })}
        minDate={new Date()}
        customInput={<CustomDatepicker />}
        name="returnDate"
        dateFormat="MM/dd/yyyy"
      />
    );
  };
  numberOfPassengerHandler = (event) => {
    const tempState = this.state.showPassengerSelecter;
    this.setState({ showPassengerSelecter: !tempState });
  };

  handlePassenger = (action, pass) => {
    let passUpdate = 0;
    if (pass === "adult") {
      passUpdate = this.state.Adult;
      if (action == "add") {
        if (this.state.Child + this.state.Adult < 9) {
          passUpdate = this.state.Adult + 1;
        }
      } else {
        if (this.state.Adult >= 2) {
          passUpdate = this.state.Adult - 1;
        }
      }
      this.setState({
        Adult: passUpdate,
        Passengers: passUpdate + this.state.Child,
      });
    }
    if (pass === "infant") {
      passUpdate = this.state.Infant;
      if (action == "add") {
        if (this.state.Infant < 3) {
          passUpdate = this.state.Infant + 1;
        }
      } else {
        if (this.state.Infant >= 1) {
          passUpdate = this.state.Infant - 1;
        }
      }
      this.setState({
        Infant: passUpdate,
        Passengers: this.state.Child + this.state.Adult,
      });
    }
    if (pass === "child") {
      passUpdate = this.state.Child;
      if (action == "add") {
        if (this.state.Child + this.state.Adult < 9) {
          passUpdate = this.state.Child + 1;
        }
      } else {
        if (this.state.Child >= 1) {
          passUpdate = this.state.Child - 1;
        }
      }
      this.setState({
        Child: passUpdate,
        Passengers: passUpdate + this.state.Adult,
      });
    }
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      window.scrollTo({ top: 50, behavior: "smooth" });
      this.setState({
        ResponseMessage:
          "Please provide values for all required fields (marked as *)",
      });
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.submitForm();
    }

    this.setState({ validated: true });
  };

  submitForm() {
    this.setState({ isLoadingPayment: true });
    let apiRelativePackageEnquire = "PackageEnquiry/SendEmail";

    let departureDate = document.getElementById("departureDate").value;
    departureDate = new Date(departureDate);
    departureDate =
      departureDate.getFullYear() +
      "-" +
      (departureDate.getMonth() + 1) +
      "-" +
      departureDate.getDate();

    let returnDate = document.getElementById("returnDate").value;
    returnDate = new Date(returnDate);
    returnDate =
      returnDate.getFullYear() +
      "-" +
      (returnDate.getMonth() + 1) +
      "-" +
      returnDate.getDate();

    if (true) {
      var RequestObject = {
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        Email: this.state.Email,
        Nationality: this.state.Nationality,
        Telephone: this.state.phone,
        Adult: this.state.Adult,
        Child: this.state.Child,
        Infant: this.state.Infant,
        DepartureDate: departureDate,
        ReturnDate: returnDate,
        FlexibleDate: departureDate,
        Airport: this.state.Airport,
        SpecialRequirements: this.state.SpecialRequirements,
        PackageName: this.state.search.packageName,
        Comment: this.state.Comment,
        submitStatus: false,
      };
      this.setState({ ResponseMessage: null });

      HttpService.postServiceHotel(
        RequestObject,
        apiRelativePackageEnquire
      ).then(
        (response) => {
          this.setState({ isLoadingPayment: false });
          let paymentApiResponse = response.data;

          if (paymentApiResponse) {
            if (this.state.agreePromotion) {
              this.submitSubscriptioin();
            }
            this.setState({
              showModal: true,
              submitStatus: true,
              ResponseMessage:
                "Your request has been submitted successfully. We will contact you via your email address.",
            });
          } else {
            this.setState({
              ResponseMessage:
                "Sorry! Your request was not submitted successfully. Please try again.",
              submitStatus: false,
            });
          }
        },
        (error) => {}
      );
    }
  }

  submitSubscriptioin() {
    var requestBody = {
      Email: this.state.Email,
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
    };
    this.setState({ isLoading: true, modalView: false });
    HttpService.postEmail(requestBody)
      .then((response) => {
        // if (response.data.messageAPI == "Already Subscribed.") {
        //   this.setState({
        //     emailModal: true,
        //     modalText: "Already Subscribed.",
        //     isLoading: false,
        //   });
        // } else {
        //   this.setState({
        //     emailModal: true,
        //     modalText: "Your have been successfully Subscribed",
        //     isLoading: false,
        //   });
        // }
      })
      .catch((error) => {
        this.setState({
          emailModal: true,
          modalText: "Error occured, try again",
          isLoading: false,
        });
      });
  }
  render() {
    var { validated } = this.state;

    const airpotList = [
      "Aarhus (AAR), Denmark",
      "Aberdeen Dyce (ABZ)), United Kingdom",
      "Abha (AHB), Saudi Arabia",
      "Abidjan (ABJ), Côte d'Ivoire",
      "Abuja (ABV), Nigeria",
      "Accra (ACC), Ghana",
      "Addis Ababa (ADD), Ethiopia",
      "Adelaide (ADL), Australia",
      "Albany Intl (ALB), United States",
      "Alicante (ALC), Spain",
      "Amman (AMM), Jordan",
      "Amsterdam (AMS), Netherland",
      "Antananarivo (TNR), Madagascar",
      "Aomori(AOJ), Japan",
      "Arba Minch (AMH), Ethiopia",
      "Asmara (ASM), Eritrea",
      "Assosa (ASO), Ethiopia",
      "Athens (ATH), Greece",
      "Atlanta (ATL), United States",
      "Auckland Airport (AKL), New Zealand",
      "Austin (AUS), United States",
      "Avalon AU(AVV), Australia",
      "Axum (AXU), Ethiopia",
      "Ayers Rock(AYQ), Australia ",
      "Bahar Dar (BJR), Ethiopia",
      "Bahrain (BAH), Bahrain",
      "Ballina(BNK), Australia",
      "Bamako (BKO), Mali",
      "Bandung (BDO), Indonesia",
      "Bangkok Suvarnabhumi (BKK), Thailand",
      "Bangui (BGF), Central African Republic",
      "Barcelona (BCN), Spain",
      "Beijing (BJS), China",
      "Beijing (PEK), China",
      "Beirut (BEY), Lebanon",
      "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil",
      "Bergen (BGO), Norway",
      "Bergenz (XGZ), Austria",
      "Berlin Tegel (TXL), Germany",
      "Bilbao (BIO), Spain",
      "Billund (BLL), Denmark",
      "Birmingham (BHX) , United Kingdom",
      "Blantyre (BLZ), Malawi",
      "Boston (BOS), United States",
      "Boulder (BLD), United States",
      "Brasilia (BSB), Brazil",
      "Brazzaville (BZV), Republic of Congo",
      "Bremen (BRE), Germany",
      "Brisbane (BNE), Australia",
      "Brussels (BRU), Belgium",
      "Bucharest (OTP), Romania",
      "Budapest, Liszt Ferenc Airport (BUD), Hungary",
      "Buenos Aires (EZE), Argentina",
      "Buffalo (BUF), United States",
      "Bujumbura (BJM), Burundi",
      "Burlington (BTV), United States",
      "Cairo (CAI), Egypt",
      "Calgary Intl AB (YYC), Canada",
      "Campo Grande International Airport (CGR), Brazil",
      "Cape Town (CPT), South Africa",
      "Cardiff (CWL) , United Kingdom",
      "Changsha (CSX), China",
      "Charleston (CHS), United States",
      "Charleston (CRW), United States",
      "Charlotte (CLT), United States",
      "Charlottesville (CHO), United States",
      "Chengdu (CTU), China",
      "Chiang Mai (CNX), Thailand",
      "Chicago Ohare (ORD), United States",
      "Chongqing (CKG), China",
      "Christchurch Airport (CHC), New Zealand",
      "Cincinnati (CVG), United States",
      "Cleveland (CLE), United States",
      "Columbia (CAE), United States",
      "Columbus (CMH), United States",
      "Conakry (CKY), Guinea",
      "Copenhagen (CPH), Denmark",
      "Coruna (LCG), Spain",
      "Cotonou (COO), Benin",
      "Cuiaba (CGB), Brazil",
      "Curitiba (CWB), Brazil",
      "Dakar Blaise Diagne Airport (DSS), Senegal",
      "Dakar(DKR), Senegal",
      "Dalian (DLC), China",
      "Dallas FT Worth , TX (DFW), United States",
      "Dammam (DMM), Saudi Arabia",
      "Dar Es Salaam (DAR), Tanzania",
      "Darwin(DRW), Australia",
      "Dayton (DAY), United States",
      "Delhi (DEL), India",
      "Dembidollo (DEM), Ethiopia",
      "Denpasar Bali (DPS), Indonesia",
      "Denver (DEN), United States",
      "Detroit (DTW), United States",
      "Dire Dawa (DIR), Ethiopia",
      "Djibouti (JIB), Djibouti",
      "Doha (DOH), Qatar",
      "Douala (DLA), Cameroon",
      "Dresden (DRS), Germany",
      "Dubai (DXB), UAE",
      "Dubai Al Maktoum (DWC), UAE",
      "Dublin (DUB), Ireland",
      "Durban (DUR), South Africa",
      "Dusseldorf (DUS), Germany",
      "Edinburgh (EDI) , United Kingdom",
      "Edmonton Intl AB (YEG), Canada",
      "El Paso, TX (ELP), United States",
      "Entebbe (EBB), Uganda",
      "Enugu (ENU), Nigeria",
      "Florianopolis (FLN) , Brazil",
      "Fortaleza (FOR), Brazil",
      "Frankfurt (FRA), Germany",
      "Freetown (FNA), Metropolitan Area,Sierra Leone",
      "Friedrichshafen (FDH), Germany",
      "Ft Lauderdale (FLL), United States",
      "Fukuoka (FUK), Japan",
      "Funchal (FNC), Portugal",
      "Gaborone (GBE), Botswana",
      "Gambella (GMB), Ethiopia",
      "Garoua International Airport (GOU), Cameroon",
      "Gassim (ELQ), Saudi Arabia",
      "Geneva (GVA), Switzerland",
      "Glasgow (GLA), United Kingdom",
      "GOA (GOI), INDIA",
      "Goba, Bale - Goba (GOB), Ethiopia",
      "Gode (GDE), Ethiopia",
      "Goiania, Santa Genoveva Airport (GYN), Brazil",
      "Goma (GOM), Democratic Republic of Congo",
      "Gondar (GDQ), Ethiopia",
      "Gothenburg (GOT), Sweden",
      "Grand Rapids (GRR), United States",
      "Graz (GRZ), Austria",
      "#60ac5dsboro (GSO), United States",
      "#60ac5dville Spartanburg  (GSP), United States",
      "Guangzhou (CAN), China",
      "Haikou (HAK), China",
      "Hali (HAS), Saudi Arabia",
      "Halifax (YHZ), Canada",
      "Hamburg (HAM), Germany",
      "Hamilton Island(HTI), Australia",
      "Hannover (HAJ), Germany",
      "Hanoi(HAN), Vietnam",
      "Harare (HRE), Zimbabwe",
      "Hargeisa (HGA), Somaliland",
      "Harrisburg (MDT), United States",
      "Hartford Sprngfld, CT (BDL), United States",
      "Hawassa (AWA), Ethiopia",
      "Helsinki (HEL), Finland",
      "Ho Chi Minh City(SGN), Vietnam",
      "Hobart(HBA), Australia ",
      "Hong Kong (HKG), China",
      "Honolulu (HNL), United States",
      "Houston (HOU), United States",
      "Houston (IAH), United States",
      "Humera (HUE), Ethiopia",
      "Huntsville (HSV), United States",
      "Hyderabad(HYD), India",
      "Ibiza (IBZ), Spain",
      "Iguassu (IGU), Brazil",
      "Indianapolis (IND), United States",
      "Innsbruck (IOB), Austria",
      "Istanbul Ataturk (IST), Turkey",
      "Jacksonville (JAX), United States",
      "Jakarta (CGK), Indonesia",
      "Jakarta(JKT), Indonesia",
      "Jazan (GIZ), Saudi Arabia",
      "Jeddah (JED), Saudi Arabia",
      "Jijiga (JIJ), Ethiopia",
      "Jimma (JIM), Ethiopia",
      "Jinka (BCO), Ethiopia",
      "Johannesburg (JNB), South Africa",
      "Juazeiro Norte (JDO), Brazil",
      "Juba (JUB), South Sudan",
      "Kabri Dar (ABK), Ethiopia",
      "Kaduna (KAD), Nigeria",
      "Kano (KAN), Nigeria",
      "Kansas City (MCI), United States",
      "Khartoum (KRT), Sudan",
      "Kigali (KGL), Rwanda",
      "Kilimanjaro (JRO), Tanzania",
      "Kinshasa Ndjili (FIH), Democratic Republic of Congo",
      "Kisangani (FKI), Democratic Republic of Congo",
      "Klagenfurt (KGV) , Austria",
      "Knoxville (TYS), United States",
      "Koh Samui (USM), Thailand ",
      "kombolcha (DSE), Ethiopia",
      "Kota Kinabalu (BKI), Malaysia",
      "Kristiansand (KRS), Norway",
      "Kuala Lumpur (KUL), Malaysia",
      "Kuching (KCH), Malaysia",
      "Kunming (KMG), China",
      "Kuwait (KWI), Kuwait",
      "Lagos (LOS), Nigeria",
      "Lalibella (LLI), Ethiopia",
      "Langkawi (LGK), Malaysia",
      "Larnaca (LCA), Cyprus",
      "Las Vegas (LAS), United States",
      "Launceston(LST) , Australia",
      "Leeds Bradford (LBA), United Kingdom",
      "Leipzig Halle (LEJ), Germany",
      "Libreville (LBV), Gabon",
      "Lilongwe (LLW), Malawi",
      "Linz (LNZ), Austria",
      "Lisbon (LIS), Portugal",
      "Lome (LFW), Togo",
      "London (LON), United Kingdom",
      "London Heathrow (LHR), United Kingdom",
      "London On (YXU), CANADA",
      "Londrina Airport (LDB), Brazil",
      "Los Angeles (LAX), United States",
      "Louisville (SDF), United States",
      "Luanda (LAD), Angola",
      "Lubumbashi (FBM), Democratic Republic of Congo",
      "Lulea (LLA), Sweden",
      "Lusaka (LUN), Zambia",
      "Lyon, Satolaos Saint Exupery Airport (LYS), France",
      "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil",
      "Madrid (MAD), Spain",
      "Mahe Island (SEZ), Seychelles",
      "Malabo (SSG), Equatorial Guinea",
      "Malmo (MMA), Sweden",
      "Manado (MDC), Indonesia",
      "Manchester(MAN), United Kingdom",
      "Manila (MNL), Philippines",
      "Maputo (MPM), Mozambique",
      "Marseille, Marignane Airport(MRS), France",
      "Mauritius (MRU), Mauritius",
      "Mbuji Mayi (MJM), Democratic Republic of Congo",
      "Medan Kuala Namu(KNO), Indonesia",
      "Medina (MED), Saudi Arabia",
      "Mekelle (MQX), Ethiopia",
      "Melbourne (MEL), Australia",
      "Memphis(MEM), United States",
      "Miami (MIA), United States",
      "Milan Malpensa (MXP), Italy",
      "Minneapolis (MSP), United States",
      "Mombassa (MBA), Kenya",
      "Moncton (YQM), Canada",
      "Monrovia (MLW), Republic of Liberia",
      "MONT TREMBLANT (YTM), Canada",
      "Montreal Trudeau (YUL), Canada",
      "Moroni (HAH), Comoros",
      "Moscow (MOW), Russia",
      "Muenster (FMO), Germany",
      "Mumbai (BOM), India",
      "Munich (MUC), Germany",
      "Muscat (MCT), Oman",
      "Nagoya (NGO), Japan",
      "Nairobi Kenyatta (NBO), Kenya",
      "Nashville (BNA), United States",
      "Natal International Airport (NAT), Brazil",
      "Navegantes, Victor Konder Airport (NVT), Brazil",
      "N'djamena (NDJ), Chad",
      "Ndola (NLA), Zambia",
      "New Orleans (MSY), United States",
      "NEW YORK (JFK), United States",
      "New York (LGA), United States",
      "Newark (EWR), United States",
      "Newark (NYC), United States",
      "Niamey (NIM), Niger",
      "Norfolk VA Beach (ORF), United States",
      "Nosy Be (NOS), Madagascar",
      "Nuremberg (NUE), Germany",
      "Oakland (OAK), United States",
      "Okinawa (OKA), Japan",
      "Oklahoma City (OKC), United States",
      "Orange County (SNA), United States",
      "Orlando (MCO), United States",
      "Osaka Itami (ITM), Japan",
      "Osaka Kansai (KIX), Japan",
      "Oslo (OSL), Norway",
      "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ",
      "Ottawa On (YOW), Canada",
      "Ouagadougou (OUA), Burkina Faso",
      "Palembang (PLM), Indonesia",
      "Palma De Mallorca (PMI), Spain",
      "Paris De Gaulle (CDG), France",
      "Pekanbaru (PKU), Indonesia",
      "Penang (PEN), Malaysia",
      "Perth (PER), Australia",
      "Philadelphia (PHL), United States",
      "Phoenix (PHX), United States",
      "Phuket (HKT), Thailand",
      "Pittsburgh (PIT), United States",
      "Pointe Noire (PNR), Republic of Congo",
      "Portland ME (PWM), United States",
      "Portland OR (PDX), United States",
      "Porto Alegre (POA), Brazil",
      "Porto Portugal (OPO), Portugal",
      "Porto Santo (PXO), Portugal",
      "Prague - Ruzyne Airport(PRG), Czech Republic",
      "Praya Lombok (LOP), Indonesia",
      "Providence (PVD), United States",
      "Pusan (PUS), South Korea",
      "Qaisumah (AQI), Saudi Arabia",
      "Québec (YQB), Canada",
      "Railway Germany (QYG), Germany",
      "Raleigh Durham (RDU), United States",
      "Recife (REC), Brazil",
      "Regina (YQR), Canada",
      "Richmond (RIC), United States",
      "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil",
      "Rio Janeiro Gig (GIG), Brazil",
      "Riyadh (RUH), Saudi Arabia",
      "Roanoke (ROA), United States",
      "Rochester (ROC), United States",
      "Rome (ROM), Italy",
      "Rome Fiumicino (FCO), Italy",
      "Rotterdam (RTM), Netherlands",
      "Sacramento (SMF), United States",
      "Salvador (SSA), Brazil",
      "Salzburg (SZG), Austria",
      "San Antonio (SAT), United States",
      "San Diego (SAN), United States",
      "San Francisco (SFO), United States",
      "Santa Maria Azors (SMA), Portugal",
      "Sao Paulo (GRU), Brazil",
      "Sao Paulo (SAO), Brazil",
      "Sapporo Chitose (CTS), Japan",
      "Saskatoon SK (YXE), Canada",
      "Savannah (SAV), United States",
      "Seattle Tacoma (SEA), United States",
      "Semarang (SRG), Indonesia",
      "Semera (SZE), Ethiopia",
      "Seoul (ICN), South Korea",
      "Seoul (SEL), South Korea",
      "Shanghai (PVG), China",
      "Shanghai (SHA), China",
      "Shantou (SWA), China",
      "Shenzhen (SZX), China",
      "Shire (SHC), Ethiopia",
      "Singapore (SIN), Singapore",
      "Skelleftea (SFT), Sweden",
      "Sofia Airport (SOF), Bulgaria",
      "ST Johns NF (YYT), Canada",
      "St. Louis (STL), United States",
      "St. Polten (POK), Austria",
      "State College (SCE), United States",
      "Stavanger(SVG), Norway",
      "Stockholm (STO), Sweden",
      "Stockholm Arlanda (ARN), Sweden",
      "Stuttgart (STR), Germany",
      "Sultan Hasanuddin (UPG), Indonesia",
      "Sunshine Coast(MCY), Australia",
      "Surabaya (SUB), Indonesia",
      "Sydney (SYD), Australia",
      "Syracuse (SYR), United States",
      "Tabuk (TUU), Saudi Arabia",
      "Taif (TIF), Saudi Arabia",
      "Tampa (TPA), United States",
      "Tel Aviv (TLV), Israel",
      "Tete (TET), Mozambique",
      "The Hague (HAG), Netherlands",
      "Tokyo (TYO), Japan",
      "Tokyo Haneda (HND), Japan",
      "Tokyo Narita (NRT), Japan",
      "Toronto (YTO), Canada",
      "Toronto (YYZ), Canada",
      "Toulouse (TLS), France",
      "Townsville(TSV), Australia",
      "Trondheim (TRD), Norway",
      "Turku (TKU), Finland",
      "Umea (UME), Sweden",
      "Vaasa (VAA), Finland",
      "Valencia (VLC), Spain",
      "Vancouver BC (YVR), Canada",
      "Victoria (YYJ), Canada",
      "Victoria Falls (VFA), Zimbabwe",
      "Vienna (VIE), Austria",
      "Vigo (VGO), Spain",
      "Vilnius, Vilnius Airport(VNO), Lithuania",
      "Warsaw, Chopin Okecie Airport (WAW), Poland",
      "Washington (WAS), United States",
      "Washington Dulles (IAD), United States",
      "Windhoek (WDH), Namibia",
      "Winnipeg MB (YWG), Canada",
      "Wuhan (WUH), China",
      "Xiamen (XMN), China",
      "Yaounde Nsimalen (NSI), Cameroon",
      "Yogyakarta (JOG), Indonesia",
      "Yundum Airport (BJL), Gambia",
      "Zanzibar (ZNZ), Tanzania",
      "Zurich (ZRH), Switzerland",
    ];
    let countriesCode = [
      {
        code: "7840",
        name: "Abkhazia",
      },
      {
        code: "93",
        name: "Afghanistan",
      },
      {
        code: "355",
        name: "Albania",
      },
      {
        code: "213",
        name: "Algeria",
      },
      {
        code: "1684",
        name: "American Samoa",
      },
      {
        code: "376",
        name: "Andorra",
      },
      {
        code: "244",
        name: "Angola",
      },
      {
        code: "1264",
        name: "Anguilla",
      },
      {
        code: "1268",
        name: "Antigua and Barbuda",
      },
      {
        code: "54",
        name: "Argentina",
      },
      {
        code: "374",
        name: "Armenia",
      },
      {
        code: "297",
        name: "Aruba",
      },
      {
        code: "247",
        name: "Ascension",
      },
      {
        code: "61",
        name: "Australia",
      },
      {
        code: "672",
        name: "Australian External Territories",
      },
      {
        code: "43",
        name: "Austria",
      },
      {
        code: "994",
        name: "Azerbaijan",
      },
      {
        code: "1242",
        name: "Bahamas",
      },
      {
        code: "973",
        name: "Bahrain",
      },
      {
        code: "880",
        name: "Bangladesh",
      },
      {
        code: "1246",
        name: "Barbados",
      },
      {
        code: "1268",
        name: "Barbuda",
      },
      {
        code: "375",
        name: "Belarus",
      },
      {
        code: "32",
        name: "Belgium",
      },
      {
        code: "501",
        name: "Belize",
      },
      {
        code: "229",
        name: "Benin",
      },
      {
        code: "1441",
        name: "Bermuda",
      },
      {
        code: "975",
        name: "Bhutan",
      },
      {
        code: "591",
        name: "Bolivia",
      },
      {
        code: "387",
        name: "Bosnia and Herzegovina",
      },
      {
        code: "267",
        name: "Botswana",
      },
      {
        code: "55",
        name: "Brazil",
      },
      {
        code: "246",
        name: "British Indian Ocean Territory",
      },
      {
        code: "1284",
        name: "British Virgin Islands",
      },
      {
        code: "673",
        name: "Brunei",
      },
      {
        code: "359",
        name: "Bulgaria",
      },
      {
        code: "226",
        name: "Burkina Faso",
      },
      {
        code: "257",
        name: "Burundi",
      },
      {
        code: "855",
        name: "Cambodia",
      },
      {
        code: "237",
        name: "Cameroon",
      },
      {
        code: "1",
        name: "Canada",
      },
      {
        code: "238",
        name: "Cape Verde",
      },
      {
        code: " 345",
        name: "Cayman Islands",
      },
      {
        code: "236",
        name: "Central African Republic",
      },
      {
        code: "235",
        name: "Chad",
      },
      {
        code: "56",
        name: "Chile",
      },
      {
        code: "86",
        name: "China",
      },
      {
        code: "61",
        name: "Christmas Island",
      },
      {
        code: "61",
        name: "Cocos-Keeling Islands",
      },
      {
        code: "57",
        name: "Colombia",
      },
      {
        code: "269",
        name: "Comoros",
      },
      {
        code: "242",
        name: "Congo",
      },
      {
        code: "243",
        name: "Congo, Dem. Rep. of (Zaire)",
      },
      {
        code: "682",
        name: "Cook Islands",
      },
      {
        code: "506",
        name: "Costa Rica",
      },
      {
        code: "385",
        name: "Croatia",
      },
      {
        code: "53",
        name: "Cuba",
      },
      {
        code: "599",
        name: "Curacao",
      },
      {
        code: "537",
        name: "Cyprus",
      },
      {
        code: "420",
        name: "Czech Republic",
      },
      {
        code: "45",
        name: "Denmark",
      },
      {
        code: "246",
        name: "Diego Garcia",
      },
      {
        code: "253",
        name: "Djibouti",
      },
      {
        code: "1767",
        name: "Dominica",
      },
      {
        code: "1809",
        name: "Dominican Republic",
      },
      {
        code: "670",
        name: "East Timor",
      },
      {
        code: "56",
        name: "Easter Island",
      },
      {
        code: "593",
        name: "Ecuador",
      },
      {
        code: "20",
        name: "Egypt",
      },
      {
        code: "503",
        name: "El Salvador",
      },
      {
        code: "240",
        name: "Equatorial Guinea",
      },
      {
        code: "291",
        name: "Eritrea",
      },
      {
        code: "372",
        name: "Estonia",
      },
      {
        code: "251",
        name: "Ethiopia",
      },
      {
        code: "500",
        name: "Falkland Islands",
      },
      {
        code: "298",
        name: "Faroe Islands",
      },
      {
        code: "679",
        name: "Fiji",
      },
      {
        code: "358",
        name: "Finland",
      },
      {
        code: "33",
        name: "France",
      },
      {
        code: "596",
        name: "French Antilles",
      },
      {
        code: "594",
        name: "French Guiana",
      },
      {
        code: "689",
        name: "French Polynesia",
      },
      {
        code: "241",
        name: "Gabon",
      },
      {
        code: "220",
        name: "Gambia",
      },
      {
        code: "995",
        name: "Georgia",
      },
      {
        code: "49",
        name: "Germany",
      },
      {
        code: "233",
        name: "Ghana",
      },
      {
        code: "350",
        name: "Gibraltar",
      },
      {
        code: "30",
        name: "Greece",
      },
      {
        code: "299",
        name: "#60ac5dland",
      },
      {
        code: "1 473",
        name: "Grenada",
      },
      {
        code: "590",
        name: "Guadeloupe",
      },
      {
        code: "1671",
        name: "Guam",
      },
      {
        code: "502",
        name: "Guatemala",
      },
      {
        code: "224",
        name: "Guinea",
      },
      {
        code: "245",
        name: "Guinea-Bissau",
      },
      {
        code: "595",
        name: "Guyana",
      },
      {
        code: "509",
        name: "Haiti",
      },
      {
        code: "504",
        name: "Honduras",
      },
      {
        code: "852",
        name: "Hong Kong SAR China",
      },
      {
        code: "36",
        name: "Hungary",
      },
      {
        code: "354",
        name: "Iceland",
      },
      {
        code: "91",
        name: "India",
      },
      {
        code: "62",
        name: "Indonesia",
      },
      {
        code: "98",
        name: "Iran",
      },
      {
        code: "964",
        name: "Iraq",
      },
      {
        code: "353",
        name: "Ireland",
      },
      {
        code: "972",
        name: "Israel",
      },
      {
        code: "39",
        name: "Italy",
      },
      {
        code: "225",
        name: "Ivory Coast",
      },
      {
        code: "1876",
        name: "Jamaica",
      },
      {
        code: "81",
        name: "Japan",
      },
      {
        code: "962",
        name: "Jordan",
      },
      {
        code: "7",
        name: "Kazakhstan",
      },
      {
        code: "254",
        name: "Kenya",
      },
      {
        code: "686",
        name: "Kiribati",
      },
      {
        code: "965",
        name: "Kuwait",
      },
      {
        code: "996",
        name: "Kyrgyzstan",
      },
      {
        code: "856",
        name: "Laos",
      },
      {
        code: "371",
        name: "Latvia",
      },
      {
        code: "961",
        name: "Lebanon",
      },
      {
        code: "266",
        name: "Lesotho",
      },
      {
        code: "231",
        name: "Liberia",
      },
      {
        code: "218",
        name: "Libya",
      },
      {
        code: "423",
        name: "Liechtenstein",
      },
      {
        code: "370",
        name: "Lithuania",
      },
      {
        code: "352",
        name: "Luxembourg",
      },
      {
        code: "853",
        name: "Macau SAR China",
      },
      {
        code: "389",
        name: "Macedonia",
      },
      {
        code: "261",
        name: "Madagascar",
      },
      {
        code: "265",
        name: "Malawi",
      },
      {
        code: "60",
        name: "Malaysia",
      },
      {
        code: "960",
        name: "Maldives",
      },
      {
        code: "223",
        name: "Mali",
      },
      {
        code: "356",
        name: "Malta",
      },
      {
        code: "692",
        name: "Marshall Islands",
      },
      {
        code: "596",
        name: "Martinique",
      },
      {
        code: "222",
        name: "Mauritania",
      },
      {
        code: "230",
        name: "Mauritius",
      },
      {
        code: "262",
        name: "Mayotte",
      },
      {
        code: "52",
        name: "Mexico",
      },
      {
        code: "691",
        name: "Micronesia",
      },
      {
        code: "1808",
        name: "Midway Island",
      },
      {
        code: "373",
        name: "Moldova",
      },
      {
        code: "377",
        name: "Monaco",
      },
      {
        code: "976",
        name: "Mongolia",
      },
      {
        code: "382",
        name: "Montenegro",
      },
      {
        code: "1664",
        name: "Montserrat",
      },
      {
        code: "212",
        name: "Morocco",
      },
      {
        code: "95",
        name: "Myanmar",
      },
      {
        code: "264",
        name: "Namibia",
      },
      {
        code: "674",
        name: "Nauru",
      },
      {
        code: "977",
        name: "Nepal",
      },
      {
        code: "31",
        name: "Netherlands",
      },
      {
        code: "599",
        name: "Netherlands Antilles",
      },
      {
        code: "1869",
        name: "Nevis",
      },
      {
        code: "687",
        name: "New Caledonia",
      },
      {
        code: "64",
        name: "New Zealand",
      },
      {
        code: "505",
        name: "Nicaragua",
      },
      {
        code: "227",
        name: "Niger",
      },
      {
        code: "234",
        name: "Nigeria",
      },
      {
        code: "683",
        name: "Niue",
      },
      {
        code: "672",
        name: "Norfolk Island",
      },
      {
        code: "850",
        name: "North Korea",
      },
      {
        code: "1670",
        name: "Northern Mariana Islands",
      },
      {
        code: "47",
        name: "Norway",
      },
      {
        code: "968",
        name: "Oman",
      },
      {
        code: "92",
        name: "Pakistan",
      },
      {
        code: "680",
        name: "Palau",
      },
      {
        code: "970",
        name: "Palestinian Territory",
      },
      {
        code: "507",
        name: "Panama",
      },
      {
        code: "675",
        name: "Papua New Guinea",
      },
      {
        code: "595",
        name: "Paraguay",
      },
      {
        code: "51",
        name: "Peru",
      },
      {
        code: "63",
        name: "Philippines",
      },
      {
        code: "48",
        name: "Poland",
      },
      {
        code: "351",
        name: "Portugal",
      },
      {
        code: "1787",
        name: "Puerto Rico",
      },
      {
        code: "974",
        name: "Qatar",
      },
      {
        code: "262",
        name: "Reunion",
      },
      {
        code: "40",
        name: "Romania",
      },
      {
        code: "7",
        name: "Russia",
      },
      {
        code: "250",
        name: "Rwanda",
      },
      {
        code: "685",
        name: "Samoa",
      },
      {
        code: "378",
        name: "San Marino",
      },
      {
        code: "966",
        name: "Saudi Arabia",
      },
      {
        code: "221",
        name: "Senegal",
      },
      {
        code: "381",
        name: "Serbia",
      },
      {
        code: "248",
        name: "Seychelles",
      },
      {
        code: "232",
        name: "Sierra Leone",
      },
      {
        code: "65",
        name: "Singapore",
      },
      {
        code: "421",
        name: "Slovakia",
      },
      {
        code: "386",
        name: "Slovenia",
      },
      {
        code: "677",
        name: "Solomon Islands",
      },
      {
        code: "27",
        name: "South Africa",
      },
      {
        code: "500",
        name: "South Georgia and the South Sandwich Islands",
      },
      {
        code: "82",
        name: "South Korea",
      },
      {
        code: "34",
        name: "Spain",
      },
      {
        code: "94",
        name: "Sri Lanka",
      },
      {
        code: "249",
        name: "Sudan",
      },
      {
        code: "597",
        name: "Suriname",
      },
      {
        code: "268",
        name: "Swaziland",
      },
      {
        code: "46",
        name: "Sweden",
      },
      {
        code: "41",
        name: "Switzerland",
      },
      {
        code: "963",
        name: "Syria",
      },
      {
        code: "886",
        name: "Taiwan",
      },
      {
        code: "992",
        name: "Tajikistan",
      },
      {
        code: "255",
        name: "Tanzania",
      },
      {
        code: "66",
        name: "Thailand",
      },
      {
        code: "670",
        name: "Timor Leste",
      },
      {
        code: "228",
        name: "Togo",
      },
      {
        code: "690",
        name: "Tokelau",
      },
      {
        code: "676",
        name: "Tonga",
      },
      {
        code: "1868",
        name: "Trinidad and Tobago",
      },
      {
        code: "216",
        name: "Tunisia",
      },
      {
        code: "90",
        name: "Turkey",
      },
      {
        code: "993",
        name: "Turkmenistan",
      },
      {
        code: "1649",
        name: "Turks and Caicos Islands",
      },
      {
        code: "688",
        name: "Tuvalu",
      },
      {
        code: "1340",
        name: "U.S. Virgin Islands",
      },
      {
        code: "256",
        name: "Uganda",
      },
      {
        code: "380",
        name: "Ukraine",
      },
      {
        code: "971",
        name: "United Arab Emirates",
      },
      {
        code: "44",
        name: "United Kingdom",
      },
      {
        code: "1",
        name: "United States",
      },
      {
        code: "598",
        name: "Uruguay",
      },
      {
        code: "998",
        name: "Uzbekistan",
      },
      {
        code: "678",
        name: "Vanuatu",
      },
      {
        code: "58",
        name: "Venezuela",
      },
      {
        code: "84",
        name: "Vietnam",
      },
      {
        code: "1 808",
        name: "Wake Island",
      },
      {
        code: "681",
        name: "Wallis and Futuna",
      },
      {
        code: "967",
        name: "Yemen",
      },
      {
        code: "260",
        name: "Zambia",
      },
      {
        code: "255",
        name: "Zanzibar",
      },
      {
        code: "263",
        name: "Zimbabwe",
      },
    ];
    let Nationality = [
      "Afghan",
      "Albanian",
      "Algerian",
      "American",
      "Andorran",
      "Angolan",
      "Antiguans",
      "Argentinean",
      "Armenian",
      "Australian",
      "Austrian",
      "Azerbaijani",
      "Bahamian",
      "Bahraini",
      "Bangladeshi",
      "Barbadian",
      "Barbudans",
      "Batswana",
      "Belarusian",
      "Belgian",
      "Belizean",
      "Beninese",
      "Bhutanese",
      "Bolivian",
      "Bosnian",
      "Brazilian",
      "British",
      "Bruneian",
      "Bulgarian",
      "Burkinabe",
      "Burmese",
      "Burundian",
      "Cambodian",
      "Cameroonian",
      "Canadian",
      "Cape Verdean",
      "Central African",
      "Chadian",
      "Chilean",
      "Chinese",
      "Colombian",
      "Comoran",
      "Congolese",
      "Costa Rican",
      "Croatian",
      "Cuban",
      "Cypriot",
      "Czech",
      "Danish",
      "Djibouti",
      "Dominican",
      "Dutch",
      "East Timorese",
      "Ecuadorean",
      "Egyptian",
      "Emirian",
      "Equatorial Guinean",
      "Eritrean",
      "Estonian",
      "Ethiopian",
      "Fijian",
      "Filipino",
      "Finnish",
      "French",
      "Gabonese",
      "Gambian",
      "Georgian",
      "German",
      "Ghanaian",
      "Greek",
      "Grenadian",
      "Guatemalan",
      "Guinea-Bissauan",
      "Guinean",
      "Guyanese",
      "Haitian",
      "Herzegovinian",
      "Honduran",
      "Hungarian",
      "I-Kiribati",
      "Icelander",
      "Indian",
      "Indonesian",
      "Iranian",
      "Iraqi",
      "Irish",
      "Israeli",
      "Italian",
      "Ivorian",
      "Jamaican",
      "Japanese",
      "Jordanian",
      "Kazakhstani",
      "Kenyan",
      "Kittian and Nevisian",
      "Kuwaiti",
      "Kyrgyz",
      "Laotian",
      "Latvian",
      "Lebanese",
      "Liberian",
      "Libyan",
      "Liechtensteiner",
      "Lithuanian",
      "Luxembourger",
      "Macedonian",
      "Malagasy",
      "Malawian",
      "Malaysian",
      "Maldivan",
      "Malian",
      "Maltese",
      "Marshallese",
      "Mauritanian",
      "Mauritian",
      "Mexican",
      "Micronesian",
      "Moldovan",
      "Monacan",
      "Mongolian",
      "Moroccan",
      "Mosotho",
      "Motswana",
      "Mozambican",
      "Namibian",
      "Nauruan",
      "Nepalese",
      "New Zealander",
      "Nicaraguan",
      "Nigerian",
      "Nigerien",
      "North Korean",
      "Northern Irish",
      "Norwegian",
      "Omani",
      "Pakistani",
      "Palauan",
      "Panamanian",
      "Papua New Guinean",
      "Paraguayan",
      "Peruvian",
      "Polish",
      "Portuguese",
      "Qatari",
      "Romanian",
      "Russian",
      "Rwandan",
      "Saint Lucian",
      "Salvadoran",
      "Samoan",
      "San Marinese",
      "Sao Tomean",
      "Saudi",
      "Scottish",
      "Senegalese",
      "Serbian",
      "Seychellois",
      "Sierra Leonean",
      "Singaporean",
      "Slovakian",
      "Slovenian",
      "Solomon Islander",
      "Somali",
      "South African",
      "South Korean",
      "Spanish",
      "Sri Lankan",
      "Sudanese",
      "Surinamer",
      "Swazi",
      "Swedish",
      "Swiss",
      "Syrian",
      "Taiwanese",
      "Tajik",
      "Tanzanian",
      "Thai",
      "Togolese",
      "Tongan",
      "Trinidadian or Tobagonian",
      "Tunisian",
      "Turkish",
      "Tuvaluan",
      "Ugandan",
      "Ukrainian",
      "Uruguayan",
      "Uzbekistani",
      "Venezuelan",
      "Vietnamese",
      "Welsh",
      "Yemenite",
      "Zambian",
      "Zimbabwean",
    ];
    return (
      <div className="container p-1 pt-2">
        <Form
          noValidate
          onSubmit={this.handleSubmit}
          className=" shadow pl-3 pr-3 pb-3  pt-2 mb-3 bg-white rounded"
        >
          <Form.Row>
            <h4 className="pt-2 float-left">
              Package Enquire ({this.state.search.packageName})
            </h4>
          </Form.Row>

          {this.state.ResponseMessage &&
            this.state.isLoadingPayment === false && (
              <>
                {!this.state.submitStatus && (
                  <Alert variant="danger">{this.state.ResponseMessage}</Alert>
                )}
                {this.state.submitStatus && (
                  <Alert variant="success">{this.state.ResponseMessage}</Alert>
                )}
              </>
            )}
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="FirstName">
              <Form.Label className="d-flex">First Name*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependFirstName">
                    <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className={
                    validated && this.state.FirstName == null
                      ? "errorBorder inputHeight"
                      : "inputHeight"
                  }
                  required
                  minLength="2"
                  aria-describedby="inputGroupPrependFirstName"
                  type="text"
                  id="enquiry_first_name"
                  defaultValue=""
                  value={this.state.FirstName}
                  onChange={(e) => this.setState({ FirstName: e.target.value })}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="LastName" className="mb-3">
              <Form.Label className="d-flex">Last Name*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependLastName">
                    <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className={
                    validated && this.state.LastName == null
                      ? "errorBorder inputHeight"
                      : "inputHeight"
                  }
                  required
                  minLength="2"
                  type="text"
                  id="enquiry_last_name"
                  aria-describedby="inputGroupPrependLastName"
                  defaultValue=""
                  value={this.state.LastName}
                  onChange={(e) => this.setState({ LastName: e.target.value })}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row></Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="Nationality">
              <Form.Label className="d-flex">Nationality*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependNationality">
                    <FontAwesomeIcon className="pr-2" icon={faFlag} size="lg" />
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <div
                  className={
                    validated && !this.state.Nationality
                      ? "errorBorder  autocomplete-wrapper "
                      : " autocomplete-wrapper " +
                        " autocomplete-wrappernationality"
                  }
                >
                  <Autocomplete
                    className={
                      validated && !this.state.Nationality
                        ? "errorBorder  "
                        : " "
                    }
                    required
                    inputProps={{
                      placeholder: "Nationality*",
                      id: "enquiry_nationality",
                    }}
                    items={Nationality}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown p-0">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item " : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.Nationality}
                    onChange={(e) =>
                      this.setState({ Nationality: e.target.value })
                    }
                    onSelect={(value) => this.setState({ Nationality: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="Phone">
              <Form.Label className="d-flex">Phone Number*</Form.Label>
              <InputGroup
                className={
                  validated && !this.state.phone ? "errorBorder " : " "
                }
              >
                <PhoneInput
                  className={"inputHeight"}
                  style={{ width: "fit-content !important;" }}
                  inputProps={{
                    // class: "form-textbox display-7",
                    required: true,
                    id: "enquiry_phone",
                  }}
                  value={this.state.phone}
                  onChange={(phone) => this.setState({ phone })}
                  country={"us"}
                  onFocus={function (event) {
                    event.target.classList.remove("is-invalid");
                    event.target.classList.remove("is-valid");
                  }}
                />

                {/* <Form.Control.Feedback type="invalid">
                                    Please Enter your Phone number
</Form.Control.Feedback> */}
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="Email">
              <Form.Label className="d-flex">Email*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependEmail">
                    <FontAwesomeIcon
                      className="pr-2"
                      icon={faMailBulk}
                      size="lg"
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  required
                  className={
                    validated && this.state.Email == null
                      ? "errorBorder inputHeight"
                      : "inputHeight"
                  }
                  minLength="3"
                  id="enquiry_email"
                  type="email"
                  aria-describedby="inputGroupPrependEmail"
                  defaultValue=""
                  value={this.state.Email}
                  onChange={(e) => this.setState({ Email: e.target.value })}
                ></Form.Control>
                {/* <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email.
                                </Form.Control.Feedback> */}
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="4"
              controlId="DepartureAirport"
              className="mb-3"
            >
              <Form.Label className="d-flex">Departure Airport</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrependExpireMonth">
                    <FontAwesomeIcon className="pr-2" icon={faCity} size="lg" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div
                  className={
                    validated && this.state.Airport == null
                      ? "errorBorder inputHeight autocomplete-wrapper p-0"
                      : "inputHeight autocomplete-wrapper p-0"
                  }
                >
                  <Autocomplete
                    required
                    inputProps={{
                      id: "enquiry_departure",
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown p-0">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item " : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.Airport}
                    onChange={(e) => this.setState({ Airport: e.target.value })}
                    onSelect={(value) => this.setState({ Airport: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group
              as={Col}
              md="4"
              controlId="DepartureDate"
              className="mb-3"
            >
              <Form.Label className="d-flex">Departure Date</Form.Label>
              <InputGroup
                className={
                  validated && !this.state.DepartureDate
                    ? "errorBorder mb-3"
                    : "mb-3"
                }
              >
                <this.openDepartureDate />

                {validated && (
                  <Form.Control.Feedback type="invalid">
                    <span> Please provide a valid departure date.</span>
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="ReturnDate">
              <Form.Label className="d-flex">Return Date</Form.Label>
              <InputGroup
                className={
                  validated && !this.state.ReturnDate
                    ? "errorBorder mb-3"
                    : "mb-3"
                }
              >
                <this.openReturnDate />

                {validated && (
                  <Form.Control.Feedback type="invalid">
                    <span> Please provide a valid return date.</span>
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="6" className="mb-2">
              <Form.Label className="d-flex">Passengers</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="TravelersRoom"
                    className="inputHeightPassenger"
                  >
                    <span className="et-icons passengers-icon"></span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className={"trip inputHeight"}
                  id="enquiry_passenger"
                  value={
                    this.state.Adult +
                    " " +
                    this.state.AdultText +
                    " , " +
                    this.state.Child +
                    " " +
                    this.state.ChildText +
                    " , " +
                    this.state.Infant +
                    " " +
                    this.state.InfantText
                  }
                  name="TravelersRoom"
                  readOnly
                  required
                  aria-label="DepartureAirport"
                  aria-describedby="DepartureAirport"
                  onClick={this.numberOfPassengerHandler}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="8" controlId="SpecialRequirements">
              <Form.Label className="d-flex">Special Requirments</Form.Label>

              <InputGroup>
                <Form.Control
                  className="inputHeight"
                  as="textarea"
                  rows="1"
                  id="enquiry_special_requirements"
                  minLength="3"
                  placeholder="Place Your Special Requirments Here"
                  aria-describedby="inputGroupPrependSpecialRequirements"
                  defaultValue=""
                  value={this.state.SpecialRequirements}
                  onChange={(e) =>
                    this.setState({ SpecialRequirements: e.target.value })
                  }
                ></Form.Control>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="8" controlId="Comment">
              <Form.Label className="d-flex">Comment</Form.Label>

              <InputGroup>
                {/* <InputGroup.Prepend>
                                </InputGroup.Prepend> */}

                <Form.Control
                  as="textarea"
                  rows="3"
                  minLength="3"
                  id="enquiry_comment"
                  placeholder="Place Your Comment Here"
                  aria-describedby="inputGroupPrependComment"
                  defaultValue=""
                  value={this.state.Comment}
                  onChange={(e) => this.setState({ Comment: e.target.value })}
                ></Form.Control>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col style={{ textAlign: "left" }} sm={6} className="my-2 ml-3">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(value) =>
                  this.setState({ agreePromotion: !this.state.agreePromotion })
                }
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1">
                I would like to recieve newsfeed and promotion from Ethiopian
                Holidays
              </label>
            </Col>
          </Form.Row>
          <Row className=" my-3">
            <Col style={{ textAlign: "left" }} sm={11}>
              <ReCAPTCHA
                sitekey="6LdZQ0gaAAAAANe9P3E4PEKYLzouQnLVq07FmjFx" // prod
                // sitekey="6Le_REgaAAAAAHZgFupnXrz7QYnyEyxJb-t0D4P5"// test
                onChange={() => {
                  this.setState({ invalidGoogleRecapthcha: false });
                }}
              />
            </Col>
          </Row>

          <Row className=" ">
            <Col style={{ textAlign: "left" }} sm={11}>
              {this.state.isLoadingPayment && (
                <Button variant="success" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Submiting...
                </Button>
              )}

              {this.state.isLoadingPayment === false && (
                <Button
                  type="submit"
                  className="btn btn-success"
                  variant="success"
                  disabled={this.state.invalidGoogleRecapthcha}
                >
                  <strong className="pr-5 pl-5 ">Submit</strong>
                </Button>
              )}
            </Col>
          </Row>
        </Form>

        <Modal
          show={this.state.showPassengerSelecter}
          onHide={this.numberOfPassengerHandler}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Number Of Passegers
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingLeft: "1em" }}>
              <div className="row m-1 mr-5">
                <div className="col-12">
                  <Button variant="">
                    {" "}
                    <strong>{this.state.AdultText}</strong>
                  </Button>
                  {/*  </div> */}
                  <div className="float-right">
                    <div className="top-circle">
                      <button
                        className="btn"
                        onClick={() => {
                          this.handlePassenger("reduce", "adult");
                          if (this.state.Adult <= 2) {
                            this.setState({ AdultText: "Adult" });
                          } else {
                            this.setState({ AdultText: "Adults" });
                          }
                        }}
                      >
                        <RemoveIcon className="inside-icon-minus" />
                      </button>
                    </div>
                    <Button variant="">{this.state.Adult} </Button>{" "}
                    <div className="top-circle">
                      <button
                        className="btn"
                        onClick={() => {
                          this.handlePassenger("add", "adult");
                          if (this.state.Adult >= 1) {
                            this.setState({ AdultText: "Adults" });
                          }
                        }}
                      >
                        <AddIcon className="inside-icon-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-1 mr-5">
                <div className="col-12">
                  <Button variant="">
                    <strong>{this.state.ChildText}</strong>{" "}
                  </Button>

                  <div className="float-right">
                    <div className="top-circle ">
                      <button
                        className="btn"
                        onClick={() => {
                          this.handlePassenger("reduce", "child");
                          if (this.state.Child <= 2) {
                            this.setState({ ChildText: "Child" });
                          } else {
                            this.setState({ ChildText: "Children" });
                          }
                        }}
                      >
                        <RemoveIcon className="inside-icon-minus" />
                      </button>
                    </div>
                    <Button variant="">{this.state.Child}</Button>{" "}
                    <div className="top-circle">
                      <button
                        className="btn"
                        onClick={() => {
                          this.handlePassenger("add", "child");
                          if (this.state.Child >= 1) {
                            this.setState({ ChildText: "Children" });
                          }
                        }}
                      >
                        <AddIcon className="inside-icon-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-1 mr-5">
                <div className="col-12">
                  <Button variant="">
                    <strong>{this.state.InfantText}</strong>{" "}
                  </Button>

                  <div className="float-right">
                    <div className="top-circle">
                      <button
                        className="btn"
                        onClick={() => {
                          this.handlePassenger("reduce", "infant");
                          if (this.state.Infant <= 2) {
                            this.setState({ InfantText: "Infant" });
                          } else {
                            this.setState({ InfantText: "Infants" });
                          }
                        }}
                      >
                        <RemoveIcon className="inside-icon-minus" />
                      </button>
                    </div>
                    <Button variant="">{this.state.Infant} </Button>{" "}
                    <div className="top-circle">
                      <button
                        className="btn"
                        onClick={() => {
                          this.handlePassenger("add", "infant");
                          if (this.state.Infant >= 1) {
                            this.setState({ InfantText: "Infants" });
                          }
                        }}
                      >
                        <AddIcon className="inside-icon-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-2  ">
                <strong className="float-right">
                  {" "}
                  {this.state.Passengers}{" "}
                  {this.state.Passengers === 1 && <span>Passenger</span>}{" "}
                  {this.state.Passengers > 1 && <span>Passengers</span>}{" "}
                </strong>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="search-btn btn btn-success"
              onClick={this.numberOfPassengerHandler}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          scrollable={true}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
            window.location.reload();
          }}
        >
          <Modal.Header closeButton className="p-1 pl-3 pt-0">
            <Modal.Title>Package Enquire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="success" id="enquire_success_message">
              {this.state.ResponseMessage}
            </Alert>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PackageEnquire;
