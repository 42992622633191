import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import ConfirmSelectedHotel from "./confirm-hotel/selected-hotel-confirmation";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import ListGroup from 'react-bootstrap/ListGroup'
import "./hotel.css";
let roomRate = [];
let roomsSelected = [];
let roomConfirmationInfo = [];
class HotelRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allRoomSelected: false,
      rooms: [],
      name: "",
    };
    this.getSelectedRoom = this.getSelectedRoom.bind(this);
    this.hotelSerach = localStorage.getItem("");
  }

  async getSelectedRoom(
    roomIndex,
    rate,
    rateType,
    rateKey,
    rateCommentId,
    name,
    price,
    currency,
    cancelationDate
  ) {
    
    await this.setState({ isConfirmationOpen: false });
    
    let selectedRate = {
      selectedRateKey: rateKey,
      selectedRateType: rateType,
      selectedRateCommentId: rateCommentId,
    };
    let roomInfo = {
      name: name,
      price: price,
      currency: currency,
      cancelationDate: cancelationDate,
    };
    roomConfirmationInfo[roomIndex] = roomInfo;
    roomRate[roomIndex] = selectedRate;
    roomsSelected[roomIndex] = rate;
    // if (this.state.rooms.length === roomRate.length) {
      if (this.state.rooms.length >=1) {
    if (this.state.rooms.length === roomRate.length) {
      await this.setState({ allRoomSelected: true });
    }
    else{
      await this.setState({ allRoomSelected: false });

    }
      // this.props.history.push("/passenger");
      localStorage.setItem("roomRate", JSON.stringify(roomRate));
      localStorage.setItem("roomsSelected", JSON.stringify(roomsSelected));
      await this.setState({ isConfirmationOpen: true });
    }
  }
  openConfirmation() {
    if (this.state.isConfirmationOpen) {
      return (
        <ConfirmSelectedHotel
          isConfirmationOpen={this.state.isConfirmationOpen}
          roomConfirmationInfo={roomConfirmationInfo}
          roomsLength={this.state.rooms.length}
          allRoomSelected={this.state.allRoomSelected}
          classId={this.props.index}
          hotelName={this.props.hotelName}
        />
      );
    } else return null;
  }
  async componentDidMount() {
    roomRate = [];
    const { room, name } = this.props.roomInfo;
    // await  console.log("this.props.roomInfo",this.props.roomInfo)
    await this.setState({ rooms: room });
    await this.setState({ name: name });
  }
  
  parseDate = (dateString) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  render() {
    
    const { localCurrency } = this.props;
    return (
      <div className="hotel-room-info">
        <div className="row m-0">
          <div
            className="col-0 col-sm-3 col-md-4 col-lg-2 hotel-room-img"
            // style={{
            //   backgroundImage:
            //     "url(" +
            //     window.location.origin +
            //     "/assets/image/special-offer/axum.jpg" +
            //     ")",
            // }}
          >
            {this.openConfirmation()}
            <FontAwesomeIcon
              icon={faBed}
              style={{ color: "#adb5bd", fontSize: "80px", marginTop: "70px" }}
            />
          </div>
          <div className="col-12 col-sm-9 col-md-8 col-lg-10 text-left pl-4 pr-3 pt-2">
            <h4 className="pb-3 room-name">{this.state.name}</h4>
            {this.state.rooms.map((roomOptions, roomKey) => (
              <div className="row m-0" key={roomKey}>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h4 className="room-head">Room {roomKey + 1}</h4>
                </div>
                {roomOptions.rates.map((rate, rateKey) => (
                  <div className="col-12 col-md-12 pl-4 pb-3 hotelRoom  " key={rateKey}>
                    <div className="room-option">
                    {/* <div>
                      <h5 className="room-rate-option-head">
                        Option {rateKey + 1}
                      </h5>
                    </div> */}
<ListGroup variant="light  ">
  <ListGroup.Item>
                    <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12  ">
              <div className="list-group text-left">
                <ul className="social-media marginULListgroup p-0" style={{"list-style-type": "none"}}>
                  <li > 
                      {/* <span className="room-rate-option-head">
                        Option {rateKey + 1}
                      </span> */}
                         <span 
                        //  select-room-radio-container
                        className="form-check form-check-inline"
                        onClick={() => {
                          this.getSelectedRoom(
                            roomKey,
                            rate,
                            rate.rateType,
                            rate.rateKey,
                            rate.rateCommentsId,
                            this.state.name,
                            rate.localNet,
                            localCurrency,
                            this.parseDate(rate.cancellationPolicies[0].from ) 
                          );
                        }}
                      >
                        <label class="main" for={
                            "select-room-radio" +
                            roomKey +
                            "" +
                            rateKey +
                            "" +
                            this.props.index
                          }>
                            {/* Select  */}
                        <input
                        class=" travel_age"                          
                        type="radio"
                          name={"rate" + roomKey}
                          id={
                            "select-room-radio" +
                            roomKey +
                            "" +
                            rateKey +
                            "" +
                            this.props.index
                          }
                          value="option1"
                        />
                        <span class="geekmark"></span>  
                                       </label>
                        
                      </span>
                   
                      <span className="no-room-guest">{"  "}{rate.adults} Adult</span>{" "}
                      {/* <br /> */}
                      {rate.children != 0 ? (
                        <span className="no-room-guest">
                          {rate.children} Children
                        </span>
                      ) : null}
                      <span className="no-room-guest">{" | "}{rate.boardName}{" "}</span>
                    <span className="room-include">{" | "}{(rate.rateClass=="NRF")?"Non Refundable":"Refundable"} {" "}</span>
                   {(rate.cancellationPolicies.length>0)&&
                    <span className="room-include">{" | "}{"Cancelation Date: "}{this.parseDate(rate.cancellationPolicies[0].from ) } {" "}</span>
                    } 
 <span  className="  float-right"   > 

                      <span className=" room-price">{" "}
                         {localStorage.getItem("Currency") + " "}
                         {rate.localNet}{"   "}
                       </span>
                      {/* <span
                        className="form-check form-check-inline select-room-radio-container"
                        onClick={() => {
                          this.getSelectedRoom(
                            roomKey,
                            rate,
                            rate.rateType,
                            rate.rateKey,
                            rate.rateCommentsId,
                            this.state.name,
                            rate.localNet,
                            localCurrency,
                            this.parseDate(rate.cancellationPolicies[0].from ) 
                          );
                        }}
                      >
                        <label class="main" for={
                            "select-room-radio" +
                            roomKey +
                            "" +
                            rateKey +
                            "" +
                            this.props.index
                          }>Select 
                        <input
                        class=" travel_age"                          
                        type="radio"
                          name={"rate" + roomKey}
                          id={
                            "select-room-radio" +
                            roomKey +
                            "" +
                            rateKey +
                            "" +
                            this.props.index
                          }
                          value="option1"
                        />
                        <span class="geekmark"></span> 
                                       </label>
                    
                    
                      </span>
                  */}
                    </span>
         
                  </li>
                </ul>
              </div>
            </div>
            <hr style={{ color: "white", height: "3px" }} />
          </div>
                   
          
          
          
          </ListGroup.Item> 
</ListGroup>


 
                  
                    {/* <div className="col-7 ">
                    <div  >
                      <span className="no-room-guest">{rate.adults} Adult</span>{" "}
                      <br />
                      {rate.children != 0 ? (
                        <span className="no-room-guest">
                          {rate.children} Children
                        </span>
                      ) : null}
                      <span className=" room-price">
                        {localStorage.getItem("Currency") + " "}
                        {rate.localNet}
                      </span>
                    </div>
                    <div className="room-include">{rate.boardName}</div>
                    <div className="room-include">{(rate.rateClass=="NRF")?"Non Refundable":"Refundable"} </div>
                   </div> */}
                    {/* <div  className="  float-right"   > 

                      <div
                        className="form-check form-check-inline select-room-radio-container"
                        onClick={() => {
                          this.getSelectedRoom(
                            roomKey,
                            rate,
                            rate.rateType,
                            rate.rateKey,
                            rate.rateCommentsId,
                            this.state.name,
                            rate.localNet,
                            localCurrency
                          );
                        }}
                      >
                        <label class="main" for={
                            "select-room-radio" +
                            roomKey +
                            "" +
                            rateKey +
                            "" +
                            this.props.index
                          }>Select
                        <input
                        class=" travel_age"                          
                        type="radio"
                          name={"rate" + roomKey}
                          id={
                            "select-room-radio" +
                            roomKey +
                            "" +
                            rateKey +
                            "" +
                            this.props.index
                          }
                          value="option1"
                        />
                        <span class="geekmark"></span> 
                                       </label>
                       
                      </div>
                    </div>
                     */}
                    </div>
                   </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HotelRoom);
