import React, { Component } from "react";
import "./paymentSummery.css";
import TermsAndCondition from "../../shared/termsAndCondition";
import TagManager from 'react-gtm-module'

import { Modal,Alert, ListGroup, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faFighterJet,
  faPlane,
} from "@fortawesome/free-solid-svg-icons";
import { HttpService } from "../../../services/http/http-service";
import ShoppingCart from "../../ShoppingCart/shopping-cart";

import parse from "html-react-parser";
import { DataService } from "../../../services/shared-services/dataServices";
import BreadCrumb from "../../shared/breadcrumb/breadcrumb";
import { BrowserView, MobileView } from "react-device-detect";
import FareRule from "../../shared/fareRule/fareRule";
// import { htmlToText } from "html-to-text";

class PaymentSummery extends Component {
  constructor(props) {
    super(props);

    let tourPreference = localStorage.getItem("tourPreference");
    let bundAndSave = localStorage.getItem("bundAndSave");
    this.state = {
      tourPreference: tourPreference,
      bundAndSave: bundAndSave,
      PackageSummary: null,
      isPackageSummary: false,
      BundleSummary: null,
      HotelSummary: null,
      roomsSelected: null,
      FlightDetails: null,
      flightTotalPrice: 0,
      packageTotalPrice: 0,
      hotelTotalPrice: 0,
      currency: "",
      agreeTerms: false,
      agreeTermsCommand: false,
      showModal: false,
      iternery: [],
      TotalPrice: 0,
      passenger: {
        ADT: 1,
        CHD: 0,
        INF: 0,
      },
      isLoaded: true,
    };
    this.indexOfFlight = 0;
  }

  async componentDidMount() {
    let flightDetails = await JSON.parse(localStorage.getItem("flightDetails"));
    let PackageSummaryData = await JSON.parse( localStorage.getItem("PackageSummery") );
    let BundleSummaryData = await JSON.parse(  localStorage.getItem("BundleSummery") );
    let hotelSearchRequest = await JSON.parse(  localStorage.getItem("hotelDetails") );
    let searchRequestObj = await JSON.parse(  localStorage.getItem("searchRequestObj") );
    let flightTotalPrice = parseFloat(localStorage.getItem("totalFlighAmount"));


    let packageTotalPrice = 0;
    let bundleTotalPrice = 0;
    let hotelTotalPrice = Math.round( parseFloat(localStorage.getItem("hotelTotalPrice")) );

    this.setState({ currency: localStorage.getItem("Currency") });
////////
    const tagManagerArgs = {
      dataLayer: {
          events:"flightConfirmation2", 
          flightItineraryParts: flightDetails,
          flightPrice:flightTotalPrice,
          packagesAdded: PackageSummaryData,  
      } 
     } 
     TagManager.dataLayer(tagManagerArgs)
/////

    if (PackageSummaryData) {
      this.setState({
        PackageSummary: PackageSummaryData,
        isPackageSummary: true,
      });

      for (let i = 0; i < PackageSummaryData.length; i++) {
        packageTotalPrice = +PackageSummaryData[i].totalAmount;
      }

      this.setState({ packageTotalPrice: packageTotalPrice });

      let description = PackageSummaryData[0].package.description;
      if (description) {
        // console.log("description:", description)
        // document.getElementById("description").innerHTML =  description.toString();
        document.getElementById("package_description").innerHTML =
          description.toString();
      }
    }

    if (BundleSummaryData && this.state.bundAndSave == "true") {
      this.setState({ BundleSummary: BundleSummaryData });
      for (let i = 0; i < BundleSummaryData.length; i++) {
        bundleTotalPrice = +BundleSummaryData[i].amount;
      }
    }
  
    if (flightDetails) {
      this.setState({
        FlightDetails: flightDetails.data.pnr,
        flightTotalPrice: flightTotalPrice,
      });
    }
    if (hotelSearchRequest) {
      // if (false) {//to be deleted
      let hotelRequest = await JSON.parse(
        localStorage.getItem("hotelSearchRequest")
      );
      let roomsSelected = await JSON.parse(
        localStorage.getItem("roomsSelected")
      );

      // let hotelRating = parseFloat(
      //   hotelSearchRequest.hotel.categoryName.replace(/[^\d\.]*/g, "")
      // );

      this.setState({
        HotelSummary: hotelSearchRequest,
        hotelTotalPrice: hotelTotalPrice,
        roomsSelected: roomsSelected,
        hotelRequest: hotelRequest,
      });
    }
    if (searchRequestObj) {
      this.setState({
        iternery: searchRequestObj.itineraryParts,
        passenger: searchRequestObj.passengers,
        isLoaded: true,
      });
    }

    let countTotalPrice = Math.round(
      parseFloat(
        flightTotalPrice +
          packageTotalPrice +
          hotelTotalPrice +
          bundleTotalPrice
      )
    );
    this.setState({ TotalPrice: countTotalPrice });
    this.getIpaddress(0);
  }

  getIpaddress(optionType) {
    let paymentInfoId = optionType;
    let ipInformationRelativePath =
      "/json/?key=185f0fbd5ca1368bc0a0edc31057ad1b944311b9";

    // this.setState({ isIpInformationLoaded: false });
    HttpService.getIPService(ipInformationRelativePath).then(
      (response) => {
        // this.setState({ ipInformation: response.data, isIpInformationLoaded: true });
        // this.__PaymentFormValidatorService.intializeFormValidation();
      },
      (error) => {}
    );
  }
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + "mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };

  renderRating(hotelstarStr) {
    let starRating = [];
    // let hoteStar = parseInt(this.props.hotelRating.replace(/[^0-9]/g, ""));
    let hoteStar = parseInt(hotelstarStr.replace(/[^0-9]/g, ""));
    for (let i = 0; i < hoteStar; i++) {
      starRating.push("star");
    }
    return (
      <div>
        {starRating.map((star, key) => (
          <span key={key}>
            {" "}
            <FontAwesomeIcon
              icon={faStar}
              style={{ color: "#60ac5d", fontSize: "13px" }}
            />
          </span>
        ))}
      </div>
    );
  }

  pay() {
    this.props.history.push("/payment-form");
  }

  render() {
    var { isLoaded, iternery, currentDestinations } = this.state;
    if (true) {
      //   return <div>loading Destination {currentDestinations.name}...{isLoaded}</div>;
      // }
      // else {
      return (
        <>
          <BreadCrumb pageIndex={4} />
          <div className="row m-0 p-0 w-100">
            <div className="col-12 col-md-3 order-md-2 m-0">
              <ShoppingCart id={"hotel"} className="m-3" pageIndex={5} />
            </div>
            <div className="col-12 col-md-9 order-md-1 m-0">
              <div className="p-2 container pt-0">
                <h3 className="d-flex pt-0 pb-0">Payment Summary </h3>
                <MobileView>
                  {this.state.FlightDetails && (
                    <Card className="text-center mb-2 shadow  bg-white rounded">
                      <Card.Header
                        style={{ textAlign: "left" }}
                        className="p-0 pl-3"
                      >
                        <h5>Selected Flight</h5>{" "}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm={10}>
                            {this.state.FlightDetails.itinerary.itineraryParts.map(
                              (flight) => (
                                <>
                                  {flight.segments.map((segment) => (
                                    <>
                                      <strong>
                                        {" "}
                                        <span className="d-flex ">
                                          Flight :{" "}
                                          {DataService.getFullCityName(
                                            segment.origin
                                          )}
                                          <FontAwesomeIcon
                                            className="pl-2 pr-2"
                                            icon={faFighterJet}
                                            size="lg"
                                          />
                                          {DataService.getFullCityName(
                                            segment.destination
                                          )}
                                        </span>
                                      </strong>
                                      <Row className="shadow-sm m-2">
                                        <Col sm={4} lg={4}>
                                          {/* <p className="mb-0">Departure City:</p> */}
                                          <strong>{segment.origin} </strong>
                                          <p>
                                            {this.parseDate(segment.departure)}
                                          </p>
                                        </Col>
                                        <Col sm={2} lg={2}>
                                          <span className="">
                                            <FontAwesomeIcon
                                              className="pr-2 text-green"
                                              icon={faFighterJet}
                                              size="lg"
                                              color="success"
                                            />
                                          </span>
                                        </Col>
                                        <Col sm={3} lg={3}>
                                          {/* <p className="mb-0">Arrival City:</p> */}
                                          <strong>{segment.destination}</strong>
                                          <p>
                                            {this.parseDate(segment.arrival)}
                                          </p>
                                        </Col>
                                        <Col sm={3} lg={3}>
                                          {/* <p className="mb-0">class:</p> */}
                                          <strong>
                                            {
                                              segment.flight
                                                .operatingAirlineCode
                                            }
                                            -{segment.flight.flightNumber}
                                          </strong>
                                          <p>{segment.cabinClass}</p>
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                </>
                              )
                            )}
                            <div style={{ float: "left" }} className="mt-3">
                              <FareRule firstLoad={false} />
                            </div>
                          </Col>

                          <Col
                            className="p-3"
                            style={{ borderLeft: "1px solid green" }}
                            sm={2}
                          >
                            <Card.Text className="text-muted"
                          style={{textAlign: "end"}}>
                              {" "}
                              <h5> Flight Price: </h5>
                            </Card.Text>
                            <Card.Text style={{ color: "green" }}>
                              <strong>
                                {Math.round(this.state.flightTotalPrice)}{" "}
                                {this.state.currency}
                              </strong>
                            </Card.Text>
                          </Col>
                        </Row>
                      </Card.Body>
                      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                    </Card>
                  )}
                </MobileView>
                <BrowserView>
                  {this.state.FlightDetails && (
                    <Card className="text-center mb-2 shadow  bg-white rounded">
                      <Card.Header
                        style={{ textAlign: "left" }}
                        className="p-0 pl-3"
                      >
                        <h5>Flight Detail</h5>{" "}
                      </Card.Header>
                      <Card.Body className="container">
                        <div className="row">
                          <div className="col-12">
                            {this.state.FlightDetails.itinerary.itineraryParts.map(
                              (flight) => (
                                <>
                                  {this.indexOfFlight % 2 == 0 ? (
                                    <div className="row shadow-sm">
                                      <div className="col-3 pt-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p style={{ float: "left" }}>
                                              <b>
                                                {flight.segments[0]
                                                  ? DataService.getFullCityName(
                                                      flight.segments[0].origin
                                                    )
                                                  : null}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <p
                                              style={{
                                                float: "left",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {this.parseDate(
                                                flight.segments[0].departure
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-3 mt-3">
                                        <FontAwesomeIcon
                                          icon={faPlane}
                                          size="lg"
                                          style={{
                                            fontSize: "40px",
                                            color: "#60ac5d",
                                            float: "left",
                                            marginTop: "-2px",
                                          }}
                                        />
                                        {/* <hr style={{height:"2px", backgroundColor:"whitesmoke"}}/> */}
                                        <hr
                                          style={{
                                            height: "2px",
                                            border: "2px dashed #c1c1c1cc",
                                            borderStyle:
                                              "dashed none none none",
                                          }}
                                        />

                                        <span
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                            backgroundColor: "#bbb",
                                            borderRadius: "50%",
                                            display: "inline",
                                            marginTop: "-24px",
                                            float: "right",
                                          }}
                                        ></span>
                                      </div>
                                      <div className="col-3 pt-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p style={{ float: "left" }}>
                                              <b>
                                                {DataService.getFullCityName(
                                                  flight.segments[
                                                    flight.segments.length - 1
                                                  ].destination
                                                )}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <p
                                              style={{
                                                float: "left",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {this.parseDate(
                                                flight.segments[
                                                  flight.segments.length - 1
                                                ].arrival
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-3 pt-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p style={{ float: "left" }}>
                                              <b>
                                                {
                                                  flight.segments[0].flight
                                                    .operatingAirlineCode
                                                }
                                                -
                                                {
                                                  flight.segments[0].flight
                                                    .flightNumber
                                                }
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <p
                                              style={{
                                                float: "left",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {flight.segments[0].cabinClass}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row shadow-sm">
                                      <div className="col-3 pt-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p style={{ float: "left" }}>
                                              <b>
                                                {DataService.getFullCityName(
                                                  flight.segments[
                                                    flight.segments.length - 1
                                                  ].destination
                                                )}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <p
                                              style={{
                                                float: "left",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {this.parseDate(
                                                flight.segments[
                                                  flight.segments.length - 1
                                                ].arrival
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-3 mt-3">
                                        <span
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                            backgroundColor: "#bbb",
                                            borderRadius: "50%",
                                            display: "inline",
                                            marginTop: "10px",
                                            float: "left",
                                          }}
                                        ></span>

                                        <hr
                                          style={{
                                            height: "2px",
                                            border: "2px dashed #c1c1c1cc",
                                            borderStyle:
                                              "dashed none none none",
                                          }}
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlane}
                                          size="lg"
                                          style={{
                                            fontSize: "40px",
                                            color: "#60ac5d",
                                            float: "right",
                                            marginTop: "-38px",
                                            transform: "rotateZ(180deg)",
                                          }}
                                        />
                                      </div>
                                      <div className="col-3 pt-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p style={{ float: "left" }}>
                                              <b>
                                                {DataService.getFullCityName(
                                                  flight.segments[0].origin
                                                )}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <p
                                              style={{
                                                float: "left",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {this.parseDate(
                                                flight.segments[0].departure
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-2 pt-2">
                                        <div className="row">
                                          <div className="col-12">
                                            <p style={{ float: "left" }}>
                                              <b>
                                                {
                                                  flight.segments[0].flight
                                                    .operatingAirlineCode
                                                }
                                                -
                                                {
                                                  flight.segments[0].flight
                                                    .flightNumber
                                                }
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <p
                                              style={{
                                                float: "left",
                                                fontSize: "0.9em",
                                              }}
                                            >
                                              {flight.segments[0].cabinClass}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <span className="d-none">
                                    {this.indexOfFlight++}
                                  </span>
                                </>
                              )
                            )}
                            <div style={{ float: "left" }} className="mt-3">
                              <FareRule firstLoad={false} />
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                      <Card className="text-center mb-3 shadow  bg-white rounded">
                        <Card.Footer
                          className="text-muted"
                          style={{ backgroundColor: "white" }}
                        >
                          <Row>
                            <Col sm={10} 
                    style={{textAlign: "end"}}>
                              <h5>Flight Price</h5>
                            </Col>

                            <Col
                              style={{ borderLeft: "1px solid green" }}
                              sm={2}
                            >
                              <Card.Text style={{ color: "green" }}>
                                <strong>
                                  {Math.round(this.state.flightTotalPrice)}{" "}
                                  {this.state.currency}
                                </strong>{" "}
                              </Card.Text>
                            </Col>
                          </Row>
                        </Card.Footer>
                      </Card>
                    </Card>
                  )}
                </BrowserView>
                <br />
                {this.state.PackageSummary &&
                  this.state.tourPreference === "flightPlusPackage" && (
                    <>
                      <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                        <Card.Header
                          style={{ textAlign: "left" }}
                          className="p-0 pl-3"
                        >
                          <h5>Selected Package(s)</h5>{" "}
                        </Card.Header>
                        {this.state.PackageSummary.map((packageItem) => (
                          <Card.Body>
                            <Row>
                              <Col sm={10}>
                                <Row>
                                  <Col sm={5}>
                                    <div
                                      class="card"
                                      style={{ cursor: "pointer !important" }}
                                    >
                                      <div
                                        className="card-img"
                                        style={{
                                          cursor: "pointer !important",
                                          backgroundImage:
                                            "url(" +
                                            packageItem.package.imagePath +
                                            ")",
                                          maxHeight: "150px",
                                        }}
                                      ></div>
                                    </div>
                                  </Col>

                                  <Col sm={7}>
                                    <ul class="list-group">
                                      <li class="list-group-item  d-flex justify-content-between align-items-center  ">
                                        <h6>{packageItem.package.name} </h6>
                                      </li>

                                      <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Validity Start
                                        <span class="badge badge-pill">
                                          {" "}
                                          {this.parseDate(
                                            packageItem.package
                                              .packageValidStartDate
                                          )}{" "}
                                        </span>
                                      </li>

                                      <li class="list-group-item d-flex justify-content-between align-items-center">
                                        Validity End
                                        <span class="badge badge-pill">
                                          {" "}
                                          {this.parseDate(
                                            packageItem.package
                                              .packageValidEndDate
                                          )}{" "}
                                        </span>
                                      </li>
                                    </ul>
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                className="p-3"
                                style={{ borderLeft: "1px solid green" }}
                                sm={2}
                              >
                                <Card.Text className="text-muted" 
                    style={{textAlign: "end"}}>
                                  {" "}
                                  <h5> Package Price: </h5>
                                </Card.Text>
                                <Card.Text style={{ color: "green" }}>
                                  <strong>
                                    {Math.round(this.state.packageTotalPrice)}{" "}
                                    {/* {Math.round(packageItem.totalAmount)}{" "} */}
                                    {this.state.currency}
                                  </strong>
                                </Card.Text>
                              </Col>
                            </Row>
                          </Card.Body>
                        ))}

                        {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                      </Card>
                    </>
                  )}

                {this.state.tourPreference == "flightPlusPackage" && (
                  <>
                    <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                      <Card.Body>
                        <div
                          style={{ textAlign: "left" }}
                          id="package_description"
                          className="m-2"
                        ></div>
                      </Card.Body>

                      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                    </Card>

                    <br />
                  </>
                )}

                {/* {((this.state.BundleSummary)  && //to be uncommented */}
                {this.state.BundleSummary && this.state.bundAndSave === "true" && (
                  <>
                    <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                      <Card.Header
                        style={{ textAlign: "left" }}
                        className="p-0 pl-3"
                      >
                        <h5>Selected Activities </h5>{" "}
                      </Card.Header>
                      {this.state.BundleSummary.map((packageItem) => (
                        <Card.Body>
                          <Row>
                            <Col sm={10}>
                              <Row>
                                <Col sm={5}>
                                  <div
                                    class="card"
                                    style={{ cursor: "pointer !important" }}
                                  >
                                    <div
                                      className="card-img"
                                      style={{
                                        cursor: "pointer !important",
                                        backgroundImage:
                                          "url(" + packageItem.imagePath + ")",
                                        maxHeight: "150px",
                                      }}
                                    ></div>
                                  </div>
                                </Col>

                                <Col sm={7}>
                                  <ul class="list-group">
                                    <li class="list-group-item  d-flex justify-content-between align-items-center  ">
                                      <h6>{packageItem.name} </h6>
                                    </li>

                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                      City, Country
                                      <span class="badge badge-pill">
                                        {" "}
                                        {DataService.getFullCityName(
                                          packageItem.cityCode
                                        )}{" "}
                                      </span>
                                    </li>
                                  </ul>
                                </Col>
                                <Col sm={12}>
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span>
                                      {" "}
                                      <strong> Description: </strong>
                                      {parse(packageItem.description)}
                                    </span>
                                  </li>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              className="p-3"
                              style={{ borderLeft: "1px solid green" }}
                              sm={2}
                            >
                              <Card.Text className="text-muted" 
                           style={{textAlign: "end"}}>
                                {" "}
                                <h5> Activity Price: </h5>
                              </Card.Text>
                              <Card.Text style={{ color: "green" }}>
                                <strong>
                                  {Math.round(packageItem.amount)}{" "}
                                  {packageItem.currency.code}
                                </strong>
                              </Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      ))}

                      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                    </Card>
                    <br />
                  </>
                )}

                {this.state.HotelSummary &&
                  this.state.tourPreference === "flightPlusHotel" && (
                    <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                      <Card.Header
                        style={{ textAlign: "left" }}
                        className="p-0 pl-3"
                      >
                        <h5>Selected Hotel</h5>{" "}
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm={10}>
                            <Row>
                              {this.state.HotelSummary.content && (
                                <>
                                  <Col sm={12}>
                                    <Row>
                                      <Col sm={6}>
                                        <div
                                          class="card"
                                          style={{
                                            cursor: "pointer !important",
                                          }}
                                        >
                                          <div
                                            className="card-img"
                                            style={{
                                              cursor: "pointer !important",
                                              backgroundImage:
                                                "url(http://photos.hotelbeds.com/giata/bigger/" +
                                                this.state.HotelSummary.content
                                                  .images[0].path +
                                                ")",
                                              maxHeight: "150px",
                                            }}
                                          ></div>
                                        </div>
                                      </Col>

                                      <Col sm={6}>
                                        <ul class="list-group">
                                          <li class="list-group-item  d-flex justify-content-between align-items-center  ">
                                            <h6>
                                              {
                                                this.state.HotelSummary.hotel
                                                  .name
                                              }{" "}
                                            </h6>
                                            <span class="badge badge-pill success">
                                              <>
                                                {this.renderRating(
                                                  this.state.HotelSummary.hotel
                                                    .categoryName
                                                )}
                                              </>
                                            </span>
                                          </li>

                                          <li class="list-group-item d-flex justify-content-between align-items-center">
                                            City
                                            <span class="badge badge-pill">
                                              {" "}
                                              {
                                                this.state.HotelSummary.hotel
                                                  .destinationName
                                              }
                                              /{" "}
                                              {
                                                this.state.HotelSummary.hotel
                                                  .zoneName
                                              }{" "}
                                            </span>
                                          </li>

                                          <li class="list-group-item d-flex justify-content-between align-items-center">
                                            address
                                            <span class="badge badge-pill">
                                              {
                                                this.state.HotelSummary.content
                                                  .address.content
                                              }{" "}
                                            </span>
                                          </li>
                                        </ul>
                                      </Col>
                                      <br />
                                    </Row>
                                    {/* <Col sm={6} md={12}> */}
                                  </Col>

                                  <Col sm={12}>
                                    <Row className="d-flex shadow-sm pt-2 pb-2">
                                      <ListGroup
                                        variant="flush"
                                        className="d-flex "
                                        style={{ borderLeft: "solid 1px gray" }}
                                      >
                                        <ListGroup.Item className="d-flex ">
                                          <p className="mb-2">Check in : </p>
                                          <strong>
                                            {" "}
                                            {this.parseDate(
                                              this.state.hotelRequest.params
                                                .CheckIn
                                            )}
                                          </strong>
                                        </ListGroup.Item>

                                        <ListGroup.Item className="d-flex ">
                                          <p className="mb-2">Check out : </p>
                                          <strong>
                                            {" "}
                                            {this.parseDate(
                                              this.state.hotelRequest.params
                                                .CheckOut
                                            )}
                                          </strong>
                                        </ListGroup.Item>
                                      </ListGroup>

                                      <ListGroup
                                        variant="flush"
                                        className="d-flex "
                                        style={{ borderLeft: "solid 1px gray" }}
                                      >
                                        <ListGroup.Item className="d-flex ">
                                          <p className="mb-2">
                                            Hotel Contact Phone :{" "}
                                          </p>
                                          <strong>
                                            {" "}
                                            {
                                              this.state.HotelSummary.content
                                                .phones[0].phoneNumber
                                            }{" "}
                                          </strong>
                                        </ListGroup.Item>

                                        <ListGroup.Item className="d-flex ">
                                          <p className="mb-2">
                                            Number of Rooms :{" "}
                                          </p>
                                          <strong>
                                            {" "}
                                            {
                                              this.state.hotelRequest.params
                                                .Rooms.length
                                            }
                                          </strong>
                                        </ListGroup.Item>
                                      </ListGroup>
                                    </Row>

                                    {this.state.roomsSelected.map(
                                      (room, index) => (
                                        <Row className=" shadow-sm pt-2 pb-2">
                                          <ListGroup
                                            variant="flush"
                                            className="d-flex "
                                            style={{
                                              borderLeft: "solid 1px gray",
                                            }}
                                          >
                                            <ListGroup.Item className="d-flex ">
                                              {" "}
                                              <h6>
                                                room {index + 1} :{" "}
                                                {room.boardName}{" "}
                                              </h6>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="d-flex ">
                                              {room.adults > 1 && (
                                                <span>
                                                  {" "}
                                                  {room.adults} Adult(s)
                                                </span>
                                              )}
                                              {room.adults === 1 && (
                                                <span>
                                                  {" "}
                                                  {room.adults} Adult
                                                </span>
                                              )}
                                              {room.children > 1 && (
                                                <span>
                                                  {" "}
                                                  , {room.children} Children
                                                </span>
                                              )}
                                              {room.children === 1 && (
                                                <span>
                                                  {" "}
                                                  , {room.children} Child
                                                </span>
                                              )}
                                            </ListGroup.Item>
                                            {room.rateComments && (
                                              <ListGroup.Item className="d-flex ">
                                                <h6>Rate Comment:</h6>
                                                <p>{room.rateComments}</p>
                                              </ListGroup.Item>
                                            )}
                                          </ListGroup>
                                          {room.cancellationPolicies && (
                                            <ListGroup
                                              variant="flush"
                                              className="d-flex "
                                              style={{
                                                borderLeft: "solid 1px gray",
                                              }}
                                            >
                                              <ListGroup.Item className="d-flex ">
                                                <h6>
                                                  Cancellation Policies :{" "}
                                                </h6>{" "}
                                              </ListGroup.Item>
                                              {room.cancellationPolicies.map(
                                                (policy) => (
                                                  <ListGroup.Item className="d-flex ">
                                                    <span>
                                                      From :{" "}
                                                      <strong>
                                                        {this.parseDate(
                                                          policy.from
                                                        )}{" "}
                                                      </strong>{" "}
                                                      Amount :
                                                      <strong>
                                                        {" "}
                                                        {
                                                          policy.localAmount
                                                        }{" "}
                                                        {this.state.currency}
                                                      </strong>
                                                    </span>
                                                  </ListGroup.Item>
                                                )
                                              )}
                                              <ListGroup.Item className="d-flex ">
                                                <p>
                                                  Note: The cancellation policy
                                                  date is based on destination
                                                  city.
                                                </p>
                                              </ListGroup.Item>
                                            </ListGroup>
                                          )}
                                        </Row>
                                      )
                                    )}
                                  </Col>
                                </>
                              )}
                              {/* </Col> */}
                            </Row>
                          </Col>

                          <Col
                            className="p-3"
                            style={{ borderLeft: "1px solid green" }}
                            sm={2}
                          >
                            <Card.Text className="text-muted" 
                          style={{textAlign: "end"}}>
                              {" "}
                              <h5> Hotel Price: </h5>
                            </Card.Text>
                            <Card.Text style={{ color: "green" }}>
                              {" "}
                              <strong>
                                {" "}
                                {this.state.hotelTotalPrice}{" "}
                                {this.state.currency}
                              </strong>
                            </Card.Text>
                          </Col>
                        </Row>
                      </Card.Body>
                      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
                    </Card>
                  )}

                <br />

                <Card className="text-center mb-3 shadow  bg-white rounded">
                  <Card.Footer
                    className="text-muted"
                    style={{ backgroundColor: "white" }}
                  >
                    <Row>
                      <Col sm={10}
                    style={{textAlign: "end"}}>
                        <h5>Total Price</h5>
                      </Col>

                      <Col style={{ borderLeft: "1px solid green" }} sm={2}>
                        <Card.Text style={{ color: "green" }}>
                          <strong>
                            {this.state.TotalPrice} {this.state.currency}
                          </strong>{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>

                <Row className="pt-2 pl-3">
                  <Col className="ml-3" style={{ textAlign: "left" }} sm={12}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      onChange={(value) =>
                        this.setState({ agreeTerms: !this.state.agreeTerms })
                      }
                      id="defaultCheck1"
                    />
                    <label class="form-check-label" for="defaultCheck1">
                      I agree to the{" "}
                    </label>
                    <Card.Link
                      onClick={() => this.setState({ showModal: true })}
                      style={{ color: "green", cursor: "pointer" }}
                    >
                      {" "}
                      Terms and Condition{" "}
                    </Card.Link>
                  </Col> 
                  <Col style={{ textAlign: "left"  }}  className=" mt-3" sm={12}>
                  {this.state.agreeTermsCommand &&
                    <Alert variant="danger">
                      Please agree the terms and condition to make payment.

                    </Alert>
                    }
                    {this.state.agreeTerms && (
                      <button  style={{ cursor:"pointer" }}
                        type="button"
                        class="btn btn-success "
                        onClick={() =>{
                          this.setState({agreeTermsCommand:false})
                           this.pay()
                          }}
                      >
                        <strong className="p-2 "> Make Payment</strong>
                      </button>
                    )}
                    {this.state.agreeTerms === false && (
                      <button type="button" 
                        onClick={() => {this.setState({agreeTermsCommand:true})}} 
                        style={{  cursor:"pointer" }} class="btn btn-success ">
                        <strong className="p-2 "> Make Payment</strong>
                      </button>
                    )}
                  </Col>
                </Row>

                <Modal
                  scrollable={true}
                  size="lg"
                  aria-labelledby="example-custom-modal-styling-title"
                  show={this.state.showModal}
                  onHide={() => this.setState({ showModal: false })}
                >
                  <Modal.Header closeButton className="p-1 pl-3 pt-0">
                    <Modal.Title>Terms and Condition</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <TermsAndCondition></TermsAndCondition>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default PaymentSummery;
