import React, { Component } from "react";
class FlightGenericException extends Component {
  state = {};
  render() {
    return (
      <div className="pt-5">
        <p className="h4">
          Sorry Unable to process your request please try again
        </p>
        <a className="btn mt-3 border" href="/">
          Try again
        </a>
      </div>
    );
  }
}

export default FlightGenericException;
