import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlane } from "@fortawesome/free-solid-svg-icons";
import FareOptions from "../fare-options";
import FlightHeader from "../flight-header";

class MulticityFlightInformationMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      flightInformationList: [],
      flightSearchContextResult: [],
      showFareOptions: false,
      flightInfo: null,
      flightIndex: 0,
      sessionId: null,
      screenWidth: null,
      exception: null,
      result: null,
      firstFlight: false,
    };
  }
  componentDidMount() {
    localStorage.setItem("totalFlighAmount", 0);
  }
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + "mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };

  async showFareOptions(flightInformation) {
    
    await this.setState({ itineraryParts: flightInformation });
    await this.setState({ showFareOptions: true });
  }
  async hideFareOptions() {
    await this.setState({ showFareOptions: false });
  }
  renderFareOptions = () => {
    return (
      <div>
        <div className="flight-options-header">
          <h5 className="text-center mt-3 d-inline-block">Fare Options</h5>
          <button
            className="btn float-right mt-2 mr-1"
            onClick={() => this.hideFareOptions()}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ color: "white", fontSize: "17px" }}
            />
          </button>
        </div>
        <FareOptions
          state={this.state.itineraryParts}
          onSearchContextClicked={this.props.onSearchContextClickedMob}
        />
      </div>
    );
  };
  render() {
    if (this.state.showFareOptions) {
      return <>{this.renderFareOptions()}</>;
    }
    return (
      <div className="mobile-widget">
        <div className="mt-4 mb-4">
          {/* <h5 className="text-center">Select Departure flight</h5> */}
          <FlightHeader flightIndex={this.state.flightIndex} />
        </div>
        {this.props.flightInfo.map((flightInfo, key) => (
          <div
            className="flight-info-multicity border"
            onClick={() => this.showFareOptions(flightInfo)}
            key={key}
            title="Click to select"
          >
            {flightInfo.itineraryParts.map(
              (itineraryPart, itineraryPartIndex) => (
                <div
                  className="flight-itinerary-container"
                  key={itineraryPartIndex}
                >
                  {itineraryPartIndex > 0 && <hr className="m-0" />}
                  <div>
                    <div className="flight-route">
                      {/* <div className="d-inline float-left fly-origin">DXB</div> */}
                      <div className="d-inline fly-duration">
                        {this.parseTime(itineraryPart.totalDuration)}{" "}
                      </div>
                      {/* <div className="d-inline float-right fly-destination">ADD</div> */}
                    </div>
                    <div className="flight-duration">
                      <div className="d-inline float-left fly-depart-time">
                        {(itineraryPart.segments.length>0)?this.parseDate(itineraryPart.segments[0].departure):""}
                      </div>
                      <div className="d-inline fly-duration">
                        <p className="path-left"> </p>
                      </div>
                      <div className="d-inline float-right fly-arrival-time">
                        {(itineraryPart.segments.length>0)?this.parseDate(
                          itineraryPart.segments[
                            itineraryPart.segments.length - 1
                          ].arrival
                        ):""}
                      </div>
                    </div>
                    <div className="row py-1 font-weight-bold">
                      <div className="col-4 col-sm-4">
                        <span>{(itineraryPart.segments.length>0)?itineraryPart.segments[0].origin:""}</span>
                      </div>
                      <div className="col-4 col-sm-4">
                        <p className="path-left">
                          {" "}
                          <FontAwesomeIcon
                            icon={faPlane}
                            style={{ color: "#adb5bd", fontSize: "18px" }}
                          />{" "}
                          <span className="flight-separtor-dot"></span>
                        </p>
                      </div>
                      <div className="col-4 col-sm-4">
                        {" "}
                        {(itineraryPart.segments.length>0)?
                          itineraryPart.segments[
                            itineraryPart.segments.length - 1
                          ].destination:""
                        }
                      </div>
                    </div>
                    {/* <div className="flight-price-info">
              <div className="d-inline float-right fly-arrival-time">
                <span className="pr-2 from-txt">from</span>
                <span className="price">{flightInfo.echeepPrice}</span>
              </div>
            </div> */}
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </div>
    );
  }
}
export default MulticityFlightInformationMob;
