import React, { Component } from "react";
import {
  faPlane,
  faAngleDown,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlightHeader from "../flight-header";
import FareOptionsLargeScreen from "../flight-result-lg-screen/fare-options-lg";
class MulticityFlightInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flightInfoList: [],
      selectedKey: null,
      showFareOptions: false,
      //   isEconomyBrandSelected: true,
      //   isBusinessBrandSelected: false,
      selectedBrand: "Eco",
    };
  }
  componentDidMount() {
    
    let flightInfo = this.props.flightInfo;
    localStorage.setItem("totalFlighAmount", 0);
  }

  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + "mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  getSelectedBrandInfoShoppingCode = (
    shoppingCode,
    arrivalDate,
    departDate
  ) => {
    
    this.props.onSearchContextClicked(shoppingCode, arrivalDate, departDate);
  };
  async showFareBrands(key, brand) {
    
    let fareDisplayStatus = document.getElementById("fare-option-row" + key)
      .style.display;
    if (
      brand === this.state.selectedBrand &&
      fareDisplayStatus !== "" &&
      fareDisplayStatus !== "none"
    ) {
      document
        .getElementById("fare-option-row" + key)
        .setAttribute("style", "display:none !important");
    } else {
      await this.setState({ selectedBrand: brand });

      let fareOptionsElmList = document.getElementsByClassName(
        "fare-options-row"
      );
      for (let i = 0; i < fareOptionsElmList.length; i++) {
        fareOptionsElmList[i].setAttribute("style", "display:none !important");
      }

      document
        .getElementById("fare-option-row" + key)
        .setAttribute("style", "display:flex !important");
    }
  }
  render() {
    
    localStorage.setItem("hotelTotalPrice", 0);
    return (
      <div className="flight-info-lg-container">
        <div className="row m-0">
          <FlightHeader flightIndex={this.props.flightIndex} />
          {/* <h5>
            Select Your Departure Flight from Addis Ababa (ADD), Ethiopia to
            Dubai (DXB), UAE
          </h5> */}
        </div>
        <div className="row flight-info-header m-0">
          <div className="col-md-4 col-lg-5 ">Time</div>
          <div className="col-md-3 col-lg-3 ">
            {" "}
            <FontAwesomeIcon
              icon={faClock}
              style={{
                color: "#545b62",
                fontSize: "25px",
                top: "2px",
                position: "relative",
              }}
              className="pr-2"
            />
            Duration
          </div>
          <div className="col-md-2 col-lg-2 ">Economy</div>
          <div className="col-md-3 col-lg-2 ">Business</div>
        </div>
        {this.props.flightInfo.map((flightInfo, key) => (
          <div key={key}>
            <div className="row flight-info-body">
              <div className="col-md-8 col-lg-8">
                {/* ###### itineraryPart start */}
                {flightInfo.itineraryParts.map(
                  (itineraryPart, itineraryPartIndex) => (
                    <div
                      className="itineraryPart-container"
                      key={itineraryPartIndex}
                    >
                      {itineraryPartIndex > 0 && <hr />}

                      <div class="row">
                        <div
                          className="col-md-8 col-lg-8"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <span className="pr-2">
                                {" "}
                                {(itineraryPart.segments.length>0)?this.parseDate(
                                  itineraryPart.segments[0].departure
                                ):""}
                              </span>{" "}
                              <span class="px-3"></span>
                              <span>
                                {" "}
                                {(itineraryPart.segments.length>0)?this.parseDate(
                                  itineraryPart.segments[
                                    itineraryPart.segments.length - 1
                                  ].arrival
                                ):""}
                              </span>
                            </div>
                          </div>
                          <div className="row py-3 font-weight-bold">
                            <div className="col-12">
                              <span className="pr-2">
                                {" "}
                                {(itineraryPart.segments.length>0)?itineraryPart.segments[0].origin:""}
                              </span>{" "}
                              <span class="px-2">
                                <FontAwesomeIcon
                                  icon={faPlane}
                                  style={{ color: "#adb5bd", fontSize: "18px" }}
                                  className="pr-2"
                                />{" "}
                              </span>
                              <span>
                                {" "}
                                {(itineraryPart.segments.length>0)?
                                  itineraryPart.segments[
                                    itineraryPart.segments.length - 1
                                  ].destination:""
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4 col-lg-4"
                          style={{ fontSize: "14px" }}
                        >
                          {" "}
                          {this.parseTime(itineraryPart.totalDuration)}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {/* ###### itineraryPart end */}
              </div>
              <div className="col-md-4 col-lg-4">
                <div class="row">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <button
                      className="btn select-eco-fare-option-btn"
                      onClick={() => this.showFareBrands(key, "Eco")}
                    >
                      {" "}
                      <div>
                        From{" "}
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          style={{
                            color: "#adb5bd",
                            fontSize: "30px",
                            position: "relative",
                            top: "7px",
                          }}
                          className="pr-2"
                        />
                      </div>{" "}
                      <div className="cheap-price-eco">
                        {flightInfo.echeepPrice}
                      </div>
                    </button>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <button
                      className={"btn select-bus-fare-option-btn"}
                      onClick={() => this.showFareBrands(key, "Bus")}
                    >
                      {" "}
                      <div>
                        From{" "}
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          style={{
                            color: "#adb5bd",
                            fontSize: "30px",
                            position: "relative",
                            top: "7px",
                          }}
                          className="pr-2"
                          id={"select-bus-fare-option-btn-dropdown" + key}
                        />
                      </div>{" "}
                      <div className="cheap-price-bus">
                        {flightInfo.bcheepPrice}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`row m-0 fare-options-row ${
                key === this.state.selectedKey ? "active" : ""
              }`}
              id={"fare-option-row" + key}
            >
              {flightInfo.brandOffers.map((brand, keyTwo) => (
                <FareOptionsLargeScreen
                  brand={brand}
                  selectedBrand={this.state.selectedBrand}
                  getSelectedBrandShoppingCode={
                    this.getSelectedBrandInfoShoppingCode
                  }
                  flightInformation={flightInfo}
                  key={keyTwo}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
export default MulticityFlightInformation;
